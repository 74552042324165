import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from './../../../environments/environment';
import { DataItem, Timeline, TimelineOptions } from 'vis-timeline';
import { InfoManagementService } from '../service/info-management.service';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { DataSet } from 'vis-data';
import { DatePipe } from '@angular/common';
import { DatasetOption } from '../model/DatasetOption';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfigService } from 'src/app/utils/config.service';

@Component({
  selector: 'app-aip-datasets',
  templateUrl: './aip-datasets.component.html',
  styleUrls: ['./aip-datasets.component.css']
})
export class AipDatasetsComponent implements OnInit {
  @ViewChild('visualization', { static: true }) visualization: ElementRef;
  configService = ConfigService;
  datasetPath: string;
  datePipe = new DatePipe("en-US");
  item: DataSet<DataItem> = new DataSet([]);
  datasetOption: DatasetOption = new DatasetOption();
  datasetOptionForm: UntypedFormGroup;

  groups = [
    {
      id: 1,
      content: 'SUP',
      order: 1
    },
    {
      id: 2,
      content: 'AIP',
      order: 2,
      subgroupStack: {
        'amdt': false,
        'UN': true
      },
      subgroupOrder: function (a, b) { return a.subgroupOrder - b.subgroupOrder; }
    }
  ];

  // subtract 60 days to show 2 AIRAC cycles in the past
  // min: Date = new Date(environment.min);
  // start: Date = new Date(environment.min);
  min: Date = new Date(this.configService.settings.visTimeLineOptions.min);
  start: Date = new Date(this.configService.settings.visTimeLineOptions.min);
  options: TimelineOptions = {
    stack: false,
    min: this.start.setDate(this.min.getDate() - 60), // TODO make it a server-side operational configuration property
    start: this.start.setDate(this.min.getDate() - 60),
    // end: environment.max, // TODO make it a server-side operational configuration property
    // max: environment.max, // TODO make it a server-side operational configuration property
    end: this.configService.settings.visTimeLineOptions.max, // TODO make it a server-side operational configuration property
    max: this.configService.settings.visTimeLineOptions.max, // TODO make it a server-side operational configuration property
    moment: function (date) {
      return moment(date).locale("en-gb").utc();
    },
    width: '100%',
    height: '230px',
    zoomMax: 315576000000, // 10 years
    zoomMin: 1000 * 60 * 60 * 24 // 24 hours
  };
  timeline: Timeline;

  constructor(private infoManagementService: InfoManagementService, private fb: UntypedFormBuilder) {
    this.datasetOptionForm = fb.group({
      date: ['', Validators.required],
      interpretation: [null],
      dataType: ['', Validators.required],
      exportFormat: ['', Validators.required],
      isFullDataset: ['', Validators.required]
    });
  }

  enableOrDisableInterpretation() {
    if (this.datasetOptionForm.get('dataType').value == 'changes') {
      this.datasetOptionForm.get('interpretation').disable();
      this.datasetOptionForm.get('interpretation').setValue(null);
    } else {
      this.datasetOptionForm.get('interpretation').enable();
    }
  }

  ngOnInit() {
    this.timeline = new Timeline(this.visualization.nativeElement, this.item, this.groups, this.options);

    this.timeline.on('click', function (properties) {
      if (properties != undefined) {
        // If item == null, it means user clicks on the timeline to select effective date
        if (properties.item == null) {
          this.datasetOptionForm.get('date').value = properties.time;
        } else if(properties.item.includes('PERMDELTA')) { // make only UN item clickable
          console.log(properties);
          // item id composed of featureid, unid and timeslice id
          this.infoManagementService.generateAipDatasetPerUN(
            properties.item.substring(properties.item.indexOf("_")+1, properties.item.lastIndexOf("_"))).subscribe(path => {
              this.datasetPath = path;
            });
        }
      }
    }.bind(this));
  }

  ngOnDestroy() {
    if (this.timeline != undefined) {
      this.timeline.off('click');
    }
  }

  generateAipDataset() {
    // clear the old items on the timeline
    this.item = new DataSet([]);
    this.datasetPath = "";
    
    this.infoManagementService.generateAipDataset(this.datasetOptionForm.getRawValue()).subscribe(data => {
      this.datasetPath = data.generatedDatasetPath;

      data.timeslices.forEach(ts => {
        if (ts.group == 2 && ts.content == 'Update Notice') {
          ts.subgroup = 'UN';
          ts.subgroupOrder = 0;
        } else if (ts.group == 2) {
          ts.subgroup = 'amdt';
          ts.subgroupOrder = 1;
        }
        this.item.add({
          id: ts.id, group: ts.group,
          content: ts.content, start: this.datePipe.transform(ts.start, 'yyyy-MM-dd'), end:
            (ts.subgroup == 'amdt' && ts.end == null) ? moment().add(new Date(environment.max).getFullYear(), 'years').format('yyyy-MM-DD')
              : this.datePipe.transform(ts.end, 'yyyy-MM-dd')
          , subgroup: ts.subgroup, subgroupOrder: ts.subgroupOrder
        } as DataItem);
      });

      this.timeline.setItems(this.item);
      this.timeline.fit();

    });
  }

  download() {
    let datasetPathPrefix = "file://";
    this.infoManagementService.downloadAipDataset(this.datasetPath.replace(datasetPathPrefix,"")).subscribe(blob => {
      saveAs(blob, this.datasetPath.substring(this.datasetPath.lastIndexOf("/") + 1));
    });
  }

}
