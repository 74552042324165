import { PropertyField } from './property-field';
import { PropertyGroup } from './property-group';
import { MultipleProperty } from './property';

export class CodeListPropertyField extends PropertyField<string> {
  controlType = 'dropdown';
  codeList: {key: string, label: string, shadowValue?:boolean}[] = [];

  constructor(options: {
    key: string,
    label?: string,
    hint?: string,
    description?: string,
    order?: number,
    minOccurs?: number,
    maxOccurs?: number,
    nil?: boolean,
    nilReason?: string,
    annotations?: MultipleProperty,
    value?: string,
    maxLength?: number,
    codeList: {key: string, label: string, description: string, shadowValue?: boolean}[],
    modified: boolean
  }) {
    super(options);
    this.codeList = options['codeList'] || [];
    if (!this.codeList.some(list => list.key === this.value)) {
      this.codeList.push({ key: this.value, label: this.value, shadowValue: true });
    }
  }

  setToNil() {
    this.value = '';
  }
}