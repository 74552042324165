import { GmlPoint } from "./GmlPoint";
import { GmlRadiusUom } from "./GmlRadiusUom";
/**
 * A geodetic circle. Points on the circle are at equal geodetic (= great circle) distance from the centre. So, the radius is
 * a distance on a great circle.
 */
export class GmlCircle {
  centerPoint: GmlPoint;
  radius: number;
  radiusUom: string;
  constructor(centrePoint: GmlPoint, radius: number, radiusUom: GmlRadiusUom) {
    this.centerPoint = centrePoint;
    this.radius = radius;
    this.radiusUom = radiusUom;
  }
}
