import { environment } from './../../environments/environment';
import { AppAuthGuard } from './../login/services/app.authguard';
import { DataProviderComponent } from './data-provider/data-provider.component';
import { NgModule } from '@angular/core';
import {RouterModule,Routes} from '@angular/router';
import { AixmProfilelistsComponent } from './aixm-profilelists/aixm-profilelists.component';
import { EditDataprofileComponent } from './edit-dataprofile/edit-dataprofile.component';
import { EditDataprofileVersionComponent } from './edit-dataprofile-version/edit-dataprofile-version.component';
import { NewProfileVersionComponent } from './new-profile-version/new-profile-version.component';
import { NewDataprofileComponent } from './new-dataprofile/new-dataprofile.component';
import { Layouts } from '../app.component';

const profileroutes: Routes = [
  {
    path: 'new-profile',
    component: NewDataprofileComponent,
    canActivate: [AppAuthGuard], 
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Data_Manager] }
  },
  {
    path: 'profile',
    component: AixmProfilelistsComponent,
    canActivate: [AppAuthGuard], 
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Data_Manager] }
  },
  {
    path: 'profile/id/:id',
    component: EditDataprofileComponent,
    canActivate: [AppAuthGuard], 
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Data_Manager]  }
  },
  {
    path: 'new-profile-version/id/:id',
    component: NewProfileVersionComponent,
    canActivate: [AppAuthGuard], 
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Data_Manager]  }
  },
  {
    path: 'edit-profile-version/profileId/versionId/:profileId/:versionId',
    component: EditDataprofileVersionComponent,
    canActivate: [AppAuthGuard], 
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Data_Manager]  }
  },

  {
    path: 'provider/:profileId/:versionId', 
    component: DataProviderComponent,
    canActivate: [AppAuthGuard], 
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Data_Manager]  } }
];

@NgModule({
  imports: [RouterModule.forChild(profileroutes)],
  exports: [RouterModule]
})
export class AixmProfileRoutingModule { }

