import { FlatTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Property, MultipleProperty } from '../model/property';
import { PropertyService } from '../property.service';
import { PropertyGroup } from '../model/property-group';
import { UntypedFormGroup } from '@angular/forms';
import { PropertyFieldControlService } from '../property-field-control.service';


/** Flat node with expandable and level information */
interface PropertyFlatNode {
  expandable: boolean;
  property: Property;
  level: number;
}

/**
 * @title Tree with flat nodes
 */
@Component({
  selector: 'tree-component',
  templateUrl: 'tree.component.html',
  styleUrls: ['tree.component.css'],
  providers: [PropertyService, PropertyFieldControlService]
})
export class TreeComponent {

  properties: Property[];
  aeroInputForm: UntypedFormGroup;

  ngOnInit() {
    this.aeroInputForm = this.pfcs.toFormGroup(this.properties);
    //this.groupString = this.pfcs.groupToString(this.properties);
  }

  private transformer = (p: Property, level: number) => {
    return {
      expandable: p instanceof PropertyGroup || p instanceof MultipleProperty,
        //|| !(typeof p.annotations === 'undefined'),
      property: p,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<PropertyFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    pFlatNode => pFlatNode.level, pFlatNode => pFlatNode.expandable,
    p => p.properties
  );

dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

constructor(private pfcs: PropertyFieldControlService, service: PropertyService) {
  this.dataSource.data = this.properties
}

hasChild = (_: number, node: PropertyFlatNode) => node.expandable;

stopPropagation(event: Event) {
  event.stopPropagation();
}
}

