import { Section } from './model/Section';
import { CreateQAProjectDto } from './model/CreateQAProjectDto';
import { ProcessStatus } from './model/processstatus';
import { ReviewComponent } from './review/review.component';
import { UserDetailsService } from './../login/services/UserDetails.service';
import { Component, OnInit } from '@angular/core';
import { Assignment } from './model/assignment'; import { User } from './model/user';
import { TaskService } from './service/task.service';
import {  MatDialog } from '@angular/material/dialog';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ClaimdialogComponent } from './claimdialog/claimdialog.component';
import { UnclaimdialogComponent } from './unclaimdialog/unclaimdialog.component';
import { UpdateNoticeService } from '../update-notice/service/updatenotice.service';
import { ConfigService } from '.././utils/config.service';
//import { KeycloakService } from '../login/services/keycloak.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverViewComponent implements OnInit {
  isDp: boolean = ConfigService.settings.isDataProvider;
  private taskId: string;
  private status: ProcessStatus;
  private assignedtasks: Assignment[] = [];
  private unassignedtasks: Assignment[] = [];
  public userName: string;
  public groupName: string;
  public num: any[];
  private project: CreateQAProjectDto;

  displayedColumns = ['date', 'task', 'title', 'actions'];
  assignedTask_dataSource = new MatTableDataSource<Assignment>(this.assignedtasks);
  unassignedTask_dataSource = new MatTableDataSource<Assignment>(this.unassignedtasks);

  constructor(private router: Router, private taskService: TaskService, private updatenoticeService: UpdateNoticeService,
    private userDetailsService: UserDetailsService, public dialog: MatDialog) { }

  ngOnInit() {
    this.getAssignedTask();
    this.getUnassignedTask();
    if (this.isDp) {
      this.getOrganization();
    }
    //this.observableSubscribeTest();
  }

  getAssignedTask(): void {
    this.taskService.findUserAssignmentTasks()
      .subscribe(assignedtasks => {
        this.assignedTask_dataSource.data = assignedtasks
      });
  }

  getUnassignedTask(): void {
    this.taskService.findUnassignedTaksByGroup()
      .subscribe(unassignedtasks => this.unassignedTask_dataSource.data = unassignedtasks);
  }

  openClaimDialog(name: string, id: string, assignee: string): void {
    let dialogRef = this.dialog.open(ClaimdialogComponent, {
      width: '500px',
      data: { name: name, id: id, assignee: assignee }
    });

    dialogRef.componentInstance.onAdd.subscribe((result) => {
      console.log("The result is " + JSON.stringify(result));
      if (JSON.stringify(result))
        this.ngOnInit();
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('After the dialog was closed:' + result);
    });
  }

  openUnclaimDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(UnclaimdialogComponent, {
      width: '500px',
      data: { name: name, id: id }
    });

    dialogRef.componentInstance.onAdd.subscribe((result) => {
      console.log("The result is " + JSON.stringify(result));
      if (JSON.stringify(result))
        this.ngOnInit();
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('After the dialog was closed:' + result);
      //this.result = result,
      this.ngOnInit();
    });

  }

  observableSubscribeTest(): void {
    this.taskService.observableSubscribeTest()
      .subscribe({
        next: num => this.num = num,
        error: err => console.error('Observer got an error: ' + err),
        complete: () => console.log('Observer got a complete notification'),
      });
  }
  openDialog(sectionId: string): void {
    let dialogRef = this.dialog.open(ReviewComponent, {
      width: '500px',
      data: { sectionId: sectionId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.ngOnInit();
    });
  }

  openTasks(): void {
    this.router.navigateByUrl('quality/task-list');
  }

  complete(sectionId: string): void {
    console.log("In component: Complete Function");

    this.taskService.complete({
      projectId: '', projectName: 'SampleProcess', hasDataInuput: null, hasTextEdition: null, has2ndReview: null,
      sections: [{ name: sectionId, currentActivity: '', assignee: '', taskId: 'open' } as Section]
    } as CreateQAProjectDto)
      .subscribe(section => this.taskId = section),
      console.log("Complete Task: " + this.taskId);
  }

  start(): void {
    console.log("In component: start Function");

    this.taskService.start({
      projectId: 'eb9302b7-149a-11e9-b128-7c67a21eada7', projectName: 'createQAProject', hasDataInuput: null, hasTextEdition: null, has2ndReview: null,
      sections: [{ name: 'GEN0.1', currentActivity: '', assignee: '' } as Section, { name: 'GEN0.2', currentActivity: '', assignee: '' } as Section,
      { name: 'GEN0.3', currentActivity: '', assignee: '' } as Section, { name: 'GEN0.4', currentActivity: '', assignee: '' } as Section,
      { name: 'GEN0.5', currentActivity: '', assignee: '' } as Section]
    } as CreateQAProjectDto)
      .subscribe(project => this.project = project),
      console.log("Started Project: " + this.project);
  }

  getCurrentStatus(sectionId: string): void {
    this.taskService.getCurrentStatus({ name: sectionId, currentActivity: '', assignee: '', taskId: '' } as Section)
      .subscribe(status => this.status = status);
    console.log("Current Status: " + status);
  }

  getOrganization(): void {
    this.updatenoticeService.getOrganization().
      subscribe(groupName => this.groupName = groupName);
  }

}
