<mat-card>
    <mat-card-title>
        Delete UpdateNotice
    </mat-card-title>
    <br>
    <ng-container *ngIf="updatenotice">
        <mat-card-subtitle>Temporality</mat-card-subtitle>
        <mat-card-content>
            <div [formGroup]="myGroup">
                <mat-form-field>
                    <mat-select placeholder="Select Temporality" [formControl]="temporalityControl" required>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let temp of temporality" [value]="temp">
                            {{temp.viewValue}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="temporalityControl.hasError('required')">Please choose a temporality</mat-error>
                    <mat-hint>{{temporalityControl.value?.viewValue}}</mat-hint>
                </mat-form-field>
            </div>
        </mat-card-content>
        <mat-card-subtitle>Last edited</mat-card-subtitle>
        <mat-card-content>
            <form class="example-form">
                <mat-form-field class="example-full-width-padding">
                    <input matInput placeholder="Last edited" value="">
                </mat-form-field>
            </form>
        </mat-card-content>
        <mat-card-subtitle>By</mat-card-subtitle>
        <mat-card-content>
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="ID" readonly="readonly">
                </mat-form-field>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="warn" class="example-btn-width-blue">Reopen</button>
        </mat-card-actions>
        <br>

        <div class="field">
            <div id="left">
              <mat-card-subtitle>Effective Date</mat-card-subtitle>
            </div>
            <div id="center">
              <mat-form-field>
                <input matInput [matDatepicker]="picker1" placeholder="Choose an effective date" [(ngModel)]="tempUN.effectiveDate">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <!-- <div *ngIf = "tempUn.temporality == 'Temporality.TEMPORARY'"> -->
            <div id="center">
              <mat-card-subtitle>Cancel</mat-card-subtitle>
            </div>
            <div id="center">
              <mat-form-field>
                <input matInput [matDatepicker]="picker2" placeholder="Choose a cancel date" [(ngModel)]="tempUN.cancelDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
            <!-- </div> -->
            <div id="center">
              <mat-card-subtitle>Due Date</mat-card-subtitle>
            </div>
            <div id="center">
              <mat-card-content>
                <form>
                  <mat-form-field>
                    <input matInput placeholder="It's Due Date" value="2018-12-31">
                  </mat-form-field>
                </form>
              </mat-card-content>
              <mat-card-actions>
                <button mat-raised-button color="primary" class="example-btn-width-blue">History</button>
            </mat-card-actions>
            <br>
            </div>
          </div>
          <br>
          <br>
          <br>
          <br>
       

        <mat-card-subtitle>Status</mat-card-subtitle>
        <mat-card-content>
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="updatenotice.status" readonly="readonly">
                </mat-form-field>
            </form>
        </mat-card-content>
        <br>

        <mat-card-subtitle>Title</mat-card-subtitle>
        <mat-card-content>
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <input matInput [(ngModel)]="updatenotice.status">
                </mat-form-field>
            </form>
        </mat-card-content>
        <br>

        <mat-card-subtitle>Description</mat-card-subtitle>
        <mat-card-content>
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <textarea matInput [(ngModel)]="updatenotice.description" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="8"></textarea>
                </mat-form-field>
            </form>
        </mat-card-content>
        <br>

        <mat-card-subtitle>Attachments</mat-card-subtitle>
        <mat-card-content>
            <table>
                <tr>
                    <td *ngFor="let item of uploader.queue">
                        <strong>{{ item.file.name }}</strong>
                    </td>
                </tr>
            </table>
        </mat-card-content>
        <mat-card-actions>
            <div class="form-group">
                <button mat-raised-button color="primary" class="example-btn-width-blue" onclick="document.getElementById('myFileInputField').click()">
                    Attach File
                </button>
                <input type="file" class="form-control" name="multiple" id="myFileInputField" ng2FileSelect [uploader]="uploader" style="display:none">
            </div>
        </mat-card-actions>
        <br>
    </ng-container>
</mat-card>

<!-- 
        <for V2 Feature>
        <mat-card-subtitle>Contributors</mat-card-subtitle>
      <mat-card-content>
          <form class="example-form">
              <mat-form-field class="example-full-width">
                <textarea matInput placeholder="Autosize textarea" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="8"></textarea>
              </mat-form-field>
            </form>
      </mat-card-content> -->

<!-- 
        <for V2 Feature>
        <i class="material-icons">&#xE924;</i>
            <i class="material-icons">&#xE152;</i>
          <mat-form-field class="example-full-width">
              <input matInput placeholder="Search">
            </mat-form-field>
          -->