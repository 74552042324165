<!-- For V2 Feature -->
<!-- <div class="demo-grid mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          Organization : Open #### Submitted ####
      </div>
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-6"></div>
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-2" [routerLink]="['/newupdatenotice']" style="background-color:green; padding: 1px;">
              <i class="material-icons">&#xE7F4;</i>New UpdateNotice
      </div>
  </div>
</div>

<div class="demo-grid mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <i class="material-icons">&#xE924;</i>
          <i class="material-icons">&#xE152;</i>
          <mat-form-field class="example-full-width">
              <input matInput placeholder="Search">
          </mat-form-field>
      </div>
  </div>
</div> -->

<div class="container">

  <mat-toolbar>
    <span>
      <h3 *ngIf="isDp"> List of Update Notices from {{groupName}} </h3>
      <h3 *ngIf="!isDp"> List of Update Notices </h3>
    </span>
    <span class="spacer"></span>
    <button mat-raised-button color="primary" class="button" [routerLink]="['/update/new']" style="cursor:pointer"
      *ngIf="isDp">
      New Update Notice
    </button>
  </mat-toolbar>

  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <mat-tab-group mat-stretch-tabs="false">
        <mat-tab label="Open Update Notices">
          <mat-table #table [dataSource]="updatenoticelist_dataSource" matSort matSortActive="effectiveDate"
            matSortDirection="asc" matSortDisableClear>

            <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
              <mat-cell *matCellDef="let updatenotice"> {{updatenotice.status}} </mat-cell>
            </ng-container>

            <!-- Title Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
              <mat-cell *matCellDef="let updatenotice">
                <a routerLink='/update/id/{{updatenotice.valueOfFeatureId}}'> {{updatenotice.name}} </a>
              </mat-cell>
            </ng-container>

            <!-- Due Date Column -->
            <ng-container matColumnDef="cancelDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Cancel date </mat-header-cell>
              <mat-cell *matCellDef="let updatenotice"> {{updatenotice.cancelDate | date: "dd MMM yy" | uppercase}}
              </mat-cell>
            </ng-container>

            <!-- Effective Date Column -->
            <ng-container matColumnDef="effectiveDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Effective date</mat-header-cell>
              <mat-cell *matCellDef="let updatenotice"> {{updatenotice.effectiveDate | date: "dd MMM yy" | uppercase}}
              </mat-cell>
            </ng-container>

            <!-- Temporality Column -->
            <ng-container matColumnDef="temporality">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Temporality </mat-header-cell>
              <mat-cell *matCellDef="let updatenotice"> {{updatenotice.temporality}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </mat-tab>
        <mat-tab label="Closed Update Notices">
          <mat-table #table [dataSource]="closed_updatenoticelist_dataSource" matSort>

            <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
              <mat-cell *matCellDef="let updatenotice"> {{updatenotice.status}} </mat-cell>
            </ng-container>

            <!-- Title Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
              <mat-cell *matCellDef="let updatenotice">
                <a routerLink='/update/id/{{updatenotice.valueOfFeatureId}}'> {{updatenotice.name}} </a>
              </mat-cell>
            </ng-container>

            <!-- Due Date Column -->
            <ng-container matColumnDef="cancelDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Cancel date </mat-header-cell>
              <mat-cell *matCellDef="let updatenotice"> {{updatenotice.cancelDate | date: "dd MMM yy" | uppercase}}
              </mat-cell>
            </ng-container>

            <!-- Effective Date Column -->
            <ng-container matColumnDef="effectiveDate">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Effective date</mat-header-cell>
              <mat-cell *matCellDef="let updatenotice"> {{updatenotice.effectiveDate | date: "dd MMM yy" | uppercase}}
              </mat-cell>
            </ng-container>

            <!-- Temporality Column -->
            <ng-container matColumnDef="temporality">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Temporality </mat-header-cell>
              <mat-cell *matCellDef="let updatenotice"> {{updatenotice.temporality}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </mat-tab>
      </mat-tab-group>


      <mat-card-footer>
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
        </mat-paginator>
      </mat-card-footer>


    </mat-card-content>
  </mat-card>

</div>