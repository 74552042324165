import { ClaimdialogComponent } from "./../claimdialog/claimdialog.component";
import { UnclaimdialogComponent } from "./../unclaimdialog/unclaimdialog.component";
import { CompleteReviewDialogComponent } from "./../complete-review-dialog/complete-review-dialog.component";
import {  MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Item } from "../model/item";
import { ActivatedRoute } from "@angular/router";
import { QualityProjectService } from "./../service/quality-project.service";
import { LogDialogComponentComponent } from "../log-dialog-component/log-dialog-component.component";
import { catchError, filter, map, mergeMap, switchMap, tap, toArray, withLatestFrom } from "rxjs/operators";
import { EMPTY, from, Observable, of, Subject } from "rxjs";
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: "app-review",
  templateUrl: "./review.component.html",
  styleUrls: ["./review.component.css"],
})
export class ReviewComponent implements OnInit {
  @Output() myEvent = new EventEmitter<String>();

  isQualityController = this.qualityProjectService.isQualityController;

  items: Item[];

  sections: Item[];

  itemsAtLaterSteps: number;

  totalItems: number;

  assignedItems: number;

  enableFourEyes$ = this.qualityProjectService.enableFourEyes$;

  projectId: string = this.route.snapshot.params.id;

  itemsSubject$: Subject<Item[]> = new Subject();

  items$: Observable<Item[]>;

  constructor(
    private qualityProjectService: QualityProjectService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private keycloackService: KeycloakService
  ) {}

  ngOnInit() {
    this.items$ = this.itemsSubject$.pipe(
      withLatestFrom(this.enableFourEyes$),
      switchMap(([items, enableFourEyes]) => {
        if (!enableFourEyes) {
          // If four-eyes is not enabled, return items with all claimable set to true
          return of(items.map((item) => ({ ...item, claimable: true })));
        }

        // If four-eyes is enabled, process each item
        return from(items).pipe(
          mergeMap((item) =>
            this.qualityProjectService.getLogOfAssignment(item.executionId).pipe(
              catchError((err) => {
                if (err) {
                  return EMPTY;
                }
              }),
              map((log) => {
                const textEditionClaimedLog = log.find((value) => {
                  return (value.action as unknown as string) === "CLAIM" && value.taskname === "Text edition";
                });
                const claimable =
                  textEditionClaimedLog?.assignee?.id !== this.keycloackService.getKeycloakInstance().subject;
                return { ...item, claimable } as Item;
              })
            )
          ),
          toArray(),
          map((result) =>
            result.sort((itemA, itemB) => {
              const trimAndLowerCase = (input: string) => {
                return input.trim().toLowerCase();
              };
              if (trimAndLowerCase(itemA.name) < trimAndLowerCase(itemB.name)) {
                return -1;
              }
              if (trimAndLowerCase(itemA.name) > trimAndLowerCase(itemB.name)) {
                return 1;
              }
              return 0;
            })
          )
        );
      })
      // tap((result) => console.log(result, "### RESULT ###"))
    );
  }

  @Input()
  set collectItems(items: Item[]) {
    if (items != undefined) {
      this.items = items.filter((item) => item.currentActivity == "Review section");
      this.itemsSubject$.next(this.items);
      this.itemsAtLaterSteps = items.filter((item) => item.currentActivity == "Done").length;
      this.totalItems = items.length;

      this.assignedItems = items.filter((item) => item.assignee != null).length;
    }
  }

  openClaimDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(ClaimdialogComponent, {
      width: "500px",
      data: { name: name, id: id },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap(() => this.myEvent.emit(this.projectId))
      )
      .subscribe();
  }

  openCompleteDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(CompleteReviewDialogComponent, {
      width: "500px",
      data: { name: name, id: id },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap(() => this.myEvent.emit(this.projectId))
      )
      .subscribe();
  }

  openUnclaimDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(UnclaimdialogComponent, {
      width: "500px",
      data: { name: name, id: id },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap(() => this.myEvent.emit(this.projectId))
      )
      .subscribe();
  }

  getLogOfAssignment(executionId: string) {
    this.dialog.open(LogDialogComponentComponent, {
      width: "600px",
      data: executionId,
    });
  }
}
