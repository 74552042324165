<div>

    <mat-dialog-content>
        Are you sure you want to delete
    </mat-dialog-content>
    <div mat-dialog-actions align="center">

        <!-- comment out for a while because of 'Property 'id' does not exist on type 'DialogData'.' error. -->
        <!-- <button mat-raised-button mat-button color="primary" class="example-btn-width" (click)="deleteGroup(data.id)">Ok</button> -->
        <button mat-raised-button mat-button color="primary" class="example-btn-width"
            (click)="Cancel()">Cancel</button>

    </div>
</div>