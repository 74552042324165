<mat-card>
  <mat-card-content>
    <form name="newUserForm" [formGroup]="form" (ngSubmit)="createNewUser(form.value)">
      <div class="space">
        User-Management/Roles
      </div>
      <div class="row">
        <h2>New User</h2>
      </div>
      <div class="justify">
        <mat-form-field>
          <input matInput placeholder="User Name" name="userName" formControlName="userName" required>
        </mat-form-field>
      </div>
      <div class="justify">
        <mat-form-field>
          <input matInput placeholder="New Password" name="password" [type]="hide ? 'password' : 'text'"
            formControlName="password" [formGroup]="form" required>
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
      <div class="justify">
        <mat-form-field>
          <input matInput placeholder="Password Confirmation" name="passwordConfirm" [type]="hide ? 'password' : 'text'"
            formControlName="confirmPassword" [formGroup]="form" required>
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="form.controls.confirmPassword.errors?.MatchPassword">
            Confirm Password does not match
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="justify">
      </div> -->
      <div class="justify">
        <mat-form-field>
          <input matInput placeholder="First Name" name="firstName" formControlName="firstName">
        </mat-form-field>
      </div>
      <div class="justify">
        <mat-form-field>
          <input matInput placeholder="Last Name" name="lastName" formControlName="lastName">
        </mat-form-field>
      </div>
      <div class="justify">
        <mat-form-field>
          <input matInput placeholder="Email" name="email" formControlName="email" required>
          <mat-error *ngIf="form.controls.email.errors?.email">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>
      </div>
      <mat-card-actions class="justify">
        <button mat-raised-button mat-button color="primary" class="example-btn-width"
          [disabled]="!form.valid || form.pristine">Create User</button>
        <button mat-raised-button mat-button color="primary" class="example-btn-width">Cancel</button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>