<div class="container">
  
  <mat-toolbar>
    <span><h3>{{logsheet.type}}: {{logsheet.subject}}</h3></span>
  </mat-toolbar> 
        
  <mat-toolbar>
    <span> Status: </span> <span class="half-spacer"></span>
    <span> {{logsheet.status}} </span> <span class="spacer"></span>
    <span> Number: </span> <span class="half-spacer"></span>
    <span> {{logsheet.id}} </span> <span class="spacer"></span>
    <span> Creation Date: </span> <span class="half-spacer"></span>
    <span> {{logsheet.id}} </span> <span class="half-spacer"></span>
    <button mat-raised-button class="example-btn-width space" (click)="close(logsheet)"
    *ngIf="logsheet.status === openStatus.valueOf()" color="warn">Close</button>
   <!-- <button mat-raised-button class="example-btn-width space" (click)="reopen(logsheet)" *ngIf="logsheet.status=='CLOSED'" color="primary">Reopen</button> -->
  </mat-toolbar> 

  <mat-card>
    
    <mat-card-header>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
        <mat-icon matSuffix (click)="applyFilter('');filter.value=''" style="cursor:pointer">clear</mat-icon>
      </mat-form-field>
      <span class="spacer"></span>
      <button mat-raised-button class="justify" (click)="openDialog()"  color="primary">Add Log Entry</button>
    </mat-card-header>
    
    <mat-card-content>  
      <mat-table #table  [dataSource]="logentries_dataSource" matSort>

              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
              
              <!--   Column -->
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> SN </mat-header-cell>
                <mat-cell *matCellDef="let logentry"> {{logentry.id}} </mat-cell>
              </ng-container>

              <!-- Author Column -->
              <ng-container matColumnDef="author">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Author </mat-header-cell>
                  <mat-cell *matCellDef="let logentry"> {{logentry.author}} </mat-cell>
                </ng-container>

              <!-- Description Column -->
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                <mat-cell *matCellDef="let logentry"> {{logentry.description}} </mat-cell>
              </ng-container> 

              <!-- Reference Column -->
              <ng-container matColumnDef="logSheetReference">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Reference </mat-header-cell>
                  <mat-cell *matCellDef="let logentry">
                  <a [routerLink]="['/logsheetref', logentry.logSheetReference]">{{logentry.logSheetReference}}</a><br/>
                  <div class="align" *ngIf="logentry.entryReference!=null">Log entry#{{logentry.entryReference}}</div>
                  </mat-cell>     
              </ng-container>
            
              <!-- Date Column -->
              <ng-container matColumnDef="creationDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
                <mat-cell *matCellDef="let logentry"> {{logentry.creationDate}} </mat-cell>
              </ng-container>
            
              
            
              <ng-container matColumnDef="edit_icon">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let logentry"><i class="material-icons" (click)="openEditDialog(logentry.id,logentry.description)" style="cursor:pointer">edit</i> </mat-cell>
              </ng-container>
            
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"> </mat-row>
            </mat-table>
    
    <mat-card-footer>     
            <mat-paginator #paginator
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 20]"
              [showFirstLastButtons]="true">
            </mat-paginator>
    </mat-card-footer> 
   
  </mat-card-content>
  </mat-card>
</div>