import { Component,ViewChild, OnInit } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '../../service/sidenav.service';

@Component({
selector: 'app-securelayout',
templateUrl :'./securelayout.component.html' ,
styleUrls: ['./securelayout.component.css']
})

export class SecureLayoutComponent{ 

@ViewChild('sidenav') public sidenav: MatSidenav;

constructor( private sidenavService: SidenavService) { }

ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }
}

