import { Pipe, PipeTransform } from "@angular/core";
import { Observable } from "rxjs";
import { FeatureSelectorService } from "../feature-selector/feature-selector.service";
import { filter, map } from "rxjs/operators";
import { Router } from "@angular/router";

@Pipe({
  name: "refUrl",
})
export class RefUrlPipe implements PipeTransform {
  constructor(
    private featureSelectorService: FeatureSelectorService,
    private router: Router
  ) {}
  transform(
    value: string,
    parentFeatureId: string,
    unId: string | undefined
  ): Observable<{ path: string; queryParams?: any }> {
    const targetFeatureId = value.replace("urn:uuid:", "");
    return this.featureSelectorService
      .getFeatureById(parentFeatureId, targetFeatureId, unId)
      .pipe(
        filter((response) => !!response),
        map((response) => {
          const isBroken = response?.statusCode === '404';

          // If the target reference feature is broken, set flag isBroken
          if (isBroken) {
            return { path: null, isBroken}
          }

          // If the reference property is in the same UN, construct link to redirect to the UN Page
          if (!!unId && (response?.unId === unId)) {
            const url = this.router.createUrlTree([
              "/info-management",
              targetFeatureId,
              response.unId,
              response.featureType,
              response.effectiveDate,
              true,
            ]);
            const result = url.toString();
            return { path: result };
          }

          // Otherwise construct link to the data browser page
          const tsId = `${response.tsId.interpretation}-${response.tsId.sequenceNumber}-${response.tsId.correction}`;
          const url = this.router.createUrlTree([
            "/info-management",
            response.featureType,
            targetFeatureId,
          ]);

          return {
            path: url.toString(),
            queryParams: { from: "browser", unId: response.unId, tsId },
          };
        })
      );
  }
}
