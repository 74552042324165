import { UserDetailsService } from './../../login/services/UserDetails.service';
import { QualityProjectService } from '../service/quality-project.service';
import { Component, OnInit, Inject } from '@angular/core';
import {  MatPaginator } from '@angular/material/paginator';
import { Assignment } from './../model/assignment';
import {  MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {

  assignmentlist: Assignment[] = [];
  assignment: Assignment;
  private result: string;

  displayedColumns = ['taskId', 'taskName', 'claim_button'];
  tasklist_dataSource = new MatTableDataSource<Assignment>(this.assignmentlist);
  constructor(private qaService: QualityProjectService, private userDetailsService: UserDetailsService) { }

  ngOnInit() {
   this.getTasks();
  }

  getTasks(): void {
    const id = "eb9302b7-149a-11e9-b128-7c67a21eada7";
    this.qaService.getTaskList(id)
    .subscribe(assignmentlist => this.tasklist_dataSource.data = assignmentlist);
  }

  claim(taskId: string): void {
    console.log("In component: Claim Function");
   
    this.qaService.claim(taskId)
    .subscribe(result => this.result = result), 
    alert("Claimed Task: " + this.result);
  }
}