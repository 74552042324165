<!-- For V2 Feature -->
<!-- <div class="demo-grid mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          Organization : Open #### Submitted ####
      </div>
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-6"></div>
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-2" [routerLink]="['/newupdatenotice']" style="background-color:green; padding: 1px;">
              <i class="material-icons">&#xE7F4;</i>New UpdateNotice
      </div>
  </div>
</div>

<div class="demo-grid mdc-layout-grid">
  <div class="mdc-layout-grid__inner">
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <i class="material-icons">&#xE924;</i>
          <i class="material-icons">&#xE152;</i>
          <mat-form-field class="example-full-width">
              <input matInput placeholder="Search">
          </mat-form-field>
      </div>

  </div>
</div> -->

<div class="container">
  <mat-toolbar>
    <span>
      <h3> List of Update Notices</h3>
    </span>
    <span class="spacer"></span>
    <button mat-raised-button color="primary" class="button" [routerLink]="['/new-updatenotice']" style="cursor:pointer">
      <i class="material-icons">&#xE7F4;</i>New UpdateNotice
    </button>
  </mat-toolbar>

  <mat-card>
    <mat-card-content>
      <mat-table #table [dataSource]="updatenoticelist_dataSource" matSort>

        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Status Column -->
        <ng-container matColumnDef="Status">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
          <mat-cell *matCellDef="let updatenotice"> {{updatenotice.status}} </mat-cell>
        </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="Title">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
          <mat-cell *matCellDef="let updatenotice">
            <a routerLink='/edit-updatenotice/{{updatenotice.id}}'> {{updatenotice.title}}</a>
          </mat-cell>
        </ng-container>

        <!-- Due Date Column -->
        <ng-container matColumnDef="Due date">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Due date </mat-header-cell>
          <mat-cell *matCellDef="let updatenotice"> {{updatenotice.due_date}} </mat-cell>
        </ng-container>

        <!-- Effective Date Column -->
        <ng-container matColumnDef="Effective date">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Effective date</mat-header-cell>
          <mat-cell *matCellDef="let updatenotice"> {{updatenotice.effective_date}} </mat-cell>
        </ng-container>

        <!-- Temporality Column -->
        <ng-container matColumnDef="Temporality">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Temporality </mat-header-cell>
          <mat-cell *matCellDef="let updatenotice"> {{updatenotice.temporality}} </mat-cell>
        </ng-container>

        <!-- Providers Column -->
        <ng-container matColumnDef="Providers">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Provider </mat-header-cell>
          <mat-cell *matCellDef="let updatenotice"> {{updatenotice.provider} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

    </mat-card-content>

    <mat-card-footer>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-footer>
  </mat-card>
</div>