import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigService } from '../../utils/config.service';

@Injectable({
  providedIn: 'root'
})
export class AboutService {

  private apiServer = ConfigService.settings.apiServer;
  private faimServerUrl=this.apiServer.url+'/about/faimServerVersion';  
  private faimClientUrl=this.apiServer.url + '/about/faimWebClientVersion'
  //private faimServerUrl='api/faimServerVersion';

  constructor(private http:HttpClient) { }

  getFaimServerVersion():Observable<string>{
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    return this.http.get<string>(this.faimServerUrl,requestOptions)
    .pipe(tap(data => console.log(data),
    error => catchError(this.handleError('getFaimServerVersion', error))
  ));
    
  }

getFaimWebClientVersion():Observable<string>{
  let requestOptions: Object = {
    /* other options here */
    responseType: 'text'
  }
  return this.http.get<string>(this.faimClientUrl,requestOptions)
  .pipe(tap(data => console.log(data),
  error => catchError(this.handleError('getFaimWebClientVersion',error))
  ));
}



  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
 private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {
    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead  
    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}
}
