<div class="container">
  <mat-toolbar>
    <span>
      <h3> List of User Properties </h3>
    </span>
    <span class="spacer"></span>
    <button mat-mini-fab color="primary" matTooltip="Add user property" [routerLink]="['/new-userproperties']">
      <mat-icon aria-label="add">add</mat-icon>
    </button>
  </mat-toolbar>


  <mat-card>
    <mat-card-content>
      <mat-table #table [dataSource]="userPropertieslist_dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!--Module Column -->
        <ng-container matColumnDef="modulename">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Module Name</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a routerLink='/edit-userproperties/{{row.id}}'>{{row.modulename}}</a>
          </mat-cell>
        </ng-container>

        <!-- Property Column -->
        <ng-container matColumnDef="property">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Property </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.property}}
          </mat-cell>
        </ng-container>

        <!-- For Property Value Column -->
        <ng-container matColumnDef="propertyvalue">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Property Value </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.propertyvalue}}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card-content>

    <mat-card-footer>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-footer>

  </mat-card>
</div>