import { ConfigService } from './../utils/config.service';
import { UserDetailsService } from './../login/services/UserDetails.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AccessControlService {
    constructor(private http: HttpClient) { }

    checkUserIsDP(checkDPUrl: string): Observable<boolean> {
        return this.http.get<boolean>(checkDPUrl).pipe(
            tap(result => {
            }, error => catchError(this.handleError<boolean>(`checkUserIsDP`))
            ));
    }

    /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead  
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
