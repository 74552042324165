import { UserManageMentService } from './../service/user-management.service';
import { GroupSummaryDTO } from './../model/groupSummaryDTO';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditGroupDTO } from '../model/editGroupDTO';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import {  MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.css']
})
export class GroupDetailComponent implements OnInit {
  private groupId: string;
  public group: GroupSummaryDTO = new GroupSummaryDTO();
  private editGroup: EditGroupDTO = new EditGroupDTO();
  private selected_value: string[] = [];
  editForm: UntypedFormGroup;

  constructor(private userManageMentService: UserManageMentService, private route: ActivatedRoute,
    public dialog: MatDialog, public fb: UntypedFormBuilder) {
    this.editForm = fb.group({
      gpName: ['', Validators.required],
    })
  }

  ngOnInit() {
    //console.log("The group name is "+ this.group.groupName)
    this.route.params.subscribe(params => {
      this.groupId = params.id;
    });
    this.getGroupById(this.groupId);
    //this.editForm.get('gpName').setValue(this.group.groupName);

  }

  getGroupById(groupId: string): void {
    console.log("getUserById: ID " + this.groupId)
    this.userManageMentService.getGroupById(groupId)
      .subscribe(group => {
        this.group = group,
          console.log("Result: " + this.group.groupName);
      });
  }

  updateGroup(editFormValue): void {
    /*     console.log("The user in service is "+ this.user.firstName +""+
        this.user.userName+""+this.user.lastName+""+this.user.password); */
    let editGroup: EditGroupDTO = {
      groupName: editFormValue.gpName,
      id: this.group.id
    }
    console.log("The group in service is " + this.group.groupName);
    this.userManageMentService.updateGroup(editGroup)
      .subscribe(result => {
        //this.result = result,    
        let dialogRef = this.dialog.open(SuccessDialogComponent, {
          width: '500px',
          data: { result }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          console.log('After the dialog was closed:' + result);
          //this.result = result,
          //this.ngOnInit();
        });
        //alert("Created Project: " + result);
      });
  }

  onSelection(e, v) {
    let current_selected_value: string[] = [];
    for (let a of v) {
      current_selected_value.push(a.value);
      this.selected_value = current_selected_value;
    }

    if (v == '') {
      this.selected_value = [];
    }
    console.log("selected_role: " + this.selected_value);
  }

  assignRole(): void {
    console.log("assignRole: group ID " + this.groupId)

    this.userManageMentService.assignRole(this.groupId, this.selected_value)
      .subscribe(groupId => {
        this.groupId = groupId,
          this.getGroupById(this.groupId);
        console.log("The updated groupId: " + groupId);
      });
  }

  unassignRole(): void {
    console.log("unassignRole: group ID " + this.groupId)

    this.userManageMentService.unassignRole(this.groupId, this.selected_value)
      .subscribe(groupId => {
        this.groupId = groupId,
          this.getGroupById(this.groupId);
        console.log("The updated groupId: " + groupId);
      });
  }

}