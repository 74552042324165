{
  "name": "faim-web-ng",
  "version": "2025.4.1",
  "license": "UNLICENSED",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "prebuild": "npm --no-git-tag-version version patch",
    "build": "ng build --configuration production",
    "test": "jasmine",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/material": "^18.2.14",
    "@angular/material-moment-adapter": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@piwikpro/ngx-piwik-pro": "^2.1.0",
    "@progress/kendo-data-query": "^1.5.2",
    "@types/arcgis-rest-api": "^10.4.5",
    "@types/file-saver": "^2.0.5",
    "@types/geojson": "^7946.0.8",
    "@types/hammerjs": "^2.0.41",
    "@types/topojson-specification": "^1.0.2",
    "angular-in-memory-web-api": "^0.18.0",
    "chart.js": "^4.4.8",
    "core-js": "^3.7.0",
    "dms-conversion": "^3.1.1",
    "error-stack-parser": "^2.0.6",
    "file-saver": "^2.0.5",
    "flatpickr": "^4.6.6",
    "geojson": "^0.5.0",
    "hammerjs": "^2.0.8",
    "keycloak-angular": "^16.1.0",
    "keycloak-js": "^26.0.0",
    "moment": "^2.24.0",
    "moment-es6": "^1.0.0",
    "moment-timezone": "^0.5.32",
    "ng2-charts": "^7.0.0",
    "ng2-file-upload": "^7.0.1",
    "ngx-csv": "^0.3.2",
    "ol": "^7.5.2",
    "operators": "^1.0.0",
    "rxjs": "~7.5.0",
    "sass": "^1.49.0",
    "tslib": "^2.3.0",
    "uuid": "^8.3.2",
    "vis-data": "^7.1.4",
    "vis-network": "^9.1.0",
    "vis-timeline": "^7.5.0",
    "vis-util": "^5.0.2",
    "xss": "^1.0.11",
    "yarn": "^1.21.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.14",
    "@angular/cli": "^18.2.14",
    "@angular/compiler-cli": "^18.2.13",
    "@types/jasmine": "~3.10.0",
    "@types/node": "^12.11.1",
    "jasmine-core": "~4.0.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "typescript": "~5.4.5"
  },
  "optionalDependencies": {
    "fsevents": "^2.2.1"
  }
}
