<mat-toolbar color="primary">
  <button mat-icon-button (click)="toggleRightSidenav()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>

  <!-- <div class="currentPage" [innerHTML]="currentPageTitle"></div> -->

  <div class="customer">
    <h2>Facilis.aero AIM | {{tenantDisplayName}}</h2>
    <!-- Facilis.aero AIM (beta version: not for operatonal use) -->
  </div>

  <!-- <div class="service">
    <div> A service of</div>
    <div><i class="material-icons">&#xE332;</i>Facilis.aero</div>
  </div> -->

  <span class="spacer"></span>

  <div class="small-font-size">Welcome: {{username}}</div>

  <div class="user-small">
    <span>
      <button mat-icon-button [matMenuTriggerFor]="helpMenu" matTooltip="Help">
        <mat-icon>help</mat-icon>
      </button>
    </span>
    <mat-menu #helpMenu="matMenu">
      <button mat-menu-item (click)="openHelp()">Help</button>
      <button mat-menu-item onclick="window.open('https://forum.facilis.aero/')">Forum</button>
    </mat-menu>
  </div>

  <div class="user-small">
    <span>
      <button mat-icon-button [matMenuTriggerFor]="settingMenu" matTooltip="Settings">
        <mat-icon>settings</mat-icon>
      </button>
    </span>
    <mat-menu #settingMenu="matMenu">
      <button mat-menu-item [routerLink]="'/datastore'" *ngIf="hasFOModuleRole()">Switch datastore</button>
      <button mat-menu-item [routerLink]="'/userproperties'" *ngIf="hasFOModuleRole()">User Settings</button>
      <button mat-menu-item [routerLink]="'/applicationproperties'" *ngIf="hasFOModuleRole()">Application
        Settings</button>
      <button mat-menu-item [routerLink]="'/about'">About</button>
    </mat-menu>
  </div>

  <div class="user-small">
    <span>
      <button type="button" mat-icon-button [matMenuTriggerFor]="userMenu" matTooltip="Account">
        <mat-icon>account_circle</mat-icon>
      </button>
    </span>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item [routerLink]="'/account-management'">Account</button>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div>
</mat-toolbar>