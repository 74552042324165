  <mat-toolbar>
    <span>
      <h3> New Data Profile Version</h3>
    </span>
  </mat-toolbar>
  <mat-card>
      <mat-card-content>
        <form name="newVersionForm" [formGroup]="form" (ngSubmit)="createNewVersion(form.value)" class="example-form">

        <div class ="attached">
         <mat-label>Attachments</mat-label>
        </div>
         <div class=" selectedfiles file" *ngFor="let fileName of fileNames">
           <a href="#" matTooltip="Download File"><label style="cursor:pointer" (click)="download(fileName)"> {{fileName}} </label></a>
            <button mat-icon-button (click)="delete(fileName)">
              <i class="material-icons">delete</i></button>
          </div>
          <div class="selectedfiles file" *ngFor="let fileName of fileNamesToDelete" [hidden]="!isDeleted">
            <label class="strikethrough"> {{fileName}} </label>
          
            <!--  <div class="file"> -->
            <button type="button" matTooltip="Undo deleted Attachment(s)" class="material-icons undo" (click)="unDo(fileName)"
            *ngIf="isDeleted" [disabled]="!isDeleted || isSaved">
            &#xe166;</button>
            <!-- </div> -->
          </div>
          <div class="spacer" >
            <input type="file" hidden ng2FileSelect #file [uploader]="uploader" multiple />
            <button type="button" matTooltip="Add Attachment" class="material-icons" (click)="file.click()">
              &#xE226;</button>
          </div>
          <br>
          <div class="next-selectedfiles" *ngFor="let item of uploader.queue">
            {{ item?.file?.name }}
            <button mat-icon-button (click)="remove(item)">
              <i class="material-icons">delete</i></button>
          </div>
          <br/>
          
          <!--Description-->
          <div class="left">
            <mat-label>Description</mat-label>
          </div>
          <mat-form-field class="space" style="width:45%">
             <textarea matInput matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="4" name="description"
                formControlName="description"></textarea>
          </mat-form-field>
          <br/>
          <br/>

          <!-- [ngClass]="{'spacer':fileNamesToDelete.length>0 || fileNames.length>0,'space':fileNames.length==0}" -->

      
          <mat-card-actions class="buttonspace">
            <button  mat-raised-button mat-button color="primary" [disabled]="!form.valid || form.pristine"  
            class="example-btn-width" >Create Profile Version</button>  
            <button mat-raised-button mat-button color="primary" type="reset" (click)="cancel()">
              Reset
            </button>            
          </mat-card-actions>
   
  
          </form>
         
      </mat-card-content>
     
  </mat-card>
