import { AppAuthGuard } from './../login/services/app.authguard';
import { environment } from './../../environments/environment';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LogSheetComponent } from './log-sheet-list/log-sheet-list.component';
import { LogentrylistComponent } from './log-entry-list/log-entry-list.component';
import { Layouts } from '../app.component';

const routes: Routes = [
  {
    path: 'log-sheet', component: LogSheetComponent,
    canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_LS_Module] }
  },
  {
    path: 'logentrylist/:id', component: LogentrylistComponent,
    canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_LS_Module] }
  },
  {
    path: 'logsheetref/:id', component: LogSheetComponent,
    canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_LS_Module] }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LogSheetRoutingModule { }
