<h3 mat-dialog-title>Would you like to claim task {{data.name}} ?</h3>
<div mat-dialog-actions>

     <button mat-raised-button mat-button color="primary" class="example-btn-width" (click)="claim(data.id)"
          style="cursor:pointer">
          Claim
     </button>

     <button mat-raised-button mat-button color="primary" class="example-btn-width" mat-dialog-close>Cancel</button>

</div>