<mat-toolbar>
  <span>
    <h3 style="align-items: center; display: flex">FAIVA:
      <img src="assets/images/FABEC-logo.png" height="38" style="margin: 0 10px 0 10px" /> Aeronautical Information
      Validator
    </h3>
  </span>
</mat-toolbar>

<mat-card>
  <mat-card-content>
    <div>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
        <mat-icon matSuffix (click)="applyFilter('');filter.value=''" style="cursor:pointer">clear</mat-icon>
      </mat-form-field>
    </div>
    <mat-table #table [dataSource]="validationHistory_DataSource">

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
        <mat-cell *matCellDef="let history">
          {{history.date}}
        </mat-cell>
      </ng-container>

      <!-- Result Column -->
      <ng-container matColumnDef="result">
        <mat-header-cell *matHeaderCellDef> Result </mat-header-cell>
        <mat-cell *matCellDef="let history"> <a routerLink="" (click)="redirectToValidationReport(history.name)"
            [ngStyle]="{'color': (history.result.toUpperCase() === 'VALID') ? 'green' : (history.result.toUpperCase() === 'INVALID' || history.result.toUpperCase() === 'FAILED') ? 'red' : (history.result.toUpperCase() === 'PENDING') ? 'blue' : ''}">
            {{transformValidationReportStatus(history.result)}}
          </a></mat-cell>
      </ng-container>

      <!-- Dataset Column -->
      <ng-container matColumnDef="dataset">
        <mat-header-cell *matHeaderCellDef> Dataset </mat-header-cell>
        <mat-cell *matCellDef="let history"> {{history.dataset}} </mat-cell>
      </ng-container>

      <!-- Version Column -->
      <ng-container matColumnDef="version">
        <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
        <mat-cell *matCellDef="let history"> {{history.version}} </mat-cell>
      </ng-container>

      <!-- Profiles Column -->
      <ng-container matColumnDef="profiles">
        <mat-header-cell *matHeaderCellDef> Profiles </mat-header-cell>
        <mat-cell *matCellDef="let history"> {{history.schema}} </mat-cell>
      </ng-container>

      <!-- Business Rules Column -->
      <ng-container matColumnDef="businessRules">
        <mat-header-cell *matHeaderCellDef> Business Rules </mat-header-cell>
        <mat-cell *matCellDef="let history"> {{history.bizrule}} </mat-cell>
      </ng-container>

      <!-- Certificate Column -->
      <ng-container matColumnDef="certificate">
        <mat-header-cell *matHeaderCellDef> Certificate </mat-header-cell>
        <mat-cell *matCellDef="let history" style="cursor:pointer;" (click)="getCertificate(history.certificate)"
          [ngStyle]="{'color': 'blue'}">
          {{history.certificate === '' ? '' : 'Download'}} </mat-cell>
      </ng-container>

      <mat-header-row class="header" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <br />

    <mat-card-footer>
      <mat-paginator #paginator [pageSize]="50" [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons>
      </mat-paginator>
    </mat-card-footer>

  </mat-card-content>
</mat-card>

<div class="space" style="font-size: smaller">FAIVA is a <a href="https://www.fabec.eu/">FABEC</a> project developed and
  hosted by
  <a href="https://www.nilacandi.com/">Nilacandi</a> on Facilis.aero.
</div>