<div class="container">
    <mat-toolbar>
      <span>
        <h3> New Data Profile</h3>
      </span>
    </mat-toolbar>
    <mat-card>
        <mat-card-content>
       <form name="newProfileForm" [formGroup]="form" (ngSubmit)="addProfile(form.value)" class="example-form">
            
          <label class="space label">Profile Name </label>
             
          <mat-form-field class="space">
            <input matInput  name="name" formControlName="name" [formGroup]="form"  required>
          </mat-form-field>
     
      <br/>
      <br/>
      <label class="space label"> Description </label>
             
      <mat-form-field class="space1">
        <input matInput  name="description" formControlName="description" [formGroup]="form" required>
      </mat-form-field>
 
        <br/>
        <br/>

        <label class="space label"> Schema File </label>
             
        <input type="file" matTooltip="Add Attachment" hidden ng2FileSelect #file [uploader]="uploader" multiple required/>
        <button type="button" class="material-icons spacer" (click)="file.click()">
            &#xE226;</button>     
   
          <br/>
          <br/>
          <div class="selectedfiles">
            <div *ngFor="let item of uploader.queue" height="300">
              {{ item?.file?.name }}
              <button mat-icon-button (click)="remove(item)">
                <i class="material-icons">delete</i></button>
            </div>
          </div>
          <br/>
            <mat-card-actions class="buttonspace">
              <button type="submit" mat-raised-button mat-button color="primary" [disabled]="!form.valid || form.pristine"  
              class="example-btn-width" >Create Profile</button>  
              <button mat-raised-button mat-button color="primary" type="reset" (click)="clear()">
                Reset
              </button>            
            </mat-card-actions>
        </form> 
       
        </mat-card-content>
       
    </mat-card>
