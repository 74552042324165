import { User } from './../model/user.model';

import { EditUserDTO } from './../model/EditUserDTO';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { UserManageMentService } from '../service/user-management.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  private userId: string;
  user: EditUserDTO = new EditUserDTO();
  changed: boolean = false;
  roleList: string[] = ['admin', 'user', 'manager', 'ROLE_DATAPROVIDER'];
  private selected_value: string[] = [];
  hide = true;
  form: UntypedFormGroup;

  constructor(private userManageMentService: UserManageMentService, private route: ActivatedRoute, public fb: UntypedFormBuilder, public dialog: MatDialog) {
    this.form = fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userId = params.id;
    });

    this.getUserById(this.userId);
  }

  detectChange(): void {
    this.changed = true;
    console.log(this.changed);
  }

  saveUser(): void {
    console.log("user: " + this.user)
    this.userManageMentService.saveUser(
      {
        id: this.user.id, userName: this.user.userName, firstName: this.user.firstName, lastName: this.user.lastName,
        email: this.user.email, enabled: this.user.enabled
      }
    )
      .subscribe(userId => {
        userId = userId,
          this.ngOnInit();
        console.log("The edited userId: " + userId);
      });
  }

  onSelection(e, v) {
    let current_selected_value: string[] = [];
    for (let a of v) {
      current_selected_value.push(a.value);
      this.selected_value = current_selected_value;
    }

    if (v == '') {
      this.selected_value = [];
    }
    console.log("selected_role: " + this.selected_value);
  }

  assignRole(): void {
    console.log("assignRole: user ID " + this.userId)

    this.userManageMentService.assignRole(this.userId, this.selected_value)
      .subscribe(userId => {
        userId = userId,
          this.getUserById(this.userId);
        console.log("The updated userId: " + userId);
      });
  }

  unassignRole(): void {
    console.log("unassignRole: user ID " + this.userId)

    this.userManageMentService.unassignRole(this.userId, this.selected_value)
      .subscribe(userId => {
        userId = userId,
          this.getUserById(this.userId);
        console.log("The updated userId: " + userId);
      });
  }

  joinGroup(): void {
    console.log("joinGroup: user ID " + this.userId)

    this.userManageMentService.joinGroup(this.userId, this.selected_value[0])
      .subscribe(userId => {
        userId = userId,
          this.getUserById(this.userId);
        console.log("The updated userId: " + userId);
      });
  }

  leaveGroup(): void {
    console.log("unassignRole: user ID " + this.userId)

    this.userManageMentService.leaveGroup(this.userId, this.selected_value[0])
      .subscribe(userId => {
        userId = userId,
          this.getUserById(this.userId);
        console.log("The updated userId: " + userId);
      });
  }

  getUserById(userId: string): void {
    console.log("getUserById: ID " + this.userId)

    this.userManageMentService.getUserById(userId)
      .subscribe(user => {
        this.user = user,
          console.log("Result: " + this.user.userName);
      });
  }

  resetPassword(formValue): void {
    let user: User = {

      password: formValue.password,
      passwordConfirm: formValue.confirmPassword,

    }
    this.userManageMentService.resetPasswordById(this.userId, user)
      .subscribe(result => {
        console.log("The result is " + result)
        //this.result = result,
        let dialogRef = this.dialog.open(SuccessDialogComponent, {
          width: '500px',
          data: { result }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          console.log('After the dialog was closed:' + result);
          //this.result = result,
          //this.ngOnInit();
        });
        //alert("Created Project: " + result);
      });

  }
}
