import { GmlRadiusUom } from "./GmlRadiusUom";
import { GmlCircle } from "./GmlCircle";
import { GmlPoint } from "./GmlPoint";
import { GmlInterpolation } from "./GmlInterpolation";
import { GmlSegment } from "./GmlSegment";
import { AngleDirection } from './angle-direction.enum';

export class ArcByCentre extends GmlSegment {
  baseCircle: GmlCircle;
  startAngle: number;
  endAngle: number;

  direction: AngleDirection;

  static readonly METERS_PER_NAUTICAL_MILE = 1852;

  /**
   * Build an ArcByCentre based on 2 geographic points, by calculating start and end angles.
   * 
   * @param startPoint
   * @param endPoint 
   * @param centrePoint
   * @param direction
   */
  constructor(startPoint: GmlPoint, endPoint: GmlPoint, centrePoint: GmlPoint, direction: AngleDirection) {
    super(GmlInterpolation.ARC_BY_CENTRE, startPoint, endPoint);

    this.direction = direction;
    //arc.radiusUom = radiusUom;

    // the rest needs to be calculated
    let radius: number = ArcByCentre.getDistance(this.baseCircle.centerPoint.latitude, this.baseCircle.centerPoint.longitude,
      startPoint.latitude, startPoint.longitude);
    this.baseCircle = new GmlCircle(centrePoint, radius/ArcByCentre.METERS_PER_NAUTICAL_MILE, GmlRadiusUom.NM);

    this.startAngle = ArcByCentre.getBearing(centrePoint.latitude, centrePoint.longitude, startPoint.latitude, startPoint.longitude);
    this.endAngle = ArcByCentre.getBearing(centrePoint.latitude, centrePoint.longitude, endPoint.latitude, endPoint.longitude);

    if (this.startAngle === this.endAngle) {
      throw new Error("Start and end bearings are equal: please create a line or a circle instead of an arc.")
    }

    if (this.direction === AngleDirection.CLOCKWISE) {
      // startAngle must be < endAngle
      if (this.startAngle > this.endAngle) {
        this.endAngle = (this.endAngle + 360) % 360;
      }
    } else {
      // Counter-clockwise: startAngle must be > endAngle
      if (this.startAngle < this.endAngle) {
        this.startAngle = (this.startAngle + 360) % 360;
      }
    }
  }


  isClockwise(): boolean {
    return this.startAngle < this.endAngle;
  }

  static degToRad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  static radToDeg(rad: number): number {
    return rad * (180 / Math.PI);
  }

  /**
   * Calculate the initial bearing from start point to end point.
   * NB: dependinng on the path (the interpolation) between the 2 points, the final bearing may be very
   * different from the initial one.
   * Sources:
   * https://www.movable-type.co.uk/scripts/latlong.html
   * 
   * @param startLat Latitude of start point
   * @param startLong 
   * @param endLat Latitude of end point
   * @param endLong
   * @returns The bearing from start point, a value in the range -180° to +180°
   */
  static getBearing(startLat: number, startLong: number, endLat: number, endLong: number): number {
    const φ1 = ArcByCentre.degToRad(startLat);
    const λ1 = ArcByCentre.degToRad(startLong);
    const φ2 = ArcByCentre.degToRad(endLat);
    const λ2 = ArcByCentre.degToRad(endLong);
    const Δλ = λ2 - λ1;

    const y = Math.sin(Δλ) * Math.cos(φ2);
    const x = Math.cos(φ1) * Math.sin(φ2) - Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);
    const θ = Math.atan2(y, x);

    return ArcByCentre.radToDeg(θ);
  }


  static readonly EARTH_RADIUS = 6371e3; // metres

  static getDistance(startLat: number, startLong: number, endLat: number, endLong: number): number {
    const φ1 = ArcByCentre.degToRad(startLat);
    const φ2 = ArcByCentre.degToRad(endLat);
    const Δφ = ArcByCentre.degToRad(endLat - startLat);
    const Δλ = ArcByCentre.degToRad(endLong - startLong);

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return ArcByCentre.EARTH_RADIUS * c; // in metres
  }
}
