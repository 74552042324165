<mat-form-field>
    <input matInput placeholder="Name" #name required>
</mat-form-field>
<div mat-dialog-actions>

    <button mat-raised-button mat-button color="primary" class="example-btn-width" [mat-dialog-close]="name.value"
        (click)="addNewItem(name.value)" style="cursor:pointer">
        Add
    </button>

    <!-- <button mat-raised-button mat-button color="primary" class="example-btn-width" [mat-dialog-close]="data.id"  (click)="claim(data.id)"
     style="cursor:pointer">
          Claim
     </button> -->

    <button mat-raised-button mat-button color="primary" class="example-btn-width" (click)="onNoClick()">Cancel</button>

</div>