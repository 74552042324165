export class LogEntry {    
    creationDate: Date;
    logSheetId: string
    author: string;    
    manual: boolean;
    description: string;
    entryReference?: number;
    isDeleted?: boolean;

    constructor(options: {
      creationDate?: Date,
      author?: string,
      logSheetId?: string,
      manual?: boolean,
      description?: string,
      entryReference?: number,
      isDeleted?: boolean
  } = {}) {
      this.creationDate = options.creationDate;
      this.author = options.author;
      this.logSheetId = options.logSheetId;
      this.manual = options.manual;
      this.description = options.description;
      this.entryReference = options.entryReference;
      this.isDeleted = options.isDeleted;
  }

  }
  