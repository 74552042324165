import { SaveUpdateNoticeDto } from './../model/SaveUpdateNoticeDto';
import { UpdateNoticeDetailsDTO } from './../model/UpdateNoticeDetailsDTO';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CreateUpdateNoticeDTO } from './../model/CreateUpdateNoticeDTO';
import { UpdateNoticeSummaryDTO } from './../model/UpdateNoticeSummaryDTO';
import { FileLikeObject } from 'ng2-file-upload';
import { AixmFeatureDTO } from '../../info-management/model/AixmFeatureDTO';
import { AixmDynamicDTO } from '../model/aixmDynamicDTO';
import * as GlobalVar from '../../utils/global.var';
import { ConfigService } from '../../utils/config.service';

const httpOptions = {
  headers: new HttpHeaders()
};

@Injectable()
export class UpdateNoticeService {

  private apiServer = ConfigService.settings.apiServer;
  //private updatenoticeUrl = 'api/updatenotice';
  //private updatenoticeListUrl = 'api/updatenotices';
  private aixmFeatureListUrl = this.apiServer.url + '/updateNotice/aixm-feature';
  private updatenoticeUrl = this.apiServer.url + '/updateNotice';
  private updatenoticeListByOriginatorUrl = this.apiServer.url + '/updateNotice/listByOriginator';
  private updatenoticeListUrl = this.apiServer.url + '/updateNotice/list';
  private closedUNListUrl = this.apiServer.url + '/updateNotice/closedList';
  private downloadFileUrl = this.apiServer.url + '/downloadFile';
  private deleteUNUrl = this.apiServer.url + '/deleteUn';
  private deleteItemUrl = this.apiServer.url + '/deleteItem';
  private submitToAISUrl = this.apiServer.url + '/submitToAIS';
  private acceptUNUrl = this.apiServer.url + '/acceptUn';
  private rejectUNUrl = this.apiServer.url + '/rejectUn';
  private checkAssignedUserUrl = this.apiServer.url + '/checkAssignedUser';
  private organizationUrl = this.apiServer.url + '/getOrganization';
  private addChangesUrl = this.apiServer.url + '/updateNotice/addChanges';
  private generateDatasetUrl = this.apiServer.url + '/aixm/generateDataset';
  private downloadDatasetUrl = this.apiServer.url + '/aixm/downloadDataset';

  attachments: Array<File> = [];

  constructor(private http: HttpClient) { }

  //  GET updatenotices from the server
  getUpdateNoticeListByOriginator(): Observable<UpdateNoticeSummaryDTO[]> {
    return this.http.get<UpdateNoticeSummaryDTO[]>(this.updatenoticeListByOriginatorUrl)
      .pipe(
        catchError(this.handleError('getUpdateNoticesByOriginator', []))
      );
  }

  getAixmFeatures(eventId: string): Observable<AixmFeatureDTO[]> {
    const url = `${this.aixmFeatureListUrl}/${eventId}`;
    return this.http.get<AixmFeatureDTO[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<AixmFeatureDTO>('getAixmFeatures', error))
      ));
  }

  //  GET updatenotices from the server
  getUpdateNoticeList(): Observable<UpdateNoticeSummaryDTO[]> {
    return this.http.get<UpdateNoticeSummaryDTO[]>(this.updatenoticeListUrl)
      .pipe(
        catchError(this.handleError('getUpdateNotices', []))
      );
  }

  // GET organiztion of the current user
  getOrganization(): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    return this.http.get<string>(this.organizationUrl, requestOptions).pipe(
      tap((result: string) => catchError(this.handleError<string>(`Get Organization`))
      ));
  }

  //  GET updatenotices from the server
  getClosedUpdateNoticeList(): Observable<UpdateNoticeSummaryDTO[]> {
    return this.http.get<UpdateNoticeSummaryDTO[]>(this.closedUNListUrl)
      .pipe(
        catchError(this.handleError('getUpdateNotices', []))
      );
  }

  /** GET UpdateNotice by id. */
  getUpdateNotice(valueOfFeatureId: string): Observable<UpdateNoticeDetailsDTO> {
    const url = `${this.updatenoticeUrl}/${valueOfFeatureId}`;
    return this.http.get<UpdateNoticeDetailsDTO>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<UpdateNoticeDetailsDTO>('getUpdateNotice', error))
      ));
  }

  download(valueOfFeatureId: string, fileName: string): Observable<Blob> {
    let requestOptions: Object = { responseType: 'blob' }

    const url = `${this.downloadFileUrl}/${valueOfFeatureId}/${fileName}`;
    return this.http.get<any>(url, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('download', error))
      ));
  }

  //////// Save methods //////////

  /** POST: add a new UpdateNotice to the server
   * https://nehalist.io/uploading-files-in-angular2/
   * https://stackoverflow.com/a/47597472/9433821
   * https://stackoverflow.com/a/48469646/9433821 (using Blob to set Content-type)
   * Appending data and files to FormData.
   * Do not set Content-type header on the request: HttpClient takes care of it.
   * Set Content-type header on data and files appended to FormData.
   */
  addUpdateNotice(updatenoticeDTO: CreateUpdateNoticeDTO, attachments: FileLikeObject[]): Observable<any> {
    var formData = new FormData();

    // Append CreateUpdateNoticeDTO data to the multi-part FormData
    const dtoString: string = JSON.stringify(updatenoticeDTO);
    const dtoBlob = new Blob([dtoString], { type: 'application/json' })
    console.log(dtoBlob);
    formData.append('CreateUpdateNoticeDTO', dtoBlob);

    // Append each file
    attachments.forEach((file) => {
      formData.append('uploadFile', file.rawFile as Blob, file.name);
    });

    // for testing
    // var file = new File(["foo"], "foo.txt", {
    //   type: "text/plain",
    // });
    // formData.append("uploadFile", file);
    //console.log("file size "+file.size);
    return this.http.post<FormData>(this.updatenoticeUrl, formData, httpOptions).pipe(
      tap((formData: FormData) => catchError(this.handleError<FormData>('addUpdateNotice'))));
  }

  editUpdateNotice(saveUpdateNoticeDto: SaveUpdateNoticeDto, attachments: FileLikeObject[], fileNamesToDelete: string[]): Observable<any> {
    var formData = new FormData();

    // Append SaveUpdateNoticeDto data to the multi-part FormData
    const dtoString: string = JSON.stringify(saveUpdateNoticeDto);
    const dtoBlob = new Blob([dtoString], { type: 'application/json' })
    formData.append('SaveUpdateNoticeDto', dtoBlob);

    // Append each file
    attachments.forEach((file) => {
      formData.append('uploadFile', file.rawFile as Blob, file.name);
    });

    // Append fileNamesToDelete
    const fileNamesToDeleteBlob = new Blob([JSON.stringify(fileNamesToDelete)], { type: 'application/json' });
    formData.append('fileNamesToDelete', fileNamesToDeleteBlob);

    return this.http.put<FormData>(this.updatenoticeUrl, formData, httpOptions)
      .pipe(tap(formData => console.log(formData),
        error => catchError(this.handleError<FormData>('editUpdateNotice', error))
      ));
  }

  checkTaskIsClaimedByCurrentUser(featureId: string): Observable<boolean> {
    const url = `${this.checkAssignedUserUrl}/${featureId}`;
    return this.http.get<boolean>(url) .pipe(tap(data => console.log(data),
    error => catchError(this.handleError('checkTaskIsClaimedByCurrentUser', error))
  ));
  }

  deleteUN(featureId: string): Observable<string> {

    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    const url = `${this.deleteUNUrl}/${featureId}`;
    console.log("The url is " + url);
    return this.http.get<any>(url, requestOptions).pipe(
      tap((result: string) => catchError(this.handleError<string>(`DeleteUN`))
      ));
  }

  submitToAIS(featureId: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: Submit to AIS Function: " + featureId);
    const url = `${this.submitToAISUrl}/${featureId}`;
    console.log("The url is " + url);
    return this.http.get<any>(url, requestOptions).pipe(
      tap((result: string) => catchError(this.handleError<string>(`SubmitToAIS`))
      ));
  }

  acceptUN(featureId: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    const url = `${this.acceptUNUrl}/${featureId}`;
    console.log("The url is " + url);
    return this.http.get<any>(url, requestOptions).pipe(
      tap((result: string) => catchError(this.handleError<string>(`AcceptUN`))
      ));
  }

  rejectUN(featureId: string, description: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: Reject Un: " + featureId);
    const url = `${this.rejectUNUrl}/${featureId}`;
    return this.http.post<string>(url, description, requestOptions).pipe(
      tap((result: string) => catchError(this.handleError<string>(`Reject Un`))
      ));
  }

  deleteItem(featureId: string, itemId: string): Observable<string> {

    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: Delete Item Function: " + featureId + itemId);
    const url = `${this.deleteItemUrl}/${featureId}/${itemId}`;
    console.log("The url is " + url);
    return this.http.get<any>(url, requestOptions).pipe(
      tap((result: string) => catchError(this.handleError<string>(`DeleteItem`))
      ));
  }

  /**
   * Add data changes to open UN
   * @param aixmDynamicDTO 
   */
  addChanges(aixmDynamicDTO: AixmDynamicDTO): Observable<any> {
    return this.http.post<any>(this.addChangesUrl, aixmDynamicDTO, httpOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('addChanges', error))
      ));
  }

  generateDraftAISProduct(unId: string): Observable<string> {  
    let requestOptions: Object = { responseType: 'string'};

    const url = `${this.generateDatasetUrl}/${unId}`;
    return this.http.get<string>(url, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('generateDraftAISProduct', error))
      ));
  }

  downloadDraftAISProduct(datasetPath: string): Observable<Blob> {
    let param = new HttpParams().set("datasetPath",datasetPath);
    let requestOptions: Object = { responseType: 'blob', params: param};

    return this.http.get<any>(this.downloadDatasetUrl, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('downloadDraftAISProduct', error))
      ));
  }

  observableSubscribeTest(): Observable<any> {
    const myObservable = of(1, 2, 3);
    return myObservable;
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
