<div class="container">
  <mat-toolbar>
    <span>
      <h3> List of Data Providers </h3>
    </span>
  </mat-toolbar>

  <div class="search-container" style="padding: 0 16px;">
    <mat-form-field >
      <mat-label>Search by Name</mat-label>
      <input matInput [formControl]="searchControl">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z0 table-wrapper">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%;">
      <!-- Select Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox [disabled]="!dataSource.data.length" color="primary" (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

      <!-- Linked Column -->
      <ng-container matColumnDef="linked">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Linked </th>
        <td mat-cell *matCellDef="let row"> {{row.linked}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25]" aria-label="Select page of users"></mat-paginator>
    <button [disabled]="selection.isEmpty()" class="assign-btn" type="submit" mat-raised-button mat-button color="primary"
      (click)="assign();"> Assign
    </button>
  </div>

</div>