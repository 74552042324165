import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ClaimdialogComponent, DialogData } from './../claimdialog/claimdialog.component';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { TaskService } from '../service/task.service';

@Component({
  selector: 'app-unclaimdialog',
  templateUrl: './unclaimdialog.component.html',
  styleUrls: ['./unclaimdialog.component.css']
})
export class UnclaimdialogComponent implements OnInit {
  private result: string;

  onAdd = new EventEmitter();

  constructor(private taskService: TaskService, private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UnclaimdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  release(taskId: string): void {
    console.log("In component: Release Function: " + taskId);
   
    this.taskService.release(taskId)
    .subscribe(result => {
      this.result = result,
      console.log("Released Task: " + result);
      this.onAdd.emit(result);
      this.snackBar.open(result, "OK", {
        duration: 3000,
      });
    });
  }
}
