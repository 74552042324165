import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  Routes,
  RouterModule,
  UrlSerializer,
  RouterLinkActive
} from '@angular/router';

//import { Observable } from 'rxjs';

import { AppMaterialModule } from '../app.material.module';

import { PropertiesRoutingModule } from './properties-routing.module';
import { ApplicationPropertiesComponent } from './application-properties/application-properties.component';
import { UserPropertiesComponent } from './user-properties/user-properties.component';

import { PropertiesService } from './services/properties.service';
import { NewUserpropertiesComponent } from './new-userproperties/new-userproperties.component';
import { NewAppPropertiesComponent } from './new-app-properties/new-app-properties.component';
import { MatSortModule } from '@angular/material/sort';
import {  MatTableModule } from '@angular/material/table';
import { EditUserpropertiesComponent} from './edit-userproperties/edit-userproperties.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    PropertiesRoutingModule,
    AppMaterialModule,
    MatSortModule,
    MatTableModule
  ],
  declarations: [   
    ApplicationPropertiesComponent,
    UserPropertiesComponent,
    NewUserpropertiesComponent,
    NewAppPropertiesComponent,
    EditUserpropertiesComponent
  ],
  providers: [
    PropertiesService
  ],
})
export class PropertiesModule { }
