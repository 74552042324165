import { FeatureDocument } from './../model/FeatureDocument';
import { InfoManagementService } from './../service/info-management.service';
import { DataprofileService } from './../../aixm-profile/service/dataprofile.service';
import { ProfileVersion } from './../../aixm-profile/model/profileversion.model';
import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Network } from 'vis-network/esnext';
import { DataSet } from 'vis-data';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'im-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.css']
})
export class BrowseComponent implements OnInit {
  @ViewChild('network') network: ElementRef;
  unId: string;
  unName: string;
  featureId: string;
  @Input() isUn: boolean = false;
  public effectiveDate: Date;

  catalogues: ProfileVersion[] = [];
  selectedFeatureId: string;
  featureType: string;
  displayMap: boolean = false;

  featureDocs: FeatureDocument[] = [];
  //datasource for table
  displayedColumns = ['featureName', 'status', 'originator', 'matchedText'];
  documents_dataSource = new MatTableDataSource<FeatureDocument>(this.featureDocs);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.documents_dataSource.sort = this.sort;
    this.documents_dataSource.paginator = this.paginator;
  }

  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.documents_dataSource.filter = filterValue;
  }

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    'group': new UntypedFormControl(),
    'featureList': new UntypedFormControl()
  });

  constructor(private dataprofileService: DataprofileService, private infoManagementService: InfoManagementService,
    private route: ActivatedRoute, private router: Router, public dialog: MatDialog) {
    this.unId = this.route.snapshot.params['unId'];
    this.unName = this.route.snapshot.params['unName'];
    this.featureId = this.route.snapshot.params['featureId'];
    this.effectiveDate = this.route.snapshot.params['effDate'];
  }

  ngOnInit() {
    if (this.unId != undefined) {
      this.isUn = true;
    }
    //this.networkInit();
    //this.getCatalogues();  
  }

  /* getCatalogues() {
    this.dataprofileService.getProfileVersionsByOriginator().
      subscribe(catalogues => this.catalogues = catalogues);
  } */

  redirectToAeroInputForm(featureId: string) {
    if (this.unId == undefined) { this.unId = "null"; }
    if (this.unName == undefined) { this.unName = ""; }
    this.router.navigate(['info-management', featureId, this.unId, this.unName]);
  }

  searchFeature(text: string) {
    this.infoManagementService.searchFeature(text).subscribe(docs => this.documents_dataSource.data = docs);
  }

  networkInit() {
    // create an array with nodes
    var nodes = new DataSet([
      { id: 1, label: 'Node 1' },
      { id: 2, label: 'Node 2' },
      { id: 3, label: 'Node 3' },
      { id: 4, label: 'Node 4' },
      { id: 5, label: 'Node 5' }
    ]);

    // create an array with edges
    var edges = new DataSet<any>([
      { from: 1, to: 3 },
      { from: 1, to: 2 },
      { from: 2, to: 4 },
      { from: 2, to: 5 }
    ]);

    // provide the data in the vis format
    var data = {
      nodes: nodes,
      edges: edges
    };
    var options = {};

    // initialize your network!
    var network = new Network(this.network.nativeElement, data, options);
  }

  onSubmit() {
  }

  tabChange(index: number) {
    console.log(index);
    if (index == 2) {
      this.displayMap = true;
    }
    /* if (index == 3 && this.featureGroups.length == 0) {
      this.getTypes();
    } */
  }

  // setSelectedFeatureId(featureId: string) {
  //   this.selectedFeatureId = featureId;
  // }

}
