<div class="progress-loader" [hidden]="!loading">
    <div class="wrapper">
        <div class="overlay">
            <div class="spinner-wrapper">
                <img class="logospinner" src="assets/images/Facilis-logo-100px.png" height="50" width="50"
                    alt="Facilis.aero is loading...">
                <!-- <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div> -->
            </div>
        </div>
    </div>
</div>