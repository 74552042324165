import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,
  ReactiveFormsModule } from '@angular/forms';

import { LogSheetRoutingModule } from './log-sheet-routing.module';
import { AppMaterialModule } from '../app.material.module';

import { LogSheetComponent } from './log-sheet-list/log-sheet-list.component';
import { LogentrylistComponent } from './log-entry-list/log-entry-list.component';
import { NewlogsheetdialogComponent } from './newlogsheetdialog/newlogsheetdialog.component';
import { NewlogentrydialogComponent } from './newlogentrydialog/newlogentrydialog.component';
import { LogsheetService } from './service/logsheet.service';
import { MessageService } from './service/message.service';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { EditLeDialogComponent } from './edit-le-dialog/edit-le-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import {  MatTableModule } from '@angular/material/table';

@NgModule({
    imports: [
        CommonModule,
        LogSheetRoutingModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatSortModule,
        MatTableModule
    ],
    declarations: [
        LogSheetComponent,
        NewlogsheetdialogComponent,
        LogentrylistComponent,
        NewlogentrydialogComponent,
        EditLeDialogComponent,
    ],
    providers: [
        LogsheetService,
        MessageService
    ]
})
export class LogSheetModule { }
