import { PropertyField } from './property-field';
import { AixmFeatureDTO } from '../../model/AixmFeatureDTO';
import { MultipleProperty } from './property';

/* A property that is a reference (link) to another one.
  The value of this property is an AixmFeatureDTO, which notably contains the gml:identifier and the gml:name.
*/
export class ReferencePropertyField extends PropertyField<string> {
  controlType = 'reference';
  // Override the property type so it doesn't treat as 'field' type
  propertyType = 'reference'

  constructor(options: {
    key: string,
    label?: string,
    hint?: string,
    description?: string,
    order?: number,
    minOccurs?: number,
    maxOccurs?: number,
    nil?: boolean,
    nilReason?: string,
    annotations?: MultipleProperty,
    featureId: string,
    value?: string,
    modified: boolean,
  }) {
    super(options);
  }
}