import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import {  MatDialog,  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogsheetService } from '../service/logsheet.service';
import { Logsheet } from '../model/logsheet';

@Component({
  selector: 'app-newlogsheetdialog',
  templateUrl: './newlogsheetdialog.component.html',
  styleUrls: ['./newlogsheetdialog.component.css'],
  providers: [LogsheetService]
})
export class NewlogsheetdialogComponent{
  private logsheetId: string;
  types: string[]=  ['PUBLICATION', 'PRODUCTION'];

  constructor(private logSheetService: LogsheetService,
    public dialogRef: MatDialogRef<NewlogsheetdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  
  onAdd = new EventEmitter();

  onNoClick(): void {
    this.dialogRef.close();
  }

  add(type: string, subject: string): void {
    console.log("In component: "+ subject);
 
    this.logSheetService.addLogSheet({type: type, subject: subject} as Logsheet)
      .subscribe(logsheetId => {
        this.logsheetId = logsheetId, 
        console.log(this.logsheetId);
        this.onAdd.emit(logsheetId);
      });
  }
}