import { DataProvider, Dataprovider } from './../../data-provider/model/dataprovider';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Profile } from '../model/profile.model';
import { ProfileVersion } from '../model/profileversion.model';
import * as GlobalVar from '../../utils/global.var';
import { ConfigService } from '../../utils/config.service';
import { FileLikeObject } from 'ng2-file-upload';
import { Observable, of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DataprofileService {

  private apiServer = ConfigService.settings.apiServer;
  private profileUrl = this.apiServer.url + '/profile';
  //private profileUrl = 'api/dataprofile'; //URL to web api 
  private profileVersionUrl = this.apiServer.url + '/profileVersion';
  //private profileVersionUrl = 'api/dataprofileVersion'; //URL to web api 
  private schemaFileNameUrl = this.apiServer.url + '/schemaFileName';
  private profileVersionListUrl = this.apiServer.url + '/profileVersion/list';
  private downloadFileUrl = this.apiServer.url + '/profileVersion/downloadFile'

  constructor(private http: HttpClient) { }

  /** GET profiles from the server */
  getProfiles(): Observable<Profile[]> {
    return this.http.get<Profile[]>(this.profileUrl)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getProfiles', error))
      ));
  }

  /** GET Profile by id. */
  getProfile(id: string): Observable<Profile> {
    console.log("in service: " + id);
    const url = `${this.profileUrl}/${id}`;
    return this.http.get<Profile>(url).pipe(tap(data => console.log(data),
      error => catchError(this.handleError('getProfile', error))
    ));
  }

  /** GET ProfileVersions from the server according to ID */
  getProfileVersions(id: string): Observable<ProfileVersion[]> {
    console.log("in service: " + id);
    const url = `${this.profileVersionListUrl}/${id}`;
    return this.http.get<ProfileVersion[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getProfileVersions', error))
      ));
  }

  getProfileVersionsByOriginator(): Observable<ProfileVersion[]> {
    return this.http.get<ProfileVersion[]>(this.profileVersionListUrl)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getProfileVersionsByOriginator', error))
      ));
  }

  /** GET latestProfiel from the server according to ID */
  getLatestSchemaFiles(id: string): Observable<ProfileVersion> {
    console.log("in service: " + id);
    const url = `${this.schemaFileNameUrl}/${id}`;
    return this.http.get<ProfileVersion>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getProfileVersionsByID', error))
      ));
  }

  /** GET ProfileVersion by number. */
  getProfileVersionbyNumber(profileId: string, versionId: number): Observable<ProfileVersion> {
    //  const url = `${this.profileVersionsUrl}/${number}`;
    const url = `${this.profileVersionUrl}/${profileId}/${versionId}`;
    return this.http.get<ProfileVersion>(url).pipe(
      tap(_ => catchError(this.handleError<ProfileVersion>(`get profile version`))
      ));
  }

  //////// Save methods //////////
  /** POST: add a new Data Profile to the server */
  addProfile(profile: Profile, schemaFiles: FileLikeObject[]): Observable<any> {
    console.log("Attached File size in service is "+schemaFiles.length);
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    var formData = new FormData();
    console.log("Success");
    // Append Profile data to the multi-part FormData
    const dtoString: string = JSON.stringify(profile);
    const dtoBlob = new Blob([dtoString], { type: 'application/json' })
    console.log(dtoBlob);
    formData.append('createProfile', dtoBlob);

    // Append each file
    schemaFiles.forEach((file) => {
      formData.append('uploadSchema', file.rawFile as unknown as Blob, file.name);
    });

    return this.http.post<FormData>(this.profileUrl, formData, requestOptions).pipe(
      tap((formData: FormData) => catchError(this.handleError<FormData>('addProfile'))));
  }

  /** POST: add a new ProfileVersion to the server */
  addProfileVersion(id: string,previousVersionId:string, profileVersion: ProfileVersion, 
    attachments: FileLikeObject[],existingFiles:string[],fileNamesToDelete: string[]): Observable<any> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }

    var formData = new FormData();
    // Append ProfileVersion data to the multi-part FormData
    const dtoString: string = JSON.stringify(profileVersion);
    const dtoBlob = new Blob([dtoString], { type: 'application/json' })
    console.log(dtoBlob);
    formData.append('createProfileVersion', dtoBlob);
    console.log("in service: " + profileVersion.originators);

    // Append each file
    attachments.forEach((file) => {
      formData.append('uploadFile', file.rawFile as unknown as Blob, file.name);
    });

/*     existingFiles.forEach((file)=>{
      var path = file.toString()
      var fileName = path.replace(/^.*[\\\/]/, '');    
      console.log("File in service is "+ fileName);
      formData.append('existingFile', path, fileName); 
    }) */

/*     for (let j = 0; j < existingFiles.length; j++) {
      formData.append("existingFile", <File>existingFiles[j])
      console.log(existingFiles[j].name);
    } */
    

    // Append fileNamesToDelete
    const fileNamesToDeleteBlob = new Blob([JSON.stringify(fileNamesToDelete)], { type: 'application/json' });
    formData.append('fileNamesToDelete', fileNamesToDeleteBlob);

    //Append existingFiles
    const existingFilesBlob = new Blob([JSON.stringify(existingFiles)], { type: 'application/json' });
    formData.append('existingFiles', existingFilesBlob);
  
    const url = `${this.profileVersionUrl}/${id}/${previousVersionId}`
    return this.http.post<FormData>(url, formData, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('addProfileVersion', error))
      ));
  }

  /** PUT: add a new Profile as a correction of name and description */
  editProfile(oldProfileId: string, profile: Profile): Observable<string> {
    console.log("in service: " + Profile.name);
    const url = `${this.profileUrl}/${oldProfileId}`;
    return this.http.put<string>(url, profile, httpOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('editProfile', error))
      ));
  }

  /** PUT: add a new Profile Version as a correction of  description */
  editProfileVersion(version: ProfileVersion): Observable<ProfileVersion> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: " + Profile.name);
    return this.http.put<ProfileVersion>(this.profileVersionUrl, version, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('editProfileVersion', error))
      ));
  }

  /** POST: link a DP to a profile version */
  assign(versionId: string, DP: DataProvider[]): Observable<any> {
    console.log("in service: " + DP);
    const url = `${this.profileVersionUrl}/assign/${versionId}`;
    return this.http.post<any>(url, DP)
      .pipe(tap(error => catchError(this.handleError('assign', error))
      ));
  }

  /** DELETE: unlink a DP from a profile version */
  unassign(versionId: string, dpId: string): Observable<any> {
    console.log("in service: " + dpId);
    const url = `${this.profileVersionUrl}/${versionId}/${dpId}`;
    return this.http.delete<any>(url)
      .pipe(tap(error => catchError(this.handleError('unassign', error))
      ));
  }

  
  download(versionId: string, fileName: string): Observable<Blob> {
    let requestOptions: Object = { responseType: 'blob' }

    const url = `${this.downloadFileUrl}/${versionId}/${fileName}`;
    return this.http.get<any>(url, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('download', error))
      ));
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
