<ng-container>
    <mat-toolbar style="display: flex;justify-content: space-between;">
        <div class="title">Edit Update Notice <span *ngIf="unName">: {{ unName }}</span></div>
        <div class="btn-actions">
            <button color="primary" *ngIf="isDp" mat-icon-button
                [disabled]="!modifyFile() && !editUNForm.dirty || editUNForm.controls.cancellationDate.errors?.CheckDate || isEmptyDescription()"
                (click)="editUpdateNotice(); onSubmit(editUNForm)">
                <mat-icon>save</mat-icon>
            </button>
            <button mat-icon-button *ngIf="isDp" [disabled]="!assignedUser" (click)="deleteUN();onSubmit(editUNForm)">
                <mat-icon color="warn">delete</mat-icon>
            </button>
            <button color="primary" mat-icon-button *ngIf="isDp" [disabled]="!assignedUser || !featuresExist"
                (click)="openConfirmationDialog()">
                <mat-icon>send</mat-icon>
            </button>
            <button mat-icon-button [disabled]="!assignedUser" matTooltip="Location on map" (click)="showMap()">
                <mat-icon color="primary">map</mat-icon>
            </button>
            <button *ngIf="isAISReviewer() && editUNForm.controls['status'].value != 'Editable'"
                [disabled]="!assignedUser" mat-icon-button matTooltip="Accept UN"
                (click)="acceptUN();onSubmit(editUNForm)">
                <mat-icon color="primary">check</mat-icon>
            </button>
            <button *ngIf="isAISReviewer() && editUNForm.controls['status'].value != 'Editable'" mat-icon-button
                matTooltip="Reject UN" [disabled]="!assignedUser" (click)="rejectUN(); onSubmit(editUNForm)">
                <mat-icon color="warn">block</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <div class="wrapper" [formGroup]="editUNForm">

        <!-- Form Section -->
        <div class="form-section" cdkScrollable>

            <div class="readonly-info-wrapper">
                <div class="row items-baseline">
                    <mat-form-field class="flex-1">
                        <mat-label>Due date</mat-label>
                        <input disabled matInput [value]="dueDate">
                    </mat-form-field>
                    <!-- <div class="flex-1 readonly-content">
                        <mat-label>Due Date</mat-label>
                        <p>{{ dueDate }}</p>
                    </div> -->
                    <div class="flex-1 readonly-content">
                        <mat-label class="status">Status</mat-label>
                        <p>{{ editUNForm.get('status').value }}</p>
                    </div>
                </div>

                <div class="row items-baseline">
                    <!-- <div class="flex-1 readonly-content">
                        <mat-label>Last edited on</mat-label>
                        <p>{{ editUNForm.get('lastEditedDate').value }}</p>
                    </div> -->
                    <mat-form-field class="flex-1">
                        <mat-label>Last edited on</mat-label>
                        <input matInput placeholder="Last edited" formControlName="lastEditedDate"
                            name="lastEditedDate">
                    </mat-form-field>
                    <div class="flex-1 readonly-content">
                        <mat-label class="by">By</mat-label>
                        <p>{{ userName }}</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <mat-form-field class="flex-0">
                    <mat-label>Event Name</mat-label>
                    <input matInput formControlName="name" name="name" [readonly]="isReadOnly()">
                </mat-form-field>
            </div>

            <div class="temporality-section">
                <mat-radio-group name="temporality" formControlName="temporality">
                    <mat-radio-button class="example-radio-button" value="PERMANENT">Permanent</mat-radio-button>
                    <mat-radio-button value="TEMPORARY">Temporary</mat-radio-button>
                </mat-radio-group>
            </div>


            <div class="eff-date-section">
                <mat-form-field class="flex-1">
                    <mat-label>Effective Date</mat-label>
                    <input matInput readonly [disabled]="isReadOnly()" [matDatepicker]="picker1"
                        (dateChange)="getDueDate($event)" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
                        id="effectiveDate" formControlName="effectiveDate" (click)="effDatePickerOpen = true">
                    <button matSuffix mat-icon-button (click)="effDatePickerOpen = true">
                        <mat-icon>today</mat-icon>
                    </button>
                    <ng-template cdkConnectedOverlay [cdkConnectedOverlayHasBackdrop]="true"
                        cdkConnectedOverlayBackdropClass="mat-overlay-transparent-backdrop"
                        [cdkConnectedOverlayPanelClass]="['mat-elevation-z2', 'bordered-pane']"
                        [cdkConnectedOverlayOffsetY]="20" [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="effDatePickerOpen" (overlayOutsideClick)="effDatePickerOpen = false">
                        <div style="border-radius: 5px; background:white; display: flex;">
                            <div class="calendar-wrapper" style="width: 290px;">
                                <mat-calendar [dateClass]="airacDateClass" (selectedChange)="setDueDate($event)"
                                    [(selected)]="selected" [minDate]="validEffDate">
                                </mat-calendar>
                            </div>
                            <div class="custom-content-wrappper">
                                <div class="wrapper-date-action">
                                    <div class="row time-format">
                                        <div class="displayed-format">
                                            <mat-label>UTC date time</mat-label>
                                            <p>{{ displayFormattedTimeZone(editUNForm.get('effectiveDate').value)
                                                }} </p>
                                        </div>
                                        <div class="displayed-format" *ngIf="selectedTz.value !== 'UTC'">
                                            <mat-label>Selected timezone <span>({{selectedTz.value}})</span></mat-label>
                                            <p>{{ displayFormattedTimeZone(editUNForm.get('effectiveDate').value,
                                                selectedTz.value) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <mat-datepicker [dateClass]="dateClass" #picker1></mat-datepicker>
                    <!-- <input matInput [matDatepicker]="picker1" name="effectiveDate" [min]="validEffDate"
                        (dateChange)="getDueDate($event)" formControlName="effectiveDate" [readonly]="isReadOnly()">
                    <mat-datepicker-toggle matSuffix [for]="picker1" *ngIf="!isReadOnly()"></mat-datepicker-toggle>
                    <mat-datepicker [dateClass]="dateClass" #picker1>
                        <mat-datepicker-actions>
                            <div class="wrapper-date-action">
                                <div class="row time-format">
                                    <div class="displayed-format">
                                        <mat-label>UTC date time</mat-label>
                                        <p>{{ displayFormattedTimeZone(editUNForm.get('effectiveDate').value, true)
                                            }} </p>
                                    </div>
                                    <div class="displayed-format">
                                        <mat-label>Local date time <span>({{localTimeZone}})</span></mat-label>
                                        <p>{{ displayFormattedTimeZone(editUNForm.get('effectiveDate').value) }}</p>
                                    </div>
                                    <button mat-flat-button color="primary" matDatepickerApply>Apply</button>
                                </div>
                            </div>
                        </mat-datepicker-actions>
                    </mat-datepicker> -->
                </mat-form-field>
                <mat-form-field class="flex-1">
                    <mat-label>Time</mat-label>
                    <input readonly matInput type="text" [value]="effDatehoursSelected">
                </mat-form-field>
                <mat-form-field class="flex-1">
                    <mat-label>Select timezone</mat-label>
                    <mat-select [formControl]="selectedTz" (selectionChange)="onTzChange($event)"
                        (openedChange)="tzSearch.focus()" [panelWidth]="null" [hideSingleSelectionIndicator]="true">
                        <div class="search-timezone">
                            <mat-form-field style="width: 100%;">
                                <input #tzSearch type="text" matInput placeholder="Search timezone"
                                    [formControl]="timezoneSearchCtrl">
                            </mat-form-field>
                        </div>
                        <mat-option *ngFor="let tz of (timezones$ | async)" [value]="tz.name">
                            <span class="name">{{ tz.name }}</span>
                            <span class="utc">
                                {{tz.offsetFormatted}}
                            </span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="cancel-date-section" *ngIf="editUNForm.controls['temporality'].value == 'TEMPORARY'">
                <mat-form-field class="flex-1">
                    <mat-label>Cancel Date</mat-label>
                    <!-- <input matInput [matDatepicker]="picker2" name="cancellationDate" [min]="validCancelDate"
                        formControlName="cancellationDate" style="margin-top: 0px;" [readonly]="isReadOnly()">
                    <mat-datepicker-toggle matSuffix [for]="picker2" *ngIf="!isReadOnly()"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker> -->
                    <input matInput readonly [disabled]="isReadOnly()" [matDatepicker]="picker2" cdkOverlayOrigin
                        #cancelDatePicker="cdkOverlayOrigin" id="cancellationDate" formControlName="cancellationDate"
                        (click)="cancelDatePickerOpen = true">
                    <button matSuffix mat-icon-button (click)="cancelDatePickerOpen = true">
                        <mat-icon>today</mat-icon>
                    </button>
                    <ng-template cdkConnectedOverlay [cdkConnectedOverlayHasBackdrop]="true"
                        cdkConnectedOverlayBackdropClass="mat-overlay-transparent-backdrop"
                        [cdkConnectedOverlayPanelClass]="['mat-elevation-z2', 'bordered-pane']"
                        [cdkConnectedOverlayOffsetY]="20" [cdkConnectedOverlayOrigin]="cancelDatePicker"
                        [cdkConnectedOverlayOpen]="cancelDatePickerOpen"
                        (overlayOutsideClick)="cancelDatePickerOpen = false">
                        <div style="border-radius: 5px; background:white; display: flex;">
                            <div class="calendar-wrapper" style="width: 290px;">
                                <mat-calendar [dateClass]="airacDateClass" [(selected)]="cancelDateSelected"
                                    [minDate]="validCancelDate">
                                </mat-calendar>
                            </div>
                            <div class="custom-content-wrappper">
                                <div class="wrapper-date-action">
                                    <div class="row time-format">
                                        <div class="displayed-format">
                                            <mat-label>UTC date time</mat-label>
                                            <p>{{ displayFormattedTimeZone(editUNForm.get('cancellationDate').value)
                                                }} </p>
                                        </div>
                                        <div class="displayed-format" *ngIf="selectedTz.value !== 'UTC'">
                                            <mat-label>Selected timezone <span>({{selectedTz.value}})</span></mat-label>
                                            <p>{{ displayFormattedTimeZone(editUNForm.get('cancellationDate').value,
                                                selectedTz.value) }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <mat-datepicker [dateClass]="dateClass" #picker2></mat-datepicker>
                    <mat-error *ngIf="editUNForm.controls.cancellationDate.errors?.CheckDate">
                        Cancel Date must not be less or equal to effective date
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="flex-1">
                    <mat-label>Time</mat-label>
                    <input readonly matInput type="text" [value]="cancelDatehoursSelected">
                </mat-form-field>
            </div>

            <div class="description-section row">
                <mat-form-field class="flex-1">
                    <mat-label>Description</mat-label>
                    <textarea matInput matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="4"
                        name="description" formControlName="description" [readonly]="isReadOnly()"></textarea>
                    <mat-error *ngIf="isEmptyDescription()">
                        description may not be empty
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="attachments-section">
                <div class="attacment-section-heading">
                    <div class="mat-subheading-1" style="margin: 0;">Attachments</div>
                    <div>
                        <input type="file" hidden ng2FileSelect #file [uploader]="uploader" multiple />
                        <button color="primary" type="button" mat-icon-button matTooltip="Add Attachment"
                            (click)="file.click()" *ngIf="!isReadOnly()"><mat-icon>attach_file</mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Temprary deleted attachment -->
                <div class="attachment-files-wrapper" *ngIf="uploader.queue.length || fileNamesToDelete.length">
                    <label for="deleted-files" class="subheader">Unsaved changes</label>
                    <div class="attached-file" *ngFor="let item of uploader.queue">
                        <div class="file-name">
                            <label>{{ item?.file?.name }}</label>
                        </div>
                        <button type="button" mat-icon-button (click)="remove(item)" *ngIf="!isReadOnly()">
                            <mat-icon color="warn">delete</mat-icon>
                        </button>
                    </div>
                    <div class="attached-file deleted" *ngFor="let fileName of fileNamesToDelete">
                        <div class="file-name">
                            <label style="text-decoration: line-through;">{{ fileName }} </label>
                        </div>
                        <button type="button" mat-icon-button matTooltip="Undo deleted Attachment(s)"
                            (click)="unDo(fileName)" *ngIf="!isReadOnly() && isDeleted" [disabled]="!isDeleted">
                            <mat-icon>undo</mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Current Attachments -->
                <div class="attachment-files-wrapper" *ngIf="fileNames.length">
                    <label for="current-files" class="subheader">Current Attachments</label>
                    <div class="attached-file" *ngFor="let fileName of fileNames">
                        <a href="" class="file-name" matTooltip="Download File"
                            (click)="download(fileName); $event.preventDefault()">
                            <label style="cursor:pointer">
                                {{fileName}}
                            </label>
                        </a>
                        <button mat-icon-button (click)="delete(fileName)" *ngIf="!isReadOnly()">
                            <i class="material-icons">delete</i>
                        </button>
                    </div>
                </div>

                <!-- No Attachment -->
                <div class="no-attachment"
                    *ngIf="!fileNames.length && !uploader.queue.length && !fileNamesToDelete.length">
                    <mat-icon>info</mat-icon>
                    <p style="margin: 0;">No attachments</p>
                </div>
            </div>
        </div>

        <!-- Table Section -->
        <div class="table-section">
            <div class="search-form">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
                    <mat-icon matSuffix (click)="applyFilter('');filter.value=''"
                        style="cursor:pointer">clear</mat-icon>
                </mat-form-field>

                <div class="search-form-btn-actions">
                    <button *ngIf="isDp" class="add-feature-btn" mat-icon-button color="primary"
                        [ngClass]="{'disable':!assignedUser, enable:assignedUser}" matTooltip="Upload a Dataset"
                        (click)="openDialog()"><mat-icon>upload</mat-icon>
                    </button>
                    <button *ngIf="isDp" class="add-feature-btn" mat-icon-button color="primary"
                        [ngClass]="{'disable':!assignedUser, enable:assignedUser}"
                        [routerLink]="['/browse', valueOfFeatureId, unName, editUNForm.controls['effectiveDate'].value]"
                        matTooltip="Add Changes"><mat-icon>add</mat-icon>
                    </button>
                    <button *ngIf="editUNForm.controls['status'].value == 'Editable' && featuresExist"
                        class="add-feature-btn" mat-icon-button color="primary" matTooltip="Generate dataset"
                        (click)="generateDraftAISProduct()">
                        <mat-icon>text_snippet</mat-icon>
                    </button>
                </div>
            </div>
            <div class="table-wrapper">
                <table class="features-table" mat-table [dataSource]="aixmFeatures_DataSource" matSort
                    matSortActive="lastEdited" matSortDirection="asc" matSortDisableClear>

                    <!-- Feature Type Column -->
                    <ng-container matColumnDef="featureType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Feature Type </th>
                        <td mat-cell *matCellDef="let feature">
                            <a [routerLink]="['/info-management', feature.featureId, valueOfFeatureId, unName, editUNForm.get('effectiveDate').value, true]"
                                [ngClass]="{'not-active': feature.summary == deletion || !assignedUser, 'active': feature.summary != deletion && assignedUser}"
                                (click)="redirectToAeroInputForm(feature.featureId)">
                                {{feature.featureType}}
                            </a>
                        </td>
                    </ng-container>

                    <!-- Feature Name Column -->
                    <ng-container matColumnDef="featureName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Feature Name </th>
                        <td mat-cell *matCellDef="let feature">
                            <a [routerLink]="['/info-management', feature.featureId, valueOfFeatureId, unName, editUNForm.get('effectiveDate').value, true]"
                                [ngClass]="{'not-active': feature.summary == deletion || !assignedUser, 'active': feature.summary != deletion && assignedUser}"
                                (click)="redirectToAeroInputForm(feature.featureId)">
                                {{ feature.featureName }}
                            </a>
                        </td>
                    </ng-container>

                    <!-- Summary Column -->
                    <ng-container matColumnDef="summary">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Change Summary </th>
                        <td mat-cell *matCellDef="let feature"
                            [ngClass]="{'not-active': feature.summary == deletion || !assignedUser}">
                            {{feature.summary}}
                        </td>
                    </ng-container>

                    <!-- Last Edited Column -->
                    <ng-container matColumnDef="lastEdited">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Edited </th>
                        <td mat-cell *matCellDef="let feature"
                            [ngClass]="{'not-active': feature.summary == deletion || !assignedUser}">
                            {{feature.lastEdited}}
                        </td>
                    </ng-container>

                    <!-- Delete Icon Column -->
                    <ng-container matColumnDef="delete_icon">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                        <td mat-cell *matCellDef="let feature">
                            <mat-icon
                                *ngIf="editUNForm.controls['status'].value == 'Editable' && assignedUser && feature.summary != deletion"
                                [ngClass]="{'disable': feature.summary == deletion,enable: feature.summary != deletion}"
                                #delete (click)="openCancelDialog(feature.featureId)">
                                delete
                            </mat-icon>
                        </td>
                    </ng-container>

                    <div class="search-form">
                        <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
                            <mat-icon matSuffix (click)="applyFilter('');filter.value=''"
                                style="cursor:pointer">clear</mat-icon>
                        </mat-form-field>
                    </div>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

                    <tr mat-row *matRowDef="let row; columns: displayedColumns;let feature"
                        [ngClass]="{'deleted-feature-hightlight': feature.summary == deletion}">
                    </tr>
                </table>

                <ng-container
                    *ngIf="!aixmFeatures_DataSource.data.length || !aixmFeatures_DataSource.filteredData.length">
                    <div class="no-data-wrapper">
                        <mat-icon>info</mat-icon>
                        <p>{{!aixmFeatures_DataSource.data.length ? 'No data yet' : 'Features not found'}}</p>
                    </div>
                </ng-container>
            </div>
            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
            </mat-paginator>
        </div>
    </div>
</ng-container>

<ng-template #customContent>
    <div class="wrapper-date-action">
        <div class="row time-format">
            <div class="displayed-format">
                <mat-label>UTC date time</mat-label>
                <p>{{ displayFormattedTimeZone(editUNForm.get('effectiveDate').value, true)
                    }} </p>
            </div>
            <div class="displayed-format">
                <mat-label>Local date time <span>({{localTimeZone}})</span></mat-label>
                <p>{{ displayFormattedTimeZone(editUNForm.get('effectiveDate').value) }}</p>
            </div>
            <!-- <button mat-flat-button color="primary" matDatepickerApply>Apply</button> -->
        </div>
    </div>
</ng-template>