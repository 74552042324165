<div class="container">
  <mat-toolbar>
    <span>
      <h3> List of Data Profiles </h3>
    </span>
    <span class="spacer"></span>
    <button mat-raised-button routerLink='/new-profile' color="primary" class="button">New Data Profile</button>
  </mat-toolbar>
  <mat-card>
    <mat-card-content>
      <mat-table #table [dataSource]="aixmprofilelist_dataSource" matSort matSortActive="name" matSortDirection="asc" matSortDisableClear>
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a routerLink='/profile/id/{{row.id}}'> {{row.name}}</a>
          </mat-cell>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.description}} </mat-cell>
        </ng-container>

        <!-- For Copy buttons Column -->
<!--         <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-raised-button (click)="openDialogForCopy(row.id,row.name,row.description)" color="primary">Copy</button>
          </mat-cell>
        </ng-container> -->

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table> 
    
    <mat-card-footer>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-footer> 
    
  </mat-card-content>
  </mat-card>

</div>