<mat-form-field class="large" *ngIf="isDMS && !isGeoBorder">
  <mat-label>{{title ? title : "Latitude Longitude"}}</mat-label>
  <input matInput type="text" [formControl]="displayCoordinates" [style.background-color]="color"
    (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'">
  <mat-hint align="start" *ngIf="propertyFocused == 'true'">DMS or DEG</mat-hint>
  <!-- <mat-slide-toggle [(ngModel)]="isDMS">DMS</mat-slide-toggle> -->
  <button type="button" mat-icon-button matPrefix
    matTooltip="Geographical coordinates of the point, either in decimal degrees or degrees, minutes, seconds, decimal of seconds. Use a space between latitude and longitude.">
    <mat-icon color="primary" aria-label="Description">info</mat-icon>
  </button>
  <!-- No delete button because gml:pos is not nillable: the point itself must be deleted.
    But gml:pos may be empty.
  -->
  <!-- map button should be on the AIXM property, not each point-->
  <!-- <mat-hint>DMS or DEG</mat-hint> -->
</mat-form-field>

<button type="button" mat-icon-button matTooltip="Annotations" [disabled]="isEmpty()" *ngIf="!isGeoBorder">
  <mat-icon color="primary" aria-label="Annotations" matBadgeColor="accent" matBadgePosition="below"
    matBadgeSize="small">note</mat-icon>
</button>

<span class="error-message" *ngIf="isInvalid">Please correct this value</span>

<geo-border-point *ngIf="isGeoBorder" [segment]="segment" [previousPoint]="previousPoint" [isSurface]="isSurface"
  [isFirstSegment]="isFirstSegment" (startPointCoordinatesChanged)="onStartPointCoordinatesChanged($event)"
  (onError)="onerror($event)" [isHighlight]="isHighlight"></geo-border-point>
