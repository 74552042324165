<div class="container">
<mat-toolbar>
  <span>
    <h3 class="title"> Edit Data Profile Version</h3>
  </span>
</mat-toolbar>

<mat-card>
  <mat-card-content>

    <ng-container *ngIf="profileVersion">
      <label class="space label"> Schema File </label>
      <div class=" selectedfiles file" *ngFor="let fileName of fileNames">
      <label class="space label"> {{fileName}} </label>
      </div>

      <br/>
        <label class="space label"> Description </label>
        <mat-form-field class="space">
          <textarea matInput name="description" [(ngModel)]="profileVersion.description"></textarea>
        </mat-form-field>
      <br/>
      <br/>

      <mat-card-actions class="buttonspace">
        <button type="submit" mat-raised-button mat-button color="primary" (click)="editProfileVersion();">Edit Profile Version</button>
        <button mat-raised-button color="primary" (click)="clear()">Reset</button>
      </mat-card-actions>
    </ng-container>

      <span>
        <h3 class="title"> Data providers and users who may use this version:</h3>
      </span>

    <div *ngFor="let dataprovider of profileVersion.originators">
      <table>
        <tr>
          <td class="justify">{{dataprovider.name}}</td>
          <span class="spacer"></span>
          <td class="justify"><i class="material-icons md-30" (click)="unassign(dataprovider.identifier.value);"
              style="cursor:pointer" matTooltip="Unassign Data Provider">cancel</i></td>
        </tr>
      </table>
    </div>

    <mat-card-actions>
      <button color="primary" (click)="assignToDataProvider()" class="right assign-btn" style="cursor:pointer"
        matTooltip="Assign Data Provider" mat-icon-button><mat-icon class="assign-icon">add_circle</mat-icon>
      </button>
    </mat-card-actions>
   
  </mat-card-content>
 
</mat-card>
</div>