<div>
    <h3>Task List</h3>
    <mat-card>
        <mat-card-content>   
    <mat-table #table [dataSource]="tasklist_dataSource" matSort>
              
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        
        <!-- TaskId Column -->
        <ng-container matColumnDef="taskId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> TaskId </mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.taskId}} </mat-cell>
        </ng-container>

        <!-- TaskName Column -->
        <ng-container matColumnDef="taskName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> TaskName </mat-header-cell>
            <mat-cell *matCellDef="let task"> {{task.taskName}} </mat-cell>
          </ng-container>

        <ng-container matColumnDef="claim_button">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let task">
                    <button mat-raised-button color="primary" class="button" (click)="claim(task.taskId)" style="cursor:pointer">
                            <i class="material-icons">&#xE7F4;</i> Claim
                    </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    </mat-card-content>
</mat-card>
</div>
