import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Property } from '../model/property';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'im-field-array[arrayName][multipleProperty][parentFormGroup]',
  templateUrl: './field-array.component.html',
  styleUrls: ['./field-array.component.css']
})
export class FieldArrayComponent implements OnInit {

  @Input() multipleProperty: Property;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() arrayName: string;
  @Input() readonly: boolean;

  @Input() tSId: string;
  @Input() fromChoice: boolean = false;
  @Output() timesliceId: EventEmitter<any> = new EventEmitter<any>();
  @Output() removedProperty: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.timesliceId.emit(this.tSId);
    console.log("PropertySwitchComponent: " + JSON.stringify(this.readonly));

    // console.log("Field Array GML ID = " + this.multipleProperty.id);
    //let gmlId = this.multipleProperty.id;
    // for (var prop of this.multipleProperty.properties) {
    //   console.log("Child GML ID = " + prop.id);
    // }

    // console.log("PropertySwitchComponent: " + this.newItemNo + this.newItem);
    // console.log("FieldArrayComponent: " + JSON.stringify(this.multipleProperty));
    // console.log("FieldArrayComponent: " + this.parentFormGroup.getRawValue().toSource());
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  removeProperty(event: any) {
    this.removedProperty.emit(event);
  }
}
