import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Layouts } from '../app.component';
import { DatastoreComponent } from './datastore.component';

const routes: Routes = [{
  path: 'datastore',
  component: DatastoreComponent,
  data: { layout: Layouts.SecureLayout }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DatastoreRoutingModule { }
