import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateQAProjectDto } from "./../model/qualityproject";
import { QualityProjectService } from "../service/quality-project.service";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import {  MatDialog } from "@angular/material/dialog";
import { EMPTY, of } from "rxjs";
import { catchError, filter, switchMap, tap } from "rxjs/operators";
import { Location } from "@angular/common";
import { KeycloakService } from "keycloak-angular";
import { ConfigService } from "../../utils/config.service";
import {  MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-new-quality-project",
  templateUrl: "./new-quality-project.component.html",
  styleUrls: ["./new-quality-project.component.css"],
})
export class NewQualityProjectComponent implements OnInit {
  private result: string;

  qualityProject: CreateQAProjectDto = new CreateQAProjectDto();

  isEdit = this.route.snapshot.url.some((segment) => segment.path === "edit");

  projectId = this.route.snapshot.params.id;

  projectPid = this.route.snapshot.params.pid;

  projectSetting: UntypedFormGroup = this.fb.group({
    pid: [null],
    oid: [null],
    use4eyes: [false],
  });

  checkedhasDataInput = true;

  checkedhasTextEdition = true;

  constructor(
    public router: Router,
    public qualityProjectService: QualityProjectService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private location: Location,
    private keycloackService: KeycloakService,
    private snackbarService: MatSnackBar
  ) {
    const currentKeyCloackRoles = this.keycloackService.getUserRoles();
    const requiredRoles: string[] = [
      ConfigService.settings.role.role_Fqa_Module,
      ConfigService.settings.role.role_Fqa_Project_Manager,
    ];
    if (!requiredRoles.every((role) => currentKeyCloackRoles.includes(role))) {
      this.router.navigate(["quality", "projects"]);
    }
  }

  items: Array<File> = [];

  ngOnInit() {
    of(this.isEdit)
      .pipe(
        filter((isEdit) => !!isEdit),
        tap(() => (this.qualityProject.projectName = this.projectId)),
        switchMap(() =>
          this.qualityProjectService.getProjectSetting(this.projectPid).pipe(
            catchError((error) => {
              if (error) {
                return EMPTY;
              }
            }),
            tap((result) => {
              console.log(result);
              this.projectSetting.patchValue(result);
            })
          )
        )
      )
      .subscribe();
  }

  uploadSection(fileInput: any) {
    console.log("File Upload");
    if (fileInput.target.files && fileInput.target.files[0]) {
      var filesAmount = fileInput.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.items.push(fileInput.target.files[i]);
        console.log("The file is " + fileInput.target.files[i]);
      }
    }
  }

  remove(item: any) {
    var index = this.items.indexOf(item);
    console.log("The index is " + index);
    this.items.splice(index, 1);
  }

  createNewProject(): void {
    this.qualityProject.hasDataInput = this.checkedhasDataInput;
    this.qualityProject.hasTextEdition = this.checkedhasTextEdition;

    this.qualityProjectService
      .createNewProject(this.qualityProject, this.items)
      .pipe(
        catchError((err) => {
          if (err) {
            const error = JSON.parse(err?.error);
            this.snackbarService.open(error.message, "OK", { duration: 4000 });
            this.router.navigate(["quality", "projects"]);
          }
          return EMPTY;
        }),
        filter((response) => !!response),
        tap((result) => {
          this.dialog.open(SuccessDialogComponent, {
            width: "500px",
            data: { result: result + " is successfully created!" },
          });
        })
      )
      .subscribe();
  }

  saveProject(): void {
    this.qualityProjectService
      .editProjectSetting(this.projectSetting.value)
      .pipe(
        catchError((err) => {
          if (err) {
            this.snackbarService.open(err?.error?.message, "OK", { duration: 4000 });
            this.router.navigate(["quality", "projects"]);
          }
          return EMPTY;
        }),
        filter((response) => !!response),
        tap(() => {
          this.router.navigate([
            "/quality",
            "detail-project",
            this.projectId,
            this.projectPid,
          ]);
        })
      )
      .subscribe();
  }

  cancel(): void {
    this.location.back();
  }
}
