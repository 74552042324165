import { ConfigService } from './../../utils/config.service';
import {
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  Router
} from '@angular/router';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { UpdateNoticeService } from '../service/updatenotice.service';
import { CreateUpdateNoticeDTO } from './../model/CreateUpdateNoticeDTO';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateValidation } from './DateValidation';
import { Temporality } from '../model/temporality';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

/* import moment from 'moment';
import 'moment-timezone';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}; */

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-new-updatenotice',
  templateUrl: './new-updatenotice.component.html',
  styleUrls: ['./new-updatenotice.component.css'],
  providers: [UpdateNoticeService,
    //comment timezone for a while as it haven't finished
    /*     {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}, */
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],

    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  // encapsulation: ViewEncapsulation.None,

})
export class NewUpdateNoticeComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({url: ''});
  tempUN: CreateUpdateNoticeDTO = new CreateUpdateNoticeDTO();
  form: UntypedFormGroup;
  timezone: any;
  validEffDate: Date = new Date();
  validCancelDate: Date = new Date();
  dueDate: any;
  noticeDay = environment.noticeNumber;
  //date = new FormControl(moment());
  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public updatenoticeService: UpdateNoticeService,
    private dateAdapter: DateAdapter<any>,
    private _snackBar: MatSnackBar) {
    this.form = formBuilder.group({
      name: ['', Validators.required],
      timezone: [''],
      effectiveDate: ['', Validators.required],
      temporality: ['', Validators.required],
      description: [''],
      cancelDate: ['']
    }, {
      validator: DateValidation.CheckDate
    })
  }
  //public lengthCheckToaddMore = 0;
  public num: any[];
  attachedFiles: FileLikeObject[];

  REFERENCE_AIRAC_DATE = moment("2018-11-08");
  AIRAC_CYCLE_DAYS = 28;

  //highlight Airac Date
  dateClass = (d: Date) => {

    var diff = moment.duration(this.REFERENCE_AIRAC_DATE.diff(d));
    var diffDays = diff.asDays();

    if ((diffDays % this.AIRAC_CYCLE_DAYS) == 0) {
      return 'airac-date-class';
    }
    else {
      return undefined;
    }
  }

  ngOnInit() {
    this.validEffDate.setDate(this.validEffDate.getDate() + ConfigService.settings.periodToCalculateValidEffDate);
    this.validCancelDate.setDate(this.validCancelDate.getDate() + ConfigService.settings.periodToCalculateValidEffDate + 1);
    let maxFileSize = 1 * 1024 * 1024;
    this.uploader = new FileUploader({
      url: '',
      maxFileSize: maxFileSize
    });

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message = 'Error! \nThe uploaded file \"' + item.name + '\" is ' + this.formatBytes(item.size) +
            ', this exceeds the maximum allowed size of ' + this.formatBytes(maxFileSize) + '.';
          break;
        default:
          message = 'Error trying to upload file \"' + item.name + '\".';
          break;
      }
      this.openSnackBar(message, '');
    };
  }

  formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }

  remove(item: any) {
    var index = this.uploader.queue.indexOf(item);
    console.log("The index is " + index);
    this.uploader.queue.splice(index, 1);
  }

  cancel() {
    this.router.navigate(['/update']);
  }
  /* 
  changeUTC() {
    this.dateAdapter.setLocale(moment.utc().format('YYYY-MM-DD HH:mm:ss'));
  }
  
  changeLocale() {
    this.dateAdapter.setLocale(moment.tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'));
  }
   */
  /*   createUploadDocuments(): FormGroup {
      return this.formBuilder.group({
        documentFile: File
      });
    }
  
    get items(): FormArray {
      return this.documentGrp.get('items') as FormArray;
    };
  
    moreFiles(): void {
  
      if (this.attachmentFiles.length != 0)
        if (((this.lengthCheckToaddMore) === (this.attachmentFiles.length))) {
          this.items.insert(0, this.createUploadDocuments())
          this.lengthCheckToaddMore = this.lengthCheckToaddMore + 1;
        }
    }
  
    public fileSelectionEvent(fileInput: any, oldIndex) {
      if (fileInput.target.files && fileInput.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
        }
        if (oldIndex == 0) {
          this.attachmentFiles.unshift((fileInput.target.files[0]))
          this.totalFileName.unshift(fileInput.target.files[0].name);
          //this.tempUN.attachmentFileNames.push(fileInput.target.files[0].name);
        }
        else {
          this.attachmentFiles[oldIndex] = (fileInput.target.files[0]);
          this.totalFileName[oldIndex] = fileInput.target.files[0].name
          //this.tempUN.attachmentFileNames[oldIndex] = fileInput.target.files[0].name
        }
        reader.readAsDataURL(fileInput.target.files[0]);
      }
  
      if (this.attachmentFiles.length == 1) {
        this.lengthCheckToaddMore = 1;
      }
    } */


  addUpdateNotice(unFormValue): void {
    this.attachedFiles = this.getFiles();
    console.log("The temporality is " + unFormValue.temporality)
    if (unFormValue.temporality == Temporality.PERMANENT) {
      unFormValue.cancelDate = null;
    }
    let newUN: CreateUpdateNoticeDTO = {
      name: unFormValue.name,
      effectiveDate: unFormValue.effectiveDate,
      cancelDate: unFormValue.cancelDate,
      description: unFormValue.description,
      temporality: unFormValue.temporality
    }
    console.log("before calling service :" + this.attachedFiles.length);
    this.updatenoticeService.addUpdateNotice(newUN, this.attachedFiles)
      .subscribe(tempUN => {
        this.tempUN = tempUN,
          this.router.navigate(['/update']);
      });

  }

  getDueDate(effectiveDate): void {
    this.dueDate = moment(effectiveDate.value);
    this.dueDate.subtract(this.noticeDay, "days");
    this.dueDate = this.dueDate.format('DD MMM YYYY').toUpperCase();
  }

  observableSubscribeTest(): void {
    this.updatenoticeService.observableSubscribeTest()
      .subscribe({
        next: num => this.num = num,
        error: err => console.error('Observer got an error: ' + err),
        complete: () => console.log('Observer got a complete notification'),
      });
  }


  isAiracDate(argDate: Date): Boolean {
    var startDate = new Date("1/14/2010");
    var argDate = new Date("1/24/2018");
    var timeDiff = Math.abs(argDate.getTime() - startDate.getTime());
    var num_of_days = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if ((num_of_days % 28) != 0)
      return false;
    else return true;
  }
}
