<div class="container">
  
  <mat-toolbar>
    <span><h3> Available Log Sheets </h3></span>
    <span class="spacer"></span>
    <button mat-raised-button (click)="openDialog()" color="primary" class="button">New Log Sheet</button>
  </mat-toolbar>    
      
  <mat-card>
      
      <mat-card-header>
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
            <mat-icon matSuffix (click)="applyFilter('');filter.value=''" style="cursor:pointer">clear</mat-icon>
          </mat-form-field>
      </mat-card-header>
         
      <mat-card-content>
          <mat-table #table [dataSource]="logsheets_dataSource" matSort>
              
              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
              
              <!-- Type Column -->
              <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
                <mat-cell *matCellDef="let logsheet"> {{logsheet.type}} </mat-cell>
              </ng-container>
              
              <!-- Number Column -->
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Number </mat-header-cell>
                <mat-cell *matCellDef="let logsheet"><a [routerLink]="['/logentrylist', logsheet.id]">{{logsheet.id}}</a></mat-cell>
              </ng-container>
              
              <!-- Status Column -->
              <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                  <mat-cell *matCellDef="let logsheet"> {{logsheet.status}} </mat-cell>
              </ng-container>
          
              <!-- Subject Column -->
              <ng-container matColumnDef="subject">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Subject </mat-header-cell>
                <mat-cell *matCellDef="let logsheet"> {{logsheet.subject}} </mat-cell>
              </ng-container>
                  
              <!-- Last Entry Column -->
              <ng-container matColumnDef="lastCreatedDate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Entry </mat-header-cell>
                  <mat-cell *matCellDef="let logsheet"> {{logsheet.lastCreatedDate}} </mat-cell>
              </ng-container>

              <!-- <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
              <mat-cell *matCellDef="let logsheet">
              <button mat-raised-button mat-button color="warn" class="example-btn-width" *ngIf="logsheet.status=='OPEN'"
               (click)="close()">CLose</button>
               <button mat-raised-button mat-button color="primary" class="example-btn-width" *ngIf="logsheet.status=='CLOSED'"
               (click)="close()">Reopen</button>
              </mat-cell>
              </ng-container> -->
              
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>  
      
      <mat-card-footer>
          <mat-paginator #paginator
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 20]"
            [showFirstLastButtons]="true">
          </mat-paginator>
      </mat-card-footer>  
      <br>
      </mat-card-content>  
  </mat-card>
</div>