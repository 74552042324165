import { environment } from './../../environments/environment';
import { NgModule } from '@angular/core';
import { Routes, 
         RouterModule } from '@angular/router';
import { AppAuthGuard } from '../login/services/app.authguard';
import { UpdatenoticeListComponent } from './updatenotice-list/updatenotice-list.component';
import { UpdatenoticeListProvidersComponent } from './updatenotice-list-providers/updatenotice-list-providers.component';
import { NewUpdateNoticeComponent } from './new-updatenotice/new-updatenotice.component';
import { ReviewUpdateNoticeComponent } from './review-updatenotice/review-updatenotice.component';
import { EditUpdateNoticeComponent } from './edit-updatenotice/edit-updatenotice.component';
import { DeleteUpdateNoticeComponent } from './delete-updatenotice/delete-updatenotice.component';
import { Layouts } from '../app.component';

const UNroutes: Routes = [
  {
    path: 'update',
    component: UpdatenoticeListComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'update/new',
    component: NewUpdateNoticeComponent,
    canActivate: [AppAuthGuard], 
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Data_Provider] }
  },
  {
    path: 'updatenotice-list-providers',
    component: UpdatenoticeListProvidersComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'review-updatenotice/:id',
    component: ReviewUpdateNoticeComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'update/id/:id',
    component: EditUpdateNoticeComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'delete-updatenotice/:id',
    component: DeleteUpdateNoticeComponent,
    data: { layout: Layouts.SecureLayout }
  },
];

@NgModule({
  imports: [RouterModule.forChild(UNroutes)],
  exports: [RouterModule]
})

export class UpdateNoticeRoutingModule { }
