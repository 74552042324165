<h3>Timeline</h3>
<div #visualization></div>

<form [formGroup]="datasetOptionForm" *ngIf="datasetOptionForm.controls['date'].value"
  (ngSubmit)="generateAipDataset()">
  <div class="container">
    <br />
    <mat-radio-group formControlName="interpretation">
      <mat-radio-button value="BASELINE">BASELINE </mat-radio-button>
      <mat-radio-button value="SNAPSHOT">SNAPSHOT</mat-radio-button>
    </mat-radio-group>
    <br />
    <mat-radio-group formControlName="dataType" (change)="enableOrDisableInterpretation()">
      <mat-radio-button value="official">Official Data </mat-radio-button>
      <mat-radio-button value="officialDataWithChanges"> Official Data with Proposed Changes </mat-radio-button>
      <mat-radio-button value="changes"> Proposed Changes </mat-radio-button>
    </mat-radio-group>
    <br />
    <label style="margin-bottom: 10px;">Export format: </label>
    <mat-radio-group formControlName="exportFormat">
      <mat-radio-button value="aixm5.1">AIXM 5.1 </mat-radio-button>
      <mat-radio-button value="aixm5.1.1">AIXM 5.1.1 </mat-radio-button>
      <mat-radio-button value="aixm5.1.1WithFacilisExtensions"> AIXM 5.1.1 with Facilis.aero extensions
      </mat-radio-button>
    </mat-radio-group>
    <br />
    <mat-radio-group formControlName="isFullDataset">
      <mat-radio-button value="true"> Full dataset </mat-radio-button>
    </mat-radio-group>
  </div>
  <br />
  <button mat-raised-button color="primary" type="submit"
    [disabled]="!datasetOptionForm.dirty || !datasetOptionForm.valid">
    Generate Dataset
  </button>
</form>
<br/>

<button mat-raised-button color="primary" *ngIf="datasetPath" (click)="download()">
Download Dataset
</button>