<!-- Choose the type of centre point -->
<button mat-icon-button [matMenuTriggerFor]="pointTypeMenu" aria-label="" matTooltip="Centre point defined by...">
  <ng-container [ngSwitch]="centrePointType">
    <mat-icon *ngSwitchCase="gmlPointType.COORDINATES" color="primary">edit_location</mat-icon>
    <mat-icon *ngSwitchCase="gmlPointType.POINT_REFERENCE" color="primary">insert_link</mat-icon>
    <!-- NB: in aviation, we would never use a GeoBorder point as arc centre -->
  </ng-container>
</button>
<mat-menu #pointTypeMenu="matMenu">
  <button mat-menu-item (click)="setCentrePointType(gmlPointType.COORDINATES)">
    <mat-icon color="primary">edit_location</mat-icon>
    <span>Latitude and longitude</span>
  </button>
  <button mat-menu-item (click)="setCentrePointType(gmlPointType.POINT_REFERENCE)">
    <mat-icon color="primary">insert_link</mat-icon>
    <span>A feature's location</span>
  </button>
</mat-menu>

<latlong *ngIf="centrePointType === gmlPointType.COORDINATES" [coordinates]="centrePointCoordinates"
  (coordinatesChange)="onCoordinatesChange($event)" [isHighlight]="isHighlight"
  (centrePointChange)="onCentrePointChange()"></latlong>
<point-reference *ngIf="centrePointType === gmlPointType.POINT_REFERENCE" [segment]="segment"
  (startPointCoordinatesChanged)="onCoordinatesChange($event)" (centrePointChange)="onCentrePointChange()"
  title="Along the arc centred on" [isHighlight]="isHighlight">
</point-reference>

<mat-form-field class="radius">
  <mat-label>Radius</mat-label>
  <input matInput type="number" [value]="radius" [style.background-color]="color" (change)="onRadiusChange($event)">
  <mat-hint>Number</mat-hint>
</mat-form-field>

<mat-form-field class="radius-uom">
  <mat-label>Unit</mat-label>
  <mat-select [value]="radiusUom" [style.background-color]="color" (selectionChange)="onRadiusUomChange($event)">
    <mat-option value="m">m</mat-option>
    <mat-option value="km">km</mat-option>
    <mat-option value="[nmi_i]">nm</mat-option>
  </mat-select>
</mat-form-field>

<!-- No field for angles: they are calculated -->

<!-- Buttons to select the arc direction -->
<!-- <mat-button-toggle-group #direction="matButtonToggleGroup" value="clockwise">
  <mat-button-toggle value="clockwise" matTooltip="Clockwise">
    <mat-icon [color]="direction.value == 'clockwise' ? 'primary' : ''" class="mirror">settings_backup_restore
    </mat-icon>
  </mat-button-toggle>
  <mat-button-toggle value="counter-clockwise" matTooltip="Counter-clockwise">
    <mat-icon [color]="direction.value == 'counter-clockwise' ? 'primary' : ''">settings_backup_restore</mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group> -->