import { Component, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ChartConfiguration, ChartData } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { Subject, takeUntil, tap, switchMap, startWith, map, forkJoin } from "rxjs";
import { FormBuilder, FormControl } from "@angular/forms";
import { CardTplContext, StatsType, ValidationStatisticsLastTwelveMonths } from "./validation-stats.interface";
import { AixmValidatorService } from "../service/aixm-validator.service";
import moment from "moment";

@Component({
  selector: "app-validation-stats",
  standalone: false,
  templateUrl: "./validation-stats.component.html",
  styleUrl: "./validation-stats.component.scss",
})
export class ValidationStatsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();

  private validatorService = inject(AixmValidatorService);

  private fb: FormBuilder = inject(FormBuilder);

  private minYear = 2023;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective<"bar"> | undefined;

  totalTplContext: CardTplContext = {
    id: "total",
    title: "Total Validations",
    value: null,
    sub: "Since project started",
    icon: "task",
    color: "primary",
  };

  successTplContent: CardTplContext = {
    id: "success",
    title: "Successful Validations",
    value: null,
    sub: `0% success rate`,
    icon: "check_circle",
    color: "primary",
  };

  usersTplContext: CardTplContext = {
    id: "num-users",
    title: "Number of Users",
    value: null,
    sub: "Over the last 12 months",
    icon: "group",
    color: "test",
  };

  selectedStatsType: StatsType = StatsType.LAST_12_MONTHS;

  statsType = StatsType;

  barChartOptions: ChartConfiguration<"bar">["options"] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
          pointStyle: "rect",
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: "#f0f0f0",
        },
        border: {
          dash: [5, 5],
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#f0f0f0",
        },
        border: {
          dash: [5, 5],
        },
      },
    },
  };

  barChartType = "bar" as const;

  barChartData: ChartData<"bar"> = null;

  yearRange: number[] = Array.from(
    { length: moment().get("year") - this.minYear + 1 },
    (_, index) => this.minYear + index
  );

  validationsPeriodForm = this.fb.group({
    range: new FormControl({
      id: "last-twelve-months",
      title: "Last 12 Months",
      startDate: moment().subtract(12, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    }),
  });

  yearRangeOptGroups = [
    {
      title: "Calendar Year",
      options: this.yearRange.map((value) => ({
        id: value,
        title: value,
        startDate: moment().year(value).startOf("year").format("YYYY-MM-DD"),
        endDate: moment().year(value).endOf("year").format("YYYY-MM-DD"),
      })),
    },
  ];

  yearRangeOptions = [
    {
      id: "last-twelve-months",
      title: "Last 12 Months",
      startDate: moment().subtract(12, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    {
      id: "faiva-2023-1",
      title: "FAIVA 2023.1",
      startDate: moment().year(2023).month(11).startOf("month").format("YYYY-MM-DD"),
      endDate: moment().year(2025).month(3).endOf("month").format("YYYY-MM-DD"),
    },
    {
      id: "faiva-2025-1",
      title: "FAIVA 2025.1",
      startDate: moment().month(3).startOf("month").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
  ];

  onSelectChange$ = this.validationsPeriodForm.valueChanges.pipe(
    map((value) => value.range),
    startWith({
      id: "last-twelve-months",
      title: "Last 12 Months",
      startDate: moment().subtract(12, "months").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    })
  );

  ngOnInit(): void {
    this.getOverviewStats();
    this.getSeriesStats();
  }

  getOverviewStats(): void {
    const activeUsers$ = () => {
      // Fixed using last 12 months
      const period = {
        startDate: moment().subtract(12, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      };
      return this.validatorService.getValidationsStatsActiveUsers(period.startDate, period.endDate);
    };

    forkJoin([this.validatorService.getValidationsStatsOverview(), activeUsers$()])
      .pipe(
        tap(([overview, activeUsers]) => {
          const isValidTotalValue = overview.totalSuccessValidations && overview.totalValidations;

          this.usersTplContext = Object.assign(this.usersTplContext, { value: activeUsers.numberOfActiveUsers });
          this.totalTplContext = Object.assign(this.totalTplContext, { value: overview.totalValidations });
          this.successTplContent = Object.assign(this.successTplContent, {
            value: overview.totalSuccessValidations,
            sub: `${
              isValidTotalValue ? ((overview.totalSuccessValidations / overview.totalValidations) * 100).toFixed(1) : 0
            }% success rate`,
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getSeriesStats(): void {
    this.onSelectChange$
      .pipe(
        switchMap((value) => {
          const { startDate, endDate } = value;
          return this.validatorService.getValidationsStatsMonthly(startDate, endDate);
        }),
        tap((response: ValidationStatisticsLastTwelveMonths) => {
          const { label, successValidations, totalValidations } = response.reduce(
            (accumulator, value) => {
              accumulator.totalValidations.push(value.numberOfValidations);
              accumulator.successValidations.push(value.successValidations);
              accumulator.label.push(moment(value.monthYearOfValidation).format("MMM YYYY"));
              return accumulator;
            },
            { totalValidations: [], successValidations: [], label: [] }
          );

          this.barChartData = this._setChartData(totalValidations, successValidations, label);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private _setChartData(totalValidations: number[], successValidations: number[], labels: string[]): ChartData<"bar"> {
    return {
      labels: labels,
      datasets: [
        {
          label: "Total Validations",
          data: totalValidations,
          backgroundColor: "rgba(0, 111, 136, .4)",
          borderWidth: 0,
          // barThickness: 30,
          barPercentage: 0.8,
          categoryPercentage: 0.8,
        },
        {
          label: "Successful Validations",
          data: successValidations,
          backgroundColor: "rgba(0, 111, 136, 1)",
          borderWidth: 0,
          // barThickness: 30,
          barPercentage: 0.8,
          categoryPercentage: 0.8,
        },
      ],
    };
  }

  compareObjects(o1: any, o2: any): boolean {
    if (o1.id === o2.id) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
