import {Component, OnInit, ViewChild} from '@angular/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import { Profile } from '../model/profile.model';
import { ProfileVersion } from '../model/profileversion.model';
import { DataprofileService } from './../service/dataprofile.service';
import { EditDataprofileVersionComponent } from '../edit-dataprofile-version/edit-dataprofile-version.component';



@Component({
  selector: 'app-edit-dataprofile',
  templateUrl: './edit-dataprofile.component.html',
  styleUrls: ['./edit-dataprofile.component.css'],
  providers: [DataprofileService],
})
export class EditDataprofileComponent implements OnInit {
  profile: Profile= new Profile();
  profilelist: Profile[] = [];
  profileversionlist: ProfileVersion[] = [];
  profileversion: ProfileVersion;

  displayedColumns = ['Number','Description','Data Providers','edit_icon'];
  aixmProfileVersionlist_dataSource = new MatTableDataSource<ProfileVersion>(this.profileversionlist);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.aixmProfileVersionlist_dataSource.sort = this.sort;
    this.aixmProfileVersionlist_dataSource.paginator = this.paginator;
  }
  
  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.aixmProfileVersionlist_dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute, 
    private router: Router, 
    private dataProfileService: DataprofileService,public dialog: MatDialog) { }

  ngOnInit() {
    const id = this.route.snapshot.params['id'];
    this.getProfile(id);
    this.getProfileVersions(id);
  }

  getProfile(id: string): void {
    this.dataProfileService.getProfile(id)
      .subscribe(profile => this.profile = profile);
  }

  getProfileVersions(id: string): void {
    this.dataProfileService.getProfileVersions(id)
      .subscribe(profileversionlist => this.aixmProfileVersionlist_dataSource.data = profileversionlist);
  } 

  clear(): void {
    this.ngOnInit();
  }

  editProfile(): void {
    this.dataProfileService.editProfile(this.profile.id,
      {id: '', name: this.profile.name, description: this.profile.description} as Profile)
    .subscribe(newProfileId => {
      this.router.navigate(['/profile/id', newProfileId]);
    });
  }  

}

