import { User } from './../model/user.model';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserDetailsService {
    constructor(protected keycloakservice: KeycloakService) { }

    getUser(): User {
        try {
            let username = this.keycloakservice.getUsername();
            let dataproviderID = this.keycloakservice.getKeycloakInstance().tokenParsed["data_provider_id"];
            //console.log(this.keycloakservice.getKeycloakInstance().tokenParsed);
            let user: User = {
                username: username,
                dataProviderId: dataproviderID
            };
            console.log('Successful to load user details', user.username, user.dataProviderId);
            return user;
        } catch (e) {
            console.log('Failed to load user details', e);
        }
    }

    // Used by other components to query roles (permissions) of the current user.
    hasRoles(roles: string[]): boolean {
        for (let i = 0; i < roles.length; i++) {
            if (this.hasRole(roles[i])) {
                return true;
            }
        }
        return false;
    }

    // Used by other components to query role (permissions) of the current user.
    hasRole(role: string): boolean {
        return this.keycloakservice.isUserInRole(role);
    }

    getToken(): string {
        return this.keycloakservice.getKeycloakInstance().token
    }

    getTenantName(): string {
        return this.getDefaultDatastore();
    }

    getDefaultDatastore(): string {
        let defaultDatastore: string = this.keycloakservice.getKeycloakInstance().tokenParsed[environment.attributes.datastore];
        defaultDatastore = defaultDatastore == undefined ? "" : defaultDatastore;
        return defaultDatastore;
    }

    //   getToken(): Promise<string> {
    //         return new Promise<string>((resolve, reject) => {
    //             if (this.keycloakservice.getKeycloakInstance().token) {
    //               this.keycloakservice
    //                     .updateToken(90) // refresh token if it will expire in 90 seconds or less
    //                     .then(() => {
    //                         resolve(this.keycloakservice.getKeycloakInstance().token);
    //                     })
    //             } else {
    //                 reject('Not logged in');
    //             }
    //         });
    //     }

}