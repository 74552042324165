import { Component, Inject, OnInit } from "@angular/core";
import {  MatDialogRef,  MAT_DIALOG_DATA } from "@angular/material/dialog";
import {  MatSnackBar } from "@angular/material/snack-bar";
import { DataProvider } from "../../data-provider/model/dataprovider";
import { InfoManagementService } from "../service/info-management.service";
import { SelectionModel } from "@angular/cdk/collections";
import {  MatSelectionListChange } from "@angular/material/list";
import { catchError, filter, tap } from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
  selector: "app-dataprovider-dialog-component",
  templateUrl: "./dataprovider-dialog-component.component.html",
  styleUrls: ["./dataprovider-dialog-component.component.css"],
})
export class DataproviderDialogComponentComponent implements OnInit {
  dataproviders: DataProvider[] = [];

  selectedEditors = new SelectionModel(true, []);

  constructor(
    private infoManagementService: InfoManagementService,
    public dialogRef: MatDialogRef<DataproviderDialogComponentComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dataproviders = this.data.dataProviders.filter(provider => !!provider.dataprovider);
    const selectedEditor = this.data.allowedEditors
      .map((value: DataProvider) => {
        return this.dataproviders.find(
          (provider) => provider?.identifier?.value === value?.identifier?.value
        );
      })
      .filter((provider) => provider !== undefined);

    this.selectedEditors.select(...selectedEditor);
  }

  onSelect(event: MatSelectionListChange) {
    this.selectedEditors.toggle(event.options[0].value);
  }

  assign() {
    this.infoManagementService
      .assignAllowedEditors(
        this.data.featureType,
        this.data.featureId,
        this.selectedEditors.selected as unknown as Set<DataProvider>
      )
      .pipe(
        catchError((err) => {
          if (err) {
            this.dialogRef.close();
            return throwError(err);
          }
        }),
        tap(() => {
          this.openSnackBar("Assigning allowed editor(s) is successful.", "Ok");
          this.dialogRef.close();
        })
      )
      .subscribe();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
