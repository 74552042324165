import { Component, OnInit, Inject } from "@angular/core";
import {  MatDialogRef,  MAT_DIALOG_DATA } from "@angular/material/dialog";
import { QualityProjectService } from "./../service/quality-project.service";
import { delay, filter, tap } from "rxjs/operators";

export interface DialogData {
  id: string;
  name: string;
}

@Component({
  selector: "app-complete-review-dialog",
  templateUrl: "./complete-review-dialog.component.html",
  styleUrls: ["./complete-review-dialog.component.css"],
})
export class CompleteReviewDialogComponent implements OnInit {
  constructor(
    private qaService: QualityProjectService,
    public dialogRef: MatDialogRef<CompleteReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {}

  complete(taskId: string, decision: boolean): void {
    this.qaService
      .completeReview(taskId, decision)
      .pipe(tap(() => this.dialogRef.close(this.data.id)))
      .subscribe();
  }

  onCloseCancel() {
    this.dialogRef.close();
  }
}
