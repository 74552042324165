import { UserSummaryDto } from './../model/UserSummaryDto';
import { UserManageMentService } from './../service/user-management.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {  MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-user-management-list',
  templateUrl: './user-management-list.component.html',
  styleUrls: ['./user-management-list.component.css']
})
export class UserManagementListComponent implements OnInit {
  private users: UserSummaryDto[] = [];

  //datasource for table
  displayedColumns = ['userName', 'firstName', 'lastName', 'email', 'enabled'];
  users_dataSource = new MatTableDataSource<UserSummaryDto>(this.users);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.users_dataSource.sort = this.sort;
    this.users_dataSource.paginator = this.paginator;
  }

  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.users_dataSource.filter = filterValue;
  }
  constructor(private userManageMentService: UserManageMentService) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers(): void {
    this.userManageMentService.getUsers()
      .subscribe(users => this.users_dataSource.data = users);
  }
}
