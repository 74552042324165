import { GmlInterpolation } from './../model/GmlInterpolation';
import { ConfirmationDialogComponent } from './../confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, Input, OnChanges, AfterViewInit, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import Collection from 'ol/Collection';
// import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
//import Geolocation from 'ol/Geolocation';
import { Feature } from 'ol';
import { ScaleLine, FullScreen, Attribution, defaults as defaultControls } from 'ol/control';
import { Units } from 'ol/control/ScaleLine';
import { GeoJSON } from 'ol/format';
import { BehaviorSubject } from 'rxjs';
import { ArcByCentre } from '../model/arc-by-centre';
import * as uuid from 'uuid';
import {  MatDialog } from '@angular/material/dialog';
import { Geometry, LineString } from 'ol/geom';

/**
 * Angular component for entering a list of coordinates either for gml:segments or gml:patches.
 */
@Component({
  selector: 'geo-editor',
  templateUrl: './geo-editor.component.html',
  styleUrls: ['./geo-editor.component.css']
})
export class GeoEditorComponent implements AfterViewInit, OnChanges {
  private _gml = new BehaviorSubject<any>([]);

  @Input()
  set gml(value) {
    this._gml.next(value);
  }
  get gml(): any { return this._gml.getValue(); };

  @Input() isSurface: boolean;
  @Input() idPrefix: string;
  @Input() isHighlight: boolean;
  @Input() isRevert: boolean;
  @Input() isEmpty: boolean;
  @Output() gmlChange = new EventEmitter<string>();
  @Output() onError = new EventEmitter<boolean>();
  @Output() isGeoEmpty = new EventEmitter<boolean>();
  @Output() isSetNil = new EventEmitter<boolean>();

  radiusChange: boolean;
  radiusUomChange: boolean;
  centrePointChange: boolean;
  arcDirectionChange: string;
  direction: string;

  mapId: string;
  toggleValue: string[] = [];
  segments: Collection<Feature> = new Collection([], { unique: true });
  geoEditorLayer = new VectorLayer({
  });

  attribution = new Attribution({
    collapsible: true,
    collapsed: true,
  });

  // initial view
  view = new View({
    center: [0, 0],
    zoom: 2,
    maxZoom: 18,
    projection: 'EPSG:4326',
    multiWorld: true
  });

  scaleUnit: Units = 'nautical';
  units = [
    { key: "Metric", value: "metric" },
    { key: "Nautical miles", value: "nautical" },
    { key: "Degrees", value: "degrees" }
  ];

  scaleLine = new ScaleLine({
    units: this.scaleUnit,
    bar: true,
    steps: 4,
    text: true,
    minWidth: 140,
  })

  // The map
  map: Map = new Map({
    layers: [
      new TileLayer({
        source: new OSM({
          attributions: 'Background: &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
          //maxZoom: 18
        }),
        //sphericalMercator: true
      }),
      this.geoEditorLayer
    ],
    controls: defaultControls({ attribution: false }).extend([
      this.attribution,
      // new ZoomToExtent({
      //   extent: this.geoEditorSource.getExtent()
      // }),
      new FullScreen(),
      this.scaleLine,
    ]),
    view: this.view
  });

  constructor(public dialog: MatDialog, private cdr: ChangeDetectorRef) {
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  _isShowMap = new BehaviorSubject<any>([]);

  @Input()
  set isShowMap(value) {
    this._isShowMap.next(value);
  }
  get isShowMap(): any { return this._isShowMap.getValue(); };

  ngOnInit() {
    this._gml.subscribe(gml => {      
      if (gml) {
        this.parseGml(gml);
      } else {
        this.segments = new Collection([]);
        // Update the VectorLayer
        let geoEditorSource: VectorSource = new VectorSource({
        })
        this.geoEditorLayer.setSource(geoEditorSource);
        this.map.setTarget(this.mapId);
      }
    });

    (this.isCircle()) ? this.toggleValue = ["CircleByCenterPoint"] : this.toggleValue = ["segments"];

    if (this.idPrefix) {
      this.mapId = this.idPrefix + "_geoEditorMap";
    } else {
      this.mapId = uuid.v4() + "_geoEditorMap";
    }
    if (this.segments.getArray().length != 0 && this.segments.getArray()[0].get("interpolation") == "CIRCLE_BY_CENTRE") {
      if (this.segments.getArray()[0].get("centre").geometry.coordinates.length != 0 && this.radiusChange != undefined &&
        this.radiusUomChange != undefined) {
        this.map.getView().fit(this.geoEditorLayer.getSource().getExtent(), {
          size: this.map.getSize(),
          //padding: [10, 10, 10, 10],
          duration: 2000,
        });
      }
    }
  }

  setScaleUnit(unit: Units) {
    this.scaleLine.setUnits(unit);
    console.log(this.scaleLine.getUnits());
  }

  // I (BM) didn't manage to make this work, possibly a OL types library error:
  // https://github.com/hanreev/types-ol/issues/22
  // geolocation = new Geolocation({
  //   trackingOptions: {
  //     enableHighAccuracy: true,
  //   },
  //   projection: this.view.getProjection(),
  // });

  onFormSubmit() {
    //alert(JSON.stringify(this.formGroup.value, null, 2));
  }

  onGeoDataChange(segmentCollection: Feature<Geometry>[]) {
    this.createGeoJsonAndEmit(segmentCollection);
  }

  onCircleChange(circle: Feature) {
    this.segments.setAt(0, circle);
    this.createGeoJsonAndEmit(this.segments.getArray());
  }

  createGeoJsonAndEmit(segmentCollection: Feature<Geometry>[]) {
    let features = [];
    var geoJsonStr: string = this.geoJson.writeFeatures(segmentCollection);
    let geoJsonObj = JSON.parse(geoJsonStr);
    features = geoJsonObj.features;
    let featuresCollection = this.calculateArcCirclePoints(features);
    this.gmlChange.emit(JSON.stringify(featuresCollection));
  }

  ngAfterViewInit() {
    console.log("mapId: " + this.mapId);
    this.map.setTarget(this.mapId);
  }

  ngOnChanges(changes: SimpleChanges) {
    // center the map on the feature collection
    if (changes.scaleUnit) {
      this.scaleLine.setUnits(this.scaleUnit);
    } else if (this.segments.getLength() === 0) {
      // center the view on the user's location
      //this.geolocation.setTracking(true);
      // TODO not working yet
      //console.log("Position: '" + this.geolocation.getPosition() + "'");
      // this.view.animate({
      //   center: this.geolocation.getPosition(),
      //   duration: 1000,
      // });
    }
    else {
      if (this.segments.getArray().length != 0 && (<LineString>this.segments.getArray()[0].getGeometry()).getCoordinates().length != 0) {
        // TODO center the view on the features
        if (this.segments.getArray()[0].get("interpolation") == "CIRCLE_BY_CENTRE") {
          if (this.segments.getArray()[0].get("centre").geometry.coordinates.length != 0 && this.radiusChange != undefined
            && this.radiusUomChange != undefined) {
            this.map.getView().fit(this.geoEditorLayer.getSource().getExtent(), {
              size: this.map.getSize(),
              //padding: [10, 10, 10, 10],
              duration: 2000,
            });
          }
        }
        else {
          this.map.getView().fit(this.geoEditorLayer.getSource().getExtent(), {
            size: this.map.getSize(),
            //padding: [10, 10, 10, 10],
            duration: 2000,
          });
        }
      }
    }
  }

  onerror(isInvalid: boolean) {
    this.onError.emit(isInvalid);
  }

  private geoJson = new GeoJSON();
  parseGml(gml: any) {
    console.log("gml json object: " + gml);
    let myobj = JSON.parse(gml);
    let features = [];
    features = myobj.features;

    // generate points for arc and cirle
    let featuresCollection = this.calculateArcCirclePoints(features);
    if (featuresCollection != null) {
      this.segments = new Collection(this.geoJson.readFeatures(featuresCollection));
    }

    // Update the VectorLayer
    let geoEditorSource: VectorSource = new VectorSource({
      features: this.segments,
      wrapX: false
    })
    this.geoEditorLayer.setSource(geoEditorSource);

  }

  // Generate points for ARC_BY_CENTRE
  calculateArcPoints(center: any, radius: any, radiusUom: string, startAzimuth: any, endAzimuth: any, segments: any,
    direction: string, startPointType: any, endPointType: any, centrePointType: any) {
    let pointList = [];
    let point: any;
    let d: any;
    let lat1: any;
    let lon1: any;
    // d = distance in km
    if (radiusUom == "km") {
      d = radius;
    }
    else if (radiusUom == "m") {
      d = radius / 1000;
    }
    else if (radiusUom == "[nmi_i]") {
      d = radius * 1.852;
    }

    lat1 = this.deg2Rad(center[1]);
    lon1 = this.deg2Rad(center[0]);

    let R = 6371.0; // km, R = earth's radius (mean radius = 6,371km)    

    let lat2: any;
    let lon2: any;
    let dAngle = segments + 1;
    let azimuth: any;
    let startAngle = startAzimuth;
    let endAngle = endAzimuth;
    startAzimuth = this.deg2Rad(startAzimuth);
    endAzimuth = this.deg2Rad(endAzimuth);

    for (let i = 0; i < dAngle; i++) {
      azimuth = startAzimuth - (startAzimuth - endAzimuth) * i / (dAngle - 1);
      lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) +
        Math.cos(lat1) * Math.sin(d / R) * Math.cos(azimuth));

      lon2 = lon1 + Math.atan2(Math.sin(azimuth) * Math.sin(d / R) * Math.cos(lat1),
        Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2));
      lat2 = 180 * lat2 / Math.PI;
      lon2 = 180 * lon2 / Math.PI;

      point = [lon2, lat2];
      pointList.push(point);
    }

    let arcPointsGJ;
    if (direction == "clockwise") {
      arcPointsGJ = {
        "type": "Feature",
        "geometry": {
          "type": "LineString",
          "coordinates": pointList
        },
        "properties": {
          "interpolation": GmlInterpolation.ARC_BY_CENTRE,
          "radius": radius,
          "radiusUom": radiusUom,
          "startAngle": startAngle,
          "endAngle": endAngle,
          "direction": direction,
          "startPoint": startPointType,
          "endPoint": endPointType,
          "centre": {
            "type": "Feature",
            "properties": {
              "centrePoint": centrePointType
            },
            "geometry": {
              "type": "Point",
              "coordinates": center
            }
          }
        }
      }
    } else {
      arcPointsGJ = {
        "type": "Feature",
        "geometry": {
          "type": "LineString",
          "coordinates": pointList
        },
        "properties": {
          "interpolation": GmlInterpolation.ARC_BY_CENTRE,
          "radius": radius,
          "radiusUom": radiusUom,
          "startAngle": startAngle,
          "endAngle": endAngle,
          "direction": direction,
          "startPoint": startPointType,
          "endPoint": endPointType,
          "centre": {
            "type": "Feature",
            "properties": {
              "centrePoint": centrePointType
            },
            "geometry": {
              "type": "Point",
              "coordinates": center
            }
          }
        }
      }
    }

    return arcPointsGJ;
  }

  // Generate Points for CIRCLE_BY_CENTRE
  calculateCirclePoints(center: any, radius: any, radiusUom: string, segments: number, centrePointType: any) {
    let pointList = [];
    let point: any;
    let d: any;
    let lat1: any, lon1: any;
    // centre point of circle

    lat1 = this.deg2Rad(center[1]);
    lon1 = this.deg2Rad(center[0]);

    if (radiusUom == "km") {
      d = radius;
    }
    else if (radiusUom == "m") {
      d = radius / 1000;
    }
    else if (radiusUom == "[nmi_i]") {
      d = radius * 1.852;
    }
    let R = 6371.0; // km, R = earth's radius (mean radius = 6,371km) 
    let lat2: any;
    let lon2: any;
    let dAngle = segments + 1;
    let azimuth: any;
    for (let i = 1; i < dAngle; i++) {
      azimuth = this.deg2Rad(i);
      lat2 = Math.asin(Math.sin(lat1) * Math.cos(d / R) +
        Math.cos(lat1) * Math.sin(d / R) * Math.cos(azimuth));

      lon2 = lon1 + Math.atan2(Math.sin(azimuth) * Math.sin(d / R) * Math.cos(lat1),
        Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2));
      lat2 = 180 * lat2 / Math.PI;
      lon2 = 180 * lon2 / Math.PI;

      point = [lon2, lat2];
      pointList.push(point);
    }

    pointList[pointList.length] = pointList[0];
    let circlePointsGJ = {
      "type": "Feature",
      "geometry": {
        "type": "LineString",
        "coordinates": pointList
      },
      "properties": {
        "interpolation": "CIRCLE_BY_CENTRE",
        "radius": radius,
        "radiusUom": radiusUom,
        "centre": {
          "type": "Feature",
          "properties": {
            "centrePoint": centrePointType
          },
          "geometry": {
            "type": "Point",
            "coordinates": center
          }
        }
      }
    }
    return circlePointsGJ;
  }

  deg2Rad(degrees: any) {
    return 2 * Math.PI * (degrees / 360);
  }

  getSegments(): Feature[] {
    return this.segments.getArray();
  }

  isCircle(): boolean {
    return (this.getSegments().length > 0 && this.getSegments()[0].get("interpolation") == GmlInterpolation.CIRCLE_BY_CENTRE) ? true : false;
  }

  // Calculate Points for ARC_BY_CENTRE or CIRCLE_BY_CENTRE
  calculateArcCirclePoints(features: any) {
    let arcPoints = [];
    for (let i = 0; i < features.length; i++) {
      if (features[i] == null) {
        let previous: Feature = i === 0 ? features[features.length - 1] : features[i - 1];
        let next: Feature = i === (features.length - 1) ? features[0] : features[i + 1];

        let preSegment = JSON.parse(JSON.stringify(previous));
        let nextSegment = JSON.parse(JSON.stringify(next));

        let startPoint = preSegment.properties.endPoint;
        let endPoint = nextSegment.properties.startPoint;

        arcPoints[i] = {
          "type": "Feature",
          "geometry": {
            "type": "LineString",
            "coordinates": []
          },
          "properties": {
            "interpolation": GmlInterpolation.ARC_BY_CENTRE_CLOCKWISE,
            "radius": undefined,
            "radiusUom": undefined,
            "startAngle": NaN,
            "endAngle": NaN,
            "startPoint": startPoint,
            "endPoint": endPoint,
            "centre": {
              "type": "Feature",
              "properties": {
                "centrePoint": { type: "COORDINATES" }
              },
              "geometry": {
                "type": "Point",
                "coordinates": []
              }
            }
          }
        }
      }
      else {
        if (features[i].properties.centre != undefined && features[i].properties.interpolation.includes("ARC_BY_CENTRE")) {
          console.log("ARC_BY_CENTRE");
          let startPoint: any;
          let endPoint: any;
          let startPointType: any;
          let endPointType: any;
          let startAngle: any;
          let endAngle: any;
          let segments: any;
          let direction = features[i].properties.direction;
          this.direction = direction == "clockwise" ? "clockwise" : "AntiClockwise";
          let center = features[i].properties.centre.geometry.coordinates;
          let radius = features[i].properties.radius;
          let radiusUom = features[i].properties.radiusUom;
          let centrePointType = features[i].properties.centre.properties.centrePoint;

          if (this.arcDirectionChange == undefined && this.centrePointChange == undefined && this.radiusUomChange == undefined &&
            this.radiusChange == undefined) {
            console.log(this.centrePointChange + "****" + this.radiusChange + "*****" + this.radiusUomChange);
            if (features[i].geometry.coordinates.length > 2) {
              // calculated Arc Points from BE
              startAngle = features[i].properties.startAngle;
              endAngle = features[i].properties.endAngle;
              segments = Math.abs(startAngle - endAngle);
              startPointType = features[i].properties.startPoint;
              endPointType = features[i].properties.endPoint;
              let pointList = features[i].geometry.coordinates;

              if (direction == "clockwise") {
                arcPoints[i] = {
                  "type": "Feature",
                  "geometry": {
                    "type": "LineString",
                    "coordinates": pointList
                  },
                  "properties": {
                    "interpolation": GmlInterpolation.ARC_BY_CENTRE_CLOCKWISE,
                    "radius": radius,
                    "radiusUom": radiusUom,
                    "startAngle": startAngle,
                    "endAngle": endAngle,
                    "direction": direction,
                    "startPoint": startPointType,
                    "endPoint": endPointType,
                    "centre": {
                      "type": "Feature",
                      "properties": {
                        "centrePoint": centrePointType
                      },
                      "geometry": {
                        "type": "Point",
                        "coordinates": center
                      }
                    }
                  }
                }
              } else {
                arcPoints[i] = {
                  "type": "Feature",
                  "geometry": {
                    "type": "LineString",
                    "coordinates": pointList
                  },
                  "properties": {
                    "interpolation": GmlInterpolation.ARC_BY_CENTRE_ANTICLOCKWISE,
                    "radius": radius,
                    "radiusUom": radiusUom,
                    "startAngle": startAngle,
                    "endAngle": endAngle,
                    "direction": direction,
                    "startPoint": startPointType,
                    "endPoint": endPointType,
                    "centre": {
                      "type": "Feature",
                      "properties": {
                        "centrePoint": centrePointType
                      },
                      "geometry": {
                        "type": "Point",
                        "coordinates": center
                      }
                    }
                  }
                }
              }

            }
          }
          else {
            // Arc direction change
            if (features.length > 1) {
              let previous: any;
              let next: any;
              let startLat: any;
              let startLon: any;
              //Previous Point of the arc
              if (i == 0) {
                if (features[features.length - 1].geometry.coordinates == 0) {
                  for (let a = 1; a < features.length; a++) {
                    if (features[features.length - a] != undefined) {
                      if (features[features.length - a].geometry.coordinates != 0) {
                        previous = features[features.length - a];
                        a = features.length;
                      }
                      else {
                        previous = features[features.length - 1];
                      }
                    }
                  }
                }
                else {
                  previous = features[features.length - 1];
                }
              }
              else {
                previous = features[i - 1];
              }
              //Next point of the arc
              if (i == features.length - 1) {
                next = features[0];                
              }
              else {
                for (let n = i; n < features.length; n++) {
                  if (features[n + 1] != undefined) {
                    if (features[n + 1].geometry.coordinates != 0) {
                      next = features[n + 1];
                      n = features.length;
                    }
                    else {
                      next = features[0];
                    }
                  }
                }
              }              
              
              if (previous.geometry.coordinates.length > 1) {
                startPoint = previous.geometry.coordinates[previous.geometry.coordinates.length - 1];
              }
              else {
                startPoint = previous.geometry.coordinates[0];
              }
              endPoint = next.geometry.coordinates[0];
              startPointType = previous.properties.endPoint == undefined ? previous.properties.startPoint : previous.properties.endPoint;
              endPointType = next.properties.startPoint;

              startLat = center[1];
              startLon = center[0];

              if (startPoint != undefined && endPoint != undefined && center.length != 0) {

                if (features[i].properties.startAngle != undefined && features[i].properties.endAngle != undefined) {
                  startAngle = features[i].properties.startAngle;
                  endAngle = features[i].properties.endAngle;
                } else {
                  startAngle = ArcByCentre.getBearing(startLat, startLon, startPoint[1], startPoint[0]);
                  endAngle = ArcByCentre.getBearing(startLat, startLon, endPoint[1], endPoint[0]);
                }

                if (direction != undefined) {
                  if (direction == "clockwise") {
                    console.log("Clockwise : StartAngle must be < endAngle");
                    // startAngle must be < endAngle
                    if (startAngle > endAngle) {
                      endAngle = (endAngle + 360) % 360;
                    }
                  } else {
                    console.log("Anti clockwise: StartAngle must be > endAngle")
                    // Counter-clockwise: startAngle must be > endAngle                  
                    if (startAngle < endAngle) {
                      startAngle = (startAngle + 360) % 360;
                      if( startAngle < endAngle){
                        endAngle = (endAngle - 360) %360;
                      }
                    }
                  }
                }
                console.log(startAngle + " *** " + endAngle)

                segments = Math.abs(startAngle - endAngle);
                if (startAngle != endAngle) {
                  arcPoints[i] = this.calculateArcPoints(center, radius, radiusUom, startAngle, endAngle, segments, direction, startPointType, endPointType, centrePointType);
                }
              }
              else {
                console.log("Undefined")
                startAngle = features[i].properties.startAngle;
                endAngle = features[i].properties.endAngle;
                segments = Math.abs(startAngle - endAngle);
                if (startAngle != undefined && endAngle != undefined) {
                  arcPoints[i] = this.calculateArcPoints(center, radius, radiusUom, startAngle, endAngle, segments, "clockwise", { "type": "COORDINATES" }, { "type": "COORDINATES" }, centrePointType);
                }
                else {
                  if (center != undefined && radius != undefined && radiusUom != undefined) {
                    arcPoints[i] = this.calculateArcPoints(center, radius, radiusUom, 0, 45, 46, "clockwise", { "type": "COORDINATES" }, { "type": "COORDINATES" }, centrePointType);
                  }
                }
              }
            }
            // there is only a single arc segment
            else {
              startAngle = features[i].properties.startAngle;
              endAngle = features[i].properties.endAngle;
              segments = Math.abs(startAngle - endAngle);
              if (startAngle != undefined && endAngle != undefined) {
                arcPoints[i] = this.calculateArcPoints(center, radius, radiusUom, startAngle, endAngle, segments, direction, { "type": "COORDINATES" }, { "type": "COORDINATES" }, centrePointType);
              }
              else {
                if (center != undefined && radius != undefined && radiusUom != undefined) {
                  arcPoints[i] = this.calculateArcPoints(center, radius, radiusUom, 0, 45, 46, direction, { "type": "COORDINATES" }, { "type": "COORDINATES" }, centrePointType);
                }
              }
            }
          }
        }
        else if (features[i].properties.interpolation == "CIRCLE_BY_CENTRE" || features[i].properties.interpolation == "CIRCLE_BY_CENTER") {
          console.log("CIRCLE_BY_CENTRE");
          let center = features[i].properties.centre.geometry.coordinates;
          let radius = features[i].properties.radius;
          let centrePointType = features[i].properties.centre.properties.centrePoint;
          let radiusUom = features[i].properties.radiusUom;
          // Circle has 360 points
          let segments = 360;

          if (features[i].geometry.coordinates.length > 2 && this.centrePointChange == undefined && this.radiusUomChange == undefined && this.radiusChange == undefined) {
            let pointList = features[i].geometry.coordinates;
            //pointList[pointList.length] = pointList[0];
            arcPoints[i] = {
              "type": "Feature",
              "geometry": {
                "type": "LineString",
                "coordinates": pointList
              },
              "properties": {
                "interpolation": "CIRCLE_BY_CENTRE",
                "radius": radius,
                "radiusUom": radiusUom,
                "centre": {
                  "type": "Feature",
                  "properties": {
                    "centrePoint": centrePointType
                  },
                  "geometry": {
                    "type": "Point",
                    "coordinates": center
                  }
                }
              }
            }
          }
          else {
            arcPoints[i] = this.calculateCirclePoints(center, radius, radiusUom, segments, centrePointType);
          }

        }
        else {
          console.log(features[i].properties.interpolation);
          arcPoints[i] = features[i];
        }
      }
    }
    let featuresCollection = {
      "type": "FeatureCollection",
      "crs": {
        "type": "name",
        "properties": {
          "name": "EPSG:4326"
        }
      },
      "features": arcPoints
    }
    return featuresCollection;
  }

  openConfirmationDialog(event): void {
    if (!event.value.includes('gml') && this.getSegments().length > 0) {
      let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '500px',
        data: {}
      });

      let toggle = event.source;
      let group = toggle.buttonToggleGroup;

      dialogRef.componentInstance.onChange.subscribe((result) => {
        if (result == "confirm") {
          if (event.value.some(item => item == toggle.value)) {
            group.value = [toggle.value];

            this.segments = new Collection([]);
            // Update the VectorLayer
            let geoEditorSource: VectorSource = new VectorSource({
            })
            this.geoEditorLayer.setSource(geoEditorSource);
            this.map.setTarget(this.mapId);
          }
        } else if (result == "cancel") {
          if (event.value.some(item => item == toggle.value)) {
            group.value = [(toggle.value == "CircleByCenterPoint") ? "segments" : "CircleByCenterPoint"];
          }
        }
      });
    } else {
      let toggle = event.source;
      let group = toggle.buttonToggleGroup;
      if (event.value.some(item => item == toggle.value)) {
        group.value = [toggle.value];
      }
    }
  }

  private readonly geoJsonFeatures = {
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": {
        "name": "EPSG:4326"
      }
    },
    "features": [
      {
        "type": "Feature",
        "properties": {
          "interpolation": "GREAT_CIRCLE",
          "startPoint": {
            "type": "COORDINATES"
          },
          "geometry": {
            "type": "LineString",
            "coordinates": [45, 6]
          }
        }
      },
      {
        "type": "Feature",
        "properties": {
          "radiusUom": "m",
          "interpolation": "ARC_BY_CENTRE",
          "startPoint": "COORDINATES",
          "startAngle": "0",
          "radius": "700",
          "endAngle": "45",
          "centre": {
            "geometry": {
              "type": "Point",
              "coordinates": [
                2,
                47
              ]
            }
          }
        }
      },
      {
        "type": "Feature",
        "properties": {
          "interpolation": "CREATE_CIRCLE",
          "startPoint": "COORDINATES"
        },
        "geometry": {
          "type": "LineString",
          "coordinates": [[0, 0], [10, 20]]
        }
      }
    ]
  }
  onRadiusChange() {
    this.radiusChange = true;
  }

  onRadiusUomChange() {
    this.radiusUomChange = true;
  }

  onCentrePointChange() {
    this.centrePointChange = true;
  }

  onArcDirectionChanged(directionChange: string) {
    this.arcDirectionChange = directionChange;
  }

  isSegmentEmpty(isEmpty: boolean) {
    this.isGeoEmpty.emit(isEmpty);
  }

}