<div class="example-container">
    <mat-nav-list cdkAccordion>
        <ng-container *ngFor="let link of navigationLinks; index as i">
            <!-- Nav item w/o sub -->
            <a *ngIf="link.satisfied && !link.sub?.length" 
                routerLinkActive="active"
                mat-list-item
                [routerLink]="link.to" >
                {{ link.title }}
            </a>
            
            <!-- Nav item w/ sub -->
            <ng-container *ngIf="link.satisfied && link.sub?.length">
                <ng-container *ngTemplateOutlet="navAccordion; context: { $implicit: link }"></ng-container>
            </ng-container>
        </ng-container>
    </mat-nav-list>
</div>

<ng-template #navAccordion let-link>
    <cdk-accordion-item 
        #accordionItem="cdkAccordionItem" 
        [expanded]="link.expanded"
        class="accordion-item" 
        role="button"
        tabindex="0" 
        [attr.id]="'accordion-header-' + link.id" 
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + link.id">
        <a *ngIf="link.satisfied" mat-list-item (click)="accordionItem.toggle()">
            <div style="display: flex;">
                <span style="flex-grow: 1;"> {{ link.title }} </span>
                <mat-icon> {{ !accordionItem.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
            </div>
        </a>
        <div class="accordion-item-body" 
            role="region"
            [style]="{ 'display': accordionItem.expanded ? '' : 'none' }"
            [attr.id]="'accordion-body-' + link.id"
            [attr.aria-labelledby]="'accordion-header-' + link.id">
            <ng-container *ngTemplateOutlet="subLink; context: { $implicit: link.sub }"></ng-container>
        </div>
    </cdk-accordion-item>
</ng-template>


<ng-template #subLink let-links>
    <mat-nav-list>
        <ng-container *ngFor="let link of links; index as j">
            <!-- Nav item w/o sub -->
            <a *ngIf="link.satisfied && !link.sub?.length else navWithSub" 
                style="font-size: 13px;"
                routerLinkActive="active"
                mat-list-item
                [routerLink]="link.to" >
                {{ link.title }}
            </a>
            
            <!-- Nav item w/ sub -->
            <ng-container *ngIf="link.satisfied && link.sub?.length">
                <ng-container *ngTemplateOutlet="navAccordion; context: { $implicit: link }"></ng-container>
            </ng-container>
        </ng-container>
    </mat-nav-list>
</ng-template>