import { Component, Inject, inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-error-status-code-template",
  templateUrl: "./error-status-code-template.component.html",
  styleUrl: "./error-status-code-template.component.scss",
})
export class ErrorStatusCodeTemplateComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { statusCode: number; message?: string },
    private snackbar: MatSnackBar
  ) {}

  close() {
    this.snackbar.dismiss();
  }
}
