<mat-expansion-panel class="choice-expansion-panel" [expanded]="!!formGroup.get(property.key).value[0]">
  <mat-expansion-panel-header>
    <mat-panel-title> {{ property.label }} </mat-panel-title>
  </mat-expansion-panel-header>

  <!-- Content -->
  <div class="content-wrapper">
    <div class="btn-actions">
      <button mat-icon-button color="primary" [matMenuTriggerFor]="choiceMenu" (click)="$event.preventDefault()"
        matTooltip="Choose one option">
        <span class="material-symbols-outlined">arrow_split</span>
      </button>
      <mat-menu #choiceMenu="matMenu">
        <ng-container *ngFor="let opt of property.choiceList | OrderBy : 'label'">
          <ng-container *ngIf="!opt.isNestedChoice; else nestedTpl">
            <button 
              class="truncate-text"
              mat-menu-item 
              [ngClass]="{'choice-active': formGroup.get(property.key).value[0]?.includes(opt.key)}"
              [matTooltip]="opt.description"
              matTooltipPosition="after"
              (click)="onMenuClicked(opt.key, property)">
              <span>{{ opt.label }}</span>
            </button>
          </ng-container>

          <ng-template #nestedTpl>
            <button 
              mat-menu-item 
              [matMenuTriggerFor]="nestedMenu" 
              [matMenuTriggerData]="{$implicit: opt.choices}"
              [ngClass]="{'choice-active': formGroup.get(property.key).value[0]?.toLowerCase()?.includes(opt.label.toLowerCase())}">
              <span>{{ opt.label }}</span>
            </button>
          </ng-template>
        </ng-container>
      </mat-menu>
      <mat-menu #nestedMenu="matMenu" [overlapTrigger]="false">
        <ng-template matMenuContent let-choices>
          <ng-container *ngFor="let choice of choices">
            <button 
              mat-menu-item 
              [matTooltip]="choice.description"
              matTooltipPosition="after"
              [ngClass]="{'choice-active': formGroup.get(property.key).value[0]?.includes(choice.key)}"
              (click)="onMenuClicked(choice.key, property)">
              {{ choice.label }}
            </button>
          </ng-container>
        </ng-template>
      </mat-menu>

      <button [disabled]="disabledChoiceBtn" mat-icon-button color="primary"
        (click)="$event.preventDefault();revertChoice($event, property.key)">
        <mat-icon>undo</mat-icon>
      </button>
    </div>

    <!-- <mat-card class="mat-elevation-z0 choice-card-content" [ngClass]="{'from-choice': fromChoice}"
      [formGroup]="formGroup">
      
      <ng-container [formArrayName]="property.key">
        <mat-radio-group [disabled]="readonly" class="choice-radio-group" aria-label="Select a point of choice"
          formControlName="0">
          <ng-container *ngFor="let opt of property.choiceList | OrderBy : 'label'">
            <mat-radio-button class="choice-radio-button" [value]="opt.key"
              (click)="onPointChoiceSelected($event, opt.key, property)">
              {{ opt.label }}
            </mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </ng-container>
      <mat-card-actions class="choice-card-action" [align]="'end'">
        <button [disabled]="disabledChoiceBtn" matTooltip="Revert to initial value" color="primary"
          (click)="revertChoice($event, property.key)" mat-icon-button>
          <mat-icon>undo</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card> -->

    <!-- As list option -->
    <!-- <ng-template #asDropdown>
      <ng-container [formGroup]="formGroup">
        <mat-form-field [formArrayName]="property.key">
          <mat-select formControlName="0">
            <mat-option *ngFor="let choice of property.choiceList | OrderBy: 'label'" [value]="choice.key">
              {{choice.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </ng-template> -->

    <div class="choice-switch-container">
      <ng-container *ngIf="formGroup.get(property.key).value[0]; else noneTpl">
        <ng-container *ngFor="let prop of usedProperties">
          <ng-container *ngIf="matchKey(prop.key, property)">
            <im-property-switch [expanded]="true" [isChoiceRef]="prop.propertyType === 'reference'" [fromChoice]="true"
              [property]="prop" [copyProperty]="property" [parentFormGroup]="formGroup" [readonly]="readonly">
            </im-property-switch>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #noneTpl>
        <div class="none-tpl">
          Please select a choice from <span class="material-symbols-outlined">arrow_split</span> button
        </div>
      </ng-template>
    </div>
  </div>
</mat-expansion-panel>

<ng-template #pointChoiceChangeTpl let-data>
  <div mat-dialog-title>Warning</div>
  <div mat-dialog-content>
    <p>
      Data from the currently selected option, including any annotations, will be deleted
    </p>
  </div>
  <div mat-dialog-actions [align]="'end'">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
      Confirm
    </button>
  </div>
</ng-template>