import { Injectable, Injector, NgZone } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { merge, Observable, of, tap } from 'rxjs';
// Cool library to deal with errors: https://www.stacktracejs.com
import * as StackTraceParser from 'error-stack-parser';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { Router, Event, NavigationError } from '@angular/router';
import { ErrorStatusCodeTemplateComponent } from 'src/app/shared/error-status-code-template/error-status-code-template.component';

//The logic for parsing error messages and stack traces from the server and client
@Injectable()
export class ErrorsService {
  constructor(
    private injector: Injector,
    private router: Router,
    public snackBar: MatSnackBar, private zone: NgZone
  ) { // Listen to the navigation errors
    this.router.events.subscribe((event: Event) => {
      // Redirect to the ErrorComponent
      if (event instanceof NavigationError) {
        if (!navigator.onLine) { return; }
        // Redirect to the ErrorComponent
        //this.log(event.error)
        // .subscribe((errorWithContext) => {
        this.router.navigate(['home-page']);
        // });
      }
    });
  }

  openErrorStatusCodeSnackBar(statusCode: number, message?:string): void {
    console.log(message, '### MESSAGE')
    this.snackBar.openFromComponent(ErrorStatusCodeTemplateComponent, {
      data: { statusCode, message },
      verticalPosition: "bottom",
      horizontalPosition: "center",
    }).afterOpened().pipe(
      tap(() => {
        const spinnerEl = document.getElementsByClassName('spinner-overlay')[0] as HTMLElement
        if (spinnerEl) {
          spinnerEl.style.zIndex = '1000'
        }
      })
    ).subscribe()
  }

  openSnackBar(message: string) {
    this.zone.run(() => {
      const snackBar = this.snackBar.open(message, 'OK', {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
        //duration: 2000,
      });
      snackBar.onAction().subscribe(() => {
        snackBar.dismiss();
      })
    });
  }

  log(error) {
    // Log the error to the console
    console.error(error);
    // Send error to server
    const errorToSend = this.addContextInfo(error);
    return fakeHttpService.post(errorToSend);
  }

  addContextInfo(error) {
    // All the context details that you want (usually coming from other services; Constants, UserService...)
    const name = error.name || null;
    const appId = 'shthppnsApp';
    const user = 'ShthppnsUser';
    const time = new Date().getTime();
    const id = `${appId}-${user}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
    const errorToSend = { name, appId, user, time, id, url, status, message, stack };
    return errorToSend;
  }

}

class fakeHttpService {
  static post(error): Observable<any> {
    console.log('Error sent to the server: ', error);
    return of(error);
  }
}