import { AppAuthGuard } from './../login/services/app.authguard';
import { environment } from './../../environments/environment';
import { QaProjectListComponent } from './qa-project-list/qa-project-list.component';
import { TaskListComponent } from './task-list/task-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewQualityProjectComponent } from './new-quality-project/new-quality-project.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { Layouts } from '../app.component';

const routes: Routes = [

  {
    path: 'quality/create-quality-project',
    component: NewQualityProjectComponent,
    canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Fqa_Module] }
  },
  {
    path: 'quality/projects',
    component: QaProjectListComponent,
    canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'quality/detail-project/:id/:pid',
    component: ProjectDetailComponent,
    canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Fqa_Module] }
  },
  {
    path: 'quality/edit/:id/:pid',
    component: NewQualityProjectComponent,
    canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Fqa_Module] }
  },
  {
    path: 'quality/task-list',
    component: TaskListComponent,
    canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Fqa_Module] }
  },
  // { path: '**', redirectTo: 'quality/projects' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QualityRoutingModule { }
