import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import {
  ActivatedRoute,
  Router
} from '@angular/router';

import { ApplicationProperties } from './../model/application-properties.model';
import { PropertiesService } from './../services/properties.service';

@Component({
  selector: 'app-application-properties',
  templateUrl: './application-properties.component.html',
  styleUrls: ['./application-properties.component.css']
})
export class ApplicationPropertiesComponent implements OnInit {
  applicationPropertieslist: ApplicationProperties[] = [];

  displayedColumns = ['Module Name', 'Property', 'Property Value'];
  applicationPropertieslist_dataSource = new MatTableDataSource<ApplicationProperties>(this.applicationPropertieslist);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private propertiesService: PropertiesService) { }

  ngOnInit() {
    this.getApplicationProperties();
  }

  getApplicationProperties(): void {
    this.propertiesService.getApplicationProperties()
      .subscribe(applicationproperties => this.applicationPropertieslist_dataSource.data = applicationproperties);
  }
}
