import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { data } from 'vis-network';
import { AixmValidatorService } from './service/aixm-validator.service';
import {  MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import moment from 'moment';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ErrorsService } from '../errors/errors-service/errors.service';

export interface bizrule {
  identifier: string;
  name: string;
  title: string;
}

export interface profile {
  identifier: string;
  name: string;
}

export interface doc {
  identifier: string;
  name: string;
  version: string;
}

@Component({
  selector: 'app-aixm-validator',
  templateUrl: './aixm-validator.component.html',
  styleUrls: ['./aixm-validator.component.css']
})

export class AixmValidatorComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({url: ''});

  fileNames: string[] = [];
  fileNamesToDelete: string[] = [];
  fileName: string;
  fileIndex: number;

  isUploaded: boolean = false;
  doValidate: boolean = false;

  aixmVersion: string;
  taskId: string;
  uploadDoc: doc;

  businessRules: bizrule[] = [];
  schemaProfiles: profile[] = [];

  selectedRules: bizrule[] = [];
  selectedProfile: profile[] = [];

  selectedRulesName: string[] = [];
  selectedProfileName: string[] = [];

  date: String = moment(new Date()).format('DD MMM YYYY');
  result: string = "in progress...";

  // Datasoure for table
  validatedResult_DataSource = new MatTableDataSource<any>();
  displayedColumns = ['type', 'name', 'valid', 'flag', 'error', 'location'];
  // Sorting
  @ViewChild(MatSort) sort: MatSort;

  uploadFormGroup: UntypedFormGroup = this._formBuilder.group({ datasetCtl: [''] });
  profileFormGroup: UntypedFormGroup = this._formBuilder.group({ schemaProfilesCtl: [''] });
  bizruleFormGroup: UntypedFormGroup = this._formBuilder.group({ businessRulesCtl: [''] });
  reportFormGroup: UntypedFormGroup = this._formBuilder.group({ validationReportCtl: [''] });

  constructor(private http: HttpClient,
    private aixmValidatorService: AixmValidatorService,
    public router: Router,
    private _formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef, private errorService: ErrorsService) { }

  ngOnInit(): void {

    this.validatedResult_DataSource.sort = this.sort;
    let maxFileSize = 250 * 1024 * 1024;
    this.uploader = new FileUploader({
      url: '',
      maxFileSize: maxFileSize,
      //queueLimit: 1
    });

    this.uploader.onAfterAddingFile = (fileItem) => {
      if (this.getUploaderQueue() > 1) {
        this.uploader.queue.splice(0, this.uploader.queue.splice.length - 1);
        this.reset();
      }
    };

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message = 'Error! \nThe uploaded file \"' + item.name + '\" is ' + this.formatBytes(item.size) +
            ', this exceeds the maximum allowed size of ' + this.formatBytes(maxFileSize) + '.';
          break;
        default:
          message = 'Error trying to upload file \"' + item.name + '\".';
          break;
      }
      this.errorService.openSnackBar(message);
    };
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.validatedResult_DataSource.filter = filterValue;
  }

  formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }

  getUploaderQueue() {
    return this.uploader.queue.length;
  }

  validationOption() {
    let uploadedFile = this.getFiles()[0];
    this.fileName = uploadedFile.name;
    this.isUploaded = true;
  }

  isFileSelected() {
    if (this.getFiles()[0] != undefined) {
      return true;
    }
    else return false;
  }

  uploadAixmDocument() {
    this.aixmValidatorService.uploadAixmDocument(this.getFiles()[0])
      .subscribe(result => {
        this.fileName = JSON.parse(result).name;
        let version: string = JSON.parse(result).version;
        this.aixmVersion = version;
        this.uploadDoc = {
          identifier: JSON.parse(result).identifier,
          name: JSON.parse(result).name,
          version: JSON.parse(result).version
        }
        console.log("Upload Aixm Document : " + result);

        this.getValidationOptionsBizRule(version);
        this.getValidationOptionsSchema(version);

        if (version != undefined) {
          this.isUploaded = true;
        }
      });
  }

  getValidationOptionsBizRule(version: string) {
    this.businessRules = [];
    // Get business rules
    this.aixmValidatorService.getValidationOptionsBizRule(version)
      .subscribe(result => {
        let bizRule: [] = JSON.parse(result);
        for (let rule of bizRule) {
          let bizrules: bizrule = {
            identifier: JSON.parse(JSON.stringify(rule)).identifier,
            name: JSON.parse(JSON.stringify(rule)).name,
            title: JSON.parse(JSON.stringify(rule)).title
          }
          this.businessRules.push(bizrules);
        }
      });
  }

  getValidationOptionsSchema(version: string) {
    this.schemaProfiles = [];
    // Get schemas
    this.aixmValidatorService.getValidationOptionsSchema(version)
      .subscribe(result => {
        let profiles: [] = JSON.parse(result);
        for (let schema of profiles) {
          let schemaProfile: profile = {
            identifier: JSON.parse(JSON.stringify(schema)).identifier,
            name: JSON.parse(JSON.stringify(schema)).name
          }
          this.schemaProfiles.push(schemaProfile);
        }
      });
  }

  validate() {
    //this.router.navigate(['/validation-report',this.selectedProfile, JSON.stringify(this.selectedRules)]);
    this.doValidate = true;

    this.selectedProfileName = [];
    this.selectedRulesName = [];

    for (let rule of this.selectedRules) {
      this.selectedRulesName.push(rule.name);
    }

    this.selectedProfileName.push(JSON.parse(JSON.stringify(this.selectedProfile)).name);

    let validateInfo = {
      "aixm-schema": [this.selectedProfile],
      "biz-rules": this.selectedRules,
      "doc": this.uploadDoc
    }

    this.aixmValidatorService.validate(validateInfo).subscribe(
      (response) => {
        // this.taskId = JSON.parse(response).taskId;
        this.taskId = response.taskId;
        console.log("Task Id: " + this.taskId);
      },
      (error) => { },
      () => { });
  }

  resetUpload() {
    this.uploader.queue = [];
    this.isUploaded = false;
  }

  resetSchema() {
    //this.schemaProfiles = [];
    this.selectedProfile = [];
    this.selectedProfileName = [];
  }

  resetBizrules() {
    //this.businessRules = [];
    this.selectedRules = [];
    this.selectedRulesName = [];
  }

  reset() {
    //this.resetUpload();
    this.resetSchema();
    this.resetBizrules();
    this.aixmVersion = "";
    this.schemaProfiles = [];
    this.businessRules = [];
    this.isUploaded = false;
    this.taskId = null;
    this.doValidate = false;
  }

  onBizRulesChange(bizRules) {
    if (bizRules != undefined) {
      this.doValidate = false;
      this.taskId = null;
    }
  }

  onSchemaChange(profile) {
    if (profile != undefined) {
      this.doValidate = false;
      this.taskId = null;
    }
  }

  onFileChanged(fileName: string) {
    this.isUploaded = false;
  }

}
