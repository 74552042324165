import { UnclaimdialogComponent } from "./../unclaimdialog/unclaimdialog.component";
import { ClaimdialogComponent } from "./../claimdialog/claimdialog.component";
import { DialogComponent } from "./../complete-dialog/dialog.component";
import {  MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Item } from "../model/item";
import { ActivatedRoute } from "@angular/router";
import { QualityProjectService } from "./../service/quality-project.service";
import { LogDialogComponentComponent } from "../log-dialog-component/log-dialog-component.component";
import { filter, tap } from "rxjs/operators";

@Component({
  selector: "app-text-edition",
  templateUrl: "./text-edition.component.html",
  styleUrls: ["./text-edition.component.css"],
})
export class TextEditionComponent implements OnInit {
  @Output() myEvent = new EventEmitter<String>();

  isProjectEditor: boolean = this.qualityProjectService.isProjectEditor;

  projectId: string = this.route.snapshot.params.id;

  items: Item[];

  sections: Item[];

  itemsAtLaterSteps: number;

  totalItems: number;

  constructor(
    private qualityProjectService: QualityProjectService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  @Input()
  set collectItems(items: Item[]) {
    if (items != undefined) {
      this.items = items.filter(
        (item) => item.currentActivity == "Text edition"
      );
      this.itemsAtLaterSteps = items.filter(
        (item) =>
          item.currentActivity != "Data input" &&
          item.currentActivity != "Text edition"
      ).length;
      this.totalItems = items.length;
    }
  }

  openClaimDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(ClaimdialogComponent, {
      width: "500px",
      data: { name: name, id: id },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap(() => this.myEvent.emit(this.projectId))
      )
      .subscribe();
  }

  openUnclaimDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(UnclaimdialogComponent, {
      width: "500px",
      data: { name: name, id: id },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap(() => this.myEvent.emit(this.projectId))
      )
      .subscribe();
  }

  openCompleteDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(DialogComponent, {
      width: "500px",
      data: { name: name, id: id },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap(() => this.myEvent.emit(this.projectId))
      )
      .subscribe();
  }

  getLogOfAssignment(executionId: string) {
    this.dialog.open(LogDialogComponentComponent, {
      width: "600px",
      data: executionId,
    });
  }
}
