import { DataProviderComponent } from './data-provider/data-provider.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import { FileUploadModule } from 'ng2-file-upload';
import { AppMaterialModule } from '../app.material.module';
import {  MatTableModule } from '@angular/material/table';
import {  MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

import { AixmProfileRoutingModule } from './aixm-profile-routing.module';
import { AixmProfilelistsComponent } from './aixm-profilelists/aixm-profilelists.component';
import { NewDataprofileComponent } from './new-dataprofile/new-dataprofile.component';
import { CopyofDataprofileDialogComponent } from './copyof-dataprofile-dialog/copyof-dataprofile-dialog.component';
import { EditDataprofileComponent } from './edit-dataprofile/edit-dataprofile.component';
import { EditDataprofileVersionComponent } from './edit-dataprofile-version/edit-dataprofile-version.component';
import { DataprofileService } from './service/dataprofile.service';
import { NewProfileVersionComponent } from './new-profile-version/new-profile-version.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { SuccessVersionDialogComponent } from './success-version-dialog/success-version-dialog.component';


@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
        RouterModule,
        AppMaterialModule,
        FileUploadModule,
        AixmProfileRoutingModule,
        MatSortModule,
        MatTableModule
    ],
    declarations: [
        AixmProfilelistsComponent,
        NewDataprofileComponent,
        CopyofDataprofileDialogComponent,
        EditDataprofileComponent,
        EditDataprofileVersionComponent,
        NewProfileVersionComponent,
        SuccessDialogComponent,
        SuccessVersionDialogComponent,
        DataProviderComponent
    ],
    providers: [
        DataprofileService
    ],
    exports: [
        EditDataprofileComponent
    ]
})
export class AixmProfileModule { }
