import { AppMaterialModule } from "./../app.material.module";
import { NavigationComponent } from "./../layout/navigation/navigation.component";
import { HeaderComponent } from "./../layout/header/header.component";
import { FooterComponent } from "./../layout/footer/footer.component";
import { SecureLayoutComponent } from "./../layout/securelayout/securelayout.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LoaderComponent } from "../loader/loader.component";
import { SidenavService } from "../service/sidenav.service";
import { CdkAccordionModule } from "@angular/cdk/accordion";

@NgModule({
  imports: [CommonModule, RouterModule.forChild([]), AppMaterialModule, CdkAccordionModule],
  exports: [SecureLayoutComponent],
  declarations: [FooterComponent, HeaderComponent, NavigationComponent, SecureLayoutComponent, LoaderComponent],
  providers: [SidenavService],
})
export class LayoutModule {}
