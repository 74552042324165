import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { QualityProjectService } from "./../service/quality-project.service";
import { ActivatedRoute } from "@angular/router";
import { Item } from "../model/item";
import { CreateQAProjectDto } from "../model/qualityproject";
import {  MatSnackBar } from "@angular/material/snack-bar";
import { KeycloakService } from "keycloak-angular";
import { BehaviorSubject, EMPTY, Observable, forkJoin } from "rxjs";
import { catchError, delay, filter, map, switchMap, tap } from "rxjs/operators";
import { ProjectSetting } from "../model/ProjectSetting";

@Component({
  selector: "app-project-detail",
  templateUrl: "./project-detail.component.html",
  styleUrls: ["./project-detail.component.css"],
})
export class ProjectDetailComponent implements OnInit {
  projectId = this.route.snapshot.params.id;

  projectPid = this.route.snapshot.params.pid;

  qualityProject: CreateQAProjectDto = new CreateQAProjectDto();

  result: string;

  reloader$ = new BehaviorSubject(this.route.snapshot.params.id);

  items$: Observable<Item[]>;

  isProjectManager = this.qualityProjectService.isProjectManager;

  projectSummary$: Observable<{
    items: Item[];
    projectSetting: ProjectSetting;
  }>;

  constructor(
    private qualityProjectService: QualityProjectService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public keycloackService: KeycloakService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.projectSummary$ = this.reloader$.pipe(
      delay(300),
      switchMap((projectId) => {
        return forkJoin([
          this.qualityProjectService.getItmes(projectId),
          this.qualityProjectService.getProjectSetting(this.projectPid),
        ]).pipe(
          catchError((err) => {
            if (err) {
              return EMPTY;
            }
          }),
          filter(([items, projectSetting]) => !!items && !!projectSetting),
          map(([items, projectSetting]) => ({
            projectSetting,
            items: items.sort((itemA, itemB) => {
              const trimAndLowerCase = (input: string) => {
                return input.trim().toLowerCase();
              };
              if (trimAndLowerCase(itemA.name) < trimAndLowerCase(itemB.name)) {
                return -1;
              }
              if (trimAndLowerCase(itemA.name) > trimAndLowerCase(itemB.name)) {
                return 1;
              }
              return 0;
            }),
          })),
          tap((result) => {
            console.log(result, "### RESULT");
            this.qualityProjectService.enableFourEyes$.next(result.projectSetting.use4eyes);
            this.cdr.detectChanges();
          })
        );
      })
    );
  }

  getItems(projectId: string): void {
    this.reloader$.next(projectId);
  }

  addNewItem(itemName: string) {
    this.qualityProject.projectName = this.projectId;
    this.qualityProject.hasDataInput = true;
    this.qualityProject.hasTextEdition = true;
    this.qualityProjectService
      .addNewItem(this.qualityProject, itemName)
      .pipe(
        catchError((err) => {
          if (err) {
            console.log(err);
            this.snackBar.open(`${JSON.parse(err?.error)?.message}`, "OK", {
              duration: 2000,
            });
            return EMPTY;
          }
        }),
        filter((response) => !!response),
        tap((result: string) => {
          this.snackBar.open(`${result} is successfully added!`, "OK", {
            duration: 2000,
          });
          this.reloader$.next(this.projectId);
        })
      )
      .subscribe();
  }
}
