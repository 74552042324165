<div class="container">
    <mat-card>

        <mat-tab-group mat-stretch-tabs="false">
            <mat-tab label="Details" class="divider">
                <form #form="ngForm" name="editUserForm">
                    <div style="margin-top: 25px;"></div>

                    <div class="form-group">
                        <div class="label" for="userName">
                            <label> User Name </label>
                        </div>
                        <div class="field">
                            <input class="border" name="userName" type="text" [(ngModel)]="user.userName"
                                (change)="detectChange()">
                        </div>
                    </div>
                    <br />

                    <div class="form-group">
                        <div class="label">
                            First Name
                        </div>
                        <div class="field">
                            <input class="border" name="firstName" type="text" [(ngModel)]="user.firstName"
                                (change)="detectChange()">
                        </div>
                    </div>
                    <br />

                    <div class="form-group">
                        <div class="label">
                            <label> Last Name </label>
                        </div>
                        <div class="field">
                            <input class="border" name="lastName" type="text" [(ngModel)]="user.lastName"
                                (change)="detectChange()">
                        </div>
                    </div>
                    <br />

                    <div class="form-group">
                        <div class="label">
                            <label> Email </label>
                        </div>
                        <div class="field">
                            <input class="border" name="email" type="text" [(ngModel)]="user.email"
                                (change)="detectChange()">
                        </div>
                    </div>
                    <br />

                    <div class="form-group">
                        <div class="label">
                            <label> User Enabled </label>
                        </div>
                        <div class="field">
                            <input class="border" name="enabled" type="text" [(ngModel)]="user.enabled"
                                (onchange)="detectChange()">
                        </div>
                    </div>

                    <div style="margin-top: 20px;"></div>
                    <div class="label"></div>
                    <div class="field">
                        <button type="submit" mat-raised-button mat-button color="primary" (click)="saveUser();"
                            [disabled]="!changed">Save</button>
                        <button mat-raised-button mat-button color="primary" [disabled]="!changed">Cancel</button>
                    </div>
                </form>
            </mat-tab>

            <mat-tab label="Credentials">
                <form name="newUserForm" [formGroup]="form" (ngSubmit)="resetPassword(form.value)">
                    <div class="spacer">
                        User-Management/Reset Password
                    </div>
                    <div class="justify">
                        <mat-form-field>
                            <input matInput placeholder="New Password" name="password"
                                [type]="hide ? 'password' : 'text'" formControlName="password" [formGroup]="form"
                                required>
                            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="justify">
                        <mat-form-field>
                            <input matInput placeholder="Password Confirmation" name="passwordConfirm"
                                [type]="hide ? 'password' : 'text'" formControlName="confirmPassword" [formGroup]="form"
                                required>
                            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                            <mat-error *ngIf="form.controls.confirmPassword.errors?.MatchPassword">
                                Confirm Password does not match
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <button mat-raised-button mat-button color="primary" class="example-btn-width justify"
                        [disabled]="!form.valid || form.prestine">Reset Password</button>
                    <button mat-raised-button mat-button color="primary"
                        class="example-btn-width justify">Cancel</button>


                </form>
            </mat-tab>

            <mat-tab label="Role Mappings">

                <div class="justify">
                    <mat-card class="box">
                        <mat-card-title>
                            <h2>Available Roles</h2>
                        </mat-card-title>
                        <mat-card-content>

                            <mat-selection-list #availableRole
                                (selectionChange)="onSelection($event, availableRole.selectedOptions.selected)">
                                <mat-list-option *ngFor="let role of user.availableRole" [value]='role'>
                                    {{role}}
                                </mat-list-option>
                            </mat-selection-list>

                        </mat-card-content>

                        <mat-card-actions class="space">
                            <button type="submit" mat-raised-button mat-button color="primary" (click)="assignRole();">
                                Add selected</button>
                        </mat-card-actions>
                        <br />
                    </mat-card>

                    <mat-card class="box">
                        <mat-card-title>
                            <h2>Assigned Roles</h2>
                        </mat-card-title>
                        <mat-card-content>

                            <mat-selection-list #assignedRole
                                (selectionChange)="onSelection($event, assignedRole.selectedOptions.selected)">
                                <mat-list-option *ngFor="let role of user.assignedRole" [value]='role'>
                                    {{role}}
                                </mat-list-option>
                            </mat-selection-list>

                        </mat-card-content>

                        <mat-card-actions class="space">
                            <button type="submit" mat-raised-button mat-button color="primary"
                                (click)="unassignRole();">
                                Remove selected</button>
                        </mat-card-actions>
                        <br />
                    </mat-card>
                </div>

            </mat-tab>

            <mat-tab label="Groups">

                <div class="justify">
                    <mat-card class="box">
                        <mat-card-title>
                            <h2>Available Groups</h2>
                        </mat-card-title>
                        <mat-card-content>

                            <mat-selection-list #availableGroup
                                (selectionChange)="onSelection($event, availableGroup.selectedOptions.selected)">
                                <mat-list-option *ngFor="let group of user.availableGroup" [value]='group.id'>
                                    {{group.groupName}}
                                </mat-list-option>
                            </mat-selection-list>

                        </mat-card-content>

                        <mat-card-actions class="space">
                            <button type="submit" mat-raised-button mat-button color="primary" (click)="joinGroup();">
                                Join</button>
                        </mat-card-actions>
                        <br />
                    </mat-card>

                    <mat-card class="box">
                        <mat-card-title>
                            <h2>Joined Groups</h2>
                        </mat-card-title>
                        <mat-card-content>

                            <mat-selection-list #joinedGroup
                                (selectionChange)="onSelection($event, joinedGroup.selectedOptions.selected)">
                                <mat-list-option *ngFor="let group of user.joinedGroup" [value]='group.id'>
                                    {{group.groupName}}
                                </mat-list-option>
                            </mat-selection-list>

                        </mat-card-content>

                        <mat-card-actions class="space">
                            <button type="submit" mat-raised-button mat-button color="primary" (click)="leaveGroup();">
                                Leave</button>
                        </mat-card-actions>
                        <br />
                    </mat-card>
                </div>

            </mat-tab>

        </mat-tab-group>

    </mat-card>
</div>