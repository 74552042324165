import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountManagementComponent } from './account-management/account-management.component';
import {AccountManagementRoutingModule} from './account-management-routing.module';
import { AppMaterialModule } from '../app.material.module';

@NgModule({
  declarations: [AccountManagementComponent],
  imports: [
    CommonModule,
    AccountManagementRoutingModule,
    AppMaterialModule
  ]
})
export class AccountManagementModule { }
