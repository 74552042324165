import { TextPropertyField } from "./text-property-field";
import { MultipleProperty } from "./property";

export class WithUnitPropertyField extends TextPropertyField {
    unit?: string;
    unitList: {key: string, label: string}[] = [];

    constructor(options: {
      key: string,
      unit?: string,
      unitList: {key: string, label: string}[],
      label?: string,
      hint?: string,
      description?: string,
      order?: number,
      minOccurs?: number,
      maxOccurs?: number,
      nil?: boolean,
      nilReason?: string,
      annotations?: MultipleProperty,
      value?: string,
      minLength?: number,
      maxLength?: number,
      minExclusive?: number,
      maxExclusive?: number,
      minInclusive?: number,
      maxInclusive?: number,
      pattern?: string,
      type?: string,
      modified: boolean
    }) {
        super(options);
        this.unit = options['unit'];
        this.unitList = options['unitList'] || [];
    }

}
