import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { Property } from "../model/property";
import { PropertyGroup } from "../model/property-group";
import { Subject } from "rxjs";
import { filter, tap } from "rxjs/operators";
import {  MatDialog } from "@angular/material/dialog";
import { PropertyFieldControlService } from "../property-field-control.service";

@Component({
  selector: "im-choice-field",
  templateUrl: "./choice-field.component.html",
  styleUrls: ["./choice-field.component.css"],
})
export class ChoiceFieldComponent implements OnInit {
  @ViewChild("pointChoiceChangeTpl") pointChoiceChangeTpl: TemplateRef<any>;

  @Input() parentFormGroup: UntypedFormGroup = null;

  @Input() property: Property = null;

  @Input() properties?: Property[] = [];

  @Input() propertyGroup?: PropertyGroup = null;

  @Input() readonly: boolean = false;

  @Input() positionInArray;

  @Input() fromChoice: boolean = false;

  intialChoicevalue = {};

  radioBtnSubject$: Subject<{ event: PointerEvent; radioBtnChoiceValue: string }> = new Subject();

  constructor(private dialog: MatDialog, private pfcs: PropertyFieldControlService, private cdr: ChangeDetectorRef) {}

  get formGroup(): UntypedFormGroup {
    return this.propertyGroup?.properties?.length
      ? (this.parentFormGroup.controls[this.getControlName()] as UntypedFormGroup)
      : this.parentFormGroup;
  }

  get usedProperties() {
    return this.propertyGroup?.properties || this.properties;
  }

  get disabledChoiceBtn() {
    const radioBtnCtrl = (this.formGroup.get(this.property.key) as UntypedFormArray).at(0);
    const validTargetKey =  this.getValidTargetKeys(radioBtnCtrl.value)
    const initialValue = Object.keys(this.intialChoicevalue);
    const isInitialValue = (validTargetKey === (initialValue.length && initialValue[0])) && !!this.findValues(this.formGroup.get(validTargetKey).value);
    return !radioBtnCtrl.touched || !radioBtnCtrl.value || isInitialValue;
  }

  ngOnInit(): void {
    this.setInitialChoiceValue();
  }

  getControlName(): string {
    return this.positionInArray == null ? this.propertyGroup?.key : this.positionInArray.toString();
  }

  getValidTargetKeys(targetKey: string) {
    return Object.keys(this.formGroup.controls).find((ctrl) => targetKey && ctrl.includes(targetKey));
  }

  setInitialChoiceValue() {
    const currentChoice = (this.formGroup.get(this.property.key) as UntypedFormArray).at(0).value;
    const validCurrentChoiceKey = this.getValidTargetKeys(currentChoice);
    const value = this.formGroup.get(validCurrentChoiceKey)?.value;
    const currentChoiceAnnotation = this.formGroup.get(`${validCurrentChoiceKey}-annotations`);

    if (value) {
      this.intialChoicevalue[validCurrentChoiceKey] = value;
    }

    if (currentChoiceAnnotation) {
      this.intialChoicevalue[`${validCurrentChoiceKey}-annotations`] = currentChoiceAnnotation.value;
    }
  }

  onMenuClicked(targetKey: string, property: Property) {
    let choiceCtrl = null;
    const radioBtnCtrl = (this.formGroup.get(property.key) as UntypedFormArray).at(0);
    const validtargetKey = this.getValidTargetKeys(targetKey);
    const prevFormDirty = this.formGroup.get(this.getValidTargetKeys(property.key)).dirty;
    const isHavingValue = this.findValues(this.formGroup.get(radioBtnCtrl?.value)?.value);
    const annotationFormDirty = this.formGroup.get(`${this.getValidTargetKeys(radioBtnCtrl.value)}-annotations`)?.dirty;

    if ((prevFormDirty && isHavingValue) || annotationFormDirty) {
      this.dialog
        .open(this.pointChoiceChangeTpl, {
          width: "300px",
        })
        .afterClosed()
        .pipe(
          filter((confirm) => !!confirm),
          tap(() => {
            radioBtnCtrl.setValue(validtargetKey);
            radioBtnCtrl.markAsTouched();
            const initialFormGroup = this.pfcs.toFormGroup(this.propertyGroup?.properties || this.properties, true);
            let childChoiceKey = null;

            Object.keys(this.formGroup.controls).forEach((key) => {
              const isChoiceCtrl = key.includes("_choiceCtrl");
              if (isChoiceCtrl) {
                choiceCtrl = property.key.split("_choiceCtrl")[0];
                childChoiceKey = this.getChildChoiceKey(property.key);
              }
              if ([choiceCtrl, childChoiceKey].some((k) => k && key.toLowerCase().includes(k?.toLowerCase())) && !isChoiceCtrl) {
                this.formGroup.get(key).patchValue(initialFormGroup.get(key).value);
                if (key.includes('-annotations')) {
                  this.formGroup.get(key).markAsPristine()
                }
              }
            });
          })
        )
        .subscribe();
    } else {
      radioBtnCtrl.setValue(validtargetKey);
    }

    radioBtnCtrl.markAsDirty();
    this.formGroup.get(validtargetKey).markAsDirty();
  }

  onPointChoiceSelected(event: PointerEvent, targetKey: string, property: Property) {
    if (this.readonly) {
      return;
    }

    const prevTargetKey = (this.formGroup.get(property.key) as UntypedFormArray).at(0).value;
    const validKeys = {
      prev: this.getValidTargetKeys(prevTargetKey),
      current: this.getValidTargetKeys(targetKey),
    };

    const currentTargetForm = this.formGroup.get(validKeys.current);
    const prevTargetForm = this.formGroup.get(validKeys.prev);
    currentTargetForm.markAsDirty();

    console.log({
      prevTargetForm,
      currentTargetForm,
      validKeys,
      property,
      formGroup: this.formGroup.value,
      propertyGroup: this.propertyGroup,
    });

    const controls = prevTargetForm?.value;
    console.log({ controls });
    const isHavingValue = this.findValues(controls);

    if (prevTargetForm?.dirty && isHavingValue) {
      event.preventDefault();

      this.dialog
        .open(this.pointChoiceChangeTpl, {
          width: "300px",
        })
        .afterClosed()
        .pipe(
          filter((confirm) => !!confirm),
          tap(() => {
            // Set to next radioBtnCtrol value
            const radioBtnCtrl = this.formGroup.get(property.key) as UntypedFormArray;
            radioBtnCtrl.at(0).setValue(targetKey);

            const selectedChoiceGroupKeys = Object.keys(this.formGroup.controls).filter((ctrlKey) => {
              const prevValidKeysPrefix = validKeys?.prev?.split("_")?.[0];
              return !ctrlKey.includes("_choiceCtrl") && ctrlKey.includes(prevValidKeysPrefix);
            });

            // Handle reset point choice
            const initialFormGroup = this.pfcs.toFormGroup(this.propertyGroup?.properties || this.properties, true);
            selectedChoiceGroupKeys.forEach((key) => {
              if (key !== validKeys.current) {
                this.formGroup.get(key).patchValue(initialFormGroup.get(key).value);
              }
            });
          })
        )
        .subscribe();
    }
  }

  revertChoice(event: PointerEvent, property: string) {
    event.preventDefault();
    event.stopPropagation();

    const hasInitialValue = Object.keys(this.intialChoicevalue).length;
    const radioBtnCtrl = this.formGroup.get(property) as UntypedFormArray;
    let choiceCtrl = null;

    this.dialog
      .open(this.pointChoiceChangeTpl, {
        width: "300px",
      })
      .afterClosed()
      .pipe(
        filter((confirm) => !!confirm),
        tap(() => {
          if (hasInitialValue) {
            const [choiceKey] = Object.keys(this.intialChoicevalue);
            const annotationKey = `${choiceKey}-annotations`;
            // Set inital choice
            radioBtnCtrl.at(0).setValue(choiceKey);
            // Set initial selected choice value
            this.formGroup.get(choiceKey).patchValue(this.intialChoicevalue[choiceKey]);
            // Set inital selected choice annotation
            if (this.intialChoicevalue[annotationKey]) {
              this.formGroup.get(annotationKey).patchValue(this.intialChoicevalue[annotationKey]);
            }
          } else {
            const initialFormGroup = this.pfcs.toFormGroup(this.propertyGroup?.properties || this.properties, true);
            radioBtnCtrl.at(0).setValue(null);
            let childChoiceKey = null;

            Object.keys(this.formGroup.controls).forEach((key) => {
              const isChoiceCtrl = key.includes("_choiceCtrl");
              if (isChoiceCtrl) {
                choiceCtrl = key.split("_choiceCtrl")[0];
                childChoiceKey = this.getChildChoiceKey(property);
              }
              if ([choiceCtrl, childChoiceKey].some((k) => k && key.toLowerCase().includes(k?.toLowerCase())) && !isChoiceCtrl) {
                this.formGroup.get(key).patchValue(initialFormGroup.get(key).value);
              }
            });
          }
        })
      )
      .subscribe();
  }

  matchKey(propertyKey: string, selectedChoice: Property): boolean {
    const choiceControlValue = this.formGroup.get(selectedChoice.key).value[0];
    const [propertyKeyPrefix] = propertyKey.split("_");
    const dynamicKeyRegex = new RegExp(`${propertyKeyPrefix}_.*?`);
    const dynamicKey = `${choiceControlValue}`;

    const matchPointChoicePositionKeyRegex = dynamicKeyRegex.test(choiceControlValue);
    const isPointChoicePositionKey = propertyKey.includes(dynamicKey) && matchPointChoicePositionKeyRegex;
    return propertyKey === choiceControlValue || isPointChoicePositionKey;
  }

  findValues(passedControl) {
    let foundTruthyValue = false;

    function recursiveCheck(obj) {
      if (foundTruthyValue) return;

      if (Array.isArray(obj)) {
        foundTruthyValue = obj?.some((value) => !!value && value !== "missing");
        return;
      }

      for (const key in obj) {
        const currentObj = obj[key];

        if (typeof currentObj === "object" && !Array.isArray(currentObj)) {
          recursiveCheck(currentObj);
        } else if (Array.isArray(currentObj)) {
          if (currentObj.every((value) => typeof value === "object" && !Array.isArray(value))) {
            currentObj.forEach(recursiveCheck);
          } else if (currentObj.every(Array.isArray)) {
            if (currentObj.some((innerArray) => innerArray.every(Boolean))) {
              foundTruthyValue = true;
              return;
            }
          } else if (!key.includes("choiceCtrl")) {
            if (currentObj.some((value) => !!value && value !== "missing")) {
              foundTruthyValue = true;
              return;
            }
          }
        }
      }
    }

    recursiveCheck(passedControl);
    return foundTruthyValue;
  }

  getChildChoiceKey(parentChoiceCtrlKey): string {
    const findChoices = this.usedProperties.find((p) => p.key === parentChoiceCtrlKey)?.choiceList;
    const childChoiceKey = findChoices.reduce((acc, f) => {
      const found = f.choices && f.choices?.find((c) => c.childOf === parentChoiceCtrlKey);
      found ? (acc = found.key.split("_")[0]) : "";
      return acc
    }, "");
    return childChoiceKey;
  }
}
