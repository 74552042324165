import { SharedModule } from './../shared/shared.module';
// import { MatSelectFilterModule } from 'mat-select-filter';
import { AppMaterialModule } from './../app.material.module';
import { FeatureTypeSearchComponentComponent } from './feature-type-search-component/feature-type-search-component.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, } from '@angular/forms';
import {  MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FeatureTypeSearchComponentComponent],
  imports: [
    CommonModule,
    AppMaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    // MatSelectFilterModule,
    RouterModule
  ],
  exports: [FeatureTypeSearchComponentComponent]
})
export class FeatureSelectorModule { }
