import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Network } from 'vis-network/esnext';

@Component({
  selector: 'im-network-search',
  templateUrl: './network-search.component.html',
  styleUrls: ['./network-search.component.css']
})
export class NetworkSearchComponent implements OnInit {

  @ViewChild('network') networkContainer: ElementRef;
  public network: any

  constructor() { }

  ngOnInit() {
    var treeData = this.getTreeData();
    var options = {};

    // initialize your network!
    this.network = new Network(this.networkContainer.nativeElement, treeData, options);
    this.network.fit();
  }

  getTreeData() {
    // create an array with nodes
        var nodes =[
            {id: 1, label: 'Node 1', title: 'I am node 1!'},
            {id: 2, label: 'Node 2', title: 'I am node 2!'},
            {id: 3, label: 'Node 3'},
            {id: 4, label: 'Node 4'},
            {id: 5, label: 'Node 5'}
        ];
    
        // create an array with edges
        var edges = [
            {from: 1, to: 3},
            {from: 1, to: 2},
            {from: 2, to: 4},
            {from: 2, to: 5}
        ];

        // provide the data in the vis format
        var treeData = {
          nodes: nodes,
          edges: edges
        };
        return treeData;
      }
}
