import { InputFormComponent } from './input-form/input-form.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowseComponent } from "./browse/browse.component";
import { TimelineComponent } from "./timeline/timeline.component";
import { TreeComponent } from "./input-form/tree/tree.component";
import { NetworkSearchComponent } from "./network-search/network-search.component";
import { Layouts } from '../app.component';

const routes: Routes = [
  {
    path: 'info-management/:featureId/:unId/:unName',
    component: InputFormComponent,
    data: { layout: Layouts.SecureLayout, state: "existing" }
  },
  {
    path: 'info-management/:featureId/:unId/:unName/:effDate/:isTentative',
    component: InputFormComponent,
    data: { layout: Layouts.SecureLayout, state: "existing" }
  },
  {
    path: 'info-management/:featureId/:unId/:unName/:effDate',
    component: InputFormComponent,
    data: { layout: Layouts.SecureLayout, state: "existing" }
  },
  {
    path: 'info-management/:featureType/:featureId',
    component: InputFormComponent,
    data: { layout: Layouts.SecureLayout, state: "existing" }
  },
  {
    path: 'info-management-empty-form/:featureType/:unId/:unName',
    component: InputFormComponent,
    data: { layout: Layouts.SecureLayout, state: "new" }
  },
  {
    path: 'info-management-empty-form/:featureType/:unId/:unName/:effDate',
    component: InputFormComponent,
    data: { layout: Layouts.SecureLayout, state: "new" }
  },
  {
    path: 'info-management',
    component: BrowseComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'browse/:unId/:unName',
    component: BrowseComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'browse/:unId/:unName/:effDate',
    component: BrowseComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'info-management/timeline',
    component: TimelineComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'info-management/net',
    component: NetworkSearchComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'info-management/tree',
    component: TreeComponent,
    data: { layout: Layouts.SecureLayout }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InfoManagementRoutingModule { }
