import { GmlPointType } from "./GmlPointType";
/**
 * A geographic point
 */
export class GmlPoint {
  type: GmlPointType;
  annotation: string;
  latitude: number;
  longitude: number;
  ref: string;
  title: string
  static fromLatLong(latitude: number, longitude: number) {
    if (latitude <= -90 || 90 <= latitude) {
      throw new RangeError("Invalid latitude '" + latitude + "'");
    }
    if (longitude <= -180 || 180 <= longitude) {
      throw new RangeError("Invalid longitude '" + longitude + "'");
    }
    var point = new GmlPoint(GmlPointType.COORDINATES, latitude, longitude);
    return point;
  }
  constructor(type?: GmlPointType, latitude?: number, longitude?: number, ref?: string, title?: string) {
    this.type = type ? type : GmlPointType.COORDINATES;
    // if (type === PointType.GEO_BORDER_POINT) {
    //   // override interpolation as a GEO_BORDER_POINT can only be for a GeoBorder
    //   this.interpolation = SegmentInterpolation.GEO_BORDER;
    // }
    this.ref = ref;
    this.title = title;
    this.latitude = latitude;
    this.longitude = longitude;
  }

}
