.text-search {
    width: 500px;
    margin: 20px;
  }
  .map {
    width: 800px;
    height:400px;
  }
  .space{
    margin-left: 30px;
  }
  .airac-date-class {
    background: #fdd4ce;
    border-radius: 100%;
  }