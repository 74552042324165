<div class="container">
  <mat-toolbar>
    <span>
      <h3> List of Application Properties </h3>
    </span>
  </mat-toolbar>

  <mat-card>
    <mat-card-content>
      <mat-table #table [dataSource]="applicationPropertieslist_dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!--Module Column -->
        <ng-container matColumnDef="Module Name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Module Name</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-form-field>
              <input matInput [value]="row.moduleName" [(ngModel)]="row.moduleName">
            </mat-form-field>
            <!-- <a [routerLink]="['/edit-dataprofile', row.id]"> {{row.name}}</a> -->
          </mat-cell>
        </ng-container>

        <!-- Property Column -->
        <ng-container matColumnDef="Property">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Property </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-form-field>
              <input matInput [value]="row.property" [(ngModel)]="row.property">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <!-- For Property Value Column -->
        <ng-container matColumnDef="Property Value">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Property Value </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-form-field>
              <input matInput [value]="row.propertyValue" [(ngModel)]="row.propertyValue">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card-content>

    <mat-card-footer>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-footer>
    <div>
      <!-- <button mat-fab color="primary" id="new-AppProp" matTooltip="Add New Application Properties" [routerLink]="['/new-app-properties']">
        <mat-icon>add</mat-icon>
      </button>   -->
    </div>
  </mat-card>
</div>