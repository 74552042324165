import { OrderByPipe } from "./OrderByPipe";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RefUrlPipe } from "./ref-url.pipe";
import { SelectFilterComponent } from "./select-filter/select-filter.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ErrorStatusCodeTemplateComponent } from "./error-status-code-template/error-status-code-template.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [OrderByPipe, RefUrlPipe, SelectFilterComponent, ErrorStatusCodeTemplateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatButtonModule,
  ],
  exports: [OrderByPipe, RefUrlPipe, SelectFilterComponent],
})
export class SharedModule {}
