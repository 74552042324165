import { Component, OnInit } from '@angular/core';
import { AboutService } from './service/about.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  serverVersion: string;
  clientVersion: string;
  date: Date = new Date();
  year: number;

  constructor(private aboutService: AboutService) { }

  ngOnInit() {
    this.getFaimServerVersion();
    this.getFaimWebClientVersionFromEnv();
    this.getYear();
  }
  getFaimServerVersion() {
    this.aboutService.getFaimServerVersion().
      subscribe(faimServerVersion => this.serverVersion = faimServerVersion);
  }
  getFaimWebClientVersion() {
    this.aboutService.getFaimWebClientVersion().
      subscribe(faimWebClientVersion => this.clientVersion = faimWebClientVersion);
  }
  getFaimWebClientVersionFromEnv() {
    this.clientVersion = environment.appVersion;
  }
  getYear() {
    this.year = this.date.getFullYear();
  }


}


