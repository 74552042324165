import { ActivatedRoute } from '@angular/router';
import { PropertyFieldControlService } from './../property-field-control.service';
import { PropertyService } from './../property.service';
import { LazyLoadedProperty } from './../model/property';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormGroup, AbstractControl, UntypedFormArray } from '@angular/forms';
import { PropertyGroup } from '../model/property-group';
import { Property } from '../model/property';
import { UserDetailsService } from '../../../login/services/UserDetails.service';
import { UpdateNoticeService } from '../../../update-notice/service/updatenotice.service';
import { ConfigService } from '../../../utils/config.service';
import { LocalStorageService } from '../../service/local-storage.service';
import { ReferencePropertyField } from '../model/reference-property-field';
import {  MatRadioChange } from '@angular/material/radio';
import {  MatDialog } from '@angular/material/dialog';
import { concatMap, delay, filter, map, pairwise, switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'im-input-form-group[propertyGroup][parentFormGroup][positionInArray]',
  templateUrl: './input-form-group.component.html',
  styleUrls: ['./input-form-group.component.css'],
})

export class InputFormGroupComponent implements OnInit {
  isDp: boolean = ConfigService.settings.isDataProvider;

  featureId: string;
  unId: string;

  @Input() propertyGroup: PropertyGroup;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() positionInArray?: number;
  @Input() property: Property;
  @Input() tSId: string;
  @Input() readonly: boolean;
  @Input() expanded: boolean = false;
  @Input() fromChoice: boolean = false;

  @Output() timesliceId: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('pointChoiceChangeTpl') pointChoiceChangeTpl: TemplateRef<any>;

  showAnnotations: boolean = false;
  showInfo: boolean = false;
  payLoad = '';

  constructor(
    private userDetailsService: UserDetailsService,
    private updateNoticeService: UpdateNoticeService,
    private propertyService: PropertyService,
    private pfcs: PropertyFieldControlService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog
    ) {

    this.unId = (this.route.snapshot.params['unId'] == undefined) ? localStorageService.unid : this.route.snapshot.params['unId'],
      this.featureId = this.route.snapshot.params['featureId']
  }

  ngOnInit() {
    this.timesliceId.emit(this.tSId);
    // this.checkDP();
    /*  console.log("PropertySwitchComponent: " + JSON.stringify(this.copyProperty));
     console.log("PropertySwitchComponent: " + this.newItem);
     console.log("featureId check: " + this.featureId + "," + this.positionInArray);
     console.log("group: " + this.getControlName() + "; parentFormGroup: " + this.parentFormGroup.getRawValue().toSource() +
       "; propertyGroup: " + JSON.stringify(this.propertyGroup)); */

    /*  this.propertyGroup.properties.forEach(property => {
       if (property instanceof LazyLoadedProperty) {
         let group: { [key: string]: AbstractControl; } = {};
         if (!this.propertyGroup.isNewProperty) {
           this.propertyService.getLazyLoadedProperties(this.featureId, this.unId, property.id, property.group, property.key).subscribe(data => {
             data.properties.forEach(jsonProp => {
               if (property.modified) {
                 //this.parentFormGroup.get(this.getControlName()).markAsDirty();
                 jsonProp.modified = true;
 
                 let loadedProp = this.propertyService.jsonToProperty(jsonProp);
 
                 // Add lazy loaded property fields to parent form group
                 let formGroup: FormGroup;
                 this.pfcs.addPropertyToGroup(loadedProp, group, '');
                 formGroup = this.parentFormGroup.get(this.getControlName()) as FormGroup;
                 formGroup.addControl(loadedProp.key, group[loadedProp.key]);
                 //}
                 //console.log("parentFormGroup with loadedProp: " + this.parentFormGroup.getRawValue().toSource());
 
                 // replace lazy loaded property with real property
                 let index = this.propertyGroup.properties.indexOf(property);
                 this.propertyGroup.properties.splice(index, 1, loadedProp);
                 this.propertyGroup.properties.push();
                 //console.log("propertyGroup with loadedProp:" + JSON.stringify(this.propertyGroup));
               }
             });
           });
         }
       }
     }) */
  }

  getLazyLoadedPropertyFieldsOfGroup() {
    let gmlId = this.propertyGroup.id;
    this.propertyGroup.properties.forEach(property => {
      if (property instanceof LazyLoadedProperty) {
        let group: { [key: string]: AbstractControl; } = {};
        if (this.propertyGroup.isNewProperty) {
          this.propertyService.getLazyLoadedPropertiesWithEmptyData(this.featureId, this.unId, "undefined", property.group, property.key, property.propOrderIndex).subscribe(data => {
            data.properties.forEach(jsonProp => {

              if (property.modified) {
                this.parentFormGroup.get(this.getControlName()).markAsDirty();
                jsonProp.modified = true;
              }

              let loadedProp = this.propertyService.jsonToProperty(jsonProp);

              // Add lazy loaded property fields to parent form group
              let formGroup: UntypedFormGroup;
              this.pfcs.addPropertyToGroup(loadedProp, group, '');
              formGroup = this.parentFormGroup.get(this.getControlName()) as UntypedFormGroup;
              formGroup.addControl(loadedProp.key, group[loadedProp.key]);
              //}
              //console.log("parentFormGroup with loadedProp: " + this.parentFormGroup.getRawValue().toSource());

              // replace lazy loaded property with real property
              let index = this.propertyGroup.properties.indexOf(property);
              this.propertyGroup.properties.splice(index, 1, loadedProp);
              this.propertyGroup.properties.push();
              //console.log("propertyGroup with loadedProp:" + JSON.stringify(this.propertyGroup));
            });
          });
          //this.isNewItem = false;
        } else {
          this.propertyService.getLazyLoadedProperties(this.featureId, this.unId, gmlId, property.group, property.key, property.propOrderIndex).subscribe(data => {
            data.properties.forEach(jsonProp => {

              if (property.modified) {
                this.parentFormGroup.get(this.getControlName()).markAsDirty();
                jsonProp.modified = true;
              }

              let loadedProp = this.propertyService.jsonToProperty(jsonProp);

              // Add lazy loaded property fields to parent form group
              let formGroup: UntypedFormGroup;
              this.pfcs.addPropertyToGroup(loadedProp, group, '');
              formGroup = this.parentFormGroup.get(this.getControlName()) as UntypedFormGroup;
              formGroup.addControl(loadedProp.key, group[loadedProp.key]);
              //}
              //console.log("parentFormGroup with loadedProp: " + this.parentFormGroup.getRawValue().toSource());

              // replace lazy loaded property with real property
              let index = this.propertyGroup.properties.indexOf(property);
              this.propertyGroup.properties.splice(index, 1, loadedProp);
              this.propertyGroup.properties.push();
              //console.log("propertyGroup with loadedProp:" + JSON.stringify(this.propertyGroup));
            });
          });
        }

      }
    });
  }

  /* The control name is the property key in general, except for MultipleProperty where it is the position. */
  getControlName(): string {
    return this.positionInArray == null ? this.propertyGroup.key : this.positionInArray.toString();
  }

  async createAnnotation() {
    if (!this.propertyGroup.annotations) {
      let annotationProperty = await this.propertyService.createAnnotationProperty();

      let formGroup: UntypedFormGroup;
      formGroup = this.parentFormGroup.get(this.getControlName()) as UntypedFormGroup;
      // this.getControlName() + '-annotations'
      formGroup.addControl('0-annotations', this.pfcs.property2Control(annotationProperty));
      // console.log("parentFormGroup with annotation: " + this.parentFormGroup.getRawValue().toSource());

      this.propertyGroup.annotations = annotationProperty;
      console.log("propertyGroup with annotation:" + JSON.stringify(this.propertyGroup));
    }
    this.showAnnotations = true;
  }

  hideAnnotaion() {
    this.showAnnotations = false;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  isNil(): boolean {
    return false; // TODO
  }

  setToNil() {
    //TODO
  }

  revert() {
    // TODO
  }

  onPointChoiceSelected(event: PointerEvent, targetKey: string, property: Property) {
    if (this.readonly) {
      return;
    }
    
    const parentFormGroup = this.parentFormGroup.controls[this.getControlName()] as UntypedFormGroup;
    const pointChoiceFormArray = parentFormGroup.get(property.key) as UntypedFormArray;
    const previousPointChoice = pointChoiceFormArray.at(0).value;
    
    if (previousPointChoice && previousPointChoice !== targetKey) {
      const prevFormArray = (parentFormGroup.get(previousPointChoice) as UntypedFormGroup)?.controls as unknown as UntypedFormArray;
        if (prevFormArray) {
          const [, prevArray]: [string, UntypedFormArray] = prevFormArray && Object.entries(prevFormArray)[0];
      
          // Show alert based on user has select a ref value or not
          if (prevArray) {
            console.log("yes");
            of(prevArray)
              .pipe(
                switchMap((formArray) => {
                  event.preventDefault();
                  return this.dialog
                    .open(this.pointChoiceChangeTpl, {
                      width: "300px",
                      data: { valueSelected: !!formArray.at(0).value },
                    })
                    .afterClosed()
                    .pipe(
                      filter((confirm) => !!confirm),
                      tap(() => pointChoiceFormArray.at(0).setValue(targetKey))
                    );
                })
              )
              .subscribe();
          }
      }
    }
  }

  notChoiceProperty(propertyKey: string): boolean {
    const [propertyKeyPrefix] = propertyKey.split('_')
    const regex = [
      new RegExp(`${propertyKeyPrefix}_.*?_ref_.*?`),
      new RegExp(/aixm:(\w+_\w+_\d+),aixm:(\w+)/),
      new RegExp(/aixm:(\w+_position+)/),
    ];
    return propertyKey ? !regex.some(value => value.test(propertyKey)): false;
  }

}
