import { ActivatedRoute } from '@angular/router';
import { PropertyFieldControlService } from './../property-field-control.service';
import { PropertyService } from './../property.service';
import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, AbstractControl, UntypedFormArray } from '@angular/forms';
import { Property, LazyLoadedProperty, MultipleProperty } from '../model/property';
import { UpdateNoticeService } from '../../../update-notice/service/updatenotice.service';
import { ConfigService } from '../../../utils/config.service';
import { ReferencePropertyField } from './../model/reference-property-field';
import { LocalStorageService } from '../../service/local-storage.service';
@Component({
  selector: 'im-property-switch',
  templateUrl: './property-switch.component.html',
  styleUrls: ['./property-switch.component.css']
})
export class PropertySwitchComponent implements OnInit {
  isDp: boolean = ConfigService.settings.isDataProvider;
  featureId: string;
  unId: string;
  tSId: string;

  @Input() property: Property;
  @Input() properties?: Property[] = []
  newProperty: Property = null;
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() positionInArray?: number;
  @Input() arrayName: string;
  @Input() state: string;
  @Input() tsId: string;
  @Input() readonly: boolean;
  @Input() expanded: boolean = false
  @Input() fromChoice: boolean = false
  @Input() isChoiceRef: boolean = false;

  showItem: boolean = true;
  showAnnotations: boolean = false;
  constructor(
    private propertyService: PropertyService,
    private pfcs: PropertyFieldControlService,
    private updateNoticeService: UpdateNoticeService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService) {
    this.unId = (this.route.snapshot.params['unId'] == undefined) ? localStorageService.unid : this.route.snapshot.params['unId'],
      this.featureId = this.route.snapshot.params['featureId']
  }

  ngOnInit() {
    // console.log("PropertySwitchComponent: " + this.readonly);
    // console.log("PropertySwitchComponent: " + JSON.stringify(this.parentFormGroup.getRawValue()));
    // console.log("test------: " + this.test.getRawValue().toSource());
    // console.log("test------: " + this.controlName); 
    // console.log("PropertySwitchComponent: " + JSON.stringify(this.copyProperty));
    // console.log("PropertySwitchComponent: " + this.newItemNo + this.newItem + this.positionInArray);
    // console.log("property switch: " + this.property.getPropertyType() + " " + this.property.key 
    // + "; parentFormGroup: " + this.parentFormGroup + "; controls: " + this.parentFormGroup.controls);

    if ((this.state == "new") || ((this.state = "existing") && this.property instanceof MultipleProperty && this.property.valueExisted)) {
      this.showItem = false;
    }
    if (this.property.propertyType == 'lazyLoaded') {
      this.getLazyLoadedPropertyFields(this.property as LazyLoadedProperty);
    }
  }

  getLazyLoadedPropertyFields(property: LazyLoadedProperty) {
    let group: { [key: string]: AbstractControl; } = {};

    this.propertyService.getLazyLoadedProperties(this.featureId, this.unId, property.id, property.group, property.key, property.propOrderIndex).subscribe(data => {
      data.properties.forEach(jsonProp => {

        if (property.modified) {
          this.parentFormGroup.get(this.property.key).markAsDirty();
          jsonProp.modified = true;
        }

        let loadedProp = this.propertyService.jsonToProperty(jsonProp);

        // Add lazy loaded property fields to parent form group
        this.pfcs.addPropertyToGroup(loadedProp, group, '');
        this.parentFormGroup.addControl(loadedProp.key, group[loadedProp.key]);
        //console.log("parentFormGroup with loadedProp: " + this.parentFormGroup.getRawValue().toSource());

        // replace lazy loaded property with real property
        this.property = loadedProp;
        //console.log("propety with loadedProp:" + JSON.stringify(this.property));
      });
    });
  }

  getLazyLoadedPropertyFieldsOfMulti() {
    this.property.properties.forEach(property => {
      if (property instanceof LazyLoadedProperty) {
        let group: { [key: string]: AbstractControl; } = {};

        this.propertyService.getLazyLoadedProperties(this.featureId, this.unId, property.id, property.group, property.key, property.propOrderIndex).subscribe(data => {
          data.properties.forEach(jsonProp => {

            if (property.modified) {
              this.parentFormGroup.get(this.property.key).markAsDirty();
              jsonProp.modified = true;
            }

            let loadedProp = this.propertyService.jsonToProperty(jsonProp);

            // Add lazy loaded property fields to parent form group
            this.pfcs.addPropertyToGroup(loadedProp, group, '');
            let formArray: UntypedFormArray;
            formArray = this.parentFormGroup.get(this.property.key) as UntypedFormArray;
            formArray.push(group[loadedProp.key]);
            //console.log("parentFormGroup with loadedProp: " + this.parentFormGroup.getRawValue().toSource());

            // replace lazy loaded property with real property
            // this.property.properties.splice(this.property.properties.indexOf(property), 1);
            // this.property.properties.push(loadedProp);
            // THM
            let index = this.property.properties.indexOf(property);
            this.property.properties.splice(index, 1, loadedProp);
            this.property.properties.push();
            //console.log("propety with loadedProp:" + JSON.stringify(this.property));
          });
        });
      }
    });
  }

  /* The MultipleProperty array name is the property key in general, except for annotation. */
  getArrayName() {
    return this.arrayName == null ? this.property.key : this.arrayName;
  }

  async createAnnotation() {
    if (!this.property.annotations) {
      let annotationProperty = await this.propertyService.createAnnotationProperty();

      let formArray: UntypedFormArray;
      formArray = this.parentFormGroup.get(this.property.key) as UntypedFormArray;
      formArray.push(this.pfcs.property2Control(annotationProperty));
      // console.log("parentFormGroup with annotation: " + this.parentFormGroup.getRawValue().toSource());

      this.property.annotations = annotationProperty;
      console.log("property with annotation:" + JSON.stringify(this.property));
    }
    this.showAnnotations = true;
  }

  hideAnnotaion() {
    this.showAnnotations = false;
  }

  addControlInFormArray(property: Property, controlName: string) {
    let formArray: UntypedFormArray = this.parentFormGroup.get(controlName) as UntypedFormArray;

    if (property instanceof ReferencePropertyField) {
      formArray.push(this.pfcs.property2Control(property));

      let size = this.property.properties.length;
      this.property.properties[size] = property;
    } else {
      let newProperty: Property = property.properties[0];
      newProperty.isNewProperty = true;
      let newGroup: { [key: string]: AbstractControl; } = {};
      this.pfcs.addPropertyToGroup(newProperty, newGroup, '');

      let size = this.property.properties.length;
      this.property.properties[size] = newProperty;

      let formGroup = newGroup[newProperty.key] as UntypedFormGroup;
      formArray.push(formGroup);
    }
  }

  async addItem() {
    // show hidden item
    if (!this.showItem) {
      this.showItem = true;
    }
    // generate new item
    else {
      if (this.arrayName != null) {
        let annotationProperty = await this.propertyService.createAnnotationProperty();
        this.addControlInFormArray(annotationProperty, this.arrayName);

      } else {
        let prop: Property = this.property.properties[0];
        if (this.property instanceof MultipleProperty && !(prop instanceof ReferencePropertyField)) {
          this.propertyService.getLazyLoadedPropertiesWithEmptyData(this.featureId, this.unId, "undefined", this.property.group, prop.key, prop.propOrderIndex).subscribe(data => {
            data.properties.forEach(jsonProp => {
              let loadedProp = this.propertyService.jsonToProperty(jsonProp);
              this.addControlInFormArray(loadedProp, this.property.key);
            });
          });
        } else if (prop instanceof ReferencePropertyField) {
          let referenceProperty: ReferencePropertyField = prop;
          referenceProperty.value = "";
          this.addControlInFormArray(referenceProperty, this.property.key);
        }
      }
    }
  }

  removeItem(event: number) {
    if (this.property.properties.length > 1) {
      let formArray: UntypedFormArray = this.parentFormGroup.get(this.getArrayName()) as UntypedFormArray;
      this.property.properties.splice(event, 1);
      formArray.removeAt(event);
      this.parentFormGroup.markAsDirty();
    }
  }

  setTimeSliceIdValue(id: string) {
    this.tSId = id;
    console.log(this.tSId);
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }
}
