import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import { ProfileVersion } from '../model/profileversion.model';
import { DataprofileService } from '../service/dataprofile.service';

/* export interface DialogData {
  profileId: string;
  versionId: number;
} */
@Component({
  selector: 'app-edit-dataprofile-version',
  templateUrl: './edit-dataprofile-version.component.html',
  styleUrls: ['./edit-dataprofile-version.component.css']
})

export class EditDataprofileVersionComponent implements OnInit {
  profileVersion: ProfileVersion= new ProfileVersion();
  fileNames: string[] = [];
  previousVersionId: string;

  constructor(private route: ActivatedRoute, private router: Router, 
    private dataProfileService: DataprofileService) {}

  ngOnInit() {
     this.getProfileVersionbyNumber();
  }

  getProfileVersionbyNumber(): void {
    let profileId = this.route.snapshot.params['profileId'];
    let versionId = this.route.snapshot.params['versionId'];
    console.log(profileId+""+versionId);
    this.dataProfileService.getProfileVersionbyNumber(profileId,versionId)
      .subscribe(profileVersion =>{ 
        this.profileVersion = profileVersion
        this.fileNames = profileVersion.fileNames;
      });
  } 


  editProfileVersion():void{
    console.log("The profile Id is "+ this.profileVersion.profileId +""+this.profileVersion.description)
    this.profileVersion.creationDate = new Date();
    this.dataProfileService.editProfileVersion(this.profileVersion)
    .subscribe(newVersionId => {
      this.router.navigate(['/profile/id',this.profileVersion.profileId]);
    });
  }

  clear(): void {
    this.ngOnInit();
  }

  unassign(identifier: string): void {
    this.dataProfileService.unassign(this.profileVersion.number, identifier)
    .subscribe(result => {
      this.ngOnInit();
     })
  }

  assignToDataProvider() {
    let versionId = this.route.snapshot.params['versionId'];
    this.router.navigate(['/provider', this.profileVersion.profileId, this.profileVersion.number])
  }
}







