
import { Component, OnInit } from '@angular/core';
import { UserManageMentService } from '../service/user-management.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
//import {SuccessDialogComponent} from '../success-dialog/success-dialog.component';
import { PasswordValidation } from './password-validation';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { CreateUserDTO } from '../model/CreateUserDTO';
import {  MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.css']
})
export class CreateUsersComponent implements OnInit {

  hide = true;
  user: CreateUserDTO = new CreateUserDTO();

  public form: UntypedFormGroup;
  constructor(public usermanagementServie: UserManageMentService, public dialog: MatDialog, public fb: UntypedFormBuilder) {
    this.form = fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      firstName: [''],
      lastName: [''],
      email: ['', Validators.email]
    }, {
      validator: PasswordValidation.MatchPassword
    })
  }


  ngOnInit() {
  }

  createNewUser(ownerFormValue): void {
    /*     console.log("The user in service is "+ this.user.firstName +""+
        this.user.userName+""+this.user.lastName+""+this.user.password); */
    let user: CreateUserDTO = {
      userName: ownerFormValue.userName,
      password: ownerFormValue.password,
      passwordConfirm: ownerFormValue.confirmPassword,
      firstName: ownerFormValue.firstName,
      lastName: ownerFormValue.lastName,
      email: ownerFormValue.email
    }
    console.log("The user in service is " + user.firstName + "" +
      user.userName + "" + user.lastName + "" + user.password);
    this.usermanagementServie.createNewUser(user)
      .subscribe(result => {
        //this.result = result,    
        let dialogRef = this.dialog.open(SuccessDialogComponent, {
          width: '500px',
          data: { result }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          console.log('After the dialog was closed:' + result);
          //this.result = result,
          //this.ngOnInit();
        });
        //alert("Created Project: " + result);
      });
  }


}
