export class GroupSummaryDTO {
    id?: string;
    groupName?: string;
    availbaleRoles?: string[];
    assignedRoles?: string[];

    constructor(options: {
        //id?: string,
        id?: string,
        groupName?: string,
        availbaleRoles?: string[],
        assignedRoles?: string[]
    } = {}) {
        //this.id = options.id || '';
        this.id = options.id;
        this.groupName = options.groupName;
        this.availbaleRoles = options.availbaleRoles;
        this.assignedRoles = options.assignedRoles;

    }
}
