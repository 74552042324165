export class CreateQAProjectDto {
    id:number;   
    projectName: string;
    hasDataInput: boolean;    
    hasTextEdition: boolean;
    //has2ndReview: boolean;

    constructor(options: {
      projectName?: string,
      hasDataInput?: boolean,
      hasTextEdition?: boolean,
      //has2ndReview?: boolean
  } = {}) {
      this.projectName = options.projectName || '';
      this.hasDataInput = options.hasDataInput;
      this.hasTextEdition = options.hasTextEdition;
      //this.has2ndReview = options.has2ndReview;
  }
}