<div>

  <mat-dialog-content>
    Would you like to complete task {{data.name}}?
  </mat-dialog-content>
  <div mat-dialog-actions>

    <button mat-raised-button mat-button color="primary" class="example-btn-width" (click)="complete(data.id)"
      style="cursor:pointer">
      Complete
    </button>

    <button mat-raised-button mat-button color="primary" class="example-btn-width"
      (click)="onCloseCancel()">Cancel</button>

  </div>
</div>