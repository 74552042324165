import { LogSheetSummaryDTO } from './../model/logsheetSummaryDTO';
import { Logsheet } from './../model/logsheet';
import { UserDetailsService } from './../../login/services/UserDetails.service';
import { Component, Input, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import {  MatDialog,  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogsheetService } from '../service/logsheet.service';
import { LogEntry } from '../model/logentry';

@Component({
  selector: 'app-newlogentrydialog',
  templateUrl: './newlogentrydialog.component.html',
  styleUrls: ['./newlogentrydialog.component.css']
})
export class NewlogentrydialogComponent implements OnInit {
  @Input()  logentry: LogEntry = new LogEntry();
   private logsheet: LogSheetSummaryDTO;
  constructor(private logSheetService: LogsheetService, private userDetailsService: UserDetailsService,
    public dialogRef: MatDialogRef<NewlogentrydialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.logsheet = data.logsheet;
    }
  private result: string;

  onAdd = new EventEmitter();

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  add(): void {
    console.log("In component: " + this.logentry.description + this.logsheet.id);
    this.logentry.logSheetId = this.logsheet.id;
    this.logentry.author = this.userDetailsService.getUser().username;
    this.logentry.creationDate = new Date();
    this.logentry.manual = true;
    this.logentry.entryReference = null;
    this.logSheetService.addLogEntry(this.logentry)
    .subscribe(result => {
      this.result = result,
      console.log("result: " + result); 
      this.onAdd.emit(result);
    });
    
  }

 
}