import { QualityProjectService } from './../service/quality-project.service';
import { QAProjectSummaryDTO } from './../model/QAProjectSummaryDTO';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { ConfigService } from '../../utils/config.service';


@Component({
  selector: 'app-qa-project-list',
  templateUrl: './qa-project-list.component.html',
  styleUrls: ['./qa-project-list.component.css']
})
export class QaProjectListComponent implements OnInit {
  private qaproject: QAProjectSummaryDTO[] = [];

  //datasource for table
  displayedColumns = ['name', 'status', 'latestActivityDate'];
  
  qaproject_dataSource = new MatTableDataSource<QAProjectSummaryDTO>(this.qaproject);

  isQualityController = this.qualityProjectService.isQualityController;

  isProjectManager = this.qualityProjectService.isProjectManager;

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.qaproject_dataSource.sort = this.sort;
    this.qaproject_dataSource.paginator = this.paginator;
  }

  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.qaproject_dataSource.filter = filterValue;
  }

  constructor(
    private qualityProjectService: QualityProjectService, 
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    console.log(this.isQualityController, 'isQualityController ###')
    this.getQAProjects();
  }

  getQAProjects(): void {
    this.qualityProjectService.getQAProjects()
      .subscribe(qaproject => this.qaproject_dataSource.data = qaproject);
  }

}
