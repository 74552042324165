import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, Form } from '@angular/forms';

import { UserProperties} from '../model/user-properties.model';
import { PropertiesService} from '../services/properties.service';


@Component({
  selector: 'app-new-userproperties',
  templateUrl: './new-userproperties.component.html',
  styleUrls: ['./new-userproperties.component.css']
})
export class NewUserpropertiesComponent implements OnInit {

  form: UntypedFormGroup;
  UProperties: UserProperties=new UserProperties(); 


  constructor(private userPropService: PropertiesService,
    private router: Router, private fb: UntypedFormBuilder) {
     this.form=fb.group({
        modulename: ['',Validators.required],
        property: ['',Validators.required],
        propertyvalue:['', Validators.required]
      })
    }

  ngOnInit() {
    
  }  

  addUserProperties(newUserPropertiesForm):void{    
    let UProperties: UserProperties={
      id:null,
      modulename: newUserPropertiesForm.modulename,
      property: newUserPropertiesForm.property,
      propertyvalue:newUserPropertiesForm.propertyvalue
    }
    
    this.userPropService.addUserProperties(UProperties)
      .subscribe(userprop=> {this.UProperties = userprop
        ,this.router.navigate(['/userproperties']);
      });
      
  }
  clear(): void {
   this.form.reset();
  }
}
