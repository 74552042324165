
import { UserManageMentService } from './../service/user-management.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupSummaryDTO } from '../model/groupSummaryDTO';
import {  MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import {  MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {  MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {

  private groups: GroupSummaryDTO[] = [];

  //datasource for table
  displayedColumns = ['groupName','delete_icon'];
  groups_dataSource = new MatTableDataSource<GroupSummaryDTO>(this.groups);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.groups_dataSource.sort = this.sort;
    this.groups_dataSource.paginator = this.paginator;
  }

  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.groups_dataSource.filter = filterValue;
  }
  constructor(private userManageMentService: UserManageMentService,public dialog: MatDialog) { }

  ngOnInit() {
    this.getGroups();
   }

   getGroups(): void {
    this.userManageMentService.getGroups()
    .subscribe(groups => this.groups_dataSource.data = groups);
  }

  openDeleteDialog(id: string): void {
    let dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: { id: id }
    });

    console.log("The group id is "+ id)
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('After the dialog was closed:'+ result);
      this.ngOnInit();
    });
  }

  
}
