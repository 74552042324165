<h3  mat-dialog-title>{{data.name}}</h3>

<div mat-dialog-actions>
   
      <button mat-button  class="example-btn-width" [mat-dialog-close]="data.id"  (click)="release(data.id)"
      style="cursor:pointer" cdkFocusInitial>
           Release
      </button>
    
      <button mat-button  class="example-btn-width" (click)="onNoClick()">Cancel</button>

</div>
