<div class="wrapper">
  <header class="header">
    <div>Overview</div>
    <div>{{num}}</div>
  </header>
  <!--   <div class="publication">
    <div>Latest publications</div>
    <div>AIP AIRAC AMDT 01/2018</div>
    <div>SUP 15/2017 Changes on RTE A123</div>
  </div> -->
  <div class="updateNotcie">
    <button mat-raised-button color="primary" class="button" [routerLink]="['/update/new']" style="cursor:pointer"
      *ngIf="isDp">
      New Update Notice
    </button>
  </div>
  <!--   <mat-card-actions>
    <button mat-raised-button color="primary" class="example-btn-width-blue"[routerLink]="['/update/new']"
     style="cursor:pointer" *ngIf="isDP()">
      Save
    </button>
  </mat-card-actions> -->

  <div class="workflow">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title class="card-header">My Tasks</mat-card-title>
      </mat-card-header>
      <mat-table #table [dataSource]="assignedTask_dataSource" class="table">
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef> Due Date </mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.dueDate}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="task">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
          <mat-cell *matCellDef="let task">
            <a routerLink='/update/id/{{task.processBusinessKey}}'>{{task.processName}}</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let task">
            <i class="material-icons" (click)="openUnclaimDialog(task.name, task.id)" style="cursor:pointer"
              matTooltip="Release task"> assignment_ind </i>
          </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
            <mat-cell *matCellDef="let task">
           <i class="material-icons" (click)="openUnclaimDialog(task.name, task.id)" style="cursor:pointer" matTooltip="Release {{task.name}}" *ngIf="task.assignee"> assignment_ind </i>
            </mat-cell>
        </ng-container>-->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card>
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title *ngIf="isDp" class="card-header">Tasks for {{groupName}}</mat-card-title>
        <mat-card-title *ngIf="!isDp" class="card-header">Tasks for AIS Group</mat-card-title>
      </mat-card-header>
      <mat-table #table [dataSource]="unassignedTask_dataSource" class="table">
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
        <!-- Position Column -->
        <!-- Name Column -->
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef> Due Date </mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.dueDate}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="task">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let task"> {{task.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
          <mat-cell *matCellDef="let task">
            <a routerLink='/update/id/{{task.processBusinessKey}}'>{{task.processName}}</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let task">
            <i class="material-icons" (click)="openClaimDialog(task.name, task.id,task.assignee)" style="cursor:pointer"
              matTooltip="Claim this task" *ngIf="!task.assignee"> assignment </i>
            <i class="material-icons" style="cursor:pointer" *ngIf="task.assignee"
              matTooltip="Assigned to {{task.assignee}}"> assignment_ind_disabled </i>
          </mat-cell>
        </ng-container>
        <!--         <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let task">
        <i class="material-icons" (click)="openUnclaimDialog(task.name, task.id)" style="cursor:pointer" matTooltip="Release {{task.name}}" *ngIf="task.assignee"> assignment_ind </i>
        </mat-cell>
        </ng-container> -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </mat-card>

  </div>
</div>