<div class="flex">
  <div style="width: 100%; padding: 12px 0;">
    <!-- Feature Groups -->
    <mat-form-field style="width: 300px;" *ngIf="referenceType != 'geoBorder'">
      <mat-select 
        #groupList 
        placeholder="Feature group"
        [(value)]="selected" 
        (openedChange)="featureSelectFilter.input.nativeElement.focus()" 
        (selectionChange)="getFeaturesByType($event.value)">
        <mat-select-filter 
          #featureSelectFilter 
          [array]="featureGroups" 
          [displayMember]="'name'"
          (filteredReturn)="featureGroupFilter = $event">
        </mat-select-filter>
        <mat-option *ngFor="let group of featureGroupFilter | OrderBy: 'name'" [value]="group.name">
          {{group.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Features -->
    <ng-container *ngIf="!isReference && (featuresSubject$ | async).length">
      <div class="selection-filter-container">
        <ng-container *ngTemplateOutlet="filterListOption"></ng-container>
        <mat-nav-list dense aria-label="feature-link" [disableRipple]="true" class="nav-list">
          <ng-container *ngFor="let featureLink of (filteredFeaturesLink$ | async) | OrderBy: 'featureName'">
            <a mat-list-item [routerLink]="featureLink.link">
              {{ featureLink.feature.featureName || featureLink.feature.featureId }}
            </a>
          </ng-container>
        </mat-nav-list>
      </div>
    </ng-container>
    
    <ng-container *ngIf="isReference && (featuresSubject$ | async).length">
      <div class="selection-filter-container">
        <ng-container *ngTemplateOutlet="filterListOption"></ng-container>
        <mat-selection-list dense [multiple]="false" (selectionChange)="setToReferencePropertyValue($event)" [hideSingleSelectionIndicator]="true">
          <mat-list-option *ngFor="let feature of (filteredFeatures$ | async) | OrderBy: 'featureName'" [value]="feature">
            {{ feature.featureName || feature.featureId}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </ng-container>

    <!--Create New Features-->
    <mat-card-actions class="buttonspace">
      <button type="submit" mat-raised-button mat-button color="primary"
        *ngIf="selectedGroup && unId != null && !isReference" (click)="redirectToEmptyForm(selectedGroup)">New
        Feature</button>
    </mat-card-actions>

  </div>
</div>

<ng-template #filterListOption>
  <mat-form-field style="width: 300px;">
    <mat-label>Filter</mat-label>
    <input matInput [formControl]="featureSearchControl">
    <button (click)="featureSearchControl.setValue(null)" mat-icon-button matSuffix>
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</ng-template>