<h3>Timeline</h3>
<div #visualization class="timeline"></div>
<div>
  <mat-form-field *ngIf="correctionsPerTs[0]">
    <mat-select placeholder="Correction" (selectionChange)="selectCorrection($event.value)">
      <mat-option *ngFor="let correction of correctionsPerTs" [value]="correction">
        Correction : {{correction.correction}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>