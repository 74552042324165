<div class="container">
  <mat-toolbar>
    <span>
      <h3> List of User Groups </h3>
    </span>
  </mat-toolbar>
  <mat-card>
    <mat-card-content>
      <mat-table #table [dataSource]="usergrouplist_dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row}}
          </mat-cell>
        </ng-container>
        
         <!-- For Link buttons Column -->
         <ng-container matColumnDef="Actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <i class="material-icons" (click)="openLinkDialog(row)" style="cursor:pointer">link</i> 
          </mat-cell>
        </ng-container>
        
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    

    <mat-card-footer>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-footer>
    
  </mat-card-content>
  </mat-card>
</div>