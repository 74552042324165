import { AixmFeatureDTO } from './../model/AixmFeatureDTO';
import { InfoManagementService } from './../service/info-management.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  MatSelectionList,  MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'im-feature-list',
  templateUrl: './feature-list.component.html',
  styleUrls: ['./feature-list.component.css']
})
export class FeatureListComponent implements OnInit {
    
  features: AixmFeatureDTO[] = [];

    // 'VYYY RWY 09/27',
    // 'VYMD RWY 09/27',
    // 'VYSX RWY 09/27',
    // 'VYSY RWY 09/27'
    
  selectedFeature: string;

  constructor(private infoManagementService: InfoManagementService) { }
  
  @ViewChild(MatSelectionList) matFeatureList: MatSelectionList;
  
  ngOnInit() {
      this.matFeatureList.selectionChange.subscribe((s: MatSelectionListChange) => {          

          this.matFeatureList.deselectAll();
          // s.option.selected = true;
          // this.selectedFeature = s.option.value;
      });

      this.getFeaturesByType();
  }

  getFeaturesByType(): void{
    // this.infoManagementService.getFeaturesByType()
    // .subscribe(features => this.features = features);
  }

}
