import { FeatureSelectorService } from './../../feature-selector/feature-selector.service';
import DmsCoordinates from "dms-conversion";
import { DMSFormat } from './../model/DMSFormat';
import Collection from 'ol/Collection';
import { AixmFeatureDTO } from './../../feature-selector/model/AixmFeatureDTO';
import { Component, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { GmlInterpolation } from '../model/GmlInterpolation';
import { Feature } from 'ol';
import LineString from 'ol/geom/LineString';
import { GeoJSON } from 'ol/format';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'geo-border-point',
  templateUrl: './geo-border-point.component.html',
  styleUrls: ['./geo-border-point.component.css']
})
export class GeoBorderPointComponent implements OnChanges {

  @Input() segment: Feature;
  @Input() previousPoint: Feature;
  @Input() isSurface: boolean;
  @Input() isFirstSegment: boolean;

  isGeoBorder: boolean = false;
  interpolation: GmlInterpolation;
  startPointCoordinates: number[];
  endPointCoordinates: number[];

  showFeatureList: boolean = false;
  feature: AixmFeatureDTO = new AixmFeatureDTO({ featureId: '', featureType: '', featureName: '', summary: '', lastEdited: '', tsId: null });
  @Output() startPointCoordinatesChanged = new EventEmitter<number[]>();
  @Output() onError = new EventEmitter<boolean>();

  @Input() isHighlight: boolean;
  public color: string;
  referencedGeoBorder: Collection<Feature> = new Collection([], { unique: true });
  borderType: string;
  borderName: string;
  pointList: string[] = [];
  pointListFilter: string[] = [];

  unId: string = null;
  isUNFeature: boolean;
  isInvalid: boolean;

  constructor(private featureSelectorService: FeatureSelectorService, private route: ActivatedRoute) {
    this.unId = this.route.snapshot.params['unId']
  }

  ngOnInit() {
    // get referenced geoborder feature name of current segment

    if (this.segment.get("startPoint").ref != undefined && this.segment.get("startPoint").ref != '' && this.segment.get("startPoint").isUNFeature != undefined) {
      this.featureSelectorService.getReferencedGmlFeature('geoBorder', this.segment.get("startPoint").ref.replace('urn:uuid:', ''), this.unId,
        this.segment.get("startPoint").isUNFeature).subscribe(geoborder => {
          this.borderType = geoborder.properties['border-type'];
          this.borderName = geoborder.featureName;
        });

    }

    if (this.isHighlight) {
      this.color = 'yellow';
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    let checkFirstPoint = (this.isFirstSegment && !this.isSurface) ? false : true;

    if (changes.segment) {
      this.startPointCoordinates = this.getStartPointCoordinates(this.segment);
      this.endPointCoordinates = this.getEndPointCoordinates(this.segment);
      this.interpolation = this.getInterpolation();
      // display the selected Geoborder feature after revert      
      if (this.startPointCoordinates.length != 0 && this.segment.get("startPoint").ref != undefined && this.segment.get("startPoint").ref != '') {
        this.featureSelectorService.getReferencedGmlFeature('geoBorder', this.segment.get("startPoint").ref.replace('urn:uuid:', ''), this.unId,
          true).subscribe(geoborder => {
            this.borderType = geoborder.properties['border-type'];
            this.borderName = geoborder.featureName;
          });
      }
    }

    if (checkFirstPoint && changes.previousPoint && this.previousPoint.get("interpolation") === GmlInterpolation.GEO_BORDER) {
      this.isGeoBorder = true;

      let refFeatureId: string = this.previousPoint.get("startPoint").ref;
      this.isUNFeature = this.previousPoint.get("startPoint").isUNFeature;
      if (refFeatureId != this.segment.get("startPoint").ref || this.isUNFeature != this.segment.get("startPoint").isUNFeature) {
        this.isInvalid = true;
        this.onError.emit(this.isInvalid);
      } else {
        this.isInvalid = false;
        this.onError.emit(this.isInvalid);
      }

      // get referenced geoborder feature of previous segment
      if (this.startPointCoordinates.length != 0 && refFeatureId != undefined && this.isUNFeature != undefined) {
        this.featureSelectorService.getReferencedGmlFeature('geoBorder', refFeatureId.replace('urn:uuid:', ''), this.unId, this.isUNFeature)
          .subscribe(geoborder => {
            this.setToReferencePropertyValue(new AixmFeatureDTO({ featureId: refFeatureId.replace('urn:uuid:', ''), featureType: '', featureName: '', summary: '', lastEdited: '', tsId: null }));
            this.showPoints(geoborder);
          }
          );
      }
    }
  }

  getInterpolation(): GmlInterpolation {
    console.log("interpolation: " + this.segment.get("interpolation"));
    return (<any>GmlInterpolation)[this.segment.get("interpolation")];
  }

  /**
   * Return the coordinates in long, lat format separated by a space
   */
  getStartPointCoordinates(feature: Feature): number[] {
    return (<LineString>feature.getGeometry()).getFirstCoordinate();
  }

  /**
    * Return the coordinates in long, lat format separated by a space
    */
  getEndPointCoordinates(feature: Feature): number[] {
    return (<LineString>feature.getGeometry()).getLastCoordinate();
  }

  showFeaturesSelector() {
    this.showFeatureList = true;
  }

  setToReferencePropertyValue(feature: AixmFeatureDTO) {
    console.log('Picked ReferencePropertyValue: ', feature);
    this.feature = feature;
  }

  showPoints(geoborder: AixmFeatureDTO) {
    //console.log("referenced geoBorder: " + JSON.stringify(geoborder));
    this.borderType = geoborder.properties['border-type'];
    this.borderName = geoborder.featureName;

    this.pointList = [];
    if (geoborder.properties['segments'] != null) {
      this.referencedGeoBorder = new Collection(new GeoJSON().readFeatures(geoborder.properties['segments']));
      this.referencedGeoBorder.getArray().forEach(feature => {
        this.pointList.push(this.getCoordinates(this.getStartPointCoordinates(feature), DMSFormat.ICAO));
        this.pointListFilter = this.pointList;
      });
    }
  }

  /* update startPointCoordinates which is listened by latlong component */
  updateStartPointCoordinates(starPoint: string) {
    // convert back to decimal and flip lat/long
    let pair: string[] = starPoint.split(' ').reverse();
    this.startPointCoordinates = pair.map(GeoBorderPointComponent.parseDms);

    this.showFeatureList = false;
  }

  setStartPointCoordinates(starPoint: number[]) {
    //this.startPointCoordinates = starPoint;
    // let geom: number[][] = (<LineString>this.segment.getGeometry()).getCoordinates();
    // geom[0] = starPoint;
    // (<LineString>this.segment.getGeometry()).setCoordinates(geom);

    if (this.feature.featureId) {
      this.segment.get("startPoint").ref = 'urn:uuid:' + this.feature.featureId;
    }
    console.log(this.feature.tsId);
    if (this.feature.tsId == null) { this.segment.get("startPoint").isUNFeature = this.previousPoint.get("startPoint").isUNFeature } else {
      this.segment.get("startPoint").isUNFeature = (this.feature.tsId.interpretation == 'BASELINE') ? false : true
    };

    // update the last coordinates of the previous segment, or of the last segment if we're on the 1st one
    this.startPointCoordinatesChanged.emit(starPoint);
  }

  // unit: 'degree', unitDisplay: 'narrow' are not supported in the version of TS we're currently using:
  // https://github.com/microsoft/TypeScript/issues/38012
  //static readonly DMS_FORMAT_WITH_UNITS = new Intl.NumberFormat('en-US', {style: 'unit', unit: 'degree', unitDisplay: 'narrow'});
  static readonly SEC_FORMAT = new Intl.NumberFormat('en', { minimumIntegerDigits: 2, maximumFractionDigits: 3 });

  /**
   * Change coordinates format
   * @param coordinates 
   * @param format 
   */
  getCoordinates(coordinates: number[], format: DMSFormat): string {
    let srcLat = coordinates[1];
    let srcLon = coordinates[0];

    typeof srcLat === 'string' ? srcLat = 0 : srcLat = srcLat;
    typeof srcLon === 'string' ? srcLon = 0 : srcLon = srcLon;

    if (srcLat == undefined || srcLon == undefined) {
      return "";
    }
    //return Point.deg2dms(this.latitude, this.longitude);
    // with OpenLayers: return toStringHDMS( [this.latitude, this.longitude], 5 );
    const dmsCoord = new DmsCoordinates(srcLat, srcLon);
    const { longitude, latitude } = dmsCoord.dmsArrays;
    let [dLat, mLat, sLat, hLat] = latitude;
    let [dLon, mLon, sLon, hLon] = longitude;
    let [sdLat, smLat, smLon] = [dLat, mLat, mLon].map(s => s.toString().padStart(2, '0'));
    let sdLon = dLon.toString().padStart(3, '0');
    let [ssLat, ssLon] = [sLat, sLon].map(s => GeoBorderPointComponent.SEC_FORMAT.format(s));
    switch (format) {
      case DMSFormat.WITH_UNITS:
        return `${sdLat}°${smLat}'${ssLat}${hLat} ${sdLon}°${smLon}'${ssLon}${hLon}`;
      case DMSFormat.ICAO:
      default:
        // no units
        return `${sdLat}${smLat}${ssLat}${hLat} ${sdLon}${smLon}${ssLon}${hLon}`;
    }
  }

  /**
   * Our own pattern because the one from dms.js doesn't support ICAO format.
   * We want to match DMS, with or without DMS symbols:
   * DMS
   *   012345N always 6 digits before decimal point for latitudes
   *   123456N
   *   123456.1234N
   *   0012345E always 7 digits before decimal point for longitudes
   *   0123456E
   *   12345678E
   *   1°20'30S leading zeroes can be omitted if symbols are given
   *   1°20'30.1234S
   *   100°20'30E
   *   100°01'02.3E
   *   100°20'30.123E
   * DM without seconds: we don't support these yet
   *   0123N
   *   00123E
   * 
   * Pattern:
   * - NSEW is mandatory and at the end
   * - minus sign is not allowed
   * - there may be any number of decimals of seconds
   * - degree, minutes and seconds must be given if 0
   * - if not using symbols, leading zeroes are mandatory
   * - no spaces
   */
  // TODO this is to support ICAO format only, without symbols
  static readonly dmsRegExp = new RegExp("^(\\d?\\d\\d)(\\d\\d)(\\d\\d(?=\\.\\d+)?)([NSEW])$");

  // Regex pattern and parseDms() copied from dms.js because I don't manage to import it: it works in TypeScript but parseDms is undefined in JS.
  //static const dmsRe = /^(-?\d+(?:\.\d+)?)[°:d]?\s?(?:(\d+(?:\.\d+)?)['′ʹ:]?\s?(?:(\d+(?:\.\d+)?)["″ʺ]?)?)?\s?([NSEW])?/i;

  /**
   * Parses a Degrees Minutes Seconds string into a Decimal Degrees number.
   * @param {string} dmsStr A string containing a coordinate in either DMS or DD format.
   * @return {Number} If dmsStr is a valid coordinate string, the value in decimal degrees will be returned. Otherwise NaN will be returned.
   */
  static parseDms(dmsStr: string): number {
    let output = NaN;
    let dmsMatch = GeoBorderPointComponent.dmsRegExp.exec(dmsStr);
    if (dmsMatch) {
      const degrees = Number(dmsMatch[1]);
      const minutes = typeof (dmsMatch[2]) !== "undefined" ? Number(dmsMatch[2]) / 60 : 0;
      const seconds = typeof (dmsMatch[3]) !== "undefined" ? Number(dmsMatch[3]) / 3600 : 0;
      const hemisphere = dmsMatch[4] || null;
      if (hemisphere !== null && /[SW]/i.test(hemisphere)) {
        output = -Math.abs(degrees) - minutes - seconds;
      }
      else {
        output = degrees + minutes + seconds;
      }
    }
    return output;
  }

  private readonly sampleReferencedGeoBorder = {
    'type': 'FeatureCollection',
    'crs': {
      'type': 'name',
      'properties': {
        'name': 'EPSG:4326'
      }
    },
    'features': [
      {
        "type": "Feature",
        "properties": {
          "interpolation": "GEO_BORDER",
          "startPoint": {
            "type": "GEO_BORDER_POINT",
          },
          "endPoint": {
            "type": "GEO_BORDER_POINT",
          }
        },
        "geometry": {
          "type": "LineString",
          "coordinates": [[10.123456, 15.2], [20, 20]]
        }
      }
    ]
  };
}

export class GeoBorder {
  type: string = "state border";
  name = "France-Belgium";
}

