<ng-container [ngSwitch]="property.propertyType">

  <!-- Simple form field -->
  <im-input-form-field *ngSwitchCase="'field'" [propertyField]="property" [parentFormGroup]="parentFormGroup"
    [positionInArray]="positionInArray" [state]="state" [tsId]="tsId" [readonly]="readonly"
    [fromChoice]="fromChoice"></im-input-form-field>

  <!-- Reference Property Field -->
  <ng-container *ngSwitchCase="'reference'">
    <mat-expansion-panel [expanded]="expanded" style="margin-bottom: 10px;" *ngIf="!isChoiceRef; else choiceRefTpl">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ property.label }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <im-input-form-field [propertyField]="property" [parentFormGroup]="parentFormGroup"
        [positionInArray]="positionInArray" [state]="state" [tsId]="tsId" [readonly]="readonly"
        [fromChoice]="fromChoice"></im-input-form-field>
    </mat-expansion-panel>

    <ng-template #choiceRefTpl>
      <div class="mat-elevation-z2" style="padding: 12px 16px; border-radius: 5px;">
        {{ parentFormGroup.get(property.key).value[0] && property.label }}
        <im-input-form-field [propertyField]="property" [parentFormGroup]="parentFormGroup"
        [positionInArray]="positionInArray" [state]="state" [tsId]="tsId" [readonly]="readonly"
        [fromChoice]="fromChoice"></im-input-form-field>
      </div>
    </ng-template>
  </ng-container>

  <!-- Group of fields -->
  <im-input-form-group *ngSwitchCase="'group'" [expanded]="expanded" [propertyGroup]="property"
    [parentFormGroup]="parentFormGroup" [positionInArray]="positionInArray" [tSId]="tSId"
    (timesliceId)="setTimeSliceIdValue($event)" [readonly]="readonly" [fromChoice]="fromChoice">
  </im-input-form-group>

  <!-- [copyProperty]="copyProperty" [newItem]="newItem" -->
  <!-- Lazy loaded drawer -->
  <mat-expansion-panel *ngSwitchCase="'lazyLoaded'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>{{property.label}}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <p>{{property.description}}</p>
  </mat-expansion-panel>

  <!-- Array of fields -->
  <mat-expansion-panel *ngSwitchCase="'multiple'" (opened)="getLazyLoadedPropertyFieldsOfMulti();"
    class="property-array">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>{{property.label | slice:0:property.label.length-1}} List</span>

      </mat-panel-title>
      <mat-panel-description>({{showItem? property.properties.length : 0}})</mat-panel-description>
    </mat-expansion-panel-header>

    <p>{{property.description}}</p>
    <im-field-array *ngIf="showItem" [multipleProperty]="property" [parentFormGroup]="parentFormGroup"
      [arrayName]="getArrayName()" [tSId]="tSId" (timesliceId)="setTimeSliceIdValue($event)"
      (removedProperty)="removeItem($event)" [readonly]="readonly" [fromChoice]="fromChoice">
    </im-field-array>

    <mat-action-row>
      <!-- Buttons for delete, annotations and info for the array itself (where it makes sense)
            For example, AirportHeliport/servedCity can occur multiple times, but these buttons control
            the list of served cities, not each city. -->
      <button type="button" mat-icon-button matTooltip="Add an item" (click)="addItem()" [disabled]="readonly" >
        <!-- (click)="addItem()" -->
        <mat-icon color="primary" aria-label="Add an item" [ngClass]="{'disable':readonly, 'enable': !readonly}">add</mat-icon>
      </button>
      <button type="button" mat-icon-button matTooltip="Revert to initial value"
        (click)="revert(); stopPropagation($event)" [disabled]="readonly">
        <mat-icon color="primary" aria-label="Set property to nil"  [ngClass]="{'disable':readonly, 'enable': !readonly}">undo</mat-icon>
      </button>
      <!-- <button type="button" mat-icon-button matTooltip="Annotations"
        *ngIf="property.key !== 'aixm:annotations,aixm:Note' && property.key !== 'aixm:translatedNotes,aixm:LinguisticNote'"
        (click)="showAnnotations? hideAnnotaion(): createAnnotation()">
        <mat-icon color="primary" aria-label="Annotations">description</mat-icon>
      </button> -->
      <!-- <button type="button" mat-icon-button matTooltip="Property description"
        (click)="showInfo = !showInfo; stopPropagation($event)">
        <mat-icon color="primary" aria-label="Description">info</mat-icon>
      </button> -->

    </mat-action-row>

    <div *ngIf="showAnnotations" class="indented">
      <im-property-switch [property]="property.annotations" [parentFormGroup]="parentFormGroup.controls[property.key]"
        [arrayName]="property.properties.length" [readonly]="readonly"></im-property-switch>
    </div>
  </mat-expansion-panel>

  <!-- Point Choice Tpl -->
  <ng-container *ngSwitchCase="'choice'">
    <im-choice-field [property]="property" [properties]="properties" [parentFormGroup]="parentFormGroup"
      [positionInArray]="positionInArray" [readonly]="readonly">
    </im-choice-field>
  </ng-container>
</ng-container>