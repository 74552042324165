import { TextAreaPropertyField } from './../model/textarea-property-field';
import { AixmFeatureDTO } from './../../../feature-selector/model/AixmFeatureDTO';
import { PropertyService } from './../property.service';
import { PropertyFieldControlService } from './../property-field-control.service';
import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter, ChangeDetectorRef, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { PropertyField } from "../model/property-field";
import { WithUnitPropertyField } from "../model/with-unit-property-field";
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailsService } from '../../../login/services/UserDetails.service';
import moment from 'moment';
import {  MatDialog } from '@angular/material/dialog';
import { MapViewDialogComponent } from '../../map-view-dialog/map-view-dialog.component';
import LineString from 'ol/geom/LineString';
import { Feature } from 'ol';
import { LatlongComponent } from '../../../geo-editor/latlong/latlong.component';
import { DataproviderDialogComponentComponent } from '../../dataprovider-dialog-component/dataprovider-dialog-component.component';
import { ReferencePropertyField } from '../model/reference-property-field';
import { ConfigService } from '../../../utils/config.service';
import { LocalStorageService } from '../../service/local-storage.service';
import { FeatureSelectorService } from '../../../feature-selector/feature-selector.service';
import { TemplatePortal } from '@angular/cdk/portal';

let timesliceId = "";

@Component({
   selector: 'im-input-form-field[propertyField][parentFormGroup]',
   templateUrl: 'input-form-field.component.html',
   styleUrls: ['input-form-field.component.css'],
   // encapsulation: ViewEncapsulation.None,
})

export class InputFormFieldComponent implements OnInit {
   isDP: boolean = ConfigService.settings.isDataProvider;
   private role = ConfigService.settings.role;
   REFERENCE_AIRAC_DATE = moment("2018-11-08");
   AIRAC_CYCLE_DAYS = 28;

   @Input() propertyField: PropertyField<any>;
   @Input() parentFormGroup: UntypedFormGroup;
   @Input() positionInArray?: number;
   @Input() state: string;
   @Input() tsId: string;
   @Input() segment: Feature;
   @Input() readonly: boolean;
   @Input() fromChoice:boolean = false;

   @Output() startPointCoordinatesChanged = new EventEmitter<number[]>();

   refLinkErrorPortal: TemplatePortal<any> = null;

   @ViewChild('templatePortalContent', {static: false}) set templatePortalContent(data: TemplateRef<unknown>) {
      if (data) {
        this.refLinkErrorPortal = new TemplatePortal(data, this.vcr);
        this.cdr.detectChanges();
      }
   };

   nilReasons = [
      { key: 'inapplicable', displayValue: 'Inapplicable' },
      { key: 'missing', displayValue: 'Missing' },
      { key: 'template', displayValue: 'Template' },
      { key: 'unknown', displayValue: 'Unknown' },
      { key: 'withheld', displayValue: 'Withheld' }
   ]

   languages = [
      { key: 'eng', label: 'English' },
      { key: 'fra', label: 'French' },
      { key: 'mya', label: 'Burmese' },
      { key: 'spa', label: 'Spanish' },
      { key: 'nld', label: 'Dutch' },
      { key: 'mul', label: 'Multiple' }
   ]

   showAnnotations: boolean = false;
   showInfo: boolean = false;
   showNilReasonList: boolean = false;
   displayMap: boolean;
   coordinateModified: boolean = false;

   featureId: string;
   unId: string;
   latitude: any;
   longitude: any;
   gmlId: string;
   coordinate: number[];
   val: string;

   backupControl: any;
   isInitialState = true;

   showFeatureList: boolean = false;
   refFeature: AixmFeatureDTO;

   isPublished: boolean = false;
   isRevert: boolean = false;
   isGeoEmpty: boolean;
   isRefFeatPicked: boolean = false;
   gmlname: string;
   normalizedInput: string;


   latLongRegexPattern = /^\d+°?\d+'?\d+\.?\d+"?[NS]\s\d+°?\d+'?\d+\.?\d+"?[EW]$/;
   ///^(\d\d\d\d\d\d)(\.\d+)?[NS]\s(\d\d\d\d\d\d\d)(\.\d+)?[EW]$/;
   decimalRegex = /^(-?\d\d?(\.\d+)?)[NS]?,?\s+?(-?\d\d?\d?(\.\d\d\d\d+)?[EW]?)$/;

   get isValid() {
      return this.parentFormGroup.controls[this.getControlName()].valid
         || this.parentFormGroup.controls[this.getControlName()].status == 'DISABLED';

   }

   get referencePropHasValue(): boolean {
      if (this.propertyField instanceof ReferencePropertyField) {
        const formFieldArray = this.parentFormGroup.controls[this.getControlName()] as UntypedFormArray;
        const hasValue = !!formFieldArray.at(0).value || !!formFieldArray.at(2).value;
        return hasValue;
      }
      return false;
   }

   isSurface: boolean;
   isShowMap: boolean = true;

   constructor(
      private propertyService: PropertyService,
      private pfcs: PropertyFieldControlService,
      private userDetailsService: UserDetailsService,
      public dialog: MatDialog,
      private route: ActivatedRoute,
      private router: Router,
      private featureSelectorService: FeatureSelectorService,
      private localStorageService: LocalStorageService,
      private cdr: ChangeDetectorRef,
      private vcr: ViewContainerRef) {
      this.unId = this.route.snapshot.params['unId'];
      this.featureId = this.route.snapshot.params['featureId'];
      this.isPublished = this.route.snapshot.params['isTentative'];

      if (this.featureId != undefined) {
         if (this.featureId.includes('_')) {
            this.featureId = this.route.snapshot.params['featureId'].split("_")[0];
            this.tsId = this.route.snapshot.params['featureId'].split("_")[2];
         }
      }
   }

   loadGmlData(): any {
      var name = this.getControlName();
      var value = this.parentFormGroup.get(name).get('0').value;
      /* if (value == "" && name.includes("patches")) {
       value = this.geoJsonFeatures; // this.patchesGeoJson
    } else if (value == "" && name.includes("segments")) {
       value = this.geoJsonFeatures; // this.segmentsGeoJson
    } */
      return value;
   }

   /*  getGmlPoint(): any {
       var name = this.getControlName();
       var value = this.parentFormGroup.get(name).get('0').value;
       this.isGmlPos = true;
       return value;
    } */

   ngOnInit() {
      if (this.tsId != undefined) {
         timesliceId = this.tsId;
         // console.log(timesliceId);
      }
      this.backupControl = this.parentFormGroup.get(this.getControlName()).value;

      if (this.propertyField instanceof TextAreaPropertyField && this.propertyField.key.includes('patches')) {
         this.isSurface = true;
      } else if (this.propertyField instanceof TextAreaPropertyField && this.propertyField.key.includes('segments')) {
         this.isSurface = false;
      }
      // console.log("property key: " + this.propertyField.key + "; position: " + this.positionInArray + "; property: " + JSON.stringify(this.propertyField));
      // console.log("Field parentFormGroup: " + this.parentFormGroup.getRawValue().toSource());
      // let controlName = this.getControlName();
      // this.parentFormGroup.get(controlName).setValidators(Validators.nullValidator);
      // this.changeDetectorRef.detectChanges();

      this.segment = new Feature({ geometry: new LineString([]) });
      (<LineString>this.segment.getGeometry()).setFlatCoordinates(null, [0, 0]);//flatCoordinates = [0, 0];  
   }

   normalizeInput(event): void {
      this.changeInitialState();
      if (this.propertyField.hint.includes("Capital letters")) {
         var controlName = this.getControlName();
         (this.parentFormGroup.get(controlName) as UntypedFormArray).at(0).setValue(event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase())
      }
   }

   openDataproviderDialog(): void {
      let dialogRef = this.dialog.open(DataproviderDialogComponentComponent, {
         width: '500px',
         data: { featureId: this.featureId, unId: this.unId, featureType: this.localStorageService.featureType }
      });
   }

   // get gmlValue () {
   //   return JSON.stringify(this.gml, null, 3);
   // }

   // set gmlValue(v) {
   //   try {
   //     this.gml = JSON.parse(v);
   //   }
   //   catch (e) {
   //     console.log('error occored while you were typing the JSON');
   //   };
   // }

   /* The control name is the property key in general, except for MultipleProperty where it is the position. */
   getControlName() {
      return this.positionInArray == null ? this.propertyField.key : this.positionInArray.toString();
   }

   isEmpty(): boolean {
      var isEmpty;
      var controlName = this.getControlName();
      var formArr = this.parentFormGroup.get(controlName).value;
      if (formArr.length > 0) {
         var value = formArr[0];
         isEmpty = value == "";
      }
      if (this.isGeoEmpty) {
         console.log("IS GEO EMPTY " + this.isGeoEmpty);
         isEmpty = true;
      }

      if (this.referencePropHasValue) {
        isEmpty = false;
      }

      if (this.isRevert == true) {
         isEmpty = false;
      }

      return isEmpty;
   }

   isNil(): boolean {
      return this.propertyField.nil;
   }

   setToNil() {
      this.isInitialState = false;
      var controlName = this.getControlName();
      var formArr = this.parentFormGroup.get(controlName).value;
      this.backupControl = formArr;
      this.isRevert = false;

      if (formArr.length == 2) {
         var nilReason = formArr[1];
         nilReason = nilReason == "" ? "missing" : nilReason;
         this.parentFormGroup.get(controlName).patchValue(["", nilReason]);
      }
      else if (formArr.length == 3) {
         var nilReason = formArr[1];
         nilReason = nilReason == "" ? "missing" : nilReason;
         this.parentFormGroup.get(controlName).patchValue(["", nilReason, ""]);
      }
      this.parentFormGroup.get(controlName).markAsDirty();
      this.parentFormGroup.get(controlName).markAllAsTouched();
      //this.showNilReasonList = !this.showNilReasonList;
      //return this.showNilReasonList;     
   }

   hasUnit() {
      return this.propertyField instanceof WithUnitPropertyField;
   }

   revert() {
      this.isInitialState = true;
      var initialValueArr = [];
      this.isRevert = true;
      for (var value of this.backupControl) {
         initialValueArr.push(value);
      }
      this.parentFormGroup.get(this.getControlName()).patchValue(initialValueArr);
   }

   changeInitialState() {
      this.isInitialState = false;
   }

   addGeoJsonInFormGroup(gml: string) {
      setTimeout(() => {
         let geoEditorControl: AbstractControl = this.parentFormGroup.get(this.propertyField.key);
         // geoEditorControl.patchValue([gml.valueOf(), "missing"]);
         // geoEditorControl.get('0').setValue(gml.valueOf(), { onlySelf: false, emitModelToViewChange: false });
         geoEditorControl.get('0').setValue(gml.valueOf());
         geoEditorControl.markAsDirty();
         this.changeInitialState();
      });
   }

   onerror(isInvalid: boolean) {
      setTimeout(() => {
         this.parentFormGroup.get(this.getControlName()).setErrors((isInvalid) ? { 'invalid': isInvalid } : null);
      });
   }

   isModified(): boolean {
      this.coordinateModified = true;
      return this.parentFormGroup.get(this.getControlName()).dirty;
   }

   isModifiedUnitField(): boolean {
      return this.parentFormGroup.get(this.getControlName()).get('0').dirty;
   }

   async createAnnotation() {
      if (!this.propertyField.annotations && !this.readonly) {
         let annotationProperty = await this.propertyService.createAnnotationProperty();

         this.parentFormGroup.addControl(this.propertyField.key + '-annotations', this.pfcs.property2Control(annotationProperty));
         // console.log("parentFormGroup with annotations: " + this.parentFormGroup.getRawValue().toSource());

         this.propertyField.annotations = annotationProperty;
         console.log("propertyField with annotations:" + JSON.stringify(this.propertyField));
      }
      this.showAnnotations = true;
   }

   hideAnnotaion() {
      this.showAnnotations = false;
   }

   //highlight Airac Date
   dateClass = (d: Date) => {

      var diff = moment.duration(this.REFERENCE_AIRAC_DATE.diff(d));
      var diffDays = diff.asDays();

      if ((diffDays % this.AIRAC_CYCLE_DAYS) == 0) {
         return 'airac-date-class';
      }
      else {
         return undefined;
      }
   }

   showMap() {
      if (this.isShowMap) {
         this.isShowMap = false;
      } else {
         this.isShowMap = true;
      }
      // let isModified = this.parentFormGroup.get(this.getControlName()).dirty;
      // console.log(isModified);
      // let dialogRef = this.dialog.open(MapViewDialogComponent, {
      //   data: { aixmDtos: undefined, unId: this.unId, latitude: this.latitude, longitude: this.longitude, featureId: this.featureId, tsId: timesliceId, gmlId: gmlId, isPublished: this.isPublished, isModified: isModified }
      // });

      // dialogRef.afterClosed().subscribe(result => {
      //   console.log('The dialog was closed');
      //   console.log('After the dialog was closed:' + result);
      // });
   }

   displayPointOnMap() {
      this.val = this.parentFormGroup.get(this.getControlName()).get('0').value;
      //this.longitude = this.parentFormGroup.get(this.getControlName()).get('1').value; 
      //let coordinates: number[] = LatlongComponent.getCoordinatesFromLatLonRegex(this.val);

      let coordinates = this.val.trim().replace(/\s+/g, ' ').replace(/[°'"]/g, '').replace(/[ ,]+/g, ' ');
      console.log(coordinates);
      let newCoordinates: number[];
      let regExp = new RegExp("[NSEW]");
      // console.log(this.latLongRegexPattern);
      // if (coordinates.match(this.latLongRegexPattern)) {
      //    console.log("DMS")
      //    let pair: string[] = coordinates.split(' ').reverse();
      //    newCoordinates = pair.map(LatlongComponent.parseDms);
      // }
      // else if (coordinates.match(this.decimalRegex)) {
      //    console.log("Decimal Degree")
      //    if (regExp.exec(coordinates)) {
      //       console.log(coordinates);
      //       let pair: string[] = coordinates.split(' ').reverse();
      //       pair[1] = pair[1].replace(/[SN]/g, '');
      //       pair[0] = pair[0].replace(/[EW]/g, '');
      //       newCoordinates = [+pair[0], +pair[1]];
      //    }
      //    else {
      //       newCoordinates = [+coordinates.split(' ')[1], +coordinates.split(' ')[0]];
      //    }
      // }

      const latLongCompInstance = new LatlongComponent();
      const result = latLongCompInstance.getCoordinatesFromLatLonRegex(this.val.trim())
      newCoordinates = result;

      console.log("New Coordinates : " + newCoordinates)

      if (newCoordinates != undefined) {
         this.latitude = newCoordinates[1];
         this.longitude = newCoordinates[0];
      }

      let dialogRef = this.dialog.open(MapViewDialogComponent, {
         data: { aixmDtos: undefined, unId: this.unId, latitude: this.latitude, longitude: this.longitude, featureId: this.featureId, tsId: timesliceId, gmlId: this.gmlId }
      });

      dialogRef.afterClosed().subscribe(result => {
         console.log('The dialog was closed');
         console.log('After the dialog was closed:' + result);
      });
   }

   setToReferencePropertyValue(feature: AixmFeatureDTO) {
      console.log('Picked ReferencePropertyValue: ', feature);
      if (feature.featureName != null) {
         this.parentFormGroup.get(this.getControlName()).get('0').setValue(feature.featureName);
      }
      else {
         this.parentFormGroup.get(this.getControlName()).get('0').setValue("urn:uuid:" + feature.featureId);
      }
      this.parentFormGroup.get(this.getControlName()).get('2').setValue("urn:uuid:" + feature.featureId);
      this.parentFormGroup.get(this.getControlName()).get('0').markAsDirty();

      this.showFeatureList = true;
   }

   selectReferencedFeature() {
      this.showFeatureList = true;
   }

   goToTargetRefFeature() {
      const parentFeatureId = this.featureId;
      localStorage.setItem('reference', JSON.stringify({ isReferencing: true, fromFeatureId: parentFeatureId }));
   }

   getFeatureTypeById(featureId: string) {
      let featureType: string;
      let referencedFeatures: AixmFeatureDTO[];
      let referencedFeature: AixmFeatureDTO;
      if (!this.showFeatureList && featureId != "" && featureId != undefined) {
         this.featureSelectorService.getFeatureTypeById(featureId.replace("urn:uuid:", ""), this.unId).subscribe(result => {
            featureType = result;
            this.featureSelectorService.getFeaturesByType(featureType, this.unId, true, true).subscribe(data => {
               referencedFeatures = data;
               for (let i = 0; i < referencedFeatures.length; i++) {
                  if (referencedFeatures[i].featureId == featureId.replace("urn:uuid:", "")) {
                     referencedFeature = referencedFeatures[i];
                     this.setToReferencePropertyValue(referencedFeature);
                     this.refFeature = referencedFeature;
                  }
               }
            })
         });
      }
   }

   onStartPointCoordinatesChanged(newCoord: number[]) {
      //this.startPointCoordinates = newCoord; 
      let geom: number[][] = (<LineString>this.segment.getGeometry()).getCoordinates();
      geom[0] = newCoord;
      (<LineString>this.segment.getGeometry()).setCoordinates(geom);
      // update the last coordinates of the previous segment, or of the last segment if we're on the 1st one
      this.startPointCoordinatesChanged.emit(newCoord);
   }

   isSegmentEmpty(isEmpty: boolean) {
      this.isGeoEmpty = isEmpty;
   }

   private readonly geoJsonFeatures = JSON.stringify({
      "type": "FeatureCollection",
      "crs": {
         "type": "name",
         "properties": {
            "name": "EPSG:4326"
         }
      },
      "features": [
         {
            "type": "Feature",
            "properties": {
               "interpolation": "GREAT_CIRCLE",
               "startPoint": {
                  "type": "COORDINATES"
               },
               "endPoint": {
                  "type": "GEO_BORDER_POINT"
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     0,
                     0
                  ],
                  [
                     0,
                     0
                  ]
               ]
            }
         },
         {
            "type": "Feature",
            "properties": {
               "interpolation": "GEO_BORDER",
               "startPoint": {
                  "type": "GEO_BORDER_POINT"
               },
               "endPoint": {
                  "type": "GEO_BORDER_POINT"
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     0,
                     0
                  ],
                  [
                     0,
                     0
                  ]
               ]
            }
         },
         {
            "type": "Feature",
            "properties": {
               "interpolation": "PARALLEL",
               "startPoint": {
                  "type": "GEO_BORDER_POINT"
               },
               "endPoint": {
                  "type": "POINT_REFERENCE",
                  "ref": "urn:uuid:123"
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     0,
                     0
                  ],
                  [
                     0,
                     0
                  ]
               ]
            }
         },
         {
            "type": "Feature",
            "properties": {
               "interpolation": "ARC_BY_CENTRE",
               "radius": "0",
               "radiusUom": "km",
               "startAngle": "0",
               "endAngle": "180",
               "startPoint": {
                  "type": "POINT_REFERENCE",
                  "ref": "urn:uuid:123"
               },
               "endPoint": {
                  "type": "COORDINATES"
               },
               "centre": {
                  "type": "Feature",
                  "properties": {
                     "centrePoint": {
                        "type": "COORDINATES"
                     }
                  },
                  "geometry": {
                     "type": "Point",
                     "coordinates": [
                        0,
                        0
                     ]
                  }
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     0,
                     0
                  ],
                  [
                     0,
                     0
                  ]
               ]
            }
         },
         {
            "type": "Feature",
            "properties": {
               "interpolation": "GREAT_CIRCLE",
               "startPoint": {
                  "type": "COORDINATES"
               },
               "endPoint": {
                  "type": "COORDINATES",
                  "annotation": "This is an annotation"
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     0,
                     0
                  ],
                  [
                     0,
                     0
                  ]
               ]
            }
         }
      ]
   });

   private readonly patchesGeoJson = JSON.stringify({
      "type": "FeatureCollection",
      "crs": {
         "type": "name",
         "properties": {
            "name": "EPSG:4326"
         }
      },
      "features": [
         {
            "type": "Feature",
            "properties": {
               "interpolation": "CIRCLE_BY_CENTER",
               "radius": "0",
               "radiusUom": "km",
               "centre": {
                  "type": "Feature",
                  "properties": {
                     "centrePoint": {
                        "type": "COORDINATES"
                     }
                  },
                  "geometry": {
                     "type": "Point",
                     "coordinates": [
                        0,
                        0
                     ]
                  }
               }
            }
         }
      ]
   })

   private readonly segmentsGeoJson = JSON.stringify({
      "type": "FeatureCollection",
      "crs": {
         "type": "name",
         "properties": {
            "name": "EPSG:4326"
         }
      },
      "features": [
         {
            "type": "Feature",
            "properties": {
               "interpolation": "GREAT_CIRCLE",
               "startPoint": {
                  "type": "COORDINATES"
               },
               "endPoint": {
                  "type": "GEO_BORDER_POINT"
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     0,
                     0
                  ],
                  [
                     0,
                     0
                  ]
               ]
            }
         }
      ]
   })



   private readonly sampleGeoJsonFeatures = {
      "type": "FeatureCollection",
      "crs": {
         "type": "name",
         "properties": {
            "name": "EPSG:4326"
         }
      },
      "features": [
         {
            "type": "Feature",
            "properties": {
               "interpolation": "GREAT_CIRCLE",
               "startPoint": {
                  "type": "COORDINATES"
               },
               "endPoint": {
                  "type": "GEO_BORDER_POINT"
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     0,
                     0
                  ],
                  [
                     10.123456,
                     15.2
                  ]
               ]
            }
         },
         {
            "type": "Feature",
            "properties": {
               "interpolation": "GEO_BORDER",
               "startPoint": {
                  "type": "GEO_BORDER_POINT"
               },
               "endPoint": {
                  "type": "GEO_BORDER_POINT"
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     10.123456,
                     15.2
                  ],
                  [
                     20,
                     20
                  ]
               ]
            }
         },
         {
            "type": "Feature",
            "properties": {
               "interpolation": "PARALLEL",
               "startPoint": {
                  "type": "GEO_BORDER_POINT"
               },
               "endPoint": {
                  "type": "POINT_REFERENCE",
                  "ref": "urn:uuid:123"
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     20,
                     20
                  ],
                  [
                     20,
                     30
                  ]
               ]
            }
         },
         {
            "type": "Feature",
            "properties": {
               "interpolation": "ARC_BY_CENTRE",
               "radius": "111",
               "radiusUom": "km",
               "startAngle": "0",
               "endAngle": "180",
               "startPoint": {
                  "type": "POINT_REFERENCE",
                  "ref": "urn:uuid:123"
               },
               "endPoint": {
                  "type": "COORDINATES"
               },
               "centre": {
                  "type": "Feature",
                  "properties": {
                     "centrePoint": {
                        "type": "COORDINATES"
                     }
                  },
                  "geometry": {
                     "type": "Point",
                     "coordinates": [
                        10,
                        30
                     ]
                  }
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     20,
                     30
                  ],
                  [
                     0,
                     30
                  ]
               ]
            }
         },
         {
            "type": "Feature",
            "properties": {
               "interpolation": "GREAT_CIRCLE",
               "startPoint": {
                  "type": "COORDINATES"
               },
               "endPoint": {
                  "type": "COORDINATES",
                  "annotation": "This is an annotation"
               }
            },
            "geometry": {
               "type": "LineString",
               "coordinates": [
                  [
                     0,
                     30
                  ],
                  [
                     0,
                     0
                  ]
               ]
            }
         }
      ]
   };
}
