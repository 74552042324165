<mat-toolbar>
    <span style="line-height: 0;">
        <h3 style="align-items: center; display: flex">FAIVA:
            <img src="assets/images/FABEC-logo.png" height="38" style="margin: 0 10px 0 10px" /> Aeronautical
            Information
            Validator
        </h3>
        <small class="mat-caption">Validation statistics</small>
    </span>
</mat-toolbar>

<section class="row card-overview">
    <ng-container *ngTemplateOutlet="cardOverviewTpl; context: { $implicit: totalTplContext }"></ng-container>
    <ng-container *ngTemplateOutlet="cardOverviewTpl; context: { $implicit: successTplContent }"></ng-container>
    <ng-container *ngTemplateOutlet="cardOverviewTpl; context: { $implicit: usersTplContext }"></ng-container>
</section>
<section class="row stats-chart">
    <mat-card class="mat-elevation-z0" appearance="outlined">
        <mat-card-header>
            <mat-card-title class="mat-subtitle-5">Monthly Validations</mat-card-title>
            <mat-card-subtitle>
                Number of validations and successful validations per month
            </mat-card-subtitle>
            <!-- <div class="year-filter" *ngIf="selectedStatsType === statsType.FULL_YEAR"> -->
            <div class="year-filter">
                <mat-form-field [formGroup]="validationsPeriodForm">
                    <mat-label>Report period</mat-label>
                    <mat-select placeholder="Select period" formControlName="range" [compareWith]="compareObjects">
                        <!-- (selectionChange)="onYearChange($event)" -->
                        <ng-container *ngFor="let option of yearRangeOptions">
                            <mat-option [value]="option">{{ option.title }}</mat-option>
                        </ng-container>
                        <ng-container *ngFor="let group of yearRangeOptGroups">
                            <mat-optgroup [label]="group.title" class="opt-group-title">
                                <ng-container *ngFor="let opt of group.options">
                                    <mat-option [value]="opt">{{ opt.title }}</mat-option>
                                </ng-container>
                            </mat-optgroup>

                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card-header>
        <mat-card-content>
            <canvas style="width: 0; height: auto;" baseChart [data]="barChartData" [options]="barChartOptions"
                [type]="barChartType">
            </canvas>
        </mat-card-content>
    </mat-card>
</section>

<ng-template #cardOverviewTpl let-data>
    <mat-card class="mat-elevation-z0" appearance="outlined">
        <mat-card-header>
            <mat-card-title class="mat-subtitle-2"> {{data?.title}} </mat-card-title>
            <mat-card-subtitle>
                <mat-icon>
                    <span class="material-symbols-outlined">
                        {{ data?.icon }}
                    </span>
                </mat-icon>
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="main mat-headline-5" [ngClass]="data?.id"> {{ data?.value || '-' }} </div>
            <small class="sub"> {{ data?.sub || '-' }} </small>
        </mat-card-content>
    </mat-card>
</ng-template>