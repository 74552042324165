import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UserManageMentService } from '../user-management/service/user-management.service'
import { UserManagementRoutingModule } from './user-management-routing.module';
import { AppMaterialModule } from '../app.material.module';
import { CreateUsersComponent } from './create-users/create-users.component';
import { UserManagementListComponent } from './user-management-list/user-management-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { CreateGroupsComponent } from './create-groups/create-groups.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { GroupDetailComponent } from './group-detail/group-detail.component';
import { GroupListComponent } from './group-list/group-list.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';


@NgModule({
    imports: [
        CommonModule,
        UserManagementRoutingModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        UserManagementListComponent,
        CreateUsersComponent,
        UserDetailComponent,
        CreateGroupsComponent,
        GroupDetailComponent,
        SuccessDialogComponent,
        GroupListComponent,
        DeleteDialogComponent
    ],
    providers: [
        UserManageMentService
    ],
    exports: [
        CreateUsersComponent
    ]
})
export class UserManagementModule { }