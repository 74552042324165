import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FileLikeObject } from "ng2-file-upload";
import { Observable, of, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ConfigService } from "../../utils/config.service";
import { ErrorsHandler } from "../../errors/errors-handler/errors-handler";
import { ErrorsService } from "../../errors/errors-service/errors.service";
import {
  ValidationStatisticActiveUsers,
  ValidationStatisticsLastTwelveMonths,
  ValidationStatisticsOverview,
} from "../validation-stats/validation-stats.interface";

const httpOptions = {
  headers: new HttpHeaders(),
};

@Injectable({
  providedIn: "root",
})
export class AixmValidatorService {
  private apiServer = ConfigService.settings.validatorApiServer;
  private uploadAixmFileUrl = this.apiServer.url + "/aixm/upload";
  private validationOptionUrl = this.apiServer.url + "/validation-options";
  private schemaProfileUrl = this.apiServer.url + "/validation-options/schema";
  private bizRuleUrl = this.apiServer.url + "/validation-options/bizrule";
  private validateUrl = this.apiServer.url + "/aixm/validate";
  private statusUrl = this.apiServer.url + "/status";
  private validationHistoryUrl = this.apiServer.url + "/aixm/validation-history";
  private validationReportUrl = this.apiServer.url + "/aixm/validation-report";
  private getCertificateUrl = this.apiServer.url + "/aixm/certificate";
  private statsOverviewURL = this.apiServer.url + "/validator/validation-statistic/overview";
  private statsActiveUsersURL = this.apiServer.url + "/validator/validation-statistic/active-users";
  private statsMonthlyURL = this.apiServer.url + "/validator/validation-statistic/monthly";

  constructor(private http: HttpClient, private errorService: ErrorsService) {}

  getValidationHistory(): Observable<any[]> {
    //return this.http.get<string[]>(this.validateionHistoryUrl)
    let requestOptions: Object = {
      responseType: "json",
    };

    const url = `${this.validationHistoryUrl}`;
    console.log("Url : " + url);
    return this.http.get<any>(url, requestOptions).pipe(
      tap(
        (data) => console.log(data),
        (error) => new ErrorsHandler(error)
      )
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  uploadAixmDocument(file: FileLikeObject): Observable<any> {
    let requestOptions: Object = {
      responseType: "text",
    };
    var formData = new FormData();
    formData.append("file", file.rawFile as Blob, file.name);

    const url = `${this.uploadAixmFileUrl}`;
    console.log("Url : " + url);
    return this.http.post<FormData>(url, formData, requestOptions).pipe(
      tap(
        (formData) => console.log(formData),
        (error) => new ErrorsHandler(error)
      )
    );
  }

  getValidationOptionsSchema(versionCode: string): Observable<any> {
    let requestOptions: Object = {
      responseType: "application/json",
    };

    const url = `${this.schemaProfileUrl}/${versionCode}`;
    console.log("Url : " + url);
    return this.http.get<any>(url, requestOptions).pipe(
      tap(
        (data) => console.log(data),
        (error) => new ErrorsHandler(error)
      )
    );
  }

  getValidationOptionsBizRule(versionCode: string): Observable<any> {
    let requestOptions: Object = {
      responseType: "application/json",
    };

    const url = `${this.bizRuleUrl}/${versionCode}`;
    console.log("Url : " + url);
    return this.http.get<any>(url, requestOptions).pipe(
      tap(
        (data) => console.log(data),
        (error) => new ErrorsHandler(error)
      )
    );
  }

  validate(validateInfo: any): Observable<any> {
    let requestOptions: Object = {
      responseType: "json",
    };

    const url = `${this.validateUrl}`;

    return this.http.post<any>(url, validateInfo, requestOptions).pipe(
      tap(
        (data) => console.log(data),
        (error) => new ErrorsHandler(error)
      )
    );
  }

  getStatus(taskId: string) {
    let requestOptions: Object = {
      responseType: "json",
    };

    const url = `${this.statusUrl}/${taskId}`;
    console.log("Url : " + url);
    return this.http.get<any>(url, requestOptions).pipe(
      tap(
        (data) => console.log(data),
        (error) => new ErrorsHandler(error)
      )
    );
  }

  getValidationReportFromHistory(reportFileName: string): Observable<any> {
    let requestOptions: Object = {
      responseType: "json",
    };

    const url = `${this.validationReportUrl}/${reportFileName}`;
    console.log("Url : " + url);
    return this.http.get<any>(url, requestOptions).pipe(
      tap(
        (data) => console.log(data),
        (error) => new ErrorsHandler(error)
      )
    );
  }

  getCertificate(certificateFileName: String): Observable<ArrayBuffer> {
    let requestOptions: Object = {
      headers: new HttpHeaders({ Accept: "application/pdf" }),
      responseType: "blob",
    };

    const url = `${this.getCertificateUrl}/${certificateFileName}`;
    console.log("Url : " + url);

    return this.http.get<ArrayBuffer>(url, requestOptions).pipe(
      tap(
        (data) => console.log(data),
        (error) => new ErrorsHandler(error)
      )
    );
  }

  getValidationsStatsOverview(): Observable<ValidationStatisticsOverview> {
    return this.http
      .get<ValidationStatisticsOverview>(this.statsOverviewURL)
      .pipe(catchError((error) => throwError(error)));
  }

  getValidationsStatsActiveUsers(startDate: string, endDate: string): Observable<ValidationStatisticActiveUsers> {
    return this.http
      .get<ValidationStatisticActiveUsers>(this.statsActiveUsersURL, {
        params: new HttpParams({ fromObject: { startDate, endDate } }),
      })
      .pipe(catchError((error) => throwError(error)));
  }

  getValidationsStatsMonthly(startDate: string, endDate: string): Observable<ValidationStatisticsLastTwelveMonths> {
    return this.http
      .get<ValidationStatisticsLastTwelveMonths>(this.statsMonthlyURL, {
        params: new HttpParams({ fromObject: { startDate, endDate } }),
      })
      .pipe(catchError((error) => throwError(error)));
  }
}
