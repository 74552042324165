<mat-form-field class="large" appearance="legacy">
  <mat-label>POINT_REFERENCE</mat-label>
  <input matInput disabled type="text" value={{point?.title}} #gml_pos [style.background-color]="color">
  <button type="button" mat-icon-button matPrefix matTooltip="This is a description">
    <mat-icon color="primary" aria-label="Description">info</mat-icon>
  </button>
  <!-- No delete button because gml:pos is not nillable: the point itself must be deleted.
      But gml:pos may be empty.
    -->
  <button type="button" mat-icon-button matSuffix matTooltip="Select a feature" (click)="showFeaturesSelector()">
    <mat-icon color="primary" aria-label="Select a feature">insert_link</mat-icon>
  </button>
</mat-form-field>

<button type="button" mat-icon-button matTooltip="Annotations">
  <mat-icon color="primary" aria-label="Annotations" matBadgeColor="accent" matBadgePosition="below"
    matBadgeSize="small">note</mat-icon>
</button>

<div *ngIf="showFeatureList" class="margin-left">
  <app-feature-type-search-component [isReference]="true" referenceType="point"
    (referencePropertyValuePicked)="setToReferencePropertyValue($event)" (geoDataPicked)="setCoordinates($event)">
  </app-feature-type-search-component>
</div>