import { InfoManagementService } from './../service/info-management.service';
import { Component, OnInit, NgModule } from '@angular/core';
import { FeatureGroup } from '../model/feature-group';

@Component({
  selector: 'im-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {
    
    selectedGroups: string[];

    grouplist: FeatureGroup[] = [];

  constructor(private infoManagementService: InfoManagementService) { }

  ngOnInit() {
    this.getTypes();
  }

  getTypes(): void {
    // this.infoManagementService.getTypes()
    //   .subscribe(types => this.grouplist = types);
    // console.log("The type is "+ this.grouplist);
  }

  
  // See https://stackoverflow.com/questions/47349528/binding-an-angular-material-selection-list
  
  onNgModelChange(event){
      console.log('on ng model change', event);
  }
}
