import { UnclaimdialogComponent } from './../unclaimdialog/unclaimdialog.component';
import { ClaimdialogComponent } from './../claimdialog/claimdialog.component';
import { Component, OnInit, Input } from '@angular/core';
import { Item } from '../model/item';
import { ActivatedRoute } from '@angular/router';
import { QualityProjectService } from './../service/quality-project.service';
import { LogDialogComponentComponent } from '../log-dialog-component/log-dialog-component.component';
import {  MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.css']
})
export class DoneComponent implements OnInit {
  isQualityController: boolean = this.qualityProjectService.isQualityController;

  items: Item[];

  projectId: string;

  constructor(
    private qualityProjectService: QualityProjectService, 
    private route: ActivatedRoute, 
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params.id;
      console.log("The id is " + params.id)
    });
  }

  @Input()
  set collectItems(items: Item[]) {
    if (items != undefined) {
      this.items = items.filter(item => item.currentActivity == 'Done');
    }
  }

  openClaimDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(ClaimdialogComponent, {
      width: '500px',
      data: { name: name, id: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('After the dialog was closed:' + result);
      //this.result = result,
      this.ngOnInit();
    });
  }

  openUnclaimDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(UnclaimdialogComponent, {
      width: '500px',
      data: { name: name, id: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('After the dialog was closed:' + result);
      //this.result = result,
      this.ngOnInit();
    });
  }

  getLogOfAssignment(executionId: string) {
    this.dialog.open(LogDialogComponentComponent, {
      width: '600px',
      data: executionId
    });
  }
}
