<mat-card>
  <mat-card-content>
    <form name="newGroupForm" [formGroup]="form" (ngSubmit)="createNewGroup(form.value)">
      <div class="space">
        User-Management/<span><a [routerLink]="['/user-management']">Groups</a></span>
      </div>
      <div class="row">
        <h2>New Group</h2>
      </div>
      <div class="justify">
        <mat-form-field>
          <input matInput placeholder="Group Name" name="groupName" formControlName="groupName" required>
        </mat-form-field>
      </div>
      <mat-card-actions class="justify">
        <button mat-raised-button mat-button color="primary" class="example-btn-width"
          [disabled]="!form.valid || form.pristine">Create User</button>
        <button mat-raised-button mat-button color="primary" class="example-btn-width">Cancel</button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>