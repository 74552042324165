import { GmlPointType } from './../model/GmlPointType';
import { GmlInterpolation } from './../model/GmlInterpolation';
import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { DMSFormat } from "../model/DMSFormat";
import DmsCoordinates from "dms-conversion";
import { UntypedFormControl, Validators } from '@angular/forms';
import { Feature } from 'ol';
import parseDMS from '../../utils/parse-dms'

@Component({
  selector: "latlong",
  templateUrl: "./latlong.component.html",
  styleUrls: ["./latlong.component.css"],
})
export class LatlongComponent implements OnInit {
  @Output() coordinatesChange = new EventEmitter<number[]>();
  @Output() onError = new EventEmitter<boolean>();
  @Input() coordinates: number[];
  @Input() title?: string;
  @Input() editable: boolean = true;
  @Input() isHighlight: boolean;
  @Input() isInvalid: boolean;
  @Input() isSurface: boolean;
  @Input() isFirstSegment: boolean;
  @Input() isGeoBorderPoint: boolean;

  @Input() segment: Feature;
  @Input() previousPoint: Feature;

  isGeoBorder: boolean = false;
  displayCoordinates = new UntypedFormControl({ value: "" }, Validators.required);
  isDMS = true;
  latLongRegexPattern: RegExp = /^\d+°?\d+'?\d+\.?\d+"?[NS]\s\d+°?\d+'?\d+\.?\d+"?[EW]$/;
  dmsRe = /([NSEW])?\s?(-)?(\d+(?:\.\d+)?)[°º:d\s]?\s?(?:(\d+(?:\.\d+)?)['’‘′:]?\s?(?:(\d{1,2}(?:\.\d+)?)(?:"|″|’’|'')?)?)?\s?([NSEW])?/i;
  ///^(\d\d\d\d\d\d)(\.\d+)?[NS]\s(\d\d\d\d\d\d\d)(\.\d+)?[EW]$/g;
  //latLongRegexPattern = /^(-?\d+(\.\d+)?)[NS],?\s+?(-?\d+(\.\d+)?)[EW]$/;
  //latLongRegexPattern = /^(\d?\d\d)[\s°]*(\d\d)[\s'′]*(\d\d(?:\.\d+)?)[\s"″]*([NSEW])$/;
  //static decimalRegex = /^(-?\d\d?(\.\d+)?)[NS]?,?\s(-?\d\d?\d?(\.\d\d\d\d+)?)[EW]?$/;
  //static latLongRegexPattern = /^(-?\d\d\°?\d\d\'?\d\d(\.\d+)?)\"?[NS],?\s+?(-?\d\d\d\°?\d\d\'?\d\d(\.\d+)?)\"?[EW]$/;
  decimalRegex: RegExp = /^(-?\d\d?(\.\d+)?),?\s+?(-?\d\d?\d?(\.\d+)?)$/g;

  coordinate: any;
  public color: string;
  constructor() {}

  ngOnInit() {
    this.displayCoordinates.setValue(
      this.getCoordinates(this.coordinates, DMSFormat.ICAO)
    );
    if (this.isGeoBorderPoint == undefined) {
      let newCoordinates;
      this.displayCoordinates.valueChanges.subscribe((val) => {
        newCoordinates = this.getCoordinatesFromLatLonRegex(val);
        if (newCoordinates != undefined) {
          this.coordinatesChange.emit(newCoordinates);
        }
      });
    }

    if (this.isHighlight) {
      this.color = "yellow";
    }

    if (!this.editable) {
      this.displayCoordinates.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.coordinates) {
      // Convert to DMS for display
      this.displayCoordinates.setValue(
        this.getCoordinates(this.coordinates, DMSFormat.ICAO)
      );
      console.log("coor in ngOnChanges: " + this.displayCoordinates.value);
      if (this.isGeoBorderPoint && this.displayCoordinates.value.length != 0) {
        this.coordinatesChange.emit(this.coordinates);
      }
    }
    let checkFirstPoint = this.isFirstSegment && !this.isSurface ? false : true;
    if (
      checkFirstPoint &&
      this.previousPoint != undefined &&
      this.previousPoint.get("interpolation") === GmlInterpolation.GEO_BORDER &&
      changes.previousPoint &&
      this.previousPoint.get("startPoint").ref
    ) {
      this.isGeoBorder = true;
      this.segment.set("startPoint", { type: GmlPointType.GEO_BORDER_POINT });
    }
  }

  onDisplayCoordinatesChanged(val: any) {
    if (this.isHighlight) {
      this.color = "yellow";
    }
    // convert back to decimal and flip lat/long
    let pair: string[] = val.split(" ").reverse();
    this.coordinates = pair.map(LatlongComponent.parseDms);
    //this.coordinates[1].toFixed(5);
    console.log(
      "onDisplayCoordinatesChanged: " + val + " => " + this.coordinates
    );
    this.coordinatesChange.emit(this.coordinates);
  }

  isEmpty() {
    return this.coordinates[0] === undefined;
  }

  isModified(): boolean {
    console.log(this.displayCoordinates.dirty);
    return true;
  }

  onStartPointCoordinatesChanged(newCoord: number[]) {
    this.coordinatesChange.emit(newCoord);
  }

  onerror(isInvalid: boolean) {
    this.onError.emit(isInvalid);
  }

  // unit: 'degree', unitDisplay: 'narrow' are not supported in the version of TS we're currently using:
  // https://github.com/microsoft/TypeScript/issues/38012
  //static readonly DMS_FORMAT_WITH_UNITS = new Intl.NumberFormat('en-US', {style: 'unit', unit: 'degree', unitDisplay: 'narrow'});
  static readonly SEC_FORMAT = new Intl.NumberFormat("en", {
    minimumIntegerDigits: 2,
    maximumFractionDigits: 3,
  });

  /**
   * Change coordinates format
   * @param coordinates
   * @param format
   */
  getCoordinates(coordinates: number[], format: DMSFormat): string {
    let srcLat = coordinates[1];
    let srcLon = coordinates[0];

    typeof srcLat === "string" ? (srcLat = +srcLat) : (srcLat = srcLat);
    typeof srcLon === "string" ? (srcLon = +srcLon) : (srcLon = srcLon);

    if (
      srcLat == undefined ||
      srcLon == undefined ||
      isNaN(srcLat) ||
      isNaN(srcLon)
    ) {
      return "";
    }
    //return Point.deg2dms(this.latitude, this.longitude);
    // with OpenLayers: return toStringHDMS( [this.latitude, this.longitude], 5 );
    const dmsCoord = new DmsCoordinates(srcLat, srcLon);
    const { longitude, latitude } = dmsCoord.dmsArrays;
    let [dLat, mLat, sLat, hLat] = latitude;
    let [dLon, mLon, sLon, hLon] = longitude;
    let [sdLat, smLat, smLon] = [dLat, mLat, mLon].map((s) =>
      s.toString().padStart(2, "0")
    );
    let sdLon = dLon.toString().padStart(3, "0");
    let [ssLat, ssLon] = [sLat, sLon].map((s) =>
      LatlongComponent.SEC_FORMAT.format(s)
    );
    switch (format) {
      case DMSFormat.WITH_UNITS:
        return `${sdLat}°${smLat}'${ssLat}${hLat} ${sdLon}°${smLon}'${ssLon}${hLon}`;
      case DMSFormat.ICAO:
      default:
        // no units
        return `${sdLat}${smLat}${ssLat}${hLat} ${sdLon}${smLon}${ssLon}${hLon}`;
    }
  }

  /**
   * Our own pattern because the one from dms.js doesn't support ICAO format.
   * We want to match DMS, with or without DMS symbols:
   * DMS
   *   012345N always 6 digits before decimal point for latitudes
   *   123456N
   *   123456.1234N
   *   0012345E always 7 digits before decimal point for longitudes
   *   0123456E
   *   12345678E
   *   1°20'30S leading zeroes can be omitted if symbols are given
   *   1°20'30.1234S
   *   100°20'30E
   *   100°01'02.3E
   *   100°20'30.123E
   * DM without seconds: we don't support these yet
   *   0123N
   *   00123E
   *
   * Pattern:
   * - NSEW is mandatory and at the end
   * - minus sign is not allowed
   * - there may be any number of decimals of seconds
   * - degree, minutes and seconds must be given if 0
   * - if not using symbols, leading zeroes are mandatory
   * - no spaces
   */
  // TODO this is to support ICAO format only, without symbols
  static readonly dmsRegExp = new RegExp(
    "^(\\d?\\d\\d)(\\d\\d)(\\d\\d(?:.\\d+)?)([NSEW])$"
  );

  // Regex pattern and parseDms() copied from dms.js because I don't manage to import it: it works in TypeScript but parseDms is undefined in JS.
  //static readonly dmsReg = new RegExp('^(-?\d+(?:\.\d+)?)[°:d]?\s?(?:(\d+(?:\.\d+)?)["′":]?\s?(?:(\d+(?:\.\d+)?)["″ʺ]?)?)?\s?([NSEW])$');

  /**
   * Parses a Degrees Minutes Seconds string into a Decimal Degrees number.
   * @param {string} dmsStr A string containing a coordinate in either DMS or DD format.
   * @return {Number} If dmsStr is a valid coordinate string, the value in decimal degrees will be returned. Otherwise NaN will be returned.
   */
  static parseDms(dmsStr: string): number {
    let output = NaN;
    let dmsMatch = LatlongComponent.dmsRegExp.exec(dmsStr);
    if (dmsMatch) {
      const degrees = Number(dmsMatch[1]);
      const minutes =
        typeof dmsMatch[2] !== "undefined" ? Number(dmsMatch[2]) / 60 : 0;
      const seconds =
        typeof dmsMatch[3] !== "undefined" ? Number(dmsMatch[3]) / 3600 : 0;
      const hemisphere = dmsMatch[4] || null;
      if (hemisphere !== null && /[SW]/i.test(hemisphere)) {
        output = -Math.abs(degrees) - minutes - seconds;
      } else {
        output = degrees + minutes + seconds;
      }
    }
    return output;
  }

  getCoordinatesFromLatLonRegex(coordinates: string) {
    coordinates = coordinates
      .trim()
      .replace(/\s+/g, " ")
      .replace(/[°'"’”]/g, " ")
      .replace(/[\s,]+/g, " ");

    let newCoordinates: number[];
    let regExp = new RegExp("[NSEW]");
    /* if(regExp.exec(coordinates)){
      coordinates = coordinates.replace(/\s/g, '').replace('S','S ').replace('N','N ');  
    } */

    const testDmsRe = new RegExp(this.dmsRe).test(coordinates);
    const decimalRe = new RegExp(this.decimalRegex).test(coordinates);

	if (testDmsRe && !decimalRe && coordinates.split(' ').length > 2) {
      const parseValue = coordinates
        .trim()
        .replace(/[’‘′]/g, "'")
        .replace(/[”″]/g, '"')
        .replace(/([NSEW])-/g, "$1 - ") ;
      const getLatLon = parseDMS(parseValue);
      const resultToEntries = Object.entries(getLatLon)
        .map(([_, value]) => value)
        .reverse() as number[];
      console.log({ parseValue, getLatLon, resultToEntries });
      newCoordinates = resultToEntries;
      return newCoordinates;
    }

    coordinates =
      coordinates.split(" ").length === 2 && !decimalRe
        ? (coordinates
            .split(" ")
            .map(LatlongComponent.parseDms)
            .join(" ") as unknown as string)
        : coordinates;
    
    if (coordinates.match(this.decimalRegex)) {
      console.log("Decimal Degree");
      if (regExp.exec(coordinates)) {
        let pair: string[] = coordinates.split(" ").reverse();
        pair[1] = pair[1].replace(/[SN]/g, "");
        pair[0] = pair[0].replace(/[EW]/g, "");
        newCoordinates = [+pair[0], +pair[1]];
      } else {
        newCoordinates = [
          +coordinates.split(" ")[1],
          +coordinates.split(" ")[0],
        ];
      }
    }
    return newCoordinates;
  }
}
