import { ActivatedRoute, Router } from '@angular/router';
import { InfoManagementService } from './../service/info-management.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Tile from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Icon, Style } from 'ol/style';
import { ConfigService } from '../../utils/config.service';
import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { UserDetailsService } from '../../login/services/UserDetails.service';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.css'],
  encapsulation: ViewEncapsulation.None,
  /* providers: [{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }] */
})
export class LocationSearchComponent implements OnInit {
  static featureId: string;
  static unId: string;
  static unName: string;
  static router: Router;

  today: Date = new Date();
  dueDate: any;
  noticeDay = environment.noticeNumber;

  latitude: number;
  longitude: number;
  map: Map;
  marker: Feature;
  pointColor: string;
  url: string;
  workspaceName: string;
  layerName: string;
  officialLayerName: string;
  officialGeoDataSource: any;
  officialGeoDataLayer: any;
  layersToRemove = [];

  vectorLayer = new VectorLayer({
    source: new VectorSource
  })
  vectorSource = this.vectorLayer.getSource();

  view: View = new View({
    center: fromLonLat([2, 47]),
    zoom: 5
  })

  constructor(private infoManagementService: InfoManagementService, private route: ActivatedRoute, private router: Router, public auth: UserDetailsService) {
    LocationSearchComponent.router = router;
    LocationSearchComponent.unName = this.route.snapshot.params['unName'];
    LocationSearchComponent.unId = this.route.snapshot.params['unId'];
    this.url = ConfigService.settings.geoServer.url;
    this.workspaceName = ConfigService.settings.geoServer.unWorkspaceName;
    this.layerName = ConfigService.settings.geoServer.layerName;
    this.officialLayerName = ConfigService.settings.geoServer.officialLayerName;
  }

  ngOnInit() {
    this.map = new Map({
      layers: [
        new Tile({
          source: new OSM({
            attributions: 'Background: &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
            maxZoom: 18
          })
        }), this.vectorLayer
      ],
      target: 'map',
      view: this.view
    });

    const that = this;
    this.map.on('singleclick', function (evt) {
      const pixel = evt.pixel;
      that.map.forEachFeatureAtPixel(pixel, function (feature, layer) {
        if (that.pointColor == "blue") {
          if (LocationSearchComponent.unId == undefined) { LocationSearchComponent.unId = "null"; }
          if (LocationSearchComponent.unName == undefined) { LocationSearchComponent.unName = ""; }
          LocationSearchComponent.router.navigate(['info-management', LocationSearchComponent.featureId, LocationSearchComponent.unId, LocationSearchComponent.unName]);
        }
        return feature;
      })
    })

    this.map.on("pointermove", function (evt) {
      var hit = this.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
        return true;
      });
      if (hit) {
        this.getTargetElement().style.cursor = 'pointer';
      } else {
        this.getTargetElement().style.cursor = '';
      }
    });

  }

  REFERENCE_AIRAC_DATE = moment("2018-11-08");
  AIRAC_CYCLE_DAYS = 28;

  //highlight Airac Date
  dateClass = (d: Date) => {

    var diff = moment.duration(this.REFERENCE_AIRAC_DATE.diff(d));
    var diffDays = diff.asDays();

    if ((diffDays % this.AIRAC_CYCLE_DAYS) == 0) {
      return 'airac-date-class';
    }
    else {
      return undefined;
    }
  }

  pointSearch(coordinate: string) {
    this.latitude = Number(coordinate.split(" ")[0]);
    this.longitude = Number(coordinate.split(" ")[1]);

    this.marker = new Feature({
      geometry: new Point(fromLonLat([this.longitude, this.latitude])),
    });

    this.infoManagementService.searchFeature(coordinate).subscribe(docs => {
      if (docs.length > 0) {
        LocationSearchComponent.featureId = docs[0].id;
        this.pointColor = "blue";
        this.marker.setStyle(new Style({
          image: new Icon({
            crossOrigin: 'anonymous',
            src: 'assets/images/mapicon.png',
            size: [50, 50]
          })
        })
        )
      } else {
        this.marker.setStyle(new Style({
          image: new Icon({
            crossOrigin: 'anonymous',
            src: 'assets/images/mapredicon.png',
            size: [50, 50]
          })
        })
        )
      }
    });
    this.vectorSource.addFeature(this.marker);
    let view = this.map.getView();
    view.setCenter(fromLonLat([this.longitude, this.latitude]));
    view.setZoom(8);
  }

  displayOfficialData(effectiveDate: string) {
    const that = this;
    let feId: string[] = [];
    let tsId: string[] = [];
    let query: string[] = [];
    let formatDate = JSON.stringify(moment(effectiveDate).utc());
    formatDate = formatDate.replace('"', '');
    formatDate = formatDate.replace('.000Z"', 'Z');
    console.log(this.layersToRemove);
    if (this.layersToRemove.length > 0) {
      let len = this.layersToRemove.length;
      for (var i = 0; i < len; i++) {
        that.map.removeLayer(this.layersToRemove[i]);
      }
      this.layersToRemove = [];
    }
    console.log(this.layersToRemove);

    this.infoManagementService.getFeatureIdAndTimesliceId(formatDate).subscribe(result => {
      console.log(result);
      let filterStr;
      for (let i = 0; i < result.length; i++) {
        console.log(result.length)
        feId[i] = result[i].split("_")[0];
        tsId[i] = result[i].split("_")[1];
        if (this.auth.getTenantName() == undefined || this.auth.getTenantName() == "") {
          filterStr = "effectivedate<='" + formatDate + "' and featureid='" + feId[i] + "' and tsid='" + tsId[i] + "' and tenantname='" + "" + "'";
        }
        else {
          filterStr = "effectivedate<='" + formatDate + "' and featureid='" + feId[i] + "' and tsid='" + tsId[i] + "' and tenantname='" + this.auth.getTenantName() + "'";
        }

        query[i] = filterStr;
        console.log(query[i]);
        this.officialGeoDataSource = new TileWMS({
          url: this.url + this.workspaceName + '/wms/',
          params: {
            layers: this.workspaceName + ":" + this.officialLayerName,
            tiled: true,
            CQL_FILTER: query[i]
          },
          serverType: 'geoserver',
        });

        this.officialGeoDataLayer = new TileLayer({
          source: this.officialGeoDataSource,
        });
        this.layersToRemove.push(this.officialGeoDataLayer);
        that.map.addLayer(this.officialGeoDataLayer);
      }
    }
    );
  }
  getDueDate(effectiveDate): void {
    this.dueDate = moment(effectiveDate.value);
    this.dueDate.subtract(this.noticeDay, "days");
    this.dueDate = this.dueDate.format('DD MMM YYYY').toUpperCase();
  }
}
