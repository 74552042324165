import { QaProjectListComponent } from './qa-project-list/qa-project-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { QualityRoutingModule } from './quality-routing.module';

import { QualityProjectService } from './service/quality-project.service';
import { NewQualityProjectComponent } from './new-quality-project/new-quality-project.component';
import { TaskListComponent } from './task-list/task-list.component';
import { DialogComponent } from './complete-dialog/dialog.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { DataInputComponent } from './data-input/data-input.component';
import { TextEditionComponent } from './text-edition/text-edition.component';
import { ReviewComponent } from './review/review.component';
import { DoneComponent } from './done/done.component';
import { ClaimdialogComponent } from './claimdialog/claimdialog.component';
import { UnclaimdialogComponent } from './unclaimdialog/unclaimdialog.component';
import { CompleteReviewDialogComponent } from './complete-review-dialog/complete-review-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { LogDialogComponentComponent } from './log-dialog-component/log-dialog-component.component';
import { AddNewItemDialogComponent } from './add-new-item-dialog/add-new-item-dialog.component';
import { AppMaterialModule } from '../app.material.module';


@NgModule({
    imports: [
        CommonModule,
        QualityRoutingModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        NewQualityProjectComponent,
        TaskListComponent,
        DialogComponent,
        ProjectDetailComponent,
        DataInputComponent,
        TextEditionComponent,
        ReviewComponent,
        DoneComponent,
        ClaimdialogComponent,
        UnclaimdialogComponent,
        CompleteReviewDialogComponent,
        SuccessDialogComponent,
        QaProjectListComponent,
        LogDialogComponentComponent,
        AddNewItemDialogComponent
    ],
    providers: [
        QualityProjectService,
    ],
    exports: [
        NewQualityProjectComponent,
        TaskListComponent,
        DataInputComponent,
        TextEditionComponent,
        ReviewComponent,
        DoneComponent
    ]
})
export class QualityModule { }
