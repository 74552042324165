import { GroupSummaryDTO } from './groupSummaryDTO';
export class EditUserDTO {
    id?: string;
    userName?: string;
    firstName?: string;
    lastName?: string;
    email?:string
    enabled?: boolean;
    availableRole?: string[];
    assignedRole?: string[];
    availableGroup?: GroupSummaryDTO[];
    joinedGroup?: GroupSummaryDTO[];

    constructor(options: {
        id?: string,
        userName?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        enabled?: boolean,
        availableRole?: string[],
        assignedRole?: string[],
        availableGroup?: GroupSummaryDTO[],
        joinedGroup?: GroupSummaryDTO[]
    } = {}) {
        this.id = options.id || '';
        this.userName = options.userName;
        this.firstName = options.firstName;
        this.lastName = options.lastName;
        this.email = options.email;
        this.enabled = options.enabled;
        this.availableRole = options.availableRole;
        this.assignedRole = options.assignedRole;
        this.availableGroup = options.availableGroup;
        this.joinedGroup = options.joinedGroup;
    }
}
