import {
  Component,
  OnInit,
  Inject
} from '@angular/core';
import {
  FormControl,
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder
} from '@angular/forms';
import {  MatDialog,  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { Profile } from '../model/profile.model';
import { DataprofileService } from '../service/dataprofile.service';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
  selector: 'app-new-dataprofile',
  templateUrl: './new-dataprofile.component.html',
  styleUrls: ['./new-dataprofile.component.css'],
  providers: [DataprofileService]
})
export class NewDataprofileComponent implements OnInit{
  public uploader: FileUploader = new FileUploader({url: ''});
  private profile: Profile = new Profile();
  schmeaFile: File;
  result: string;
  errorMessage: string;
  form: UntypedFormGroup;
  attachedFiles: FileLikeObject[];
    
  constructor(private dataProfileService: DataprofileService,private snackBar: MatSnackBar,private dialog:MatDialog,private router:Router,private fb:UntypedFormBuilder ) {
    this.form = fb.group({
      name:['',Validators.required],
      description: ['', Validators.required]
    })
  }

  ngOnInit(){
    
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }

  remove(item: any) {
    var index = this.uploader.queue.indexOf(item);
    console.log("The index is " + index);
    this.uploader.queue.splice(index, 1);
  }

/*   uploadSchemaFile(fileInput:any){
    this.schmeaFile = fileInput.target.files[0];
  } */


  addProfile(profileFormValue): void {
    console.log(this.profile.name);
    console.log(this.profile.description);
    this.attachedFiles = this.getFiles();
    let profile: Profile ={
      id: null,
      name: profileFormValue.name,
      description: profileFormValue.description
    }
    console.log("before calling service :" + this.attachedFiles.length);
    this.dataProfileService.addProfile(profile,this.attachedFiles).subscribe(result => {
         //this.result = result, 
         this.result=result;
         if(this.result.includes('already exist')){   
         this.snackBar.open(result, "OK", {
          duration: 3000,
        });}
        else{
          this.snackBar.open(result +" is successfully created.", "OK", {
            duration: 3000,
          });
        }
          this.router.navigate(['/profile']);
          //this.result = result,
          //this.ngOnInit();
        });
    
/*     (error) => {
      this.errorMessage= error;
      console.log("Error Message:"+ this.errorMessage);           
      }  */

}

  clear(): void {
    this.form.reset();   
  }
}
