<h3  mat-dialog-title> Reject this update notice </h3>

<div mat-dialog-content>
      <mat-card-subtitle>Reason For Rejection</mat-card-subtitle>
        <mat-form-field>
            <textarea matInput placeholder="Description" #description></textarea>
        </mat-form-field>

</div>
  
  <div mat-dialog-actions>

      <button mat-raised-button mat-button color="primary" [mat-dialog-close]="data"
      (click)="reject(data.id, description.value);">Confirm</button>  
    
      <button mat-raised-button mat-button color="primary" (click)="onNoClick()">Cancel</button>
  </div>