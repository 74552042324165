import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorsService } from '../errors-service/errors.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
   constructor(
      // Because the ErrorHandler is created before the providers, we’ll have to use the Injector to get them.
      private injector: Injector,
   ) { }

   handleError(error: Error | HttpErrorResponse) {
      const router = this.injector.get(Router);
      const errorsService = this.injector.get(ErrorsService);

      if (error instanceof HttpErrorResponse) {
         // Server or connection error happened
         if ([400, 500, 502, 504].includes(error.status)) {
            if (error.status === 400) {
               errorsService.openErrorStatusCodeSnackBar(error.status, error.message);
               return;
            }
            errorsService.openErrorStatusCodeSnackBar(error.status);
            return;
         }
         if (!navigator.onLine) {
            // Handle offline error
            errorsService.openSnackBar('No Internet Connection');
         } else {
            // Show notification to the user
            // The backend returned an unsuccessful response code.
            console.log("Server Error")
            let errMsg = "";
            try {
               let errJson = JSON.parse(error.error);
               // errMsg = errJson.status + ": " + errJson.message;
               errMsg = errJson.message;

            } catch (e) {
               errMsg = error.message;
            }
            errorsService.openSnackBar(`${errMsg}`);
         }
      } else {
         errorsService.openSnackBar(
            (error.name != undefined && error.message != undefined) ? `${error.name}: ${error.message}` : `${error}`
         );
      }
      // Log the error anyway
      console.error('It happens: ', error);
   }
}
