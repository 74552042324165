import { FeatureSelectorService } from './../feature-selector.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AixmFeatureDTO } from './../model/AixmFeatureDTO';
import { FeatureGroup } from './../model/feature-group';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ConfigService } from '../../utils/config.service';
import { WFS } from 'ol/format';
import { equalTo, and, lessThanOrEqualTo } from 'ol/format/filter';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { startWith, map, tap} from 'rxjs/operators';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'app-feature-type-search-component',
  templateUrl: './feature-type-search-component.component.html',
  styleUrls: ['./feature-type-search-component.component.css']
})
export class FeatureTypeSearchComponentComponent implements OnInit {
  private appUrl = ConfigService.settings.app.url;

  private layerName = ConfigService.settings.geoServer.layerName;

  private officialLayerName = ConfigService.settings.geoServer.officialLayerName;

  public featureGroupFilter;

  featureGroups: FeatureGroup[] = [];

  selectedGroup: string;

  features: AixmFeatureDTO[] = [];

  unId: string = null;

  unName: string;

  selected: string;

  featureSearchControl = new UntypedFormControl();

  featuresSubject$ = new BehaviorSubject<AixmFeatureDTO[]>([])

  filteredFeatures$: Observable<AixmFeatureDTO[]>;

  filteredFeaturesLink$: Observable<{feature: AixmFeatureDTO, link: string}[]>;

  @Input() isReference: boolean = false;
  @Input() isUn: boolean = false;
  @Input() referenceType: string;
  @Input() effectiveDate: Date;
  @Input() referencedFeature: AixmFeatureDTO;
  @Output() referencePropertyValuePicked: EventEmitter<AixmFeatureDTO> = new EventEmitter<AixmFeatureDTO>();
  @Output() geoDataPicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private featureSelectorService: FeatureSelectorService, 
    private route: ActivatedRoute, 
    private router: Router
  ) {
    this.unId = this.route.snapshot.params['unId'],
    this.unName = this.route.snapshot.params['unName']
  }

  ngOnInit() {
    if(this.referencedFeature != undefined){      
      this.selected = this.referencedFeature.featureType;
      this.getFeaturesByType(this.referencedFeature.featureType);
    }
    if (this.referenceType == 'geoBorder') {
      this.getFeaturesByType('GeoBorder');
    } else {
      this.getTypes();
    }

    this.filteredFeatures$ = combineLatest([
      this.featureSearchControl.valueChanges.pipe(startWith("")),
      this.featuresSubject$,
    ]).pipe(
      map(([controlValue, features]) => {
        return features.filter((feature) => {
          if (feature.featureName) {
            return feature.featureName
              ?.trim()
              ?.toLowerCase()
              ?.includes((controlValue || "")?.trim()?.toLowerCase());
          }
          return feature;
        });
      })
    );

    this.filteredFeaturesLink$ = this.filteredFeatures$.pipe(
      map((features) =>
        features.map((feature) => {
          return {
            feature,
            link: this.unId
              ? this.router
                  .createUrlTree(["info-management", feature.featureId, this.unId, this.unName, this.effectiveDate])
                  .toString()
              : this.router
                  .createUrlTree(["info-management", this.selectedGroup, feature.featureId])
                  .toString(),
          };
        })
      ),
    );
  }

  getTypes() {
    if (this.referenceType == 'point') {
      this.featureGroups = ConfigService.settings.featureTypesForPointRef;
      this.featureGroupFilter = this.featureGroups.slice();
    } else {
      this.featureSelectorService.getTypes().
        subscribe(featureGroups => {
          this.featureGroups = featureGroups,
            this.featureGroupFilter = this.featureGroups.slice();
        });
    }
  }

  getFeaturesByType(group: string) {
    this.selectedGroup = group;
    this.featureSelectorService.getFeaturesByType(group, this.unId, this.isReference, this.isUn).
      subscribe(features => {
        this.features = features;
        this.featuresSubject$.next(features)
      });
  }

  redirectToAeroInputForm(featureId: string) {
    console.log({featureId, unID: this.unId, selectedGroup: this.selectedGroup})
    if (this.isUn) {
      this.router.navigate(['info-management', featureId, this.unId, this.unName, this.effectiveDate]);
    } else {
      this.router.navigate(['info-management', this.selectedGroup, featureId]);
    }

  }

  redirectToEmptyForm(featureType: string) {
    this.router.navigate(['info-management-empty-form', featureType, this.unId, this.unName, this.effectiveDate]);
  }

  setToReferencePropertyValue(event: MatSelectionListChange) {
    const selectedFeature = event.options[0].value
    console.log(selectedFeature)
    if (this.referenceType == 'point' || this.referenceType == 'geoBorder') {
      let isUn: boolean = (selectedFeature.tsId.interpretation == 'BASELINE') ? false : true;
      this.featureSelectorService.getReferencedGmlFeature(this.referenceType, selectedFeature.featureId, this.unId, isUn)
        .subscribe(refFeature => this.doEmit(refFeature, selectedFeature));
    } else {
      this.referencePropertyValuePicked.emit(selectedFeature);
    }
  }

  private doEmit(geoJson: AixmFeatureDTO, selectedFeature: AixmFeatureDTO) {
    this.geoDataPicked.emit(geoJson);
    this.referencePropertyValuePicked.emit(selectedFeature);
  }

  getGeometry(selectedFeature: AixmFeatureDTO) {
    var featureRequest;
    if (selectedFeature.tsId.interpretation == 'BASELINE') {
      // generate a GetFeature request for official feature
      featureRequest = new WFS().writeGetFeature({
        srsName: 'EPSG:4326',
        featureNS: 'facilisGeo',
        featurePrefix: 'facilisGeo',
        featureTypes: [this.officialLayerName],
        outputFormat: 'application/json',
        filter:
          and(
            equalTo('featureid', selectedFeature.featureId),
            lessThanOrEqualTo('effectivedate', this.route.snapshot.params['effDate']),
            equalTo('tsid', selectedFeature.tsId.interpretation + '-' +
              selectedFeature.tsId.sequenceNumber + '-' + selectedFeature.tsId.correction)
          )
      });
    } else {
      // generate a GetFeature request for UN feature
      featureRequest = new WFS().writeGetFeature({
        srsName: 'EPSG:4326',
        featureNS: 'facilisGeo',
        featurePrefix: 'facilisGeo',
        featureTypes: [this.layerName],
        outputFormat: 'application/json',
        filter: equalTo('idforsinglets', selectedFeature.featureId + '_' + this.unId + '_' + selectedFeature.tsId.interpretation + '-' +
          selectedFeature.tsId.sequenceNumber + '-' + selectedFeature.tsId.correction)
      });
    }

    //then post the request and add the received features 
    return fetch(this.appUrl + '/geoserver/wfs', {
      method: 'POST',
      body: new XMLSerializer().serializeToString(featureRequest),
    });
  }

}