import { TimeSliceId } from './TimeSliceId';

export class AixmFeatureDTO {
  featureId: string;
  featureType: string;
  featureName: string;
  summary: string;
  lastEdited: string;
  tsId: TimeSliceId;

  constructor(options: {
    featureId: string,
    featureType: string,
    featureName?: string,
    summary: string,
    lastEdited: string,
    tsId: TimeSliceId

  }) {
    this.featureId = options.featureId;
    this.featureType = options.featureType;
    this.featureName = options.featureName || '';
    this.summary = options.summary;
    this.lastEdited = options.lastEdited;
    this.tsId = options.tsId;
  }
}