import { UserDetailsService } from "./../../login/services/UserDetails.service";
import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../../utils/config.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation-sub.component.html",
  styleUrls: ["./navigation.component.css"],
})
export class NavigationComponent implements OnInit {
  isDp: boolean = ConfigService.settings.isDataProvider;

  private role = ConfigService.settings.role;

  navigationLinks = [
    { id: "overview", to: "overview", title: "Overview", satisfied: this.hasFOModuleRole() },
    { id: "info-management", to: "info-management", title: "AIS Data", satisfied: this.hasDataReaderRole() },
    { id: "update", to: "update", title: "Update Notices", satisfied: this.hasDataReaderRole() },
    { id: "log-sheet", to: "log-sheet", title: "Log Sheets", satisfied: this.hasLSModuleRole() },
    { id: "provider", to: "provider", title: "Data Providers", satisfied: this.isAdmin() },
    { id: "profile", to: "profile", title: "AIXM Profiles", satisfied: this.isDataManager() },
    { id: "quality/projects", to: "quality/projects", title: "Quality Assessment", satisfied: this.hasFqaModuleRole() },
    {
      to: null,
      id: "validator",
      title: "Validator",
      expanded: false,
      satisfied: this.hasValidatorModuleRole(),
      sub: [
        {
          id: "aixm-validator",
          to: "aixm-validator",
          title: "New Validation",
          satisfied: this.hasValidatorModuleRole(),
        },
        {
          id: "validation-history",
          to: "validation-history",
          title: "My Validations",
          satisfied: this.hasValidatorModuleRole(),
        },
        {
          id: "validation-stats",
          to: "validation-stats",
          title: "Statistics",
          satisfied: this.hasValidatorModuleRole(),
        },
      ],
    },
  ];

  constructor(private userDetailsService: UserDetailsService, private router: Router) {}

  ngOnInit() {
    this.setInitialLinkExpanded();
  }

  // Check the current user has ROLE_FACILIS_ORIGIN_MODULE
  hasFOModuleRole(): boolean {
    return this.userDetailsService.hasRole(this.role.role_FO_Module);
  }

  // Check the current user is AIS admin
  isAdmin(): boolean {
    return this.userDetailsService.hasRole(this.role.role_Ais_Admin);
  }

  // Check the current user is Data manger
  isDataManager(): boolean {
    return this.userDetailsService.hasRole(this.role.role_Data_Manager);
  }

  // Check the current user is Data reader
  hasDataReaderRole(): boolean {
    return this.userDetailsService.hasRole(this.role.role_Data_Reader);
  }

  // Check the current user has ROLE_LOG_SHEET_MODULE role
  hasLSModuleRole(): boolean {
    return this.userDetailsService.hasRole(this.role.role_LS_Module);
  }

  // Check the current user has ROLE_FQA_MODULE role
  hasFqaModuleRole(): boolean {
    return this.userDetailsService.hasRole(this.role.role_Fqa_Module);
  }

  // Check the current user has ROLE_FAIVA_MODULE role
  hasValidatorModuleRole(): boolean {
    return this.userDetailsService.hasRole(this.role.role_Validator_Module);
  }

  setInitialLinkExpanded(): void {
    const intialExpanded = this.navigationLinks
      .filter((link) => !!link.sub)
      .find((link) => link.sub.some((value) => this.router.url.includes(value.to)));

    console.log(intialExpanded, "### intialExpanded");

    if (intialExpanded) {
      intialExpanded.expanded = true;
    }
  }
}
