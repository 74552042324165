<ng-container>

  <mat-toolbar>
    <span>
      <h3>Browse Features for Update Notice<a routerLink='/update/id/{{unId}}'>: {{unName}}</a></h3>
    </span>
  </mat-toolbar>

  <mat-card>
    <form (ngSubmit)="onSubmit()" [formGroup]="searchForm" novalidate>
      <mat-card-content>

        <!-- <mat-form-field>
          <mat-label>Filter by ICAO Catalogue</mat-label>
          <mat-select (selectionChange)="getTypes($event.value)">
            <mat-option *ngFor="let catalogue of catalogues" [value]="catalogue.number">
              {{catalogue.description}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <mat-tab-group mat-stretch-tabs="false" class="searchTabs" #tabRef [selectedIndex]="3"
          (selectedTabChange)="tabChange(tabRef.selectedIndex)">

          <!-- issue #500 remove ICAO data catalog search -->
          <!-- ICAO Data Catalogues -->
          <!-- <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="Search based on ICAO Data Catalogues">account_tree</mat-icon>
            </ng-template>

            <im-catalog-search></im-catalog-search>
          </mat-tab> -->

          <!-- Free-text Search -->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="Free-text Search">search</mat-icon>
            </ng-template>

            <div class="text">
              <mat-form-field class="text-search">
                <input matInput type="search" placeholder="Find names, designators, coordinates and more..." #text>
              </mat-form-field>

              <button mat-flat-button color="primary" (click)="searchFeature(text.value)"
                [disabled]="!text.value">Search</button>
            </div>

            <mat-card *ngIf="documents_dataSource.data[0]">
              <mat-card-header>
                <mat-form-field>
                  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
                  <mat-icon matSuffix (click)="applyFilter('');filter.value=''" style="cursor:pointer">clear</mat-icon>
                </mat-form-field>
              </mat-card-header>

              <mat-card-content>
                <mat-table #table [dataSource]="documents_dataSource" matSort>

                  <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

                  <!-- status Column -->
                  <ng-container matColumnDef="featureName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Feature Name </mat-header-cell>
                    <mat-cell *matCellDef="let document"><a [routerLink]=""
                        (click)="redirectToAeroInputForm(document.id)">{{document.featureName}}</a></mat-cell>
                  </ng-container>

                  <!-- feature name Column -->
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let document"> {{document.status}} </mat-cell>
                  </ng-container>

                  <!-- originator Column -->
                  <ng-container matColumnDef="originator">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Originator </mat-header-cell>
                    <mat-cell *matCellDef="let document"> {{document.originator}} </mat-cell>
                  </ng-container>

                  <!-- matched text Column -->
                  <ng-container matColumnDef="matchedText">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
                    <mat-cell *matCellDef="let document">
                      <div [innerHTML]="document.matchedText"></div>
                    </mat-cell>
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>

                <mat-card-footer>
                  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
                    [showFirstLastButtons]="true">
                  </mat-paginator>
                </mat-card-footer>
                <br>
              </mat-card-content>
            </mat-card>

          </mat-tab>

          <!-- Search by relations -->
          <!-- <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon matTooltip="Search by Relations">share</mat-icon>
          </ng-template>

          <im-network-search></im-network-search>
        </mat-tab> -->

          <!-- Search by location -->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="Search by Location">location_on</mat-icon>
            </ng-template>

            <div *ngIf="displayMap">
              <app-location-search></app-location-search>
            </div>

          </mat-tab>

          <!-- Search in lists -->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon matTooltip="Search in Lists">view_list</mat-icon>
            </ng-template>
            <app-feature-type-search-component [isReference]="false" [isUn]="isUn" [effectiveDate]="effectiveDate">
            </app-feature-type-search-component>
          </mat-tab>

          <!-- AIP Datasets-->
          <mat-tab *ngIf="!isUn">
            <ng-template mat-tab-label>
              <mat-icon matTooltip="AIP Datasets">file_download</mat-icon>
            </ng-template>
            <app-aip-datasets>
            </app-aip-datasets>
          </mat-tab>

          <!-- Search using recent searches -->
          <!-- <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon matTooltip="Recent features">history</mat-icon>
          </ng-template>

          <div>history here</div>
        </mat-tab> -->

        </mat-tab-group>

        <!-- Timeline -->
        <!-- <div class="time-slice-list" *ngIf="!isUn && selectedFeatureId">
          <im-time-slice-list [featureId]="selectedFeatureId"></im-time-slice-list>
        </div> -->

      </mat-card-content>
    </form>
  </mat-card>
</ng-container>