import { Component, OnInit } from '@angular/core';
import { UserProperties } from './../model/user-properties.model';
import { PropertiesService } from './../services/properties.service';
import {ActivatedRoute, Router} from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';


@Component({
  selector: 'app-edit-userproperties',
  templateUrl: './edit-userproperties.component.html',
  styleUrls: ['./edit-userproperties.component.css']
})
export class EditUserpropertiesComponent implements OnInit {

  Uprops: UserProperties=new UserProperties();

  editUserPropForm: UntypedFormGroup;
  moduleName:string;
  property: string;
  propertyValue:string;

  constructor(private route: ActivatedRoute,private router: Router,private fb:UntypedFormBuilder,
    private propertiesService:PropertiesService) {
   
     }

  ngOnInit() {
    const id=this.route.snapshot.params['id'];
    this.getUserProperty(id);
  }
getUserProperty(id: string):void{
  this.propertiesService.getUserProperty(id)
  .subscribe(Uprop=>this.Uprops=Uprop);

}
clear(): void {
  this.ngOnInit();
}

editUserproperties(): void {
 this.propertiesService.editUserProperty(this.Uprops.id,{id:'', modulename: this.Uprops.modulename,
 property:this.Uprops.property,propertyvalue:this.Uprops.propertyvalue}as UserProperties)
 .subscribe(newUserProperty => {
  this.router.navigate(['/userproperties', newUserProperty]);
});
}      
}
