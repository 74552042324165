import {Component, OnInit, ViewChild} from '@angular/core';
import {  MatPaginator } from '@angular/material/paginator';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatDialog } from '@angular/material/dialog';
import { Profile } from '../model/profile.model';
import { DataprofileService } from './../service/dataprofile.service';
import { NewDataprofileComponent } from '../new-dataprofile/new-dataprofile.component';
import { CopyofDataprofileDialogComponent } from '../copyof-dataprofile-dialog/copyof-dataprofile-dialog.component';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';

@Component({
  selector: 'app-aixm-profilelists',
  templateUrl: './aixm-profilelists.component.html',
  styleUrls: ['./aixm-profilelists.component.css']
})
export class AixmProfilelistsComponent implements OnInit {

  profilelist: Profile[] = [];
  profile: Profile;

  displayedColumns = ['name', 'description'];
  aixmprofilelist_dataSource= new MatTableDataSource<Profile>(this.profilelist);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.aixmprofilelist_dataSource.sort = this.sort;
    this.aixmprofilelist_dataSource.paginator = this.paginator;
  }
  
  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.aixmprofilelist_dataSource.filter = filterValue;
  }

  constructor(private dataProfileService: DataprofileService, public dialog: MatDialog,public snackBar:MatSnackBar) { }

  ngOnInit() {
    this.getProfiles();
  }

  getProfiles(): void {
    this.dataProfileService.getProfiles()
      .subscribe(profiles => this.aixmprofilelist_dataSource.data=profiles);
  }


/*   openDialogForCopy(id: number, name: string, description: string): void {
    let dialogRef = this.dialog.open(CopyofDataprofileDialogComponent, {
      width: '500px',
      data: {
        id: id,
        name: name,
        description: description
      }
    });

    dialogRef.afterClosed().subscribe(result => {
       this.ngOnInit();
      let idx = this.aixmprofilelist_dataSource.data.findIndex(element => id == element.id);
    });
  } */
}
