import { PropertyField } from "./property-field";
import { TypeMapper } from "./text-property-field";
import { MultipleProperty } from './property';

export class TextAreaPropertyField extends PropertyField<string>{
    controlType = 'textarea';
    type: string;
    id: string;
  
    constructor(options: {
      key: string,
      id?: string,
      label?: string,
      hint?: string,
      description?: string,
      order?: number,
      minOccurs?: number,
      maxOccurs?: number,
      nil?: boolean,
      nilReason?: string,
      annotations?: MultipleProperty,
      value?: string,
      minLength?: number,
      maxLength?: number,
      minExclusive?: number,
      maxExclusive?: number,
      minInclusive?: number,
      maxInclusive?: number,
      pattern?: string,
      type?: string,
      modified: boolean
    }) {      
      super(options);
      this.id = options['id'] || '';      
      this.type = (options['maxLength'] >= 100 && options['type'] == 'string')? 'textarea': TypeMapper[options['type']] 
      || TypeMapper.string;
      this.maxLength = options.maxLength;
    }
}