import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LatlongService {

  constructor() { }

  //Convert Decimal to DMS
  toDMS(coordinate) {
    var absolute = Math.abs(coordinate);
    var degrees = absolute - Math.floor(absolute) >= 0.999999 ? Math.round(absolute) : Math.trunc(absolute);
    var minutesNotTruncated = (absolute - degrees) * 60;
    var minutes = minutesNotTruncated - Math.floor(minutesNotTruncated) >= 0.9999 ? Math.round(minutesNotTruncated) : Math.trunc(minutesNotTruncated);
    //console.log("Minutes : " + minutes);   

    var secondsNotTrancated = (minutesNotTruncated - minutes) * 60;
    var decimalPart = secondsNotTrancated - Math.floor(secondsNotTrancated);
    var seconds: number;
    var secSlice: number;

    if (decimalPart <= 0.99 || decimalPart >= 0.1 && Math.floor(secondsNotTrancated) <= 59) {
      // Take the decimal places if the decimal part is between 0.1 and 0.99 and Math.floor of secondsNotTrancated <= 59     
      var seconds = secondsNotTrancated % 1 === 0.0 ? parseFloat(secondsNotTrancated.toFixed(1)) : parseFloat(secondsNotTrancated.toFixed(2));
      secSlice = Math.floor(seconds).toString().length > 1 ? seconds.toString().length : seconds.toString().length + 1;

    } else if (decimalPart >= 0.99 || Math.floor(secondsNotTrancated) == 59) {
      // decimal part is greater than 0.99 // round the seconds
      seconds = Math.round(secondsNotTrancated);
    } 
    // console.log("Seconds : " + seconds);

    var mins = ('0' + minutes).slice(-2);
    var secs = seconds !== Math.trunc(seconds) ? ('0' + seconds).slice(-secSlice) : ('0' + seconds).slice(-2);

    return degrees + " " + mins + " " + secs;
  }

  convertDMS(lat: number, lng: number) {
    var latitudeDMS = this.toDMS(lat);
    var degrees = latitudeDMS.split(" ")[0].length == 1 ? '0' + latitudeDMS.split(" ")[0] : latitudeDMS.split(" ")[0];
    var minutes = latitudeDMS.split(" ")[1]
    var seconds = latitudeDMS.split(" ")[2]
    var latitude = degrees + "" + minutes + "" + seconds;
    var latitudeCardinal = lat >= 0 ? "N" : "S";

    var longitudeDMS = this.toDMS(lng);
    var degrees = longitudeDMS.split(" ")[0].length == 1 ? '00' + longitudeDMS.split(" ")[0] : longitudeDMS.split(" ")[0];
    var minutes = longitudeDMS.split(" ")[1]
    var seconds = longitudeDMS.split(" ")[2]
    var longitude = degrees + "" + minutes + "" + seconds;
    var longitudeCardinal = lng >= 0 ? "E" : "W";

    return latitude + "" + latitudeCardinal + " " + longitude + "" + longitudeCardinal;
  }
}
