<h3  mat-dialog-title >Review</h3>

<div mat-dialog-actions>
 
    <!-- (click) passes input value to add() and then clears the input -->
    <button mat-raised-button mat-button color="primary" class="example-btn-width" 
    (click)="approved()">Approved</button>  

    <button mat-raised-button mat-button color="primary" class="example-btn-width" (click)="onNoClick()">Cancel</button>
</div>
