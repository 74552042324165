import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Timeline, TimelineOptions, DateType} from 'vis-timeline';
import { DataSet } from 'vis-data';
import moment from 'moment';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {

  @ViewChild('visualization') visualization:ElementRef;

  data = new DataSet([
    {id: 'r2018-1', start: '2018-01-04', end: '2018-01-05', type: 'background', className: 'airac' },
    {id: 'r2018-2', start: '2018-02-01', end: '2018-02-02', type: 'background', className: 'airac' },
    {id: 'r2018-3', start: '2018-03-01', end: '2018-03-02', type: 'background', className: 'airac' },
    {id: 'r2018-4', start: '2018-03-29', end: '2018-03-30', type: 'background', className: 'airac' },
    {id: 'r2018-5', start: '2018-04-26', end: '2018-04-27', type: 'background', className: 'airac' },
    {id: 'r2018-6', start: '2018-05-24', end: '2018-05-25', type: 'background', className: 'airac' },
    {id: 'r2018-7', start: '2018-06-21', end: '2018-06-22', type: 'background', className: 'airac' },
    {id: 'r2018-8', start: '2018-07-19', end: '2018-07-20', type: 'background', className: 'airac' },
    {id: 'r2018-9', start: '2018-08-16', end: '2018-08-17', type: 'background', className: 'airac' },
    {id: 1, group: 2, content: 'AIP 3rd Edition', start: '2018-02-01', end: '2018-03-10', subgroup: 'amdt', subgroupOrder: 1},
    {id: 2, group: 2, content: 'AMDT 01/18', start: '2018-03-10', end: '2018-04-26', subgroup: 'amdt', subgroupOrder: 1},
    {id: 3, group: 2, content: 'AIRAC AMDT 01/18', start: '2018-04-26', end: '9999-04-26', subgroup: 'amdt', subgroupOrder: 1, className: 'airac'},
    {id: 4, group: 1, content: 'SUP 01/18', start: '2018-04-16', end: '2018-08-19'},
    {id: 'n100', group: 3, content: 'A001/19', start: '2018-04-10', end: '2018-04-11'},
    {id: 'n101', group: 3, content: 'A002/19', start: '2018-05-22T06:00:00Z', end: '2018-05-22T18:00:00Z'},
  ]);

  groups = [
    {
      id: 1,
      content: 'SUP',
      order:20
    },
    {
      id: 3,
      content: 'NOTAM',
      order:10
    },
    {
      id: 2,
      content: 'AMDT',
      order: 30,
      subgroupStack: {
        'amdt': false,
        'UN': true
      },
      subgroupOrder: function (a,b) {return a.subgroupOrder - b.subgroupOrder;}
    }
  ];
  
  options: TimelineOptions = {
    min: '2018-01-01T00:00:00Z', // TODO make it a server-side operational configuration property
    start: '2018-01-01T00:00:00Z', // subtract 60 days to show 2 AIRAC cycles in the past
    end: '2018-09-19T00:00:00Z', // TODO make it a server-side operational configuration property
    max: '2018-09-19T00:00:00Z', // TODO make it a server-side operational configuration property
    moment: function(date) {
      return moment(date).utc();
    },
    width: '100%',
    height: '200px',
    zoomMax: 315576000000, // 10 years
    zoomMin: 1000*60*60*24 // 24 hours
  };
  timeline: Timeline;

  constructor() { }

  ngOnInit() {
      //var container = document.getElementById('mynetwork');
     // this.timeline = new Timeline(this.visualization.nativeElement, this.data, this.groups, this.options);
  }

}
