 <mat-toolbar><h3> Edit-Data Profile </h3></mat-toolbar>

<mat-card>
  <mat-card-content>

      <ng-container *ngIf="profile">       
          <div>
              <div id="left">
                <mat-card-subtitle> Profile Name </mat-card-subtitle>
              </div>
              <div id="center">
                  <form>
                 <mat-form-field>
                   <input matInput [(ngModel)]="profile.name" [ngModelOptions]="{standalone: true}">
                 </mat-form-field>
                 </form>
              </div>
              <button mat-raised-button color="primary" (click)="editProfile()">
                Save
              </button>
          </div>
<br/>
          <div>
              <div id="left">
                  <mat-card-subtitle> Profile Description </mat-card-subtitle>
              </div>
              <div id="center">
                   <mat-form-field >
                     <input matInput [(ngModel)]="profile.description" [ngModelOptions]="{standalone: true}">
                   </mat-form-field>
              </div>
              <button mat-raised-button color="primary" (click)="clear()">
                Reset
              </button>
          </div>
        </ng-container>
      <br>
      <br>
      <mat-toolbar>
      <span>
      <h3> List Profile Versions </h3>
      </span>
      <span class="spacer"></span>
      <mat-card-actions>
          <button mat-raised-button  color="primary" class="button" [routerLink]="['/new-profile-version/id', profile.id]">
          New Version</button>
      </mat-card-actions>
    </mat-toolbar>
   
      <mat-table #table [dataSource]="aixmProfileVersionlist_dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

        <!-- Version Column dataprofileVersionlist_dataSource.data-->
        <ng-container matColumnDef="Number">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Version </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.number}}
          </mat-cell>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="Description">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.description}} </mat-cell>
        </ng-container>

        <!-- Data Providers Column -->
       <ng-container matColumnDef="Data Providers">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Data Providers </mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.originators.length}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit_icon">
            <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
            <mat-cell *matCellDef="let row"><i class="material-icons" [routerLink]="['/edit-profile-version/profileId/versionId', profile.id,row.number]" style="cursor:pointer">edit</i> </mat-cell>
          </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
   

    <mat-card-footer>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-footer>
        
  </mat-card-content>
</mat-card>
