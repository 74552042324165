<mat-toolbar>
  <span>
    <h3> New Update Notice</h3>
  </span>
</mat-toolbar>

<mat-card class="new-update-notice-form">
  <form #f="ngForm" name="newUnForm" [formGroup]="form" (ngSubmit)="addUpdateNotice(form.value)">
        
        <!--Temporality-->
        <div class="left">
        <mat-label>Temporality</mat-label>
        </div>
        <mat-radio-group class="example-radio-group space" name="temporality" formControlName="temporality">
            <mat-radio-button class="example-radio-button" value="PERMANENT">Permanent</mat-radio-button>
            <mat-radio-button value="TEMPORARY">Temporary</mat-radio-button>
        </mat-radio-group>
        <br/>   
        
        <!--Effective Date-->
        <div class="left">
        <mat-label>Effective Date </mat-label>
        </div>
        
       <!--  <mat-form-field class="space form-width"> 
            <input matInput [matDatepicker]="picker1" name="effectiveDate" [min]="today" 
            (dateChange)="getDueDate($event)" formControlName="effectiveDate"
                style="margin-top: 0px;">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field> -->

       <!-- <app-airac-date></app-airac-date> -->

       <mat-form-field class="space form-width">
        <input autocomplete="off" matInput [matDatepicker]="picker1" name="effectiveDate" [min]="validEffDate" 
        (dateChange)="getDueDate($event)" (click)="picker1.open()" formControlName="effectiveDate"
            style="margin-top: 0px;">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker [dateClass]="dateClass" #picker1></mat-datepicker>
      </mat-form-field>

        <!--Cancel Date-->
        <span *ngIf="form.controls['temporality'].value == 'TEMPORARY'">
              <mat-label class="space size">Cancel Date</mat-label>
              <mat-form-field class="space form-width">
                <input autocomplete="off" matInput [matDatepicker]="picker2" name="cancelDate" [min]="validCancelDate" formControlName="cancelDate"
                    style="margin-top: 0px;" (click)="picker2.open()">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>  
                <mat-error *ngIf="form.controls.cancelDate.errors?.CheckDate">
                    Cancel Date must not be less or equal to effective date
                </mat-error>
              </mat-form-field>
        </span>
        <br/>
        <br/>
        
        <!--Due Date-->
        <div *ngIf="dueDate!=null">
        <div class="left" *ngIf="dueDate!=null">
            <mat-label>Due Date </mat-label>
        </div>
        <span class="left space size" >{{dueDate}}</span>       
        <br/>
        <br/>
        </div>

        <!-- Timezone -->
        <div class="left">
          <mat-label>Timezone </mat-label>
        </div>
        <span class="left space size">UTC</span>       
        <br/>
        <!-- <br/> -->

        <!-- Title -->
        <div class="left">
            <mat-label>Title</mat-label>
        </div>
        <mat-form-field class="space form-width">
            <input matInput name="name" formControlName="name"  required>
        </mat-form-field>
        <br/>

        <!--Description-->
        <div class="left">
            <mat-label>Description</mat-label>
        </div>
        <mat-form-field class="space" style="width:45%">
            <textarea matInput  name="description" 
                    formControlName="description"></textarea>
        </mat-form-field>
        <br/>
        <br/>

        <!--Attachments-->
        <div class="left">
            <mat-label>Attachments</mat-label>
        </div>
       <!--  <div id="center"> -->
           <!--  <div class="justify"> -->
              <input type="file" matTooltip="Add Attachment" hidden ng2FileSelect #file [uploader]="uploader" multiple />
              <button type="button" class="material-icons spacer" (click)="file.click()">
                  &#xE226;</button>     
            <!-- </div> -->
        <!-- </div> -->
          <br>
          <br>
          <div class="selectedfiles">
            <div *ngFor="let item of uploader.queue" height="300">
              {{ item?.file?.name }}
              <button mat-icon-button (click)="remove(item)">
                <i class="material-icons">delete</i></button>
            </div>
          </div>
          <br/>

          <mat-card-actions class="buttonspace">
            <button mat-raised-button color="primary" class="btnwidth"
            [disabled]="!form.valid || form.pristine">
            Save
            </button>
            <button mat-raised-button color="warn" class="btnwidth"
            (click)="cancel();">Cancel</button>           
          </mat-card-actions>

      </form>
  </mat-card>

<!-- For V2 Feature -->
<!-- <div class="demo-grid mdc-layout-grid">
    <div class="mdc-layout-grid__inner">
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-1">Contributors</div>
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-8">
        </div>
      <div class="demo-cell mdc-layout-grid__cell mdc-layout-grid__cell--span-2">
        </div>
    </div>
  </div> -->
