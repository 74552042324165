<div [formGroup]="parentFormGroup">
  <div [formArrayName]="arrayName">

    <div *ngFor="let property of multipleProperty.properties; let i = index">
      <ng-container [ngSwitch]="property.propertyType">

        <div *ngSwitchDefault [formGroupName]="i">
          <span>#{{i+1}}</span>
          <button type="button" mat-icon-button matTooltip="Remove this item" (click)="removeProperty(i)" [disabled]="readonly" >
            <mat-icon color="primary" aria-label="Remove this item " [ngClass]="{'disable':readonly, 'enable': !readonly}">remove</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Move this item before the previous one" [disabled]="readonly" 
            (click)="stopPropagation($event)">
            <mat-icon color="primary" aria-label="Move this item before the previous one" [ngClass]="{'disable':readonly, 'enable': !readonly}">keyboard_arrow_up</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Move this item after the previous one" [disabled]="readonly" 
            (click)="stopPropagation($event)">
            <mat-icon color="primary" aria-label="Move this item after the previous one" [ngClass]="{'disable':readonly, 'enable': !readonly}">keyboard_arrow_down
            </mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Move this item to the top" [disabled]="readonly" 
            (click)="stopPropagation($event)">
            <mat-icon color="primary" aria-label="Move this item to the top" [ngClass]="{'disable':readonly, 'enable': !readonly}">vertical_align_top</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Move this item to the bottom" [disabled]="readonly" 
            (click)="stopPropagation($event)">
            <mat-icon color="primary" aria-label="Move this item to the bottom" [ngClass]="{'disable':readonly, 'enable': !readonly}">vertical_align_bottom</mat-icon>
          </button>

          <im-property-switch [property]="property" [parentFormGroup]="parentFormGroup.controls[arrayName]"
            [copyProperty]="property" [positionInArray]="i" [readonly]="readonly"></im-property-switch>
          <!-- [newItem]="newItem" -->
        </div>

        <!-- <im-property-switch [copyProperty]="copyProperty" [parentFormGroup]="parentFormGroup.controls[arrayName]"
        [positionInArray]="i+newItemNo" [newItem]="newItem" *ngIf="copyProperty != null"></im-property-switch> -->

        <mat-expansion-panel *ngSwitchCase="'lazyLoaded'">
          <span>#{{i+1}}</span>
          <button type="button" mat-icon-button matTooltip="Remove this item" (click)="stopPropagation($event)" [disabled]="readonly" >
            <mat-icon color="primary" aria-label="Remove this item" [ngClass]="{'disable':readonly, 'enable': !readonly}">remove</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Move this item before the previous one" [disabled]="readonly" 
            (click)="stopPropagation($event)">
            <mat-icon color="primary" aria-label="Move this item before the previous one" [ngClass]="{'disable':readonly, 'enable': !readonly}">keyboard_arrow_up</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Move this item after the previous one" [disabled]="readonly" 
            (click)="stopPropagation($event)">
            <mat-icon color="primary" aria-label="Move this item after the previous one" [ngClass]="{'disable':readonly, 'enable': !readonly}">keyboard_arrow_down
            </mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Move this item to the top" [disabled]="readonly" 
            (click)="stopPropagation($event)">
            <mat-icon color="primary" aria-label="Move this item to the top" [ngClass]="{'disable':readonly, 'enable': !readonly}">vertical_align_top</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Move this item to the bottom" [disabled]="readonly" 
            (click)="stopPropagation($event)">
            <mat-icon color="primary" aria-label="Move this item to the bottom" [ngClass]="{'disable':readonly, 'enable': !readonly}">vertical_align_bottom</mat-icon>
          </button>

          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>{{property.label | slice:0:property.label.length-1}} List</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p>{{property.description}}</p>
        </mat-expansion-panel>
      </ng-container>
    </div>

  </div>
</div>