import { Component, OnInit, Inject } from '@angular/core';
// import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import Tile from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Icon, Style } from 'ol/style';
import { AixmFeatureDTO } from '../model/AixmFeatureDTO';
import {  MAT_DIALOG_DATA } from '@angular/material/dialog';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { ConfigService } from '../../utils/config.service';
import { ScaleLine } from 'ol/control';
import { InfoManagementService } from '../service/info-management.service';
import { Control, defaults as defaultControls } from 'ol/control';
import { UserDetailsService } from '../../login/services/UserDetails.service';

export interface DialogData {
  aixmDtos: AixmFeatureDTO[];
  unId: string;
  latitude: number;
  longitude: number;
  featureId: string;
  tsId: string;
  gmlId: string;
  effectiveDate: string;
  isPublished: boolean;
  isModified: boolean;
}

@Component({
  selector: 'app-map-view-dialog',
  templateUrl: './map-view-dialog.component.html',
  styleUrls: ['./map-view-dialog.component.css']
})
export class MapViewDialogComponent implements OnInit {

  aixmFeatures: AixmFeatureDTO[] = [];
  unId: string;
  featureId: string;
  tsId: string;
  gmlId: string;
  effectiveDate: string;
  isPublished: boolean;
  isModified: boolean;
  layerChoosed: boolean = false;
  isSelected: boolean = false;

  latitude: number;
  longitude: number;
  url: string;
  workspaceName: string;
  officialWorkspaceName: string;
  layerName: string;
  officialLayerName: string;
  layer: string;
  officialGeoDataSource: any;
  officialGeoDataLayer: any;
  marker: Feature;
  map: Map;
  layersToRemove = [];


  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private infoManagementService: InfoManagementService, public auth: UserDetailsService) {
    this.unId = data.unId;
    this.aixmFeatures = data.aixmDtos;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.featureId = data.featureId;
    this.tsId = data.tsId;
    this.gmlId = data.gmlId;
    this.effectiveDate = data.effectiveDate;
    this.isPublished = data.isPublished;
    this.isModified = data.isModified;
    this.url = ConfigService.settings.geoServer.url;
    this.workspaceName = ConfigService.settings.geoServer.unWorkspaceName;
    this.officialWorkspaceName = ConfigService.settings.geoServer.officialLayerName;
    //this.layerName = ConfigService.settings.geoServer.layerName;
    //this.officialLayerName = ConfigService.settings.geoServer.officialLayerName;
    //console.log("test: " + JSON.stringify(this.aixmFeatures));
  }

  vectorLayer = new VectorLayer({
    source: new VectorSource
  })
  vectorSource = this.vectorLayer.getSource();
  polygonTile = new TileLayer;
  segmentTile = new TileLayer;

  layers = [
    new Tile({
      source: new OSM({
        attributions: 'Background: &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
        maxZoom: 18
      })
    }), this.vectorLayer
  ];

  // initial view
  view = new View({
    center: [0, 0],
    zoom: 2,
    maxZoom: 18,
    projection: 'EPSG:4326',
    multiWorld: true
  });

  ngOnInit() {
    if (this.aixmFeatures != undefined) {
      this.map = new Map({
        controls: defaultControls({ zoom: true, }).extend([
          new ScaleLine()
        ]),
        layers: this.layers,
        target: 'map',
        view: new View({
          center: [0, 0],
          zoom: 2,
          maxZoom: 18,
          projection: 'EPSG:4326',
          multiWorld: true
        }),
      });
      this.getFeatureFromGeoserver(this.aixmFeatures, this.unId);
    }
    else {
      this.map = new Map({
        controls: defaultControls({ zoom: true, }).extend([
          new ScaleLine()
        ]),
        layers: this.layers,
        target: 'map'
      })
    }

    // Add layer list control to the map
    const that = this;
    var element = document.getElementById('layers');
    var layerListControl = new Control({
      element: element
    });
    that.map.addControl(layerListControl);

    //display point that doesn't exist in DB on map before feature is created
    if (this.latitude != null && this.longitude != null) {
      let coordinate = this.latitude + " " + this.longitude;
      this.showpoint(coordinate);
    }

  }

  showpoint(coordinate: string) {
    this.latitude = Number(coordinate.split(" ")[0]);
    this.longitude = Number(coordinate.split(" ")[1]);

    this.marker = new Feature({
      geometry: new Point(fromLonLat([this.longitude, this.latitude])),
    });

    this.marker.setStyle(new Style({
      image: new Icon({
        crossOrigin: 'anonymous',
        src: 'assets/images/mapicon.png',
        size: [50, 50]
      })
    })
    )
    this.vectorSource.addFeature(this.marker);
    let view = this.map.getView();
    view.setCenter(fromLonLat([this.longitude, this.latitude]));
    view.setZoom(8);
  }

  getFeatureFromGeoserver(aixmFeatures: AixmFeatureDTO[], unId: string) {
    const that = this;
    let url = this.url + this.workspaceName + '/wms/';
    let layer: string;

    //let filter: string[] = [];
    //let query;

    /* if (aixmFeatures != undefined) {      
      aixmFeatures.forEach(feature => {
        let featureType = feature.featureType;
        //layer = this.workspaceName + ":" + this.getLayerNamefromFeatureType(featureType);
        if (this.auth.getTenantName() == undefined || this.auth.getTenantName() == "") {
          query = "fname='"+ featureType + "' and idforsinglets='" + feature.featureId + '_' + this.unId + '_' + feature.tsId.interpretation + '-' +
            feature.tsId.sequenceNumber + '-' + feature.tsId.correction + "' and tenant_name='" + "" + "'";
        }
        else {
          query = "fname='"+ featureType + "' and idforsinglets='" + feature.featureId + '_' + this.unId + '_' + feature.tsId.interpretation + '-' +
            feature.tsId.sequenceNumber + '-' + feature.tsId.correction + "' and tenant_name='" + this.auth.getTenantName() + "'";
        }
        //filter.push(query);         
      });  
      for (let i = 0; i < filter.length; i++) {
        console.log(filter[i]);
        this.polygonTile = new TileLayer({
          opacity: 0.5,
          source: new TileWMS({
            url: url,
            params: {
              layers: layer,
              CQL_FILTER: filter[i],
            },
            serverType: 'geoserver'
          }),
          visible: true
        });
        that.map.addLayer(this.polygonTile);
      } 
    }
    else {
      if (this.isModified == true) {
        this.infoManagementService.getTimesliceId(this.featureId, this.unId).subscribe(result => {
          this.tsId = result.interpretation + "-" + result.sequenceNumber + "-" + result.correction;

          let fId = this.layerName + '.' + this.featureId + '_' + this.unId + '_' + this.tsId + '_' + this.gmlId;
          console.log(fId);

          this.polygonTile = new TileLayer({
            opacity: 0.5,
            source: new TileWMS({
              url: url,
              params: {
                layers: layer,
                featureID: fId
              },
              serverType: 'geoserver'
            }),
            visible: true
          });
          that.map.addLayer(this.polygonTile);
        });
      }
      else {
        this.displayOfficialData(this.effectiveDate);
      }
    }*/

    if (this.auth.getTenantName() != undefined || this.auth.getTenantName() != "") {
      layer = this.unId + "_" + "UN_LayerGroup";
    }

    console.log("Layer name : " + layer);

    this.polygonTile = new TileLayer({
      opacity: 0.5,
      source: new TileWMS({
        url: url,
        params: {
          layers: layer,
          //CQL_FILTER: query,
        },
        serverType: 'geoserver'
      }),
      visible: true
    });
    this.map.addLayer(this.polygonTile);
  }

  // Display official Geo Data on the map
  displayOfficialData(effectiveDate: string) {
    let layer: string;
    const that = this;
    /* let feId: string[] = [];
    let tsId: string[] = [];
    let query: string[] = [];
    that.map.removeLayer(this.officialGeoDataLayer);
    console.log(this.tsId);
    if (effectiveDate == undefined) {
      let fId = this.officialLayerName + '.' + this.featureId + '_' + this.tsId + '_' + this.gmlId;
      console.log(fId);
      this.officialGeoDataSource = new TileWMS({
        url: this.url + this.workspaceName + '/wms/',
        params: {
          layers: this.workspaceName + ":" + this.officialLayerName,
          tiled: true,
          featureID: fId
        },
        serverType: 'geoserver',
      });

      this.officialGeoDataLayer = new TileLayer({
        source: this.officialGeoDataSource,
      });
      that.map.addLayer(this.officialGeoDataLayer);
    }
    else {
      this.infoManagementService.getFeatureIdAndTimesliceId(effectiveDate).subscribe(result => {
        for (let i = 0; i < result.length; i++) {
          feId[i] = result[i].split("_")[0];
          tsId[i] = result[i].split("_")[1];

          if (this.auth.getTenantName() == undefined || this.auth.getTenantName() == "") {
            query[i] = "effectivedate<='" + effectiveDate + "' and featureid='" + feId[i] + "' and tsid='" + tsId[i] + "' and tenantname='" + "" + "'";
          }
          else {
            query[i] = "effectivedate<='" + effectiveDate + "' and featureid='" + feId[i] + "' and tsid='" + tsId[i] + "' and tenantname='" + this.auth.getTenantName() + "'";
          }
          console.log(query[i]);
          this.officialGeoDataSource = new TileWMS({
            url: this.url + this.workspaceName + '/wms/',
            params: {
              layers: this.workspaceName + ":" + this.officialLayerName,
              tiled: true,
              CQL_FILTER: query[i]
            },
            serverType: 'geoserver',
          });

          this.officialGeoDataLayer = new TileLayer({
            source: this.officialGeoDataSource,
          });
          this.layersToRemove.push(this.officialGeoDataLayer);
          that.map.addLayer(this.officialGeoDataLayer);
        }
      }
      ); 
    } */

    if (this.auth.getTenantName() != undefined || this.auth.getTenantName() != "") {
      layer = this.unId + "_" + "_Official_LayerGroup";
    }

    console.log("Official Layer : " + layer);

    this.officialGeoDataSource = new TileWMS({
      url: this.url + this.workspaceName + '/wms/',
      params: {
        layers: layer,
        tiled: true,
      },
      serverType: 'geoserver',
    });

    this.officialGeoDataLayer = new TileLayer({
      source: this.officialGeoDataSource,
    });
    this.layersToRemove.push(this.officialGeoDataLayer);
    that.map.addLayer(this.officialGeoDataLayer);


  }
  chooseLayers() {
    this.layerChoosed = !this.layerChoosed;
  }
  selectLayer(event) {
    const that = this;
    if (event) {
      this.isSelected = true;
      this.displayOfficialData(this.effectiveDate);
    }
    else {
      this.isSelected = false;
      if (this.isSelected == false) {
        console.log("Is selected false")
        //that.map.removeLayer(this.officialGeoDataLayer);

        let len = this.layersToRemove.length;
        for (var i = 0; i < len; i++) {
          that.map.removeLayer(this.layersToRemove[i]);
        }
      }
    }
  }
  // get layer name from feature name
  getLayerNamefromFeatureType(featureName: string) {
    return featureName.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`).substring(1);
  }
}
