import { Assignee } from "./Assignee";

export enum CurrentActivity {
  DATA_INPUT = "Data input",
  TEXT_EDITION = "Text edition",
  REVIEW = "Review section",
  DONE = "Done",
}

export class Item {
  id: string;
  name: string;
  currentActivity: string;
  assignee: Assignee;
  executionId: string;
  claimable?: boolean;
}
