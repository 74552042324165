<h3  mat-dialog-title>Cancel Feature</h3>
<div mat-dialog-actions>
   
      <button  mat-button class="example-btn-width" [mat-dialog-close]="data.id"  (click)="deleteItem(data.featureId,data.itemId)"
      style="cursor:pointer" cdkFocusInitial>
           Yes
      </button>

      <!-- <button mat-raised-button mat-button color="primary" class="example-btn-width" [mat-dialog-close]="data.id"  (click)="claim(data.id)"
      style="cursor:pointer">
           Claim
      </button> -->
    
      <button mat-button  class="example-btn-width" (click)="onNoClick()">No</button>

</div>
