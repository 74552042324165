import { environment } from './../../environments/environment';
import { AppAuthGuard } from './../login/services/app.authguard';
import { UsergroupListComponent } from './usergroup-list/usergroup-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataProviderComponent } from './data-provider/data-provider.component';
import { Layouts } from '../app.component';

const routes: Routes = [

  {
    path: 'provider', component: DataProviderComponent, canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Ais_Admin] }
  },

  {
    path: 'user-group/originator-id/:id',
    component: UsergroupListComponent,
    canActivate: [AppAuthGuard],
    data: { layout: Layouts.SecureLayout, roles: [environment.roles.role_Ais_Admin]}
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataProviderRoutingModule {
}
