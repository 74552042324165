import { GeoEditorModule } from './../geo-editor/geo-editor.module';
import { SharedModule } from './../shared/shared.module';
import { FileUploadModule } from 'ng2-file-upload';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoManagementRoutingModule } from './info-management-routing.module';
import { GroupListComponent } from './group-list/group-list.component';
import { AppMaterialModule } from '../app.material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatureListComponent } from './feature-list/feature-list.component';
import { FeatureSearchComponent } from './feature-search/feature-search.component';
import { TimeSliceListComponent } from './time-slice-list/time-slice-list.component';
import { InputFormComponent } from './input-form/input-form.component';
import { InputFormFieldComponent } from './input-form/input-form-field/input-form-field.component';
import { InputFormGroupComponent } from './input-form/input-form-group/input-form-group.component';
import { BrowseComponent } from './browse/browse.component';
import { FieldArrayComponent } from './input-form/field-array/field-array.component';
import { PropertySwitchComponent } from "./input-form/property-switch/property-switch.component";
import { TreeComponent } from './input-form/tree/tree.component';
import { NetworkSearchComponent } from './network-search/network-search.component';
import { TimelineComponent } from './timeline/timeline.component';
import { PropertyService } from './input-form/property.service';
import { CatalogSearchComponent } from './catalog-search/catalog-search.component';
import {  MatSelectModule } from '@angular/material/select';
// import { MatSelectFilterModule } from 'mat-select-filter';
import { MapViewDialogComponent } from './map-view-dialog/map-view-dialog.component';
import { LocationSearchComponent } from './location-search/location-search.component';
import { UploadDatasetDialogComponent } from './upload-dataset-dialog/upload-dataset-dialog.component';
import { FeatureSelectorModule } from '../feature-selector/feature-selector.module';
import { AipDatasetsComponent } from './aip-datasets/aip-datasets.component';
import { DataproviderDialogComponentComponent } from './dataprovider-dialog-component/dataprovider-dialog-component.component';
import { PortalModule } from '@angular/cdk/portal';
import { ChoiceFieldComponent } from './input-form/choice-field/choice-field.component';


@NgModule({
    imports: [
        CommonModule,
        InfoManagementRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        // MatSelectModule,
        // MatSelectFilterModule,
        FileUploadModule,
        FeatureSelectorModule,
        SharedModule,
        GeoEditorModule,
        PortalModule
    ],
    declarations: [
        GroupListComponent,
        FeatureListComponent,
        FeatureSearchComponent,
        TimeSliceListComponent,
        InputFormComponent,
        InputFormFieldComponent,
        InputFormGroupComponent,
        PropertySwitchComponent,
        BrowseComponent,
        FieldArrayComponent,
        TreeComponent,
        NetworkSearchComponent,
        TimelineComponent,
        CatalogSearchComponent,
        MapViewDialogComponent,
        LocationSearchComponent,
        UploadDatasetDialogComponent,
        AipDatasetsComponent,
        DataproviderDialogComponentComponent,
        ChoiceFieldComponent
    ],
    providers: [
        PropertyService
    ]
})
export class InfoManagementModule { }
