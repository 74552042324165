import { ErrorsModule } from "./errors/errors.module";
import { TokenInterceptor } from "./login/services/keycloak.httpclient";
import { NgModule, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppMaterialModule } from "./app.material.module";
import { AppRoutingModule } from "./app.routing.module";
import { AppComponent } from "./app.component";
import { SidenavService } from "./service/sidenav.service";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";

import { InfoManagementModule } from "./info-management/info-management.module";
import { LogSheetModule } from "./log-sheet/log-sheet.module";
import { UpdateNoticeModule } from "./update-notice/update-notice.module";
import { AixmProfileModule } from "./aixm-profile/aixm-profile.module";
import { PropertiesModule } from "./properties/properties.module";
import { LayoutModule } from "./layout/layout.module";
import { TitleService } from "./service/title.service";
import { TaskService } from "./overview/service/task.service";
import { DataProviderModule } from "./data-provider/data-provider.module";
import { InMemoryDataService } from "./service/in-memory-data.service";
import { KeycloakService, KeycloakAngularModule } from "keycloak-angular";
import { KeycloakInitializer } from "./login/services/keycloak.service";
import { UserDetailsService } from "./login/services/UserDetails.service";
import { ReviewComponent } from "./overview/review/review.component";
import { OverviewModule } from "./overview/overview.module";
import { ConfigService } from "./utils/config.service";
import { initializeApp } from "./utils/runtime.service";

import { LoaderService } from "./loader.service";
import { LoaderInterceptor } from "./loader.interceptor";
import { IcaoCatalogService } from "./utils/icao-catalog.service";
import { AboutModule } from "./about/about.module";
import { AccountManagementModule } from "./account-management/account-management.module";
import { environment } from "./../environments/environment";
import { GeoEditorModule } from "./geo-editor/geo-editor.module";
import { DatastoreModule } from "./datastore/datastore.module";
import { QualityModule } from "./quality/quality.module";
import { UserManagementModule } from "./user-management/user-management.module";
import { AixmValidatorModule } from "./aixm-validator/aixm-validator.module";

import { NgxPiwikProModule } from "@piwikpro/ngx-piwik-pro";
import { HomePageModule } from "./home-page/home-page.module";
import { ErrorsHandler } from "./errors/errors-handler/errors-handler";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";

let TestApiModule = [];
if (!environment.production) {
  TestApiModule.push(
    HttpClientInMemoryWebApiModule.forFeature(InMemoryDataService, {
      dataEncapsulation: false,
      passThruUnknownUrl: true,
    })
  );
}

@NgModule({
  declarations: [AppComponent, ReviewComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    TestApiModule,
    ReactiveFormsModule,
    InfoManagementModule,
    LogSheetModule,
    AixmProfileModule,
    UpdateNoticeModule,
    PropertiesModule,
    DataProviderModule,
    AppRoutingModule,
    OverviewModule,
    LayoutModule,
    KeycloakAngularModule,
    ErrorsModule,
    AboutModule,
    AccountManagementModule,
    GeoEditorModule,
    DatastoreModule,
    QualityModule,
    UserManagementModule,
    AixmValidatorModule,
    HomePageModule,
    NgxPiwikProModule.forRoot("8f991b4d-5f9d-4fe9-bf02-4da8ee2eccd8", "https://nilacandi.containers.piwik.pro"),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: KeycloakInitializer,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [ConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [IcaoCatalogService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    UserDetailsService,
    SidenavService,
    TitleService,
    TaskService,
    ConfigService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline"} },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
