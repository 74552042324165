import { Component, OnInit, Inject} from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Router} from '@angular/router';

export interface DialogData {
  result: string;
}
@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.css']
})
export class SuccessDialogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router) { }

  ngOnInit() {
  }

  Success():void{
    this.dialogRef.close();
    this.router.navigate(['/quality/projects']);
  }

}
