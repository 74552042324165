<app-header></app-header>
<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" opened="true">
    <app-navigation></app-navigation>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="min-height: calc(100vh - 64px); background-color: white;">
      <router-outlet>
        <app-loader></app-loader>
      </router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>