<div class="container">
    <mat-toolbar>
        <span>
            <h3> New User Properties</h3>
        </span>
    </mat-toolbar>
    <mat-card>
        <mat-card-content>
        <form name="newUserPropertiesForm" [formGroup]="form" class="example-form"
        (ngSubmit)="addUserProperties(form.value)">
                
            <label class="space label">Module Name </label>                 
              <mat-form-field class="space">
                <input matInput  name="modulename" formControlName="modulename" 
                [formGroup]="form"  required>
              </mat-form-field>
         
          <br/>
          <br/>
            <label class="space label"> Property </label>                 
            <mat-form-field class="space1">
                <input matInput  name="property" formControlName="property" 
                [formGroup]="form" required>
            </mat-form-field>
     
            <br/>
            <br/>
    
            <label class="space label"> Property Value </label>                 
            <mat-form-field class="space2">
                <input matInput  name="propertyvalue" formControlName="propertyvalue"                     
                [formGroup]="form" required>
            </mat-form-field>

              <br/>
              <br/>
              <br/>

                <mat-card-actions class="buttonspace">
                  <button type="submit" mat-raised-button mat-button color="primary" [disabled]="!form.valid || form.pristine"  
                  class="example-btn-width">Create</button>
                  <button mat-raised-button mat-button color="primary" type="reset" (click)="clear()">
                    Reset
                  </button>
                </mat-card-actions>               
        
        </form>         
        </mat-card-content>     
    </mat-card>
</div>