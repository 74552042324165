import { Property, MultipleProperty } from "./property";
import { PropertyGroup } from "./property-group";

/**
 * Represents a simple data property, such as a designator in AIXM, which has a value.
 */
export class PropertyField<T> extends Property {
  propertyType = 'field';
  value: T;
  minLength: number;
  maxLength: number;
  minExclusive?: number;
  maxExclusive?: number;
  minInclusive?: number;
  maxInclusive?: number;
  pattern?: string;
  controlType: string;
  nilReason: string;
  featureId: string;
  unId?:string;

  constructor(options: {
    key: string,
    label?: string,
    hint?: string,
    order?: number,
    minOccurs?: number,
    maxOccurs?: number,
    value?: T,
    minLength?: number,
    maxLength?: number,
    minExclusive?: number,
    maxExclusive?: number,
    minInclusive?: number,
    maxInclusive?: number,
    pattern?: string,
    nil?: boolean,
    nilReason?: string,
    annotations?: MultipleProperty,
    controlType?: string,
    modified: boolean,
    featureId?: string,
    unId?:string;
  }) {
    super(options);
    this.value = options.value;
    this.maxLength = options.maxLength;
    this.maxLength = options.maxLength || 10000;
    this.minExclusive = options.minExclusive;
    this.maxExclusive = options.maxExclusive;
    this.minInclusive = options.minInclusive;
    this.maxInclusive = options.maxInclusive;
    this.pattern = options.pattern;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.nilReason = options.nilReason || '';
    this.featureId = options.featureId;
    this.unId = options.unId;
  }
}


