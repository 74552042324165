<div mat-dialog-content>
    <div id="map" class="map">
       <div class="rotate-north ol-control" id="layers">
         <button mat-icon-button (click)="chooseLayers()">
            <mat-icon>layers</mat-icon>
         </button>
         <mat-selection-list *ngIf='layerChoosed'>
            <mat-list-option class="option" #option (click)="selectLayer(option.selected)" [selected]="isSelected">
               Effective data
            </mat-list-option>
          </mat-selection-list>
       </div>
    </div>    
</div>