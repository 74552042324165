import { AixmFeatureDTO } from './model/AixmFeatureDTO';
import { of } from 'rxjs';
import { FeatureGroup } from './model/feature-group';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utils/config.service';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureSelectorService {

  private apiServer = ConfigService.settings.apiServer;
  private featureGroupUrl = this.apiServer.url + '/aixm/featureType/list';
  private featureUrl = this.apiServer.url + '/aixm/feature/list';
  private geoborderSegmentsUrl = this.apiServer.url + '/aixm/geoborderSegments';
  private pointUrl = this.apiServer.url + '/aixm/point';
  private featureTypeUrl = this.apiServer.url + '/aixm/featureType';
  
  constructor(private http: HttpClient) { }

  /** GET feature types  from the server */
  getTypes(): Observable<FeatureGroup[]> {
    return this.http.get<FeatureGroup[]>(this.featureGroupUrl)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getTypes', error))
      ));
  }

  /** GET feature list by type from the server */
  getFeaturesByType(group: string, unId: string, isRef: boolean, isUn: boolean): Observable<AixmFeatureDTO[]> {
    const url = `${this.featureUrl}/${group}/${unId}/${isRef}/${isUn}`;
    return this.http.get<AixmFeatureDTO[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getFeaturesByType', error))
      ));
  }

  /** Get referenced GeoBorder or point */
  getReferencedGmlFeature(referenceType: string, featureId: string, unId: string, isUn: boolean): Observable<AixmFeatureDTO> {
    const url = (referenceType == 'geoBorder') ? `${this.geoborderSegmentsUrl}/${featureId}/${unId}/${isUn}` : `${this.pointUrl}/${featureId}/${unId}/${isUn}`;
    return this.http.get<AixmFeatureDTO>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getReferencedGmlFeature', error))
      ));
  }

  /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  
  getFeatureTypeById(featureId: string, unId: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    const url = `${this.featureTypeUrl}/${featureId}/${unId}`;
    return this.http.get<string>(url,requestOptions)
      .pipe(tap((result: string) => console.log(result),
        error => catchError(this.handleError('getFeatureTypeById', error))
      ));
  }  

  getFeatureById(parentFeatureId, featureId: string, unId: string): Observable<any> {
    const url = `${this.featureUrl}/${parentFeatureId}/${featureId}/${unId}`;
    return this.http.get<any>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getFeatureById', error))
      ));
  }
}
