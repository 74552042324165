import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {  MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import {
  ActivatedRoute,
  Router
} from '@angular/router';

import { UserProperties } from './../model/user-properties.model';
import { PropertiesService } from './../services/properties.service';

@Component({
  selector: 'app-user-properties',
  templateUrl: './user-properties.component.html',
  styleUrls: ['./user-properties.component.css']
})
export class UserPropertiesComponent implements OnInit {
  userPropertieslist: UserProperties[] = [];
  readonly:boolean;
  saveBtn: MatButton;

  displayedColumns = ['modulename', 'property', 'propertyvalue'];
  userPropertieslist_dataSource = new MatTableDataSource<UserProperties>(this.userPropertieslist);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private propertiesService: PropertiesService) { }

  ngAfterViewInit() {
    this.userPropertieslist_dataSource.sort = this.sort;
    this.userPropertieslist_dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.getUserProperties()
    this.readonly=true;
  }

  getUserProperties(): void {
    this.propertiesService.getUserProperties()
      .subscribe(userproperties => this.userPropertieslist_dataSource.data = userproperties);
  }
  userEdit():void{
    this.readonly=!this.readonly;    
  }
  save():void{
    this.readonly=!this.readonly;
  }
}
