import { UserDetailsService } from './../../login/services/UserDetails.service';
import { LogSheetSummaryDTO } from './../model/logsheetSummaryDTO';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import {  MatDialog,  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogsheetService } from '../service/logsheet.service';
import { LogEntry } from '../model/logentry';

@Component({
  selector: 'app-edit-le-dialog',
  templateUrl: './edit-le-dialog.component.html',
  styleUrls: ['./edit-le-dialog.component.css']
})
export class EditLeDialogComponent implements OnInit {
  private logentries: LogEntry[] = [];
  private logsheet: LogSheetSummaryDTO = new LogSheetSummaryDTO();
  private oldEntryId: number;
  private desp: string;
    constructor(private logSheetService: LogsheetService, private userDetailsService: UserDetailsService,
      public dialogRef: MatDialogRef<EditLeDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
        this.logsheet = data.Logsheet;
        this.oldEntryId = data.id;
        this.desp = data.desp;
      }
    
    onAdd = new EventEmitter();

    ngOnInit() {
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    edit(desp: string): void {
      console.log("In component: " + desp + this.logsheet.id);
      // let ref = 'Log entry #' + this.oldEntryId;
      this.logSheetService.correctLogEntry({logSheetId: this.logsheet.id, creationDate: new Date(), 
        author: this.userDetailsService.getUser().username, description: desp,  manual: true, entryReference: this.oldEntryId} as LogEntry)
          .subscribe(result => {
            console.log("result: " + result);
            this.onAdd.emit(result);
          });
    }

    // strike(field: string, editValue: string, el: any) {
    //   let idx = this.logentries_dataSource.data.findIndex(ele => id == ele.id);
    //   console.log(this.logentries_dataSource.data[id]);
    //   this.logentries_dataSource.data[id].author.strike;
    //   this.logentries_dataSource.data[id].date.strike;
    //   this.logentries_dataSource.data[id].desp.strike;
    //   this.logentries_dataSource.data[id].ref.strike;

    //   }

}
