import { QualityProjectService } from './../service/quality-project.service';
import { DialogData } from './../../user-management/delete-dialog/delete-dialog.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { LogOfAssignment } from '../model/LogOfAssignment';
import { DatePipe } from '@angular/common';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-log-dialog-component',
  templateUrl: './log-dialog-component.component.html',
  styleUrls: ['./log-dialog-component.component.css']
})
export class LogDialogComponentComponent implements OnInit {
  datePipe = new DatePipe("en-US");

  logOfAssignment: LogOfAssignment[] = [];

  displayedColumns = ['username', 'taskname', 'action', 'date'];
  log_dataSource = new MatTableDataSource<LogOfAssignment>(this.logOfAssignment);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
    * Set the sort after the view init since this component will
    * be able to query its view for the initialized sort.
    */
  ngAfterViewInit() {
    this.log_dataSource.sort = this.sort;
    this.log_dataSource.paginator = this.paginator;
  }

  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.log_dataSource.filter = filterValue;
  }

  constructor(private qaService: QualityProjectService, public dialogRef: MatDialogRef<LogDialogComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public executionId: string) { }

  ngOnInit() {
    this.qaService.getLogOfAssignment(this.executionId).subscribe(result => this.log_dataSource.data = result);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
