import { Component,
         OnInit,
         ViewChild } from '@angular/core';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router'; 

import { UpdateNoticeService } from './../service/updatenotice.service';
import { UpdateNotice } from '../model/update-notice.model';
import { UpdateNoticeSummaryDTO } from '../model/UpdateNoticeSummaryDTO';

@Component({
selector: 'app-updatenotice-list-providers',
templateUrl: './updatenotice-list-providers.component.html',
styleUrls: ['./updatenotice-list-providers.component.css']
})
export class UpdatenoticeListProvidersComponent implements OnInit {

updatenoticelist: UpdateNoticeSummaryDTO[] = [];
updatenotice: UpdateNotice;

displayedColumns = ['Status', 'Title', 'Due date', 'Effective date', 'Temporality','Provider'];
updatenoticelist_dataSource = new MatTableDataSource<UpdateNoticeSummaryDTO>(this.updatenoticelist);

 
 //sorting
 @ViewChild(MatSort) sort: MatSort;
 //pagination
 @ViewChild(MatPaginator) paginator: MatPaginator;

constructor(private route: ActivatedRoute, private router: Router, private updatenoticeService : UpdateNoticeService) { }

ngOnInit() {
  this.getUpdateNoticeList();
}

getUpdateNoticeList() : void {
 this.updatenoticeService.getUpdateNoticeList().
 subscribe ( updatenoticelist => this.updatenoticelist_dataSource.data = updatenoticelist);
}

}
