import { Component, OnInit } from '@angular/core';
import { UserDetailsService } from '../login/services/UserDetails.service';
import { ConfigService } from '../utils/config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  private role = ConfigService.settings.role;

  constructor(private router: Router,
    private userDetailsService: UserDetailsService) { }

  ngOnInit(): void {
    if (this.userDetailsService.hasRole(this.role.role_Validator_Module)) {
      this.router.navigate(['aixm-validator']);
    }
    else {
      this.router.navigate(['overview']);
    }
  }

}
