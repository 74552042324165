<h3 mat-dialog-title>Delete Points</h3>

<mat-form-field appearance="fill">
    <input matInput placeholder="number of points to delete" #input>
</mat-form-field>

<div mat-dialog-actions>
    <button mat-raised-button mat-button color="primary" [mat-dialog-close]="data"
        matTooltip="delete {{input.value}} points" (click)="deletePoints(input.value)">Delete</button>
    <button mat-raised-button mat-button color="primary" [mat-dialog-close]="data" matTooltip="delete all points"
        (click)="deleteAllPoints()">Delete All Point</button>
    <button mat-raised-button mat-button color="primary" (click)="onNoClick()">Cancel</button>
</div>