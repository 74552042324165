import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import { AixmValidatorService } from '../service/aixm-validator.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {  MatPaginator } from '@angular/material/paginator';
import FileSaver from 'file-saver';

@Component({
  selector: 'app-validation-history',
  templateUrl: './validation-history.component.html',
  styleUrls: ['./validation-history.component.css']
})
export class ValidationHistoryComponent implements OnInit {

  // Datasoure for table
  validationHistory_DataSource = new MatTableDataSource<any>();
  displayedColumns = ['date', 'result', 'dataset', 'version', 'profiles', 'businessRules', 'certificate'];
  // Sorting
  @ViewChild(MatSort) sort: MatSort;
  // Pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private http: HttpClient,
    private aixmValidatorService: AixmValidatorService,
    public router: Router) { }

  ngOnInit(): void {
    this.getValidationHistory();
  }

  ngAfterViewInit() {
    this.validationHistory_DataSource.sort = this.sort;
    this.validationHistory_DataSource.paginator = this.paginator;
  }

  // Filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.validationHistory_DataSource.filter = filterValue;
  }

  redirectToValidationReport(reportFileName: string) {
    this.router.navigate(['validation-report', reportFileName]);
  }

  getValidationHistory() {
    this.aixmValidatorService.getValidationHistory().subscribe(
      (response) => {
        if (response instanceof Array) {
          this.validationHistory_DataSource = new MatTableDataSource<any>(response);
          this.validationHistory_DataSource.paginator = this.paginator;
        }
      });
  }

  getCertificate(certificateFileName: string) {
    this.aixmValidatorService.getCertificate(certificateFileName).subscribe(
      (response: ArrayBuffer) => {
        const blob: any = new Blob([response], { type: 'blob' });
        FileSaver.saveAs(blob, certificateFileName);
      }
    );
  }

  transformValidationReportStatus(status: string) {
    if (status.toUpperCase() === "VALID") {
      status = "Valid";
    } else if (status.toUpperCase() === "INVALID") {
      status = "Invalid";
    } else if (status.toUpperCase() === "FAILED") {
      status = "Failed";
    } else if (status.toUpperCase() === "PENDING") {
      status = "Pending…";
    }
    return status;
  }

}
