import { GmlInterpolation } from "./GmlInterpolation";
import { GeoPoint } from "./GeoPoint";
import { Feature } from 'ol';
/**
 * A simplified representation of a GML curve segment, encoded as a GeoJSON feature.
 * Special case: a segment instance is used to represent the last point in a list of points. Such segment
 * has only a start point, which represents the end point of the real last segment of the curve.
 */
export class GmlSegment extends Feature {
  interpolation?: GmlInterpolation;
  startPoint: GeoPoint;
  endPoint?: GeoPoint;

  constructor(interpolation?: GmlInterpolation, startPoint?: GeoPoint, endPoint?: GeoPoint) {
    super();
    this.interpolation = interpolation ? interpolation : GmlInterpolation.GREAT_CIRCLE;
    this.startPoint = startPoint;
    this.endPoint = endPoint;
  }

  static fromFeature(feature: Feature): GmlSegment {
    return new GmlSegment((<any>GmlInterpolation)[feature.get('interpolation')], new GeoPoint(feature.get('startPoint')),
      new GeoPoint(feature.get('endPoint')));
  }

  /**
   * Change or set the start point, and adapt the segment's geometry.
   * @param point The new end point.
   */
  setStartPoint(point: GeoPoint) {
    this.startPoint = point;
    // TODO recalculate geometry
  }

  /**
   * Change or set the end point, and adapt the segment's geometry.
   * @param point The new end point.
   */
  setEndPoint(point: GeoPoint) {
    this.endPoint = point;
    // TODO recalculate geometry
  }
}
