<!-- TODO Show a map with each point of the GeoBorder marked and let the user select a start and end point -->

<latlong [coordinates]="startPointCoordinates" (coordinatesChange)="setStartPointCoordinates($event)"
  [isHighlight]="isHighlight" [isInvalid]="isInvalid" [editable]="false" [isGeoBorderPoint]="true">
</latlong>

<div>
  <span class="spacing">Along:</span>
  <!-- Selector for GeoBorder -->
  <mat-form-field appearance="legacy">
    <mat-label>{{borderType ? borderType : "(please select a GeoBorder)"}}</mat-label>

    <input matInput disabled type="text" value={{borderName}} #xlink_title>

    <button type="button" mat-icon-button matPrefix matTooltip="Select a point on the GeoBorder">
      <mat-icon color="primary" aria-label="Description">info</mat-icon>
    </button>
    <button type="button" mat-icon-button matSuffix matTooltip="Select a feature" (click)="showFeaturesSelector()">
      <mat-icon color="primary" aria-label="Select a feature">insert_link</mat-icon>
    </button>
  </mat-form-field>

  <!-- No annotation possible on GeoBorder reference -->
  <div class="margin-left">
    <app-feature-type-search-component *ngIf="showFeatureList" [isReference]="true" referenceType="geoBorder"
      (referencePropertyValuePicked)="setToReferencePropertyValue($event)" (geoDataPicked)="showPoints($event)">
    </app-feature-type-search-component>

    <mat-form-field *ngIf="pointList.length > 0 && showFeatureList">
      <mat-select placeholder="Points on GeoBorder" (selectionChange)="updateStartPointCoordinates($event.value)">
        <mat-select-filter [array]="pointList" (filteredReturn)="pointListFilter = $event"></mat-select-filter>
        <mat-option *ngFor="let point of pointListFilter" [value]="point">
          {{point}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>