// import { MatSelectFilterModule } from 'mat-select-filter';
import { AppMaterialModule } from "./../app.material.module";
import { GeotestComponent } from "./geotest/geotest.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GeoEditorComponent } from "./geo-editor/geo-editor.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LatlongComponent } from "./latlong/latlong.component";
import { PointReferenceComponent } from "./point-reference/point-reference.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GeoPointListComponent } from "./geo-point-list/geo-point-list.component";
import { GeoCircleComponent } from "./geo-circle/geo-circle.component";
import { GeoArcByCenterComponent } from "./geo-arc-by-center/geo-arc-by-center.component";
import { GeoPointComponent } from "./geo-point/geo-point.component";
import { GeoSegmentComponent } from "./geo-segment/geo-segment.component";
import { GeoBorderPointComponent } from "./geo-border-point/geo-border-point.component";
import { GeoSegmentListComponent } from "./geo-segment-list/geo-segment-list.component";
import { GeoEditorRoutingModule } from "./geo-editor-routing.module";
import {  MatSelectModule } from "@angular/material/select";
import { FeatureSelectorModule } from "../feature-selector/feature-selector.module";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { DeletePointsConfirmationDialogComponent } from "./delete-points-confirmation-dialog/delete-points-confirmation-dialog.component";

@NgModule({
  declarations: [
    GeoEditorComponent,
    LatlongComponent,
    PointReferenceComponent,
    GeoPointListComponent,
    GeoCircleComponent,
    GeoArcByCenterComponent,
    GeoPointComponent,
    GeoSegmentComponent,
    GeoBorderPointComponent,
    GeoSegmentListComponent,
    GeotestComponent,
    ConfirmationDialogComponent,
    DeletePointsConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    GeoEditorRoutingModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FeatureSelectorModule,
  ],
  exports: [
    GeoEditorComponent,
    LatlongComponent,
    PointReferenceComponent,
    GeoPointListComponent,
    GeoCircleComponent,
    GeoArcByCenterComponent,
    GeoPointComponent,
    GeoSegmentComponent,
    GeoBorderPointComponent,
    GeoSegmentListComponent,
  ],
  providers: [],
})
export class GeoEditorModule {}
