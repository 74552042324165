import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverViewComponent } from './overview.component';
import { Layouts } from '../app.component';


const routes: Routes = [
  
  { path: 'overview', component: OverViewComponent,
    data: { layout: Layouts.SecureLayout }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OverviewRoutingModule { }