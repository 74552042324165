import { ReferencePropertyField } from './model/reference-property-field';
import { TextPropertyField, TranslatedNotePropertyField } from './model/text-property-field';
import { PropertyService } from './property.service';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import { Property, MultipleProperty, LazyLoadedProperty } from "./model/property";
import { PropertyGroup } from "./model/property-group";
import { PropertyField } from './model/property-field';
import { WithUnitPropertyField } from './model/with-unit-property-field';
import { LatlongService } from '../../geo-editor/latlong.service';

@Injectable()
export class PropertyFieldControlService {
  private resetFormGroup: boolean = false;

  constructor(private propertyService: PropertyService, private latlongService: LatlongService) { }

  toFormGroup(properties: Property[], reset?:boolean) {
    this.resetFormGroup = reset;
    
    let group: { [key: string]: AbstractControl; } = {};

    properties.forEach(property => {
      this.addPropertyToGroup(property, group, '');
    });
    return new UntypedFormGroup(group);
  }

  // Create a form control for a property and add it to the given parentGroup
  addPropertyToGroup(property: Property, parentGroup: { [key: string]: AbstractControl; }, parentKey: string) {
    // first the property itself
    if (!(property instanceof LazyLoadedProperty)) {
      parentGroup[property.key] = this.property2Control(property);
    }
    // then annotations to the property
    if (property.annotations) {
      parentGroup[property.key + '-annotations'] = this.property2Control(property.annotations);
    }

    //console.log("addPropertyToGroup:" +  JSON.stringify(new FormGroup(parentGroup).getRawValue()));
  }

  // Build a FormControl, FormGroup or FormArray for the given property, depending on its class
  property2Control(property: Property): AbstractControl {
    if (property instanceof PropertyField && !(property instanceof WithUnitPropertyField) && !(property instanceof TranslatedNotePropertyField)
      && !(property instanceof ReferencePropertyField)) {
      // simple property: build a FormControl
      let propertyField: PropertyField<any> = property;
      let formArray: UntypedFormArray;
      let nilReasonFormControl = new UntypedFormControl(property.nilReason == '' || this.resetFormGroup ? 'missing' : property.nilReason || '');

      if (propertyField.key == 'gml:pos_1') {
        if (property.value != undefined && property.value != "") {
          let latitude = property.value.split(' ')[0];
          let longitude = property.value.split(' ')[1];
          let coordinate = this.latlongService.convertDMS(latitude, longitude);
          let coordinateControl = new UntypedFormControl(!this.resetFormGroup ? coordinate : '')
          formArray = new UntypedFormArray([coordinateControl, nilReasonFormControl]);
          console.log("Form Array " + formArray.value);
        }
        else {
          formArray = new UntypedFormArray([this.propertyField2FormControl(propertyField), nilReasonFormControl]);
        }
      }
      else {
        formArray = new UntypedFormArray([this.propertyField2FormControl(propertyField), nilReasonFormControl]);
      }

      if (this.propertyField2FormControl(propertyField).dirty == true) {
        formArray.markAsDirty();
      }
      return formArray;
    }
    // reference property: build a form array of featureId, featureName and nil reason
    if (property instanceof ReferencePropertyField) {
      let referenceProperty: ReferencePropertyField = property;
      let formArray: UntypedFormArray;
      let referencePropertyFormControl = new UntypedFormControl(!this.resetFormGroup ? property.featureId : '');
      let nilReasonFormControl = new UntypedFormControl(property.nilReason == '' || this.resetFormGroup ? 'missing' : property.nilReason || '');
      let referencePropertyNameControl = this.propertyField2FormControl(referenceProperty);
      formArray = new UntypedFormArray([referencePropertyNameControl, nilReasonFormControl, referencePropertyFormControl]);

      if (referencePropertyNameControl.dirty == true) {
        formArray.markAsDirty();
      }
      return formArray;
    }
    else if (property instanceof WithUnitPropertyField) {
      let withUnitProperty: WithUnitPropertyField = property;
      // let unitFormControl = (withUnitProperty.minOccurs == 1) ?
      //   new FormControl(withUnitProperty.unit || '', Validators.required)
      //   : new FormControl(withUnitProperty.unit || '');
      let formArray: UntypedFormArray;
      let unitFormControl = new UntypedFormControl(this.resetFormGroup ? '' : withUnitProperty.unit || '');
      let nilReasonFormControl = new UntypedFormControl(property.nilReason == '' || this.resetFormGroup ? 'missing' : property.nilReason || '');
      formArray = new UntypedFormArray([this.propertyField2FormControl(withUnitProperty), unitFormControl, nilReasonFormControl]);

      if (this.propertyField2FormControl(withUnitProperty).dirty == true) {
        formArray.markAsDirty();
      }
      return formArray;
    }
    else if (property instanceof TranslatedNotePropertyField) {
      let translatedNoteProperty: TranslatedNotePropertyField = property;
      let formArray: UntypedFormArray;
      let noteFormControl = new UntypedFormControl(this.resetFormGroup ? '' : translatedNoteProperty.lang || '');
      let nilReasonFormControl = new UntypedFormControl(property.nilReason == '' || this.resetFormGroup ? 'missing' : property.nilReason || '');
      formArray = new UntypedFormArray([this.propertyField2FormControl(translatedNoteProperty), noteFormControl, nilReasonFormControl]);

      if (this.propertyField2FormControl(translatedNoteProperty).dirty == true) {
        formArray.markAsDirty();
      }
      return formArray;
    }
    else if (property instanceof PropertyGroup) {
      // group of named properties: build a FormGroup
      let propertyGroup: PropertyGroup = property;

      let formGroup: UntypedFormGroup;
      formGroup = this.toFormGroup(propertyGroup.properties, this.resetFormGroup);
      // Annotations of Property Group itself
      if (propertyGroup.annotations) {
        // formGroup[propertyGroup.key + '-annotations'] = this.property2Control(propertyGroup.annotations);
        formGroup.addControl('0-annotations', this.property2Control(propertyGroup.annotations));
        //console.log("Annotations of Property Group itself:" + formGroup[propertyGroup.key+ '-annotations'].value.toSource());
      }

      Object.keys(formGroup.controls)
        .forEach(key => {
          let currentControl = formGroup.controls[key];
          if (currentControl.dirty == true) {
            formGroup.markAsDirty();
          }
        });

      return formGroup;
    } else if (property instanceof MultipleProperty) {
      // array of properties: build a FormArray
      const multipleProperty: MultipleProperty = property;

      const controls: AbstractControl[] = [];
      multipleProperty.properties.forEach(property => {
        if (!(property instanceof LazyLoadedProperty)) {
          controls.push(this.property2Control(property));
        }
      });

      // Annotations of multipleProperty itself
      if (multipleProperty.annotations) {
        controls.push(this.property2Control(multipleProperty.annotations));
        //console.log("Annotations of multipleProperty itself:" + controls[controls.length-1].value.toSource());
      }

      let formArray: UntypedFormArray;
      formArray = new UntypedFormArray(controls);

      controls.forEach(control => {
        if (control.dirty == true) {
          formArray.markAsDirty();
        }
      });

      return formArray;
    }
    else {
      throw new TypeError('Expected PropertyField, MultipleProperty or PropertyGroup');
    }
  }

  private propertyField2FormControl(propertyField: PropertyField<any>) {
    let formControl;

    // formControl = (propertyField.type == 1) ?
    //   new FormControl(propertyField.nilReason || '', Validators.required)
    //   : new FormControl(propertyField.nilReason || '');

    formControl = new UntypedFormControl(this.resetFormGroup ? '' : propertyField.value || '', [Validators.minLength(propertyField.minLength), Validators.maxLength(propertyField.maxLength)
      , Validators.min(((propertyField instanceof TextPropertyField || propertyField instanceof WithUnitPropertyField) && propertyField.type == "unsignedInt"
        && !propertyField.minInclusive) ? 0 : propertyField.minInclusive),
    Validators.max(((propertyField instanceof TextPropertyField || propertyField instanceof WithUnitPropertyField) && propertyField.type == "unsignedInt"
      && !propertyField.maxInclusive) ? 4294967295 : propertyField.maxInclusive)
      , Validators.min(propertyField.minExclusive + 1), Validators.max(propertyField.maxExclusive - 1)
      , Validators.pattern(propertyField.pattern)]);

    // mark the form control as dirty if the property is modified
    if (propertyField.modified) {
      formControl.markAsDirty();
    }

    return formControl;
  }
}

