import { PropertyGroup } from "./property-group";

/** Represents a generic data property such as an AIXM property.
 * The value is not defined here because a property may be complex (it contains child propeties).
 * Simple properties are represented by PropertyField, complex properties by PropertyGroup.
 * A property that may have annotations but has none must have an ampty PropertyGroup.
 * A property that may not have any annotation (such as AIXM annotations themselves) must have annotations
 * set to null.
 */
export class Property {
  propertyType = 'abstract';
  key: string;
  label: string;
  hint: string;
  description: string;
  order: number;
  minOccurs: number;
  maxOccurs: number;
  nil: boolean;
  nilReason: string;
  annotations?: MultipleProperty;
  properties: Property[];
  modified: boolean;
  isNewProperty: boolean;
  id: string;
  propOrderIndex: number;
  choiceList?: any;
  choices?:any[]

  constructor(options: {
    key: string,
    label?: string,
    hint?: string,
    description?: string,
    id?: string,
    order?: number,
    minOccurs?: number,
    maxOccurs?: number,
    nil?: boolean,
    nilReason?: string,
    annotations?: MultipleProperty,
    modified?: boolean,
    isNewProperty?: boolean,
    propOrderIndex?: number
  }) {
    this.key = options.key;
    this.label = options.label || options.key;
    this.hint = options.hint || '';
    this.description = options.description || '';
    this.order = options.order === undefined ? 1 : options.order;
    this.minOccurs = options.minOccurs || 1; // by default, at least 1
    this.maxOccurs = options.maxOccurs || 1; // by default, maximum 1
    this.nil = options.nil || false;
    this.nilReason = options.nilReason || '';
    this.annotations = options.annotations || undefined;
    this.modified = options.modified || undefined;
    this.isNewProperty = options.isNewProperty || false;
    this.id = options["id"];
    this.propOrderIndex = options.propOrderIndex === undefined ? 0 : options.propOrderIndex;
  }

  getPropertyType(): string {
    return this.propertyType;
  }
}

export class MultipleProperty extends Property {
  propertyType = 'multiple';
  template: Function;
  group: string;
  valueExisted?: boolean;

  constructor(options: {
    key: string,
    label?: string,
    order?: number,
    minOccurs?: number,
    maxOccurs?: number,
    annotations?: MultipleProperty,
    properties: Property[],
    template?: Function,
    group: string,
    valueExisted?: boolean
  }) {
    super(options);
    this.properties = options.properties || [];
    this.group = options['group'];
    this.valueExisted = options.valueExisted || undefined;
  }
}

export class LazyLoadedProperty extends Property {
  propertyType = 'lazyLoaded';
  id: string;
  group: string;

  constructor(options: {
    key: string,
    id: string,
    group: string
  }) {
    super(options);
    this.id = options['id'];
    this.group = options['group'];
  }
}