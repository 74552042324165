<div class="container">
  <mat-toolbar>
    <span>
      <h3> List of Data Providers </h3>
    </span>
  </mat-toolbar>
  <mat-card>
    <mat-card-content>
      <mat-table #table [dataSource]="dataproviderlist_dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.name}}
          </mat-cell>
        </ng-container>
        
         <!-- Actions Column -->
         <ng-container matColumnDef="linked">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Linked</mat-header-cell>
          <mat-cell *matCellDef="let row">
             <i class="material-icons" *ngIf="!row.linked" 
             routerLink='/user-group/originator-id/{{row.identifier.value}}'
             style="cursor:pointer">link</i> 
             <i class="material-icons" *ngIf="row.linked" (click)="unLink(row.identifier.value);" 
             style="cursor:pointer">link_off</i>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dataprovider">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Dataprovider </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-icon [ngClass]="{'mat-primary': row.dataprovider, 'not-data-provider': !row.dataprovider}">
              {{row.dataprovider ? 'check_circle' : 'cancel'}}
            </mat-icon>
          </mat-cell>
        </ng-container>
       

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    

    <mat-card-footer>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-footer>
   
  </mat-card-content>
  </mat-card>
</div>