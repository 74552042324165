import { DataProviderService } from './../service/data-provider.service';
import {  MatDialog } from '@angular/material/dialog';
import {  MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { LinkdialogComponent } from '../linkdialog/linkdialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usergroup-list',
  templateUrl: './usergroup-list.component.html',
  styleUrls: ['./usergroup-list.component.css']
})
export class UsergroupListComponent implements OnInit {
  private groupNames: string[]=[];

  displayedColumns = ['Name', 'Actions'];
  usergrouplist_dataSource = new MatTableDataSource<string>(this.groupNames);
  
  constructor(private DataProviderService: DataProviderService,public dialog: MatDialog,private route: ActivatedRoute) { }
  
  public ngOnInit(): void {
    this.getuserGroups();
}

getuserGroups(): void {
  this.DataProviderService.getuserGroups()
  .subscribe(groupNames => this.usergrouplist_dataSource.data = groupNames);
}

openLinkDialog(name:  String): void {
  const id = this.route.snapshot.params['id'];
  let dialogRef = this.dialog.open(LinkdialogComponent, {
    width: '500px',
    data: { id: id,name: name }
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    console.log('After the dialog was closed:'+ result);
    //this.result = result,
    //this.ngOnInit();

  });
}

}
