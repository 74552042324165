import { UserDetailsService } from './../../login/services/UserDetails.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { UpdateNoticeService } from './../service/updatenotice.service';
import { UpdateNoticeSummaryDTO } from './../model/UpdateNoticeSummaryDTO';
import { ConfigService } from '../../utils/config.service';

@Component({
  selector: 'app-updatenotice-list',
  templateUrl: './updatenotice-list.component.html',
  styleUrls: ['./updatenotice-list.component.css']
})
export class UpdatenoticeListComponent implements OnInit {
  isDp: boolean = ConfigService.settings.isDataProvider;
  isUnReader: boolean = ConfigService.settings.isUnReader;
  isAis: boolean = ConfigService.settings.isAis;

  public userName: string;
  public groupName: string;

  updatenoticelist: UpdateNoticeSummaryDTO[] = [];
  closedUnList: UpdateNoticeSummaryDTO[] = [];
  updatenotice: UpdateNoticeSummaryDTO;

  displayedColumns = ['status', 'name', 'cancelDate', 'effectiveDate', 'temporality'];
  //displayedColumns = ['Title', 'Cancel date', 'Effective date', 'Temporality'];
  updatenoticelist_dataSource = new MatTableDataSource<UpdateNoticeSummaryDTO>(this.updatenoticelist);
  closed_updatenoticelist_dataSource = new MatTableDataSource<UpdateNoticeSummaryDTO>(this.closedUnList);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.updatenoticelist_dataSource.sort = this.sort;
    this.updatenoticelist_dataSource.paginator = this.paginator;
  }

  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.updatenoticelist_dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute, private router: Router,
    private updatenoticeService: UpdateNoticeService, private userDetailsService: UserDetailsService) { }
  ngOnInit() {
    console.log(this.isDp, '### IS DP')
    this.getUpdateNoticeList();
    this.getClosedUpdateNoticeList();
    if (this.isDp) {
      this.getOrganization();
    }
  }

  getUpdateNoticeList(): void {
    if (this.isAis) {
      this.updatenoticeService.getUpdateNoticeList().
        subscribe(updatenoticelist => this.updatenoticelist_dataSource.data = updatenoticelist);
    }
    else if (this.isDp && this.isUnReader) {
      this.updatenoticeService.getUpdateNoticeListByOriginator().
        subscribe(updatenoticelist => this.updatenoticelist_dataSource.data = updatenoticelist);
    }
    /* else {
      this.updatenoticeService.getUpdateNoticeList().
        subscribe(updatenoticelist => this.updatenoticelist_dataSource.data = updatenoticelist);
    } */
  }

  getClosedUpdateNoticeList(): void {
    if (this.isDp && this.isUnReader) {
      this.updatenoticeService.getClosedUpdateNoticeList().
        subscribe(closedUnList => this.closed_updatenoticelist_dataSource.data = closedUnList);
    }
  }

  getOrganization(): void {
    this.updatenoticeService.getOrganization().
      subscribe(groupName => this.groupName = groupName);
  }
}
