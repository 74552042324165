import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,
  ReactiveFormsModule } from '@angular/forms';

import { AppMaterialModule } from '../app.material.module';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { MatSortModule } from '@angular/material/sort';
import {  MatTableModule } from '@angular/material/table';
import { OverviewRoutingModule } from './overview-routing.module';
import { OverViewComponent } from './overview.component';
import { TaskService } from './service/task.service';
import { ClaimdialogComponent } from './claimdialog/claimdialog.component';
import { UnclaimdialogComponent } from './unclaimdialog/unclaimdialog.component';

@NgModule({
    imports: [
        CommonModule,
        OverviewRoutingModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatSortModule,
        MatTableModule
    ],
    declarations: [
        OverViewComponent,
        ClaimdialogComponent,
        UnclaimdialogComponent
    ],
    providers: [
        TaskService
    ]
})
export class OverviewModule { }
