import { FeatureDocument } from './../model/FeatureDocument';
import { AixmFeatureDTO } from './../model/AixmFeatureDTO';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfigService } from '../../utils/config.service';
import { FileLikeObject } from 'ng2-file-upload';
import { TimeSliceId } from '../model/TimeSliceId';
import { TimesliceDTO } from '../model/TimesliceDTO';
import { DatasetOption } from '../model/DatasetOption';
import { DataProvider, FeatureId } from '../../data-provider/model/dataprovider';

const httpOptions = {
  headers: new HttpHeaders()
};

@Injectable({
  providedIn: 'root'
})
export class InfoManagementService {

  private apiServer = ConfigService.settings.apiServer;
  private featureUrl = this.apiServer.url + '/aixm/feature/list';
  private searchFeatureUrl = this.apiServer.url + '/search';
  private uploadDataSetUrl = this.apiServer.url + '/aixm/dataSet/new';
  private latestTSIdUrl = this.apiServer.url + '/tsId';
  private timesliceIdUrl = this.apiServer.url + '/timesliceId';
  private timesliceIdListUrl = this.apiServer.url + '/timesliceId/list';
  private timeslicesUrl = this.apiServer.url + '/aixm/timeslice/list';
  private downloadDatasetUrl = this.apiServer.url + '/aixm/downloadDataset';
  private generateDatasetUrl = this.apiServer.url + '/aixm/generateDataset';
  private assignAllowedEditorsUrl = this.apiServer.url + '/aixm/assign';
  private getAllowedEditorsUrl = this.apiServer.url + '/aixm/allowedEditor';

  constructor(private http: HttpClient) { }

  /** GET feature list by type from the server */
  getFeaturesByType(group: string, unId: string): Observable<AixmFeatureDTO[]> {
    const url = `${this.featureUrl}/${group}/${unId}`;
    return this.http.get<AixmFeatureDTO[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getFeaturesByType', error))
      ));
  }

  /** GET feature Id and latest time slice id from postgres db from the server*/
  getFeatureIdAndTimesliceId(effDate: string): Observable<string[]> {
    const url = `${this.latestTSIdUrl}/${effDate}`;
    return this.http.get<string[]>(url).
      pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getFeatureIdAndTimesliceId', error))
      ));
  }

  /**GET UN tsId */
  getTimesliceId(featureId: string, unId: string): Observable<TimeSliceId> {
    const url = `${this.timesliceIdUrl}/${featureId}/${unId}`;
    return this.http.get<TimeSliceId>(url).
      pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getTimesliceId', error))
      ));
  }

  /**GET UN tsId list */
  getTimesliceIdList(featureId: string, unId: string): Observable<TimeSliceId[]> {
    const url = `${this.timesliceIdListUrl}/${featureId}/${unId}`;
    return this.http.get<TimeSliceId[]>(url).
      pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getTimesliceIdList', error))
      ));
  }

  /** search feature by free-text */
  searchFeature(text: string): Observable<FeatureDocument[]> {
    const url = `${this.searchFeatureUrl}/${text}`;
    return this.http.get<FeatureDocument[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('searchFeature', error))
      ));
  }

  uploadDataSet(unId: string, dataSet: FileLikeObject): Observable<any> {
    let requestOptions: Object = {
      responseType: 'text'
    };
    var formData = new FormData();
    formData.append('dataset', dataSet.rawFile as Blob, dataSet.name);

    const url = `${this.uploadDataSetUrl}/${unId}`;
    return this.http.post<FormData>(url, formData, requestOptions)
      .pipe(tap(formData => console.log(formData),
        error => catchError(this.handleError<FormData>('uploadDataSet', error))
      ));
  }

  /** get time slices by feature id */
  getTimeSlices(featureId: string): Observable<TimesliceDTO[]> {
    const url = `${this.timeslicesUrl}/${featureId}`;
    return this.http.get<TimesliceDTO[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getTimeSlices', error))
      ));
  }

  generateAipDataset(datasetOption: DatasetOption): Observable<any> {
    return this.http.post<any>(this.generateDatasetUrl, datasetOption)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('generateAipDataset', error))
      ));
  }

  generateAipDatasetPerUN(unId: string): Observable<string> {
    let requestOptions: Object = { responseType: 'string' };

    const url = `${this.generateDatasetUrl}/${unId}`;
    return this.http.get<string>(url, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('generateAipDatasetPerUN', error))
      ));
  }

  downloadAipDataset(datasetPath: string): Observable<Blob> {
    let param = new HttpParams().set("datasetPath", datasetPath);
    let requestOptions: Object = { responseType: 'blob', params: param };

    const url = `${this.downloadDatasetUrl}`;
    return this.http.get<any>(url, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('downloadAipDataset', error))
      ));
  }

  assignAllowedEditors(featureType: String, featureId: String, allowedEditors: Set<DataProvider>): Observable<FeatureId> {

    const url = `${this.assignAllowedEditorsUrl}/${featureType}/${featureId}`;
    return this.http.post<FeatureId>(url, allowedEditors)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('assignAllowedEditors', error))
      ));
  }

  getAllowedEditors(featureId: string): Observable<any> {
    const url = `${this.getAllowedEditorsUrl}/${featureId}`;
    return this.http.get<any>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getAllowedEditors', error))
      ));
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
