import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogsheetService } from '../service/logsheet.service';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatDialog,  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NewlogentrydialogComponent } from '../newlogentrydialog/newlogentrydialog.component';
import { EditLeDialogComponent } from '../edit-le-dialog/edit-le-dialog.component';
import { LogEntrySummaryDTO } from '../model/logentrySummaryDTO';
import { LogSheetSummaryDTO } from '../model/logsheetSummaryDTO';
import { Status } from '../model/status';

@Component({
  selector: 'app-logentrylist',
  templateUrl: './log-entry-list.component.html',
  styleUrls: ['./log-entry-list.component.css']
})
export class LogentrylistComponent implements OnInit {
  
  public openStatus: Status = Status.OPEN;
  private logentries: LogEntrySummaryDTO[] = [];
  logsheet: LogSheetSummaryDTO = new LogSheetSummaryDTO();
  
  displayedColumns = ['id', 'author', 'description', 'logSheetReference', 'creationDate', 'edit_icon'];
  logentries_dataSource =new MatTableDataSource<LogEntrySummaryDTO>(this.logentries);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;
  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.logentries_dataSource.sort = this.sort;
    this.logentries_dataSource.paginator = this.paginator;
    
/*     this.logentries_dataSource.sortingDataAccessor = (item, property) => {
      // property = this.sortBy;
       // console.log('item: '+JSON.stringify(item)+' '+' property: '+ property);
   switch (property) {
     case 'sn': {
       console.log(property);
       return item.id;
       }
 
     case 'date': {
         console.log('Inside date');
         let newDate = new Date(item.creationDate);
         console.log(newDate);
       return newDate;
     }
     case 'author': {
      console.log(property);
      return item[property];
      }
      case 'ref': {
        console.log('Inside date');
        let newDate = new Date(item.logSheetReference);
        console.log(newDate);
      return newDate;
    }
     default: {
       console.log('Inside default sort');
       return item[property];}
             }
         }; */
    //this.logentries_dataSource.sortingDataAccessor = (data, header) => data[header];

  }
  
  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.logentries_dataSource.filter = filterValue;
  }

  constructor(private route: ActivatedRoute, 
    private logSheetService: LogsheetService, public dialog: MatDialog,private snackBar: MatSnackBar) {}

 // @Output() myEvent = new EventEmitter<String>()

  ngOnInit() {    
    this.getLogSheetById();
    this.getLogEntries();
  }

  //inline edit
  // editField(field: string, editValue: string, el: any) {
  //   let idx = this.logentries_dataSource.data.findIndex(ele => el.id == ele.id);
  //   this.logentries_dataSource.data[idx][field] = editValue;
  // }

  getLogSheetById(): void {
    const id = this.route.snapshot.params['id'];
    console.log("in component: "+ id);
    this.logSheetService.getLogSheetById(id)
      .subscribe(logsheet => this.logsheet = logsheet);
  }

  getLogEntries(): void {
    const id = this.route.snapshot.params['id'];
    console.log("in component: "+ id);
    this.logSheetService.getLogEntries(id)
    .subscribe(
      logentries =>{this.logentries_dataSource.data = logentries,
      console.log("The entries size is "+ logentries.length)});

  }

  close(logsheet:LogSheetSummaryDTO){
    console.log("The logsheet is "+ logsheet.id);
    this.logSheetService.close(logsheet).subscribe
    (result => {
      //this.result = result,
      //console.log("Claimed Task: " + result);
      this.snackBar.open(result, "OK", {
        duration: 2000,
      });
      this.ngOnInit();
    });
    
  }

/*   reopen(logsheet:LogSheetSummaryDTO){
    this.logSheetService.reopen(logsheet)
    .subscribe(result => {
      //this.result = result,
      //console.log("Claimed Task: " + result);
      this.snackBar.open(result, "OK", {
        duration: 2000,
      });
    });
  } */

  openDialog(): void {
    let dialogRef = this.dialog.open(NewlogentrydialogComponent, {
      width: '500px',
      data: {logsheet: this.logsheet}
    });

    dialogRef.componentInstance.onAdd.subscribe((result) => {
      console.log("The result is "+ JSON.stringify(result));
      if(JSON.stringify(result))
      this.getLogEntries();
    });

     dialogRef.afterClosed().subscribe(result => {
/*        if(result==null){
         console.log("Show Error")
       }
       else{
      console.log("Success")
       this.getLogEntries();
      } */
      console.log('The dialog was closed');
      /*  let dialogRef = this.dialog.open(SuccessDialogComponent, {
        width: '500px',
        data: {result: result}
        });
        dialogRef.afterClosed().subscribe(result => {
        });  */
        
      }); 
  }

  openEditDialog(id: number, desp: string): void {
    let dialogRef = this.dialog.open(EditLeDialogComponent, {
      width: '500px',
      data: { id : id, desp: desp, Logsheet: this.logsheet }
    });

    dialogRef.componentInstance.onAdd.subscribe((result) => {
      console.log("The result is "+ JSON.stringify(result));
      if(JSON.stringify(result))
      this.getLogEntries();
    });

    console.log(id);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed'+ result);
      if(result!=null){
      this.ngOnInit();
      }
      //need to adapt
/*       //to mark as deleted
      let idx = this.logentries_dataSource.data.findIndex(ele => id == ele.id);
      this.logentries_dataSource.data[idx].isDeleted = true;   */
    });
  }

}
