/**
 * Types of segment interpolation that we implement in the interface.
 * GML offers others that we don't need yet or aren't used in aviation.
 */
export enum GmlInterpolation {
  GREAT_CIRCLE = 'GREAT_CIRCLE',
  ARC_BY_CENTRE = 'ARC_BY_CENTRE',
  PARALLEL = 'PARALLEL',
  GEO_BORDER = 'GEO_BORDER', /* A reference to an AIXM 5 GeoBorder */
  CIRCLE_BY_CENTRE = "CIRCLE_BY_CENTRE",
  ARC_BY_CENTRE_CLOCKWISE = "ARC_BY_CENTRE_CLOCKWISE",
  ARC_BY_CENTRE_ANTICLOCKWISE = "ARC_BY_CENTRE_ANTICLOCKWISE"
}
