import { Section } from './../model/Section';
import { CreateQAProjectDto } from './../model/CreateQAProjectDto';
import { ProcessStatus } from './../model/processstatus';
import { section } from '../model/test';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { Assignment } from '../model/assignment';
import { User } from '../model/user';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as GlobalVar from '../../utils/global.var';
import { ConfigService } from '../../utils/config.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class TaskService {

  private apiServer = ConfigService.settings.apiServer;
  public groupName: string;
  private assignedUrl = this.apiServer.url + '/overview/assignedTasks';
  private unassignedUrl = this.apiServer.url + '/overview/unassignedTasks';
  private claimUrl = this.apiServer.url + '/claim';
  private releaseUrl = this.apiServer.url + '/release';
  private taskListUrl = this.apiServer.url + '/listUnassignTasks';
  //private unassignedUrl = 'api/unassigned_task';
  private taskUrl = 'api/task';
  private startUrl = this.apiServer.url + '/start';
  private completeUrl = this.apiServer.url + '/complete';
  private reviewUrl = this.apiServer.url + '/review';
  private currentstatusUrl = this.apiServer.url + '/currentstatus';
  private groupUrl = 'api/user';
  constructor(private http: HttpClient) { }

  findUserAssignmentTasks(): Observable<Assignment[]> {
    const url = `${this.assignedUrl}`;
    return this.http.get<Assignment[]>(url)
      .pipe(tap(assignments => console.log(`fetched OverView ` + assignments.length)),
        catchError(this.handleError('getOverView', []))
      );
  }


  findUnassignedTaksByGroup(): Observable<Assignment[]> {
    const url = `${this.unassignedUrl}`;
    return this.http.get<Assignment[]>(url)
      .pipe(tap(tasks => console.log(`fetched OverView ` + tasks.length)),
        catchError(this.handleError('getOverView', []))
      );
  }

  claim(taskId: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: Claim Function: " + taskId);
    const url = `${this.claimUrl}/${taskId}`;
    return this.http.get<string>(url, requestOptions).pipe(
      tap(data => console.log(data),
        error => catchError(this.handleError<string>(`claim id=${taskId}`, error))
      ));
  }

  release(taskId: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: Release Function: " + taskId);
    const url = `${this.releaseUrl}/${taskId}`;
    return this.http.get<string>(url, requestOptions).pipe(
      tap(_ => catchError(this.handleError<string>(`release id=${taskId}`))
      ));
  }

  start(project: CreateQAProjectDto): Observable<CreateQAProjectDto> {
    console.log("in service: start Function" + project);
    return this.http.post<CreateQAProjectDto>(this.startUrl, project, httpOptions)
      .pipe(tap(assignmentlist => console.log(`fetched Task List`)),
        catchError(this.handleError<CreateQAProjectDto>('Started'))
      );
  }

  complete(section: CreateQAProjectDto): Observable<string> {
    console.log("in service: Complete Function" + section);
    return this.http.post<string>(this.completeUrl, section, httpOptions)
      .pipe(tap(taskId => console.log(`Completed task: "${taskId}"`)),
        catchError(this.handleError<string>('complete'))
      );
  }

  approved(task: Assignment, branch_value: string): Observable<any> {
    console.log("in service: Approved Function" + branch_value);
    const url = `${this.reviewUrl}/${branch_value}`;

    return this.http.post<Assignment>(url, task, httpOptions)
      .pipe(tap(assignment => console.log(`Approved task: "${assignment.id}"`)),
        catchError(this.handleError<Assignment>('approved'))
      );
  }

  getCurrentStatus(section: Section): Observable<ProcessStatus> {
    return this.http.get<ProcessStatus>(this.currentstatusUrl)
      .pipe(tap(processStatus => console.log(`fetched Current Status "${processStatus}"`)),
        catchError(this.handleError<ProcessStatus>('getCurrentStatus'))
      );
  }

  /* getTaskList(id: string): Observable<Assignment[]> {
      const url = `${this.taskListUrl}/${id}`;
      return this.http.get<Assignment[]>(url)   
      .pipe( tap(assignmentlist => console.log(`fetched Task List` )),
        catchError(this.handleError('getOverView', []))
      );  
  } */

  observableSubscribeTest(): Observable<any> {
    const myObservable = of(1, 2, 3);
    return myObservable;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error);

      return of(result as T);
    };
  }
}