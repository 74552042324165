import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Network} from 'vis-network/esnext';
import { DataSet } from 'vis-data';

@Component({
  selector: 'im-feature-search',
  templateUrl: './feature-search.component.html',
  styleUrls: ['./feature-search.component.css']
})
export class FeatureSearchComponent implements OnInit {

  @ViewChild('network') network: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<FeatureSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    // create an array with nodes
    var nodes = new DataSet([
      { id: 1, label: 'Node 1' },
      { id: 2, label: 'Node 2' },
      { id: 3, label: 'Node 3' },
      { id: 4, label: 'Node 4' },
      { id: 5, label: 'Node 5' }
    ]);

    // create an array with edges
    var edges = new DataSet<any>([
      { from: 1, to: 3 },
      { from: 1, to: 2 },
      { from: 2, to: 4 },
      { from: 2, to: 5 }
    ]);

    // provide the data in the vis format
    var data = {
      nodes: nodes,
      edges: edges
    };
    var options = {};

    // initialize your network!
    var network = new Network(this.network.nativeElement, data, options);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
