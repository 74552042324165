import { Temporality } from './../model/temporality';
import { UserDetailsService } from './../../login/services/UserDetails.service';
import { SaveUpdateNoticeDto } from './../model/SaveUpdateNoticeDto';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { Component, OnInit, ViewChild, Inject, LOCALE_ID, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UpdateNoticeService } from '../service/updatenotice.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import {  MatDialog } from '@angular/material/dialog';
import { RejectUnDialogComponent } from '../reject-un-dialog/reject-un-dialog.component';
import { EditDateValidation } from './EditDateValidation';
import { AixmFeatureDTO } from '../../info-management/model/AixmFeatureDTO';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ChangeSummary } from '../../info-management/model/ChangeSummary';
import { DeleteItemDialogComponent } from '../delete-item-dialog/delete-item-dialog.component';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../utils/config.service';
import { MapViewDialogComponent } from '../../info-management/map-view-dialog/map-view-dialog.component';
import { UploadDatasetDialogComponent } from '../../info-management/upload-dataset-dialog/upload-dataset-dialog.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { debounceTime, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-edit-updatenotice',
  // templateUrl: './edit-updatenotice.component.html',
  templateUrl: './edit-updatenotice-revamp.component.html',
  styleUrls: ['./edit-updatenotice.component.css'],
  // encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],

    },
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class EditUpdateNoticeComponent implements OnInit, AfterViewInit {
  isDp: boolean = ConfigService.settings.isDataProvider;
  isAis: boolean = ConfigService.settings.isAis;

  private role = ConfigService.settings.role;
  editUNForm: UntypedFormGroup;
  isloaded: boolean = false;
  clicked: string;
  public uploader: FileUploader = new FileUploader({url: ''});
  validEffDate: Date = new Date();
  validCancelDate: Date = new Date();
  //status: string = this.editUNForm.get['status'].value
  valueOfFeatureId: string;
  unName: string;
  selectedRowIndex;
  correctionNumber: number;
  lastDate: string;
  userName: string;
  dueDate: any;
  effectiveDate: string;
  cancelDate: string;
  assignedUser: boolean;
  isDirty: boolean = false;
  isDeleted: boolean = false;
  isUndo: boolean = false;
  fileIndex: number;
  noticeDay = environment.noticeNumber;
  latitude: number;
  longitude: number;
  unId: string;
  featureId: string;
  featuresExist: boolean;

  fileNames: string[] = [];
  fileNamesToDelete: string[] = [];

  aixmDtos: AixmFeatureDTO[] = [];
  deletion = ChangeSummary.DELETION;
  aixmFeatures: AixmFeatureDTO[] = [];

  localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  //datasoure for table
  aixmFeatures_DataSource = new MatTableDataSource<AixmFeatureDTO>(this.aixmDtos);
  displayedColumns = ['featureType', 'featureName', 'summary', 'lastEdited', 'delete_icon'];
  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  effDatePickerOpen: boolean = false;

  cancelDatePickerOpen: boolean = false;

  timezoneSearchCtrl = new FormControl('');

  selectedTz = new FormControl(this.userTz)

  get userTz(): string {
    return new Intl.DateTimeFormat('en-US').resolvedOptions().timeZone;
  }

  get selected(): moment.Moment | undefined {
    return this._getDate('effectiveDate');
  }

  get effDatehoursSelected() {
    if (!this.selected) {
      return null;
    }
    return moment.tz(this.selected, this.selectedTz.value).format('HH:mm')
  }

  set selected(value: moment.Moment | undefined) {
    if (value) {
      this._setDate(value, 'effectiveDate')
    }
  }

  get cancelDateSelected(): moment.Moment | undefined {
    return this._getDate('cancellationDate');
  }

  get cancelDatehoursSelected() {
    if (!this.cancelDateSelected) {
      return null;
    }
    return moment.tz(this.cancelDateSelected, this.selectedTz.value).format('HH:mm')
  }

  set cancelDateSelected(value: moment.Moment | undefined) {
    if (value) {
      this._setDate(value, 'cancellationDate')
    }
  }

  
  timezones$ = this.timezoneSearchCtrl.valueChanges.pipe(
    startWith(this.timezoneSearchCtrl.value),
    debounceTime(300),
    distinctUntilChanged(),
    map((ctrlValue) => {
      const tz = this.getTimeZonesWithOffsets();
      const nCtrlValue = ctrlValue.trim().toLowerCase()
      return tz.filter((zone) => zone.name.trim().toLowerCase().includes(nCtrlValue))
    })
  )

  onTzChange(event: MatSelectChange) {
    const targetControl = ['effectiveDate', 'cancellationDate'];
    targetControl.forEach(ctrl => {
      const targetDate = this.editUNForm.get(ctrl)?.value;
      if (targetDate) {
        const timezone = event.value;
        // Adjust the stored date to the new timezone without shifting the day
        const adjustedDate = moment.tz(
          {
            year: moment(targetDate).year(),
            month: moment(targetDate).month(),
            date: moment(targetDate).date(),
          },
          timezone
        );
        
        this.editUNForm.get(ctrl)?.setValue(adjustedDate.toISOString());
        this.editUNForm.get(ctrl)?.markAsDirty();
        console.log(`Updated ${ctrl} with Timezone Change:`, adjustedDate.toISOString());
      }
    })
  }

  private _getDate(controlName: string): moment.Moment | undefined {
    return this.editUNForm.get(controlName).value
  }

  private _setDate(value: moment.Moment, controlName: string) {
    if (value) {
      const timezone = this.selectedTz.value;
      // Use the selected date and explicitly assign it to the selected timezone
      const usedDateTimeTz = moment.tz(
        {
          year: value.year(),
          month: value.month(),
          date: value.date(),
        },
        timezone
      )
      
      this.editUNForm.get(controlName)?.setValue(usedDateTimeTz.toISOString());
      this.editUNForm.get(controlName)?.markAsDirty();
      console.log('Effective Date ISO:', usedDateTimeTz.toISOString());
    }
  }

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.aixmFeatures_DataSource.sort = this.sort;
    this.aixmFeatures_DataSource.paginator = this.paginator;
  }

  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.aixmFeatures_DataSource.filter = filterValue;
    console.log(this.aixmFeatures_DataSource, '## this.aixmFeatures_DataSource on filter')
  }

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private route: ActivatedRoute,
    public router: Router,
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public updatenoticeService: UpdateNoticeService,
    private userDetailsService: UserDetailsService,
    private _snackBar: MatSnackBar) {
    this.unId = this.route.snapshot.params['unId'];
    this.featureId = this.route.snapshot.params['featureId'],
      this.valueOfFeatureId = this.route.snapshot.params['id'],
      this.editUNForm = fb.group({
        name: ['', Validators.required],
        lastEditedDate: ['', Validators.required],
        originatorName: ['', Validators.required],
        effectiveDate: ['', Validators.required],
        dueDate: ['', Validators.required],
        temporality: ['', Validators.required],
        cancellationDate: [''],
        description: [''],
        timezone: [''],
        status: [''] // Enable Validators.required after workflow is implemented
      }, {
        validator: EditDateValidation.CheckDate
      })
  }

  ngOnInit() {
    this.validEffDate.setDate(this.validEffDate.getDate() + ConfigService.settings.periodToCalculateValidEffDate);
    this.validCancelDate.setDate(this.validCancelDate.getDate() + ConfigService.settings.periodToCalculateValidEffDate + 1);
    this.getUpdateNotice();
    this.checkAssignedUser();
    this.getAixmFeatures(this.valueOfFeatureId);
    let maxFileSize = 1 * 1024 * 1024;
    this.uploader = new FileUploader({
      url: '',
      maxFileSize: maxFileSize
    });

    this.uploader.onWhenAddingFileFailed = (item, filter) => {
      let message = '';
      switch (filter.name) {
        case 'fileSize':
          message = 'Error! \nThe uploaded file \"' + item.name + '\" is ' + this.formatBytes(item.size) +
            ', this exceeds the maximum allowed size of ' + this.formatBytes(maxFileSize) + '.';
          break;
        default:
          message = 'Error trying to upload file \"' + item.name + '\".';
          break;
      }
      this.openSnackBar(message, '');
    };
  }

  REFERENCE_AIRAC_DATE = moment("2018-11-08");
  AIRAC_CYCLE_DAYS = 28;

  airacDateClass: MatCalendarCellClassFunction<moment.Moment> = (cellDate, view) => {
    if (view === 'month') {
      // Calculate the difference in days from the reference date
      const diffInDays = cellDate.startOf('day').diff(this.REFERENCE_AIRAC_DATE.startOf('day'), 'days');
      // Check if the difference is divisible by 28
      return diffInDays >= 0 && diffInDays % 28 === 0 ? 'airac-date-class' : '';
    }

    return '';
  }

  getTimeZonesWithOffsets() {
    // Get a list of all available time zones
    const timeZones = moment.tz.names();
  
    // Map each time zone to its current offset and formatted string
    const formatOffset = (offset: number): string => {
      const sign = offset >= 0 ? '+' : '-';
      const absOffset = Math.abs(offset);
      const hours = Math.floor(absOffset / 60);
      const minutes = absOffset % 60;
      return `UTC${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }

    const timeZoneWithOffsets = timeZones.map((zone) => {
      const offset = moment.tz(zone).utcOffset(); // Offset in minutes
      return {
        name: zone,
        offset: offset / 60, // Convert offset to hours
        offsetFormatted: formatOffset(offset), // Format offset as UTC+/-hh:mm
      };
    });
  
    return timeZoneWithOffsets;
  }

  //highlight Airac Date
  dateClass = (d: Date) => {
    var diff = moment.duration(this.REFERENCE_AIRAC_DATE.diff(d));
    var diffDays = diff.asDays();

    if ((diffDays % this.AIRAC_CYCLE_DAYS) == 0) {
      return 'airac-date-class';
    }
    else {
      return undefined;
    }
  }

  formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  getAixmFeatures(eventId: string): void {
    this.updatenoticeService.getAixmFeatures(eventId).subscribe(res => {
      this.aixmDtos = res;
      this.aixmFeatures = [];      

      for (let i = 0; i < res.length; i++) {
        //if (res[i].summary != "Deleted Change") {
          this.aixmFeatures.push(res[i]);
        //}
      }
      if (this.aixmFeatures.length == 0) {
        this.featuresExist = false;
      }
      else { this.featuresExist = true; }

      if (this.isAISReviewer() == false) {
        this.aixmFeatures_DataSource.data = res;
      }
      else {
        this.aixmFeatures_DataSource.data = this.aixmFeatures;
        console.log(this.aixmFeatures_DataSource, '### this.aixmFeatures_DataSource')
      }
    });

  }

  onSubmit(editUnForm): void {
    if (this.clicked == 'editUn') {
      let changedProperties = new Map();

      Object.keys(editUnForm.controls)
        .forEach(key => {
          let currentControl = editUnForm.controls[key];
          const isTemporary = key === 'temporality' && currentControl.value === 'TEMPORARY';

          if (currentControl.dirty && key != 'temporality') {
            if (key == 'effectiveDate' || key == 'cancellationDate') {
              changedProperties['effectiveDate'] = editUnForm.get('effectiveDate').value;
              if (editUnForm.controls["temporality"].value == Temporality.TEMPORARY) {
                changedProperties['cancellationDate'] = editUnForm.get('cancellationDate').value;
              }
            } else if (key != 'effectiveDate' && key != 'cancellationDate') {
              changedProperties[key] = currentControl.value;
            }
          } else if (currentControl.dirty && key == 'temporality' && currentControl.value == "PERMANENT") {
            changedProperties['cancellationDate'] = null;
            changedProperties['effectiveDate'] = editUnForm.get('effectiveDate').value;
          } 
          else if (isTemporary) {
            changedProperties['temporality'] = editUnForm.get('temporality').value;
            changedProperties['cancellationDate'] = editUnForm.get('cancellationDate').value;
            changedProperties['effectiveDate'] = editUnForm.get('effectiveDate').value;
          }

          if (key === 'timezone') {
            changedProperties['timezone'] = this.selectedTz.value;
          }
        });

      let saveUpdateNoticeDto: SaveUpdateNoticeDto = {
        featureId: this.valueOfFeatureId,
        baseCorrection: this.correctionNumber,
        changedProperties: changedProperties
      }

      console.log(saveUpdateNoticeDto);
      this.updatenoticeService.editUpdateNotice(saveUpdateNoticeDto, this.getFiles(), this.fileNamesToDelete)
        .subscribe(result => {
          this.correctionNumber = result.correction
          this.router.navigate(['/update'])

        });
    }
    if (this.clicked == 'deleteUn') {
      console.log("Button Click Delete Un")
      this.updatenoticeService.deleteUN(this.valueOfFeatureId).subscribe(result => {
        console.log(result);
        this.router.navigate(['/update']);
      });
    }
    if (this.clicked == 'acceptUn') {
      console.log("Button Click AcceptUn")
      this.updatenoticeService.acceptUN(this.valueOfFeatureId).subscribe(result => {
        console.log(result);
        this.router.navigate(['/update']);
      });
    }
    if (this.clicked == 'rejectUn') {
      let dialogRef = this.dialog.open(RejectUnDialogComponent, {
        width: '500px',
        data: { id: this.valueOfFeatureId }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        console.log('After the dialog was closed:' + result);

      });
    }
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      console.log(fileItem)
      return fileItem.file;
    });
  }

  remove(item: any) {
    var index = this.uploader.queue.indexOf(item);
    console.log("The index is " + index);
    this.uploader.queue.splice(index, 1);
    if (this.uploader.queue.length == 0) {
      this.isDirty = false;
    }
  }

  delete(fileName: string) {
    this.isDirty = true;
    this.isDeleted = true;
    this.fileNamesToDelete.push(fileName);
    console.log(this.fileNamesToDelete, 'this.fileNamesToDelete')
    this.fileIndex = this.fileNamesToDelete.indexOf(fileName);
    console.log(this.fileIndex);
    this.fileNames.splice(this.fileNames.indexOf(fileName), 1);
  }

  unDo(fileName: string) {
    if (this.fileNamesToDelete.length > 0) {
      let filename: string;
      this.fileIndex = this.fileNamesToDelete.indexOf(fileName);
      filename = this.fileNamesToDelete[this.fileIndex];
      this.fileNamesToDelete.splice(this.fileNamesToDelete.indexOf(filename), 1);
      this.fileNames.push(filename);
      this.isDeleted = true;
    }
  }

  getUpdateNotice(): void {
    this.updatenoticeService.getUpdateNotice(this.valueOfFeatureId)
      .subscribe(
        result => {
          var datePipe = new DatePipe("en-US");
          this.lastDate = datePipe.transform(result.lastEditedDate, 'dd MMM yyy').toUpperCase();
          //this.dueDate = datePipe.transform(result.lastEditedDate, 'dd MMM yyy').toUpperCase();
          this.unName = result.name;
          this.dueDate = moment(result.effectiveDate);
          this.dueDate.subtract(this.noticeDay, "days")
          this.dueDate = this.dueDate.locale(this.locale).format('DD MMM YYYY').toUpperCase();
          console.log("Due Date is " + this.dueDate + "" + result.effectiveDate + "Originator Name is"
            + result.originator.name);

          this.editUNForm.patchValue({
            name: result.name,
            lastEditedDate: this.lastDate,
            originatorName: null,
            effectiveDate: result.effectiveDate,
            dueDate: null,
            temporality: result.temporality,
            cancellationDate: result.cancelDate,
            description: result.description,
            status: result.status,
            timezone: result.timezone
          }),
            this.userName = result.userName;
          this.correctionNumber = result.timeSliceId.correction,
            this.fileNames = result.fileNames;
          console.log("fileNames: " + this.fileNames);
          this.editUNForm.get('dueDate').disable()
          this.editUNForm.get('lastEditedDate').disable()
          this.editUNForm.get('status').disable()

          this.isloaded = true;

          if (result.timezone) {
            this.selectedTz.setValue(result.timezone);
          }
        });

  }

  getDueDate(effectiveDate): void {
    console.log(effectiveDate, 'kepanggil')
    this.dueDate = moment(effectiveDate.value);
    this.dueDate.subtract(this.noticeDay, "days")
    this.dueDate = this.dueDate.format('DD MMM YYYY');
  }

  setDueDate(effectiveDate): void {
    this.dueDate = moment(effectiveDate);
    this.dueDate.subtract(this.noticeDay, "days")
    this.dueDate = this.dueDate.format('DD MMM YYYY').toUpperCase();;
  }

  editUpdateNotice(): void {
    console.log(this.editUNForm.valid)
    this.clicked = 'editUn';
  }

  generateDraftAISProduct() {
    this.updatenoticeService.generateDraftAISProduct(
      this.valueOfFeatureId).subscribe(path => {
        this.updatenoticeService.downloadDraftAISProduct(path).subscribe(blob => {
          saveAs(blob, path.substring(path.lastIndexOf("/") + 1));
        });
      });
  }

  download(fileName: string) {
    this.updatenoticeService.download(this.valueOfFeatureId, fileName)
      .subscribe(blob => {
        saveAs(blob, fileName);
      });
  }

  isReadOnly(): boolean {
    if (this.isDp && this.assignedUser) {
      this.editUNForm.get('temporality').enable();
      return false;
    } else {
      this.editUNForm.get('temporality').disable();
      return true;
    }
  }

  checkAssignedUser(): void {
    this.updatenoticeService.checkTaskIsClaimedByCurrentUser(this.valueOfFeatureId).subscribe(result => {
      this.assignedUser = this.isAis ? true : result;
    });
  }

  openConfirmationDialog(): void {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.componentInstance.onConfirm.subscribe((result) => {
      this.updatenoticeService.submitToAIS(this.valueOfFeatureId).subscribe(result => {
        console.log(result);
        this.router.navigate(['/update']);
      });
    });
  }

  isAISReviewer(): boolean {
    return this.userDetailsService.hasRole(this.role.role_Ais_Reviewer);
  }

  modifyFile(): boolean {
    if (this.uploader.queue.length > 0 || this.fileNamesToDelete.length > 0) return true;
    else return false;
  }

  rejectUN(): void {
    console.log("Reject Un");
    this.clicked = 'rejectUn';
  }

  acceptUN(): void {
    console.log("Accept Un");
    this.clicked = 'acceptUn';
  }

  deleteUN(): void {
    this.clicked = 'deleteUn';
  }

  openCancelDialog(itemId: string): void {    
    let dialogRef = this.dialog.open(DeleteItemDialogComponent, {
      width: '500px',
      data: { featureId: this.valueOfFeatureId, itemId: itemId }
    });

    dialogRef.componentInstance.onAdd.subscribe((result) => {
      console.log("The result is " + JSON.stringify(result));
      if (JSON.stringify(result))
        this.ngOnInit();
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('After the dialog was closed:' + result);     
    });

  }


  /*   highlight(row) {
      console.log("The id is "+ row.id);
      if(row.id = 1){
      this.selectedRowIndex = row.id;
    }
  } */

  isEmptyDescription() {
    let description = this.editUNForm.get('description').value;

    if (description == null || description == "" || !description.trim()) {
      this.editUNForm.get('description').setErrors({ isEmptyDescription: true })
      return { isEmptyDescription: true }
    }
    else {
      this.editUNForm.get('description').setErrors(null)
      return null
    }
  }

  showMap() {
    if (this.effectiveDate == undefined) {
      this.effectiveDate = this.editUNForm.get('effectiveDate').value;
    }
    let dialogRef = this.dialog.open(MapViewDialogComponent, {
      data: { aixmDtos: this.aixmDtos, unId: this.valueOfFeatureId, latitude: this.latitude, longitude: this.longitude, effectiveDate: this.effectiveDate }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log('After the dialog was closed:' + result);
    });
  }

  redirectToAeroInputForm(featureId: string) {
    this.router.navigate(['info-management', featureId, this.valueOfFeatureId, this.unName, this.editUNForm.get('effectiveDate').value, true]);
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(UploadDatasetDialogComponent, {
      width: '500px',
      data: { unId: this.valueOfFeatureId }
    });

    dialogRef.componentInstance.onAdd.subscribe((result) => {
      console.log("The result is " + JSON.stringify(result));
      this.getAixmFeatures(this.valueOfFeatureId);
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
    });
  }

  onDateSelected(event, picker) {
    console.log(event)
  }

  displayFormattedTimeZone(date, tz:string = 'UTC') {
    return date ? moment(date)
      .tz(tz === 'UTC' ? 'UTC' : tz)
      .format('DD MMM YYYY HH:mm z') : '-'
  }

}
