import { Dataprovider } from './../../data-provider/model/dataprovider';

export class ProfileVersion {
  number : string;
  description : string;
  originators : Dataprovider[];
  profileId : string;
  creationDate: Date;
  fileNames: string[];

  constructor(options: {
    number ?: string,
    description ?: string,
    originators ?: Dataprovider[],
    profileId ?: string,
    creationDate?: Date;
    fileNames?: string[];
  } = {}) {
    this.number = options.number;
    this.description = options.description || '';
    this.originators = options.originators;
    this.profileId = options.profileId;
    this.creationDate = options.creationDate;
    this.fileNames = options.fileNames;
  }
}