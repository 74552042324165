<h1 mat-dialog-title>Feature Search</h1>
<div mat-dialog-content>

  <mat-tab-group mat-stretch-tabs="false">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon matTooltip="Search by Relations">share</mat-icon>
      </ng-template>

      <div #network></div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon matTooltip="Search in Lists">view_list</mat-icon>
      </ng-template>

      <div class="flex">
        <div>
          <im-group-list></im-group-list>
        </div>
        <div>
          <im-feature-list></im-feature-list>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>

  <div class="time-slice-list">
    <im-time-slice-list></im-time-slice-list>
  </div>
  <div>
    {{data | json}}
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.timeSlice" cdkFocusInitial>OK</button>
</div>