import { Injectable } from '@angular/core';

@Injectable()
export class TitleService {

  private titleName : string; 

  public getTitle(): string
   { 
    return this.titleName;
  }
  
  /**
     * Set the title of the current HTML document.
     * @param newTitle
     */
    public setTitle( newTitle: string) {
      this.titleName = newTitle;
    }

}
