<div class="md-padding">

  <ng-container [ngSwitch]="layout">
    <!-- Alternativerly use the main layout as the default switch case -->
    <app-securelayout *ngSwitchCase="Layouts.SecureLayout">
    </app-securelayout>
  </ng-container>

  <!--     <mat-card>
      Main Theme:
      <button mat-raised-button color="primary">
        Primary
      </button>
      <button mat-raised-button color="accent">
        Accent
      </button>
      <button mat-raised-button color="warn">
        Warning
      </button>
    </mat-card> -->

</div>