import { environment } from './../../../environments/environment';
import { InfoManagementService } from './../service/info-management.service';
import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Timeline, TimelineOptions, DataItem } from 'vis-timeline';
import moment from 'moment';
import { TimesliceDTO } from '../model/TimesliceDTO';
import { DatePipe } from '@angular/common';
import { DataSet } from 'vis-data';
import { Interpretation } from '../model/Interpretation';
import { TimeSliceId } from '../model/TimeSliceId';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/utils/config.service';

@Component({
  selector: 'im-time-slice-list',
  templateUrl: './time-slice-list.component.html',
  styleUrls: ['./time-slice-list.component.css']
})
export class TimeSliceListComponent implements OnInit {
  configService = ConfigService
  @Input() featureId: string
  @ViewChild('visualization', { static: true }) visualization: ElementRef;
  @Output() selectedTSEmitter: EventEmitter<any> = new EventEmitter<any>();
  datePipe = new DatePipe("en-US");
  selectedCorrection = '3';
  correctionsPerItem: TimesliceDTO[] = [];
  correctionsPerTs: TimeSliceId[] = [];
  baselineObj: TimesliceDTO;
  data: DataSet<DataItem> = new DataSet([]);
  unId: string;

  groups = [
    {
      id: 1,
      content: 'SUP',
      order: 1
    },
    {
      id: 2,
      content: 'AIP',
      order: 2,
      subgroupStack: {
        'amdt': false,
        'UN': true
      },
      subgroupOrder: function (a, b) { return a.subgroupOrder - b.subgroupOrder; }
    }
  ];

  // subtract 60 days to show 2 AIRAC cycles in the past
  // min: Date = new Date(environment.min);
  // start: Date = new Date(environment.min);
  min: Date = new Date(this.configService.settings.visTimeLineOptions.min);
  start: Date = new Date(this.configService.settings.visTimeLineOptions.min);
  options: TimelineOptions = {
    min: this.start.setDate(this.min.getDate() - 60), // TODO make it a server-side operational configuration property
    start: this.start.setDate(this.min.getDate() - 60),
    // end: environment.max, // TODO make it a server-side operational configuration property
    // max: environment.max, // TODO make it a server-side operational configuration property
    end: this.configService.settings.visTimeLineOptions.max , // TODO make it a server-side operational configuration property
    max: this.configService.settings.visTimeLineOptions.max, // TODO make it a server-side operational configuration property
    moment: function (date) {
      return moment(date).locale("en-gb").utc();
    },
    width: '100%',
    height: '230px',
    zoomMax: 315576000000, // 10 years
    zoomMin: 1000 * 60 * 60 * 24 // 24 hours
  };
  timeline: Timeline;

  constructor(
    private infoManagementService: InfoManagementService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getTimeSlices();
    //var container = document.getElementById('mynetwork');
  }

  ngAfterViewInit() {
  }

  getTimeSlices() {
    this.infoManagementService.getTimeSlices(this.featureId).subscribe(timeslices => {
      let tsGroupById = this.groupTSByInterpretation(timeslices, timeslice => timeslice.id);

      // only show latest correction on timeline
      tsGroupById.forEach((v, k) => {
        // We get PERMDELTA and TERMDELTA from official DB. As we don't increase correction number and we create a new TS. So, use sequence 
        // number to get latest TS.
        let latestSequenceNumber = Math.max.apply(Math, v.map(function (o) { return o.timesliceId.sequenceNumber; }));
        var latestCorrection = v.find(function (o) { return o.timesliceId.sequenceNumber == latestSequenceNumber; })

        if (latestCorrection.group == 2 && latestCorrection.content == 'Update Notice') {
          latestCorrection.subgroup = 'UN';
          latestCorrection.subgroupOrder = 0;
        } else if (latestCorrection.group == 2) {
          latestCorrection.subgroup = 'amdt';
          latestCorrection.subgroupOrder = 1;
        }

        this.data.add({
          id: latestCorrection.id, group: latestCorrection.group,
          content: latestCorrection.content, start: this.datePipe.transform(latestCorrection.start, 'yyyy-MM-dd'), end:
            (latestCorrection.subgroup == 'amdt' && latestCorrection.end == null) ? moment().add(new Date(environment.max).getFullYear(), 'years').format('yyyy-MM-DD')
              : this.datePipe.transform(latestCorrection.end, 'yyyy-MM-dd')
          , subgroup: latestCorrection.subgroup, subgroupOrder: latestCorrection.subgroupOrder
        } as DataItem);
      });

      this.timeline = new Timeline(this.visualization.nativeElement, this.data, this.groups, this.options);

      // If it's being redirected from the referencing feature through url query params, pre-select the timeline block
      if (this.route.snapshot.queryParams?.from === 'browser') {
        // console.log(this.timeline.getVisibleItems(), '### RESULT this.timeline.getVisibleItems()')
        const currentTsIdFromUrl = this.route.snapshot.queryParams?.tsId;
        const getVisibleItem = this.timeline.getVisibleItems().find(item => (item as string).includes(currentTsIdFromUrl));
        if (getVisibleItem) {
          this.timeline.setSelection(getVisibleItem)
        }
      }
     
      this.timeline.on('select', function (properties) {
        if (properties != undefined) {
          // create corrections per item
          // this.correctionsPerItem = tsGroupById.get(properties.items[0]);
          this.baselineObj = tsGroupById.get(properties.items[0])[0];

          // retrieve corrections per ts
          this.unId = properties.items[0].split("_")[0];
          this.infoManagementService.getTimesliceIdList(this.featureId, this.unId).subscribe(timesliceIdList => {
            this.correctionsPerTs = timesliceIdList;
            // this.correction = timesliceIdList[timesliceIdList.length - 1];
          });

          this.correctionsPerItem.forEach(correction => {
            let effdate = this.datePipe.transform(correction.start, 'dd MMM yy').toUpperCase();
            if (correction.timesliceId.correction == 0) {
              correction.content = 'Initial changes by ' + correction.originator.name + ' on ' + effdate;
            } else if (correction.timesliceId.correction == properties.items[0].slice(properties.items[0].lastIndexOf('-') + 1, properties.items[0].length)) {
              correction.content = 'Latest correction by ' + correction.originator.name + ' on ' + effdate;
            } else {
              correction.content = 'Correction' + correction.timesliceId.correction + ' by ' + correction.originator.name + ' on ' + effdate;
            }
          });
          // this.sortCorrectionsByEffDate(this.correctionsPerItem);

          // emit id which is the combination of unid and tsid
          this.selectedTSEmitter.emit(this.baselineObj);
        }
      }.bind(this));
    });
    console.log(this.data);
  }

  sortCorrectionsByEffDate(corrections: TimesliceDTO[]) {
    return corrections.sort((a, b) => {
      return <any>new Date(a.start) - <any>new Date(b.start);
    });
  }

  groupTSByInterpretation(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  ngOnDestroy(): void {
    if (this.timeline != undefined) {
      this.timeline.off('select');
    }
  }

  selectCorrection(timeSliceId: TimeSliceId) {
    // emit id which is the combination of unid and tsid
    let unTsId = this.unId.concat('_', timeSliceId.interpretation, '-', timeSliceId.sequenceNumber.toString(), '-', timeSliceId.correction.toString());
    this.baselineObj.id = unTsId;
    this.selectedTSEmitter.emit(this.baselineObj);
  }

  // data = new DataSet([
  //   { id: 'r2018-1', start: '2018-01-04', end: '2018-01-05', type: 'background', className: 'airac' },
  //   { id: 'r2018-2', start: '2018-02-01', end: '2018-02-02', type: 'background', className: 'airac' },
  //   { id: 'r2018-3', start: '2018-03-01', end: '2018-03-02', type: 'background', className: 'airac' },
  //   { id: 'r2018-4', start: '2018-03-29', end: '2018-03-30', type: 'background', className: 'airac' },
  //   { id: 'r2018-5', start: '2018-04-26', end: '2018-04-27', type: 'background', className: 'airac' },
  //   { id: 'r2018-6', start: '2018-05-24', end: '2018-05-25', type: 'background', className: 'airac' },
  //   { id: 'r2018-7', start: '2018-06-21', end: '2018-06-22', type: 'background', className: 'airac' },
  //   { id: 'r2018-8', start: '2018-07-19', end: '2018-07-20', type: 'background', className: 'airac' },
  //   { id: 5, group: 2, content: 'Update Notice', start: '2018-05-24', subgroup: 'UN', subgroupOrder: 0 },
  //   { id: 1, group: 2, content: 'AIP 3rd Edition', start: '2018-02-01', end: '2018-03-10', subgroup: 'amdt', subgroupOrder: 1 },
  //   { id: 2, group: 2, content: 'AMDT 01/18', start: '2018-03-10', end: '2018-04-26', subgroup: 'amdt', subgroupOrder: 1 },
  //   { id: 3, group: 2, content: 'AIRAC AMDT 01/18', start: '2018-04-26', end: '9999-04-26', subgroup: 'amdt', subgroupOrder: 1, className: 'airac' },
  //   { id: 4, group: 1, content: 'SUP 01/18', start: '2018-04-16', end: '2018-08-19' },
  //   { id: 6, group: 1, content: 'Update Notice', start: '2018-06-27', end: '2018-07-20' }
  // ]);
}
