export class CreateUserDTO {
    userName?: string;
    password?: string;
    passwordConfirm?: string;
    firstName?: string;
    lastName?: string;
    email?: string
    enabled?: boolean;
    role?: string[];

    constructor(options: {
        userName?: string,
        password?: string,
        passwordConfirm?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        enabled?: boolean
        role?: string[]
    } = {}) {
        this.userName = options.userName;
        this.password = options.password;
        this.passwordConfirm = options.passwordConfirm;
        this.firstName = options.firstName;
        this.lastName = options.lastName;
        this.email = options.email;
        this.enabled = options.enabled;
        this.role = options.role;
    }
}
