export class EditGroupDTO{
    id?: string;
    groupName?: string;
    

    constructor(options: {
        //id?: string,
        id?: string,
        groupName?: string,
    } = {}) {
        //this.id = options.id || '';
        this.id = options.id;
        this.groupName = options.groupName;
       
    }
}