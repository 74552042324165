<!-- Choose the type of centre point -->
<button mat-icon-button [matMenuTriggerFor]="pointTypeMenu" aria-label="" matTooltip="Centre point defined by...">
  <ng-container [ngSwitch]="centrePointType">
    <mat-icon *ngSwitchCase="gmlPointType.COORDINATES" color="primary">edit_location</mat-icon>
    <mat-icon *ngSwitchCase="gmlPointType.POINT_REFERENCE" color="primary">insert_link</mat-icon>
  </ng-container>
</button>
<mat-menu #pointTypeMenu="matMenu">
  <button mat-menu-item (click)="setCentrePointType(gmlPointType.COORDINATES)">
    <mat-icon color="primary">edit_location</mat-icon>
    <span>Latitude and longitude</span>
  </button>
  <button mat-menu-item (click)="setCentrePointType(gmlPointType.POINT_REFERENCE)">
    <mat-icon color="primary">insert_link</mat-icon>
    <span>A feature's location</span>
  </button>
</mat-menu>

<!-- Centre point fields -->
<latlong *ngIf="centrePointType === gmlPointType.COORDINATES" [coordinates]="centrePointCoordinates"
  title="Centre point at" (coordinatesChange)="onCoordinatesChange($event)" [isHighlight]="isHighlight"></latlong>
<point-reference *ngIf="centrePointType === gmlPointType.POINT_REFERENCE" [segment]="circle" title="Centre point on"
  (startPointCoordinatesChanged)="onCoordinatesChange($event)" [isHighlight]="isHighlight"></point-reference>

<!-- Radius -->
<mat-form-field class="radius">
  <mat-label>Radius</mat-label>
  <input matInput type="number" [(ngModel)]="radius" (change)="onRadiusChange($event)" [style.background-color]="color">
  <!-- We don't support point annotations yet -->
  <!-- No delete button because gml:radius is not nillable.
          But gml:pos may be empty.
        -->
  <!-- comment out because we decide to remove the revert button from each field and keep the top one of geo-editor -->
  <!-- <button type="button" mat-icon-button matSuffix matTooltip="Revert to initial value">
    <mat-icon color="primary" aria-label="Set property to nil">undo</mat-icon> 
  </button>-->
  <!-- map button should be on the AIXM property, not each point-->
  <mat-hint>Number</mat-hint>
</mat-form-field>

<mat-form-field class="radius-uom">
  <mat-label>Unit</mat-label>
  <mat-select [(ngModel)]="radiusUom" (selectionChange)="onRadiusUomChange($event)" [style.background-color]="color">
    <mat-option value="m">meters</mat-option>
    <mat-option value="km">kilometers</mat-option>
    <mat-option value="[nmi_i]">nautical miles</mat-option>
  </mat-select>
</mat-form-field>