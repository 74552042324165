import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { UserManageMentService } from '../service/user-management.service';

export interface DialogData {
  result: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

  constructor(private userManageMentService: UserManageMentService,private snackBar: MatSnackBar, public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

  Cancel():void{
  this.dialogRef.close();
  }

  deleteGroup(groupId: string): void {
    this.userManageMentService.deleteGroup(groupId)
    .subscribe(result => {
      this.dialogRef.close();
      //this.result = result,
      //console.log("Claimed Task: " + result);
      this.snackBar.open(result, "OK", {
        duration: 2000,
      });
    });
  }
 
}
