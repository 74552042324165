<div class="wrapper">

  <div class="space">
    <h2 style="margin: 0;"> Quality Assessment Projects </h2>
  </div>

  <mat-card>
    <mat-card-content>
      <!-- Search Field -->
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
        <mat-icon matSuffix (click)="applyFilter('');filter.value=''" style="cursor:pointer">clear</mat-icon>
      </mat-form-field>

      <!-- Table -->
      <mat-table #table [dataSource]="qaproject_dataSource" matSort matSortDirection="asc" matSortDisableClear>

        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
          <mat-cell *matCellDef="let qaproject"><a
              [routerLink]="['/quality/detail-project', qaproject.name, qaproject.pid]">{{qaproject.name}}</a></mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
          <mat-cell *matCellDef="let qaproject">{{qaproject.status}}</mat-cell>
        </ng-container>

        <!-- Updated Column -->
        <ng-container matColumnDef="latestActivityDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Updated </mat-header-cell>
          <mat-cell *matCellDef="let qaproject">{{qaproject.latestActivityDate}}</mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="edit_icon">
                <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
                <mat-cell *matCellDef="let qaproject">
                  <i class="material-icons" [routerLink]="['/quality/detail-project', qaproject.name]" style="cursor:pointer" *ngIf="qaproject.status !== 'COMPLETED'"
                     matTooltip="Edit Project">edit</i>
                </mat-cell>
              </ng-container> -->

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <!-- Paginator -->
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-content>

    <mat-card-actions class="card-action">
      <mat-icon *ngIf="isProjectManager" class="md-48" style="cursor:pointer"
        [routerLink]="['/quality/create-quality-project']" matTooltip="Add new Project">
        add_circle
      </mat-icon>
    </mat-card-actions>
  </mat-card>
</div>