import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-version-dialog',
  templateUrl: './success-version-dialog.component.html',
  styleUrls: ['./success-version-dialog.component.css']
})
export class SuccessVersionDialogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<SuccessVersionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  Success():void{
    this.dialogRef.close();
  }

}
