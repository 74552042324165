import { Assignment } from '../model/assignment';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskService } from './../service/task.service';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  private taskId: string;
  constructor(private taskService: TaskService, public dialogRef: MatDialogRef<ReviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

   onNoClick(): void {
/*    console.log("In component: Cancel Function" + this.data.sectionId);
    this.taskService.approved({date: null, title: '', username: '', taskId: 'review', taskName: '',
    processInstanceId: 'SampleProcess', sectionId: this.data.sectionId, dueDate: ''} as Assignment, 'no')
    .subscribe(assignment => this.taskId = assignment), 
      console.log("Complete Task: " + this.taskId);
    this.dialogRef.close();*/
  } 

   approved(): void {
/*    console.log("In component: Approved Function" + this.data.sectionId);
   
    this.taskService.approved({date: null, title: '', username: '', taskId: 'review', taskName: '',
    processInstanceId: 'SampleProcess', sectionId: this.data.sectionId, dueDate: ''} as Assignment, 'yes')
    .subscribe(assignment => this.taskId = assignment), 
      console.log("Complete Task: " + this.taskId);*/
  } 
}
