import { UsergroupListComponent } from './usergroup-list/usergroup-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataProviderRoutingModule } from './data-provider-routing.module';
import { AppMaterialModule } from '../app.material.module';

import { DataProviderComponent } from './data-provider/data-provider.component';
import { DataProviderService } from './service/data-provider.service';
import { LinkdialogComponent } from './linkdialog/linkdialog.component';
import { MatSortModule } from '@angular/material/sort';
import {  MatTableModule } from '@angular/material/table';

@NgModule({
    imports: [
        CommonModule,
        DataProviderRoutingModule,
        AppMaterialModule,
        MatSortModule,
        MatTableModule
    ],
    declarations: [DataProviderComponent, UsergroupListComponent, LinkdialogComponent],
    providers: [
        DataProviderService
    ]
})
export class DataProviderModule { }