<table>
  <tr>
    <td>
      <div>
        <div>

          <!-- Buttons to select the type of shape to draw -->

          <mat-button-toggle-group #editorToggle="matButtonToggleGroup" (change)="openConfirmationDialog($event)"
            multiple [(ngModel)]="toggleValue">
            <!-- Circle -->
            <mat-button-toggle value="CircleByCenterPoint" matTooltip="Circle defined by centre and radius"
              [disabled]="isSurface === false">
              <mat-icon [color]="editorToggle.value == 'CircleByCenterPoint' ? 'primary' : ''">adjust</mat-icon>
            </mat-button-toggle>
            <!-- Curve segments -->
            <mat-button-toggle value="segments" matTooltip="List of points">
              <mat-icon [color]="editorToggle.value == 'segments' ? 'primary' : ''">timeline</mat-icon>
            </mat-button-toggle>
            <!-- GML text -->
            <mat-button-toggle value="gml" matTooltip="GML code">
              <mat-icon [color]="editorToggle.value == 'gml' ? 'primary' : ''">integration_instructions</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
          <!-- <button type="button" mat-icon-button matTooltip="Revert to initial value">
            <mat-icon color="primary" aria-label="Set property to nil">undo</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Annotations">
            <mat-icon color="primary" aria-label="Annotations">note</mat-icon>
          </button>
          <button type="button" mat-icon-button>
            <mat-icon color="primary" aria-label="Description">info</mat-icon>
          </button> 
          <button type="button" color="primary" mat-icon-button>
            <mat-icon matTooltip="Location on map">map</mat-icon>
          </button> -->
        </div>

        <!-- List of points -->
        <geo-point-list *ngIf="editorToggle.value == 'segments'" [segmentCollection]="isCircle()? [] : segments"
          [isSurface]="isSurface" (segmentCollectionChange)="onGeoDataChange($event)" (onError)="onerror($event)"
          [isCircle]="isCircle()" [isHighlight]="isHighlight" (radiusChange)="onRadiusChange()"
          (radiusUomChange)="onRadiusUomChange()" (centrePointChange)="onCentrePointChange()" [isRevert]="isRevert"
          [isEmpty]="isEmpty" [direction]="direction" (isGeoEmpty)="isSegmentEmpty($event)"
          (arcDirectionChange)="onArcDirectionChanged($event)">
        </geo-point-list>
        <!-- For a polygon, repeat the first point (not for a circle) -->

        <!-- Circle: it should be the only item in the segments collection -->
        <geo-circle *ngIf="editorToggle.value == 'CircleByCenterPoint'" [circle]="getSegments()[0]"
          (circleChange)="onCircleChange($event)" [isHighlight]="isHighlight" (radiusChange)="onRadiusChange()"
          (radiusUomChange)="onRadiusUomChange()" (centrePointChange)="onCentrePointChange()"></geo-circle>

        <!-- GML source -->
        <mat-form-field *ngIf="editorToggle.value == 'gml' && gml" class="large" appearance="fill">
          <mat-label>GML code</mat-label>
          <textarea matInput rows="10">{{gml}}</textarea>
        </mat-form-field>
      </div>
    </td>
    <td [hidden]="!isShowMap">
      <div [id]="mapId" class="geoEditorMap"></div>
      <mat-form-field appearance="fill">
        <mat-label>Scale unit</mat-label>
        <mat-select (selectionChange)="setScaleUnit($event.value)">
          <mat-option *ngFor="let unit of units" [value]="unit.value">
            {{unit.key}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </tr>
</table>