<!-- Start point -->
<div matLine>

  <!-- First choose the type of start point-->
  <!-- TODO If the previous segment has parallel interpolation, then this semgent's first point must
  be of type COORDINATES and the latitude must be equal to the previous segment's start point latitude. -->
  <button mat-icon-button [matMenuTriggerFor]="pointTypeMenu" aria-label="" matTooltip="Point defined by...">
    <ng-container [ngSwitch]="startPointType">
      <mat-icon *ngSwitchCase="gmlPointType.COORDINATES" color="primary">edit_location</mat-icon>
      <mat-icon *ngSwitchCase="gmlPointType.POINT_REFERENCE" color="primary">insert_link</mat-icon>
      <mat-icon *ngSwitchCase="gmlPointType.GEO_BORDER_POINT" color="primary">flag</mat-icon>
    </ng-container>
  </button>
  <mat-menu #pointTypeMenu="matMenu">
    <!-- Set point type to coordinates
        Disabled if startPoint is GEO_BORDER_POINT because to change such point, user must change
        the previous segment's interpolation -->
    <button mat-menu-item (click)="setStartPointType(gmlPointType.COORDINATES)"
      [disabled]="startPointType === gmlPointType.GEO_BORDER_POINT">
      <mat-icon color="primary">edit_location</mat-icon>
      <span>Latitude and longitude</span>
    </button>
    <!-- Set point type to point reference
        Disabled if startPoint is GEO_BORDER_POINT because to change such point, user must change
        the previous segment's interpolation -->
    <button mat-menu-item (click)="setStartPointType(gmlPointType.POINT_REFERENCE)"
      [disabled]="startPointType === gmlPointType.GEO_BORDER_POINT">
      <mat-icon color="primary">insert_link</mat-icon>
      <span>A linked feature</span>
    </button>
    <!--
    Set point type to GEO_BORDER_POINT
    Always disabled: user should change the interpolation, then this point will be changed to GEO_BORDER_POINT automatically.
    -->
    <button mat-menu-item (click)="setStartPointType(gmlPointType.GEO_BORDER_POINT)">
      <mat-icon color="primary">flag</mat-icon>
      <span>A point on a GeoBorder</span>
    </button>
  </mat-menu>


  <!-- Different point input depending on chosen pointType
        Disabled if startPoint is GEO_BORDER_POINT because to change such point, user must change
        the previous segment's interpolation -->
  <ng-container [ngSwitch]="startPointType">
    <latlong *ngSwitchCase="gmlPointType.COORDINATES" [coordinates]="startPointCoordinates" [segment]="segment"
      [previousPoint]="previousPoint" (coordinatesChange)="onStartPointCoordinatesChanged($event)"
      (onError)="onerror($event)" [isHighlight]="isHighlight" [isSurface]="isSurface" [isFirstSegment]="isFirstSegment">
    </latlong>
    <point-reference *ngSwitchCase="gmlPointType.POINT_REFERENCE" [segment]="segment" [previousPoint]="previousPoint"
      [isHighlight]="isHighlight" (startPointCoordinatesChanged)="onStartPointCoordinatesChanged($event)">
    </point-reference>
    <geo-border-point *ngSwitchCase="gmlPointType.GEO_BORDER_POINT" [segment]="segment" [previousPoint]="previousPoint"
      (startPointCoordinatesChanged)="onStartPointCoordinatesChanged($event)" (onError)="onerror($event)"
      [isHighlight]="isHighlight" [isSurface]="isSurface" [isFirstSegment]="isFirstSegment">
    </geo-border-point>
  </ng-container>

</div>
<!-- Segment interpolation if requested, except for geoBorder, which defines its own interpolation -->
<div matLine class="interpolation" *ngIf="showInterpolation && startPointType !== gmlPointType.GEO_BORDER_POINT">
  <!-- Then choose the segment interpolation, if required (great circle by default) -->
  <span>Along: </span>
  <button mat-icon-button [matMenuTriggerFor]="interpolationTypeMenu">
    <mat-icon *ngIf="interpolation === gmlInterpolation.GREAT_CIRCLE" matTooltip="Great circle">looks</mat-icon>
    <mat-icon *ngIf="interpolation === gmlInterpolation.PARALLEL" matTooltip="Parallel">power_input
    </mat-icon>
    <mat-icon *ngIf="interpolation === gmlInterpolation.ARC_BY_CENTRE_CLOCKWISE" class="mirror"
      matTooltip="Clockwise arc">
      settings_backup_restore
    </mat-icon>
    <mat-icon *ngIf="interpolation === gmlInterpolation.ARC_BY_CENTRE_ANTICLOCKWISE" matTooltip="Anticlockwise arc">
      settings_backup_restore
    </mat-icon>
  </button>
  <mat-menu #interpolationTypeMenu="matMenu">
    <button mat-menu-item (click)="interpolation = gmlInterpolation.GREAT_CIRCLE; setInterpolation(interpolation);">
      <mat-icon>looks</mat-icon>
      <span>Great circle (default)</span>
    </button>
    <button mat-menu-item (click)="interpolation = gmlInterpolation.PARALLEL; setInterpolation(interpolation);">
      <mat-icon>power_input</mat-icon>
      <span>Parallel</span>
    </button>
    <button mat-menu-item
      (click)="interpolation = gmlInterpolation.ARC_BY_CENTRE_CLOCKWISE; direction = 'clockwise'; setDirection(direction);">
      <mat-icon class="mirror">settings_backup_restore</mat-icon>
      <span>Clockwise arc</span>
    </button>
    <button mat-menu-item
      (click)="interpolation = gmlInterpolation.ARC_BY_CENTRE_ANTICLOCKWISE; direction = 'AntiClockwise'; setDirection(direction);">
      <mat-icon>settings_backup_restore</mat-icon>
      <span>Anticlockwise arc</span>
    </button>
  </mat-menu>

  <geo-arc-by-center
    *ngIf="interpolation === gmlInterpolation.ARC_BY_CENTRE_CLOCKWISE || interpolation === gmlInterpolation.ARC_BY_CENTRE_ANTICLOCKWISE"
    [segment]="segment" (radiusChange)="onRadiusChange()" (radiusUomChange)="onRadiusUomChange()"
    (segmentChange)="onSegmentChange($event)" (centrePointChange)="onCentrePointChange()" [isHighlight]="isHighlight">
  </geo-arc-by-center>
</div>