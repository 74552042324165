import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigService } from '../..//utils/config.service';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.css']
})
export class AccountManagementComponent implements OnInit {

  srcUrl: string;
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer,private configService : ConfigService) {
      this.srcUrl = ConfigService.settings.keycloakServer.url + "/realms/"  + ConfigService.settings.keycloakServer.realm + "/account" ;
      //console.log(this.srcUrl);
   }

  ngOnInit() {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.srcUrl);
  }

}
