import { QualityProjectService } from '../service/quality-project.service';
import { Component, OnInit } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import {  MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-new-item-dialog',
  templateUrl: './add-new-item-dialog.component.html',
  styleUrls: ['./add-new-item-dialog.component.css']
})
export class AddNewItemDialogComponent implements OnInit {
  //private result: string;
  constructor(private qaService: QualityProjectService, private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddNewItemDialogComponent>) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addNewItem(name: string) {
    console.log(name);
  }

}
