import { DataProvider } from "../../data-provider/model/dataprovider";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { DataProviderService } from "../../data-provider/service/data-provider.service";
import { DataprofileService } from "../service/dataprofile.service";
import {  MatTableDataSource } from "@angular/material/table";
import {  MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  tap,
} from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { UntypedFormControl } from "@angular/forms";
import { combineLatest } from "rxjs";
import { ProfileVersion } from "../model/profileversion.model";

@Component({
  selector: "app-data-provider",
  templateUrl: "./data-provider.component.html",
  styleUrls: ["./data-provider.component.css"],
})
export class DataProviderComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ["select", "name"];

  dataSource: MatTableDataSource<DataProvider> = new MatTableDataSource([]);

  selection = new SelectionModel<DataProvider>(true, []);

  searchControl = new UntypedFormControl();

  public dataproviders: DataProvider[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataProviderService: DataProviderService,
    private dataProfileService: DataprofileService,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.getDataProviders();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getDataProviders(): void {
    const searchTerms$ = this.searchControl.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      startWith("")
    );

    const dataSource$ = this.dataProviderService.getDataProviders();
    const profileId = this.route.snapshot.params.profileId;
    const versionId = this.route.snapshot.params.versionId;
    const selectedProvider$ = this.dataProfileService.getProfileVersionbyNumber(
      profileId,
      versionId
    );

    combineLatest([dataSource$, selectedProvider$, searchTerms$])
      .pipe(
        map(
          ([dataSource, selectedProvider, searchTerm]: [
            DataProvider[],
            ProfileVersion,
            string
          ]) => {
            return dataSource.filter((value) => {
              const isDataProvider = value.dataprovider;
              const containsSearch = value.name
                ?.trim()
                ?.toLowerCase()
                .includes(searchTerm?.trim()?.toLocaleLowerCase());

              const findOriginators = selectedProvider.originators.find(
                (origin) => {
                  return (
                    (origin.identifier as any).value === value.identifier.value
                  );
                }
              );
              return isDataProvider && containsSearch && !findOriginators;
            });
          }
        ),
        tap((result) => {
          this.dataSource.data = result;
        })
      )
      .subscribe();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  assign(): void {
    let versionId = this.route.snapshot.params["versionId"];
    this.dataProfileService
      .assign(versionId, this.selection.selected)
      .pipe(
        tap(() => {
          this._location.back();
        })
      )
      .subscribe();
  }
}
