import {AbstractControl} from '@angular/forms';
import { Temporality } from '../model/temporality';
export class DateValidation {

    static CheckDate(AC: AbstractControl) {
       let effectiveDate = new Date(AC.get('effectiveDate').value);
       let cancelDate =  new Date(AC.get('cancelDate').value); 
       let temporality = AC.get('temporality').value;
       console.log("Temporality is "+ temporality)
        if((cancelDate.getTime() < effectiveDate.getTime() || cancelDate.getTime() == effectiveDate.getTime())
        && temporality == Temporality.TEMPORARY) {
            console.log('false');
            AC.get('cancelDate').setErrors( {CheckDate: true} )
            return {CheckDate: true}
        } else {
            console.log('true');
            AC.get('cancelDate').setErrors(null)
            return null;
        }
    }
}