import { UserDetailsService } from './UserDetails.service';
import { Injectable, OnInit } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * This provides a wrapper over the ng2 HttpClient class that insures tokens are refreshed on each request.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token: string;
  constructor(public auth: UserDetailsService) {
    //get token
    // auth.getToken().then(function(result) { 
    //   console.log(result);
    //   this.token = result;
    // });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(this.auth.getToken());
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ` + this.auth.getToken(),
        DefaultDatastore: this.auth.getDefaultDatastore()
      }
    });
    console.log(request);
    return next.handle(request);
  }
}