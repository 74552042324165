<mat-card>
  <mat-card-content>
    <div class="space">
      <span><a [routerLink]="['/user-management']">Groups/</a></span>
      <span>{{group.groupName}}</span>
    </div>
    <!--           <div>
            <h2 class="space">{{group.groupName}}
          <i class="material-icons" style="cursor:pointer"
            matTooltip="Delete User">delete</i></h2>
          </div>  -->

    <mat-tab-group mat-stretch-tabs="false">
      <mat-tab label="Details">
        <form name="editGroupForm" [formGroup]="editForm" (ngSubmit)="updateGroup(editForm.value)">

          <div class="form-group">
            <div class="label" for="groupName">
              <label> Group Name </label>
            </div>
            <div class="field">
              <mat-form-field>
                <input matInput class="border" name="gpName" formControlName="gpName" [(ngModel)]="group.groupName"
                  required />
              </mat-form-field>
            </div>
          </div>
          <br />

          <div class="justify">
            <button mat-raised-button mat-button color="primary" class="example-btn-width"
              [disabled]="!editForm.valid || editForm.pristine">Edit Group</button>
            <button mat-raised-button mat-button color="primary" class="example-btn-width right">Cancel</button>
          </div>
        </form>
      </mat-tab>

      <mat-tab label="Credentials"> Credentials </mat-tab>

      <mat-tab label="Role Mappings">

        <div class="justify">
          <mat-card class="box">
            <mat-card-title>
              <h2>Available Roles</h2>
            </mat-card-title>
            <mat-card-content>

              <mat-selection-list #availableRole
                (selectionChange)="onSelection($event, availableRole.selectedOptions.selected)">
                <mat-list-option *ngFor="let role of group.availbaleRoles" [value]='role'>
                  {{role}}
                </mat-list-option>
              </mat-selection-list>

            </mat-card-content>

            <mat-card-actions class="space">
              <button type="submit" mat-raised-button mat-button color="primary" (click)="assignRole();">
                Add selected</button>
            </mat-card-actions>
            <br />
          </mat-card>

          <mat-card class="box">
            <mat-card-title>
              <h2>Assigned Roles</h2>
            </mat-card-title>
            <mat-card-content>

              <mat-selection-list #assignedRole
                (selectionChange)="onSelection($event, assignedRole.selectedOptions.selected)">
                <mat-list-option *ngFor="let role of group.assignedRoles" [value]='role'>
                  {{role}}
                </mat-list-option>
              </mat-selection-list>

            </mat-card-content>

            <mat-card-actions class="space">
              <button type="submit" mat-raised-button mat-button color="primary" (click)="unassignRole();">
                Remove selected</button>
            </mat-card-actions>
            <br />
          </mat-card>
        </div>

      </mat-tab>

    </mat-tab-group>
  </mat-card-content>

</mat-card>