<mat-toolbar>
  <span>
    <h3 style="align-items: center; display: flex">FAIVA:
      <img src="assets/images/FABEC-logo.png" height="38" style="margin: 0 10px 0 10px" /> Aeronautical Information
      Validator
    </h3>
  </span>
</mat-toolbar>


<mat-card>
  <mat-card-content>
    <mat-stepper orientation="vertical" linear #stepper>

      <mat-step [stepControl]="uploadFormGroup">
        <form [formGroup]="uploadFormGroup">
          <ng-template matStepLabel>Upload a dataset</ng-template>
          <div matStepContent>

            <div class="space">
              <mat-label>Select a dataset file</mat-label>
            </div>

            <div class="space">
              <input type="file" hidden ng2FileSelect #file [uploader]="uploader" formControlName="datasetCtl" required
                (change)="onFileChanged($event)" />
              <button type="button" matTooltip="Add Attachment" class="material-icons" (click)="file.click()">
                &#xE226;</button>
            </div>
            <br>
            <div class="next-selectedfiles" *ngFor="let item of uploader.queue">
              {{ item?.file?.name }}
            </div>
            <br>

            <div id="action-wrapper" class="action-wrapper">
              <button mat-raised-button color="primary" (click)="uploadAixmDocument()"
                [disabled]="!isFileSelected()">
                Upload
              </button>
              <button mat-button matStepperNext class="stepbtn" [disabled]="!isUploaded">Next</button>
            </div>
            <div>
              Only AIXM 5.1 and 5.1.1 datasets are supported at the moment.
              The root element must be <code>AIXMBasicMessage</code>, in the namespace
              <code>http://www.aixm.aero/schema/5.1/message</code> or
              <code>http://www.aixm.aero/schema/5.1.1/message</code>. XInclude, and
              external entities are not supported.
              File size limit: 250MB.
            </div>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="profileFormGroup">
        <form [formGroup]="profileFormGroup">
          <ng-template matStepLabel>Select a schema profile</ng-template>
          <ng-template matStepContent>

            <mat-label class="space">Dataset uploaded: {{fileName}}</mat-label>
            <br>
            <div class="space">
              Version : {{aixmVersion}}
            </div>
            <br><br>
            <mat-form-field class="formField space">
              <mat-select formControlName="schemaProfilesCtl" required [(value)]="selectedProfile"
                placeholder="Select a schema profile" (selectionChange)="onSchemaChange($event)">
                <mat-option *ngFor="let profile of schemaProfiles" [value]="profile">
                  {{profile.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <button mat-button class="stepbtn" matStepperPrevious>Back</button>
            <button mat-button class="stepbtn" matStepperNext [disabled]="selectedProfile.length==0">Next</button>
          </ng-template>
        </form>
      </mat-step>

      <mat-step [stepControl]="bizruleFormGroup">
        <form [formGroup]="bizruleFormGroup">
          <ng-template matStepLabel>Select business rules</ng-template>
          <ng-template matStepContent>

            <mat-label class="space">Dataset uploaded: {{fileName}}:</mat-label>
            <br>
            <div class="space">
              Version : {{aixmVersion}}
            </div>
            <br>
            <mat-form-field class="formField space">
              <mat-select name="businessRules" [(value)]="selectedRules" placeholder="Select business rules"
                (selectionChange)="onBizRulesChange($event)" multiple>
                <mat-option *ngFor="let rule of businessRules" [value]="rule" [matTooltip]="rule.title">
                  {{rule.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br>
            <button mat-button class="stepbtn" matStepperPrevious>Back</button>
            <button mat-button class="stepbtn" matStepperNext *ngIf="doValidate">Next</button>
            <button mat-button class="stepbtn" [disabled]="doValidate" (click)="validate()"
              matStepperNext>Validate</button>
          </ng-template>
        </form>
      </mat-step>

      <!-- Validation Report  -->
      <mat-step [stepControl]="reportFormGroup">
        <form [formGroup]="reportFormGroup">
          <ng-template matStepLabel>Validation Report</ng-template>
          <ng-template matStepContent>
            <app-validation-report [aixmVersion]="aixmVersion" [taskId]="taskId" [selectedProfile]="selectedProfile"
              [selectedRules]="selectedRules" [doValidate]="doValidate" [fileName]="fileName"></app-validation-report>
            <br />
            <button mat-button class="stepbtn" matStepperPrevious>Back</button>
            <!-- <button mat-button (click)="stepper.reset();reset()">Start Over</button> -->
          </ng-template>
        </form>
      </mat-step>

    </mat-stepper>
  </mat-card-content>
</mat-card>
<div class="space" style="font-size: smaller">FAIVA is a <a href="https://www.fabec.eu/">FABEC</a> project developed and
  hosted by
  <a href="https://www.nilacandi.com/">Nilacandi</a> on Facilis.aero.
</div>