<h3  mat-dialog-title> Edit Log Entry of SN No: {{data.id}} </h3>

<div mat-dialog-content>
       
        <mat-form-field>
            <textarea matInput [(ngModel)]="data.desp" placeholder="Description" #logEntryDesp></textarea>
        </mat-form-field>

</div>
  
  <div mat-dialog-actions>

      <button mat-raised-button mat-button color="primary" [mat-dialog-close]="data"
      (click)="edit(logEntryDesp.value);">Confirm</button>  
    
      <button mat-raised-button mat-button color="primary" (click)="onNoClick()">Cancel</button>
  </div>