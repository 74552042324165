<mat-toolbar *ngIf="hasReportFileName()">
  <span>
    <h3 style="align-items: center; display: flex">FAIVA:
      <img src="assets/images/FABEC-logo.png" height="38" style="margin: 0 10px 0 10px" /> Aeronautical Information
      Validator
    </h3>
  </span>
</mat-toolbar>

<mat-card class="report" *ngIf="doValidate">
  <mat-card-content>
    <form>
      <mat-label class="space">Date: </mat-label>
      <div class="space1">{{date}}</div>
      <br>
      <mat-label class="space">Result: </mat-label>
      <div class="space1"
        [ngStyle]="{'color': (result.toUpperCase() === 'VALID') ? 'green' : (result.toUpperCase() === 'INVALID' || result.toUpperCase() === 'FAILED') ? 'red' : (result.toUpperCase() === 'PENDING') ? 'blue' : ''}">
        {{transformValidationReportStatus(result)}}</div>
      <br>
      <mat-label class="space">Dataset:</mat-label>
      <div class="space1">{{fileName}}</div>
      <br>
      <mat-label class="space">Version: </mat-label>
      <div class="space1">{{aixmVersion}}</div>
      <br>
      <mat-label class="space">Profiles: </mat-label>
      <div class="space1" *ngFor="let profile of selectedProfileName">{{profile}}</div>
      <br>
      <mat-label class="space">Business Rules: </mat-label>
      <div class="space1" *ngFor="let rule of selectedRulesName">{{rule}}</div>
      <br>
      <mat-label class="space">Certificate: </mat-label>
      <div class="space1" style="cursor:pointer;" (click)="getCertificate(certificateFileName)"
        [ngStyle]="{'color': 'blue'}">{{certificateLabel}}</div>
    </form>
    <br>

    <div class="warning">Disclaimer: this validator checks the input dataset against technical and operational
      rules as selected in the interface. It
      does <strong>not</strong> verify the correctness of the data but only its conformance to these rules.
    </div>

    <mat-card>
      <mat-card-content>
        <div style="display: flex; align-items: center;">
          <mat-form-field style="position: relative; top: 5px;">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
            <mat-icon matSuffix (click)="applyFilter('');filter.value=''" style="cursor:pointer">clear</mat-icon>
          </mat-form-field>

          <input type="checkbox" style="margin-left: 20px;" [(ngModel)]="isChecked"
            (change)="checkGroup(isChecked?'Group':'Ungroup')" value=isChecked>
          <span> Group </span>

          <mat-form-field class="formField space">
            <mat-select required [(value)]="displayedColumnsName" placeholder="Group by ..."
              (selectionChange)="onGroupnameChange($event)">
              <mat-option *ngFor="let groupby of groupByColumnName" [value]="groupby">
                {{groupby.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-raised-button color="primary" matTooltip="Export Validation report to csv file"
            style="margin-left: 20px;" (click)="exportToCsv()">Export to
            Csv</button>

          <button mat-raised-button color="primary" style="margin-left: 20px;" (click)="toggle()">
            {{ allRowsExpanded ? 'Collapse All' : 'Expand All' }}
          </button>

          <!-- <span *ngIf="isChecked">
            Group
          </span>
          <span *ngIf="!isChecked">
            Ungroup
          </span> -->
        </div>

        <!-- <mat-table #table [dataSource]="validatedResult_DataSource" matSort matSortActive="type" matSortDirection="asc"
          matSortDisableClear> -->

        <!-- Type Column -->
        <!-- <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
            <mat-cell *matCellDef="let status">
              {{status.type}}
            </mat-cell>
          </ng-container> -->

        <!-- Schema name Column -->
        <!-- <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Schema name </mat-header-cell>
            <mat-cell *matCellDef="let status">
              {{status.schemaName}}
            </mat-cell>
          </ng-container> -->

        <!-- Flag Column -->
        <!-- <ng-container matColumnDef="flag">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Severity </mat-header-cell>
            <mat-cell *matCellDef="let status">
              {{status.flag}}
            </mat-cell>
          </ng-container> -->

        <!-- Description Column -->
        <!-- <ng-container matColumnDef="error">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
            <mat-cell *matCellDef="let status">
              {{status.errorMessage}}
            </mat-cell>
          </ng-container> -->

        <!-- Location Column -->
        <!--  <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Location </mat-header-cell>
            <mat-cell *matCellDef="let status">
              {{status.location}}
            </mat-cell>
          </ng-container>

          <mat-header-row class="header" *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <br />

        <mat-card-footer>
          <mat-paginator #paginator [pageSize]="50" [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons>
          </mat-paginator>
        </mat-card-footer> -->

        <mat-table [dataSource]="validatedResult_DataSource" class="mat-elevation-z8">

          <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
            <mat-header-cell *matHeaderCellDef><!-- {{ column.field }} -->
              <span *ngIf="column.field == 'type'">
                Type
              </span>
              <span *ngIf="column.field == 'name'">
                Schema Name
              </span>
              <span *ngIf="column.field == 'flag'">
                Severity
              </span>
              <span *ngIf="column.field == 'error'">
                Description
              </span>
              <span *ngIf="column.field == 'location'">
                Location
              </span>
            </mat-header-cell>

            <mat-cell *matCellDef="let row">
              <span *ngIf="column.field == 'name'">
                {{ row["schemaName"] }}
              </span>
              <span *ngIf="column.field == 'error'">
                {{ row["errorMessage"] }}
              </span>
              <span *ngIf="column.field != 'name' && column.field!='error'">
                {{ row[column.field] }}
              </span>
            </mat-cell>
          </ng-container>

          <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

          <!-- Group header -->
          <ng-container matColumnDef="groupHeader">
            <mat-cell colspan="999" *matCellDef="let group">
              <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
              <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
              <strong>{{groupByColumns[group.level-1]}} = {{group[groupByColumns[group.level-1]]}}
                ({{group.totalCounts}})</strong>
            </mat-cell>
          </ng-container>

          <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)">
          </mat-row>
        </mat-table>

        <mat-card-footer>
          <mat-paginator #paginator [pageSize]="50" [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons>
          </mat-paginator>
        </mat-card-footer>

      </mat-card-content>
    </mat-card>
  </mat-card-content>
</mat-card>

<div class="warning" *ngIf="!doValidate">
  Click <strong>Validate</strong> button in previous step to see the validation report!
</div>

<div *ngIf="hasReportFileName()" class="space" style="font-size: smaller">FAIVA is a <a
    href="https://www.fabec.eu/">FABEC</a> project developed and
  hosted by
  <a href="https://www.nilacandi.com/">Nilacandi</a> on Facilis.aero.
</div>