<div class="input-form">

  <mat-card class="mat-elevation-z0">
    <form (ngSubmit)="onSubmit()" [formGroup]="aeroInputForm" novalidate>

      <mat-card-header>
        <mat-card-subtitle *ngIf="unId">Update Notice: <a routerLink='/update/id/{{unId}}'>{{unName}}</a>
        </mat-card-subtitle>
        <mat-card-title>{{featureType}}: {{gmlname}}</mat-card-title>

        <!-- This fills the remaining space of the current row -->
        <span class="fill-remaining-space"></span>

        <span>
          <button type="button" mat-icon-button matTooltip="Assign allowed Editor" (click)="openDataproviderDialog()"
            *ngIf="state != 'new' && isAisReviewer()">
            <mat-icon color="primary" aria-label="Assign allowed Editor">
              lock
            </mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Decommission feature" [disabled]="isDisableDecommission()"
            (click)="decommission()" *ngIf="!readonly">
            <mat-icon color="warn" aria-label="decommission feature">
              delete
            </mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Annotations" [disabled]="readonly && !featureAnnotationProperty"
          (click)="showAnnotations? hideAnnotaion(): createAnnotation()">
            <mat-icon color="primary" aria-label="Annotations" 
            [ngClass]="{'disable':readonly && !featureAnnotationProperty, 'enable': featureAnnotationProperty}">note</mat-icon>
          </button>
          <button type="button" mat-icon-button (click)="openDialog()" matTooltip="Search another feature">
            <mat-icon color="primary" aria-label="Search another feature">search</mat-icon>
          </button>
          <button mat-icon-button type="submit" [disabled]="!aeroInputForm.dirty || !aeroInputForm.valid"
            matTooltip="Save" *ngIf="!readonly">
            <mat-icon color="primary" aria-label="Save">save</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Revert to initial value"
            (click)="revert(); stopPropagation($event)" *ngIf="!readonly">
            <mat-icon color="primary" aria-label="Set property to nil">undo</mat-icon>
          </button>
          <button type="button" mat-icon-button matTooltip="Close" (click)="revert(); stopPropagation($event)"
            *ngIf="!readonly">
            <mat-icon color="primary" aria-label="Set property to nil">close</mat-icon>
          </button>
        </span>
      </mat-card-header>

      <mat-divider></mat-divider>

      <div *ngIf="state != 'new' && beginPosition" style="padding:16px;">
        <mat-label>Data valid from {{beginPosition}} to </mat-label>
        <span *ngIf="endPosition">{{endPosition}}</span>
        <span *ngIf="!endPosition">Until Further Notice</span>
        <div *ngIf="effectiveDate">
          <span>Changes effective {{effectiveDate}} </span>
          <span *ngIf="isTentative" style="font-size: 12px;">(tentative)</span>
        </div>
      </div>

      <div *ngIf="showAnnotations" class="indented">
        <im-property-switch 
          [property]="annotationProperty" 
          [parentFormGroup]="aeroInputForm"
          [arrayName]="0 + '-annotations'"
          [readonly]="readonly">
        </im-property-switch>
      </div>

      <!-- Timeline -->
      <div class="time-slice-list" *ngIf="!unId">
        <im-time-slice-list [featureId]="featureId" (selectedTSEmitter)="getPropertiesWithSelectedTS($event)">
        </im-time-slice-list>
      </div>

      <mat-card-content>
        <div *ngFor="let property of properties" class="form-row">
          <ng-container *ngIf="!isChoiceProperty(property.key)">
            <im-property-switch 
              *ngIf="properties[0]"
              [property]="property" 
              [properties]="properties"
              [parentFormGroup]="aeroInputForm" 
              [state]="state" 
              [tsId]="tsId"
              [readonly]="readonly"
              >
            </im-property-switch>
          </ng-container>
        </div>
      </mat-card-content>

      <mat-card-actions>
        <button mat-raised-button color="primary" class="example-btn-width-blue" *ngIf="!readonly" type="submit"
          [disabled]="!aeroInputForm.dirty || !aeroInputForm.valid">
          Save
        </button>
      </mat-card-actions>

    </form>
  </mat-card>
  <!-- <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong>
    <hr>{{payLoad}}
  </div>
  <pre>group string:  {{groupString | json}}</pre> -->
</div>