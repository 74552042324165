<h3  mat-dialog-title>{{data.name}}</h3>
<div mat-dialog-actions>
   
      <button mat-raised-button mat-button color="primary" class="example-btn-width" [mat-dialog-close]="data.id"  (click)="linkUnitToGroup(data.id,data.name)"
      style="cursor:pointer">
           Link
      </button>

      <!-- <button mat-raised-button mat-button color="primary" class="example-btn-width" [mat-dialog-close]="data.id"  (click)="claim(data.id)"
      style="cursor:pointer">
           Claim
      </button> -->
    
      <button mat-raised-button mat-button color="primary" class="example-btn-width" (click)="onNoClick()">Cancel</button>

</div>
