import { PropertyField } from "./property-field";
import { Property } from "./property";

export class ChoicePropertyField extends PropertyField<string> {
  controlType = "dropdown";
  propertyType: string = "choice";
  key: string;
  modified: boolean;
  value: string;
  choiceList: Partial<Property>[];
  properties: Property[];
  nestedChoices: ChoicePropertyField[];

  constructor(data: any) {
    super(data);
    this.key = `aixm:${(data.key as string).toLowerCase()}_choiceCtrl`;
    // Set modified to `true` if a choice has value, so it treated as dirty when it created in the form service
    this.modified = !!data.value;
    this.value = data.value;
    this.choiceList = this.getChoices(data);
    this.nestedChoices = data.nestedChoices.map((choice) => new ChoicePropertyField(choice));
  }

  private getChoices(data) {
    const groupChoice = data.choiceList.reduce((acc, choice) => {
      const findGroup = acc.find((value) => value.label === choice.choiceName);
      if (choice.isNestedChoice) {
        if (!findGroup) {
          acc.push({
            label: choice.choiceName,
            triggerFor: choice.choiceName.toLowerCase(),
            isNestedChoice: choice.isNestedChoice,
            choices: [{ ...choice, childOf: this.key }],
          });
        } else {
          findGroup.choices.push({ ...choice, childOf: this.key });
        }
        return acc;
      }

      acc.push(choice);

      return acc;
    }, []);

    return groupChoice;
  }
}
