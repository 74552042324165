<ng-container *ngIf="{
    items: items$ | async
   } as vm">
    <mat-card class="box column">
        <mat-card-title>
            <h2>Review</h2>
            <mat-card-subtitle>
                <!-- Available: {{items?.length - assignedItems}}
            <br />
            Assigned: {{assignedItems}}
            <br /> -->
                Completed: {{itemsAtLaterSteps}} {{itemsAtLaterSteps/ totalItems |
                percent}}
            </mat-card-subtitle>
        </mat-card-title>

        <mat-card-content>
            <div class="inner-content">
                <div class="item-wrapper" *ngFor="let item of vm.items">
                    <div class="text-inside-grid title">{{item.name}}</div>
                    <div class="right">
                        <ng-container *ngIf="isQualityController && item.claimable">
                            <i class="material-icons" (click)="openClaimDialog(item.name, item.id)"
                                style="cursor:pointer" matTooltip="Claim {{item.name}}" *ngIf="!item.assignee">
                                assignment </i>
                            <i class="material-icons" (click)="openUnclaimDialog(item.name, item.id)"
                                style="cursor:pointer"
                                matTooltip="Release {{item.name}}&#13;Assignee: {{item.assignee.name}}"
                                matTooltipClass="my-tooltip" *ngIf="item.assignee"> assignment_ind </i>
                            <i class="material-icons" (click)="openCompleteDialog(item.name,item.id)"
                                style="cursor:pointer" matTooltip="Complete" *ngIf="item.assignee"> done_all </i>
                        </ng-container>
                        <i class="material-icons" (click)="getLogOfAssignment(item.executionId)" style="cursor:pointer"
                            matTooltip="Log of Assignment"> history </i>
                    </div>
                </div>
            </div>

        </mat-card-content>
        <!-- <mat-card-footer class="space">
    <i class="material-icons md-30" style="cursor:pointer" 
           matTooltip="Add new Item">add_circle</i>
    </mat-card-footer>-->
    </mat-card>
</ng-container>