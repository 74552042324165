import { Component, 
          OnInit, 
          Attribute } from '@angular/core';
import { UntypedFormControl,
         Validators,
         UntypedFormGroup} from '@angular/forms';
import { Router, 
          ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { FileUploader } from 'ng2-file-upload';

import { UpdateNoticeService } from '../service/updatenotice.service';
import { UpdateNotice } from '../model/update-notice.model';

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
selector: 'app-review-updatenotice',
templateUrl: './review-updatenotice.component.html',
styleUrls: ['./review-updatenotice.component.css']
})

export class ReviewUpdateNoticeComponent implements OnInit {
myGroup: UntypedFormGroup;
public uploader:FileUploader = new FileUploader({url:'http://localhost:3001/upload'});
response:String;
updatenotice: UpdateNotice;

temporalityControl= new UntypedFormControl('', [Validators.required]);

temporality = [
{ value: 'permanent-0', viewValue: 'Permanent' },
{ value: 'temporary-1', viewValue: 'Temporary' }
];

constructor(public route: ActivatedRoute,
            public router: Router,
            public updatenoticeService: UpdateNoticeService) {

this.myGroup = new UntypedFormGroup({
fcneff: new UntypedFormControl(),
fcncancel: new UntypedFormControl()
});
this.uploader.response.subscribe(res => this.response = res);
}
ngOnInit() {
    this.getUpdateNotice();
}

getUpdateNotice():void {
    const id = this.route.snapshot.params['id'];
    console.log("id name of review UN"+id);
    this.updatenoticeService.getUpdateNotice(id)
    .subscribe(updatenotice => this.updatenotice = updatenotice[0]);
}
}

function isAiracDate(argDate: Date):Boolean
{
var startDate = new Date("1/14/2010");
var argDate = new Date("1/24/2018");
var timeDiff = Math.abs(argDate.getTime() - startDate.getTime());
var num_of_days = Math.ceil(timeDiff / (1000 * 3600 * 24)); 

if ((num_of_days%28)!=0)
return false;
else return true; }
