import { QualityProjectService } from "./../service/quality-project.service";
import { Component, OnInit, Inject } from "@angular/core";
import {  MatSnackBar } from "@angular/material/snack-bar";
import {  MatDialogRef,  MAT_DIALOG_DATA } from "@angular/material/dialog";
import { filter, tap } from "rxjs/operators";

export interface DialogData {
  id: string;
  name: string;
}

@Component({
  selector: "app-claimdialog",
  templateUrl: "./claimdialog.component.html",
  styleUrls: ["./claimdialog.component.css"],
})
export class ClaimdialogComponent implements OnInit {
  constructor(
    private qaService: QualityProjectService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ClaimdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  claim(taskId: string): void {
    this.qaService
      .claim(taskId)
      .pipe(
        filter((response) => !!response),
        tap((result) => {
          this.snackBar.open(`${result} is successfully claimed!`, "OK", {
            duration: 2000,
          });
          this.dialogRef.close(this.data.id);
        })
      )
      .subscribe();
  }
}
