<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let pFlatNode" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled></button>
    <div class="border">

      <im-input-form-field [propertyField]="pFlatNode.property" [parentFormGroup]="aeroInputForm"></im-input-form-field>

    </div>
  </mat-tree-node>

  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let pFlatNode; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + pFlatNode.property.key">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(pFlatNode) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>

    <div class="border-red">
      <ng-container [ngSwitch]="pFlatNode.property.propertyType">

        <!-- Simple form field, but potentially with annotations -->
        <im-input-form-field *ngSwitchCase="'field'" [propertyField]="pFlatNode.property"
          [parentFormGroup]="parentFormGroup" [positionInArray]="positionInArray"></im-input-form-field>

        <!-- Group of fields -->
        <im-input-form-group *ngSwitchCase="'group'" [propertyGroup]="pFlatNode.property"
          [parentFormGroup]="parentFormGroup" [positionInArray]="positionInArray"></im-input-form-group>

        <!-- Array of fields -->
        <mat-expansion-panel *ngSwitchCase="'multiple'">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>Multiple: {{pFlatNode.property.label}}</span>

            </mat-panel-title>
            <mat-panel-description>({{pFlatNode.property.properties.length}})</mat-panel-description>
          </mat-expansion-panel-header>

          <p>array description {{pFlatNode.property.description}}</p>
          <im-field-array [multipleProperty]="pFlatNode.property" [parentFormGroup]="parentFormGroup"
            [arrayName]="pFlatNode.property.key">
          </im-field-array>

          <mat-action-row>
            <!-- Buttons for delete, annotations and info for the array itself (where it makes sense)
                    For example, AirportHeliport/servedCity can occur multiple times, but these buttons control
                    the list of served cities, not each city.
                  -->
            <button type="button" mat-icon-button matTooltip="Add an item" (click)="stopPropagation($event)">
              <mat-icon color="primary" aria-label="Add an item">add</mat-icon>
            </button>
            <button type="button" mat-icon-button matTooltip="Revert to initial value"
              (click)="revert(); stopPropagation($event)">
              <mat-icon color="primary" aria-label="Set property to nil">undo</mat-icon>
            </button>
            <button type="button" mat-icon-button matTooltip="Annotations"
              (click)="showAnnotations = !showAnnotations; stopPropagation($event)">
              <mat-icon color="primary" aria-label="Annotations">description</mat-icon>
            </button>
            <button type="button" mat-icon-button matTooltip="Property description"
              (click)="showInfo = !showInfo; stopPropagation($event)">
              <mat-icon color="primary" aria-label="Description">info</mat-icon>
            </button>

          </mat-action-row>
        </mat-expansion-panel>

      </ng-container>
    </div>
  </mat-tree-node>
</mat-tree>