import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigService } from '../../utils/config.service';
import { DatastoreDto } from '../model/DatastoreDto';

const httpOptions = {
  headers: new HttpHeaders()
};

@Injectable({
  providedIn: 'root'
})
export class DatastoreService {

  private apiServer = ConfigService.settings.apiServer;
  private saveUrl = this.apiServer.url + "/datastore/save";
  private getDatastoreNameUrl = this.apiServer.url + "/datastore/get/datastoreName";
  private getDatastoreListUrl = this.apiServer.url + "/datastore/get/datastoreList";

  constructor(private http: HttpClient) { }

  save(datastoreName: String): Observable<any> {
    const url = `${this.saveUrl}/${datastoreName}`;
    return this.http.post<string>(url, httpOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('saveDatastore', error))
      ));
  }

  getDatastoreName(): Observable<string> {
    let requestOptions: Object = {
      responseType: 'text'
    }
    return this.http.get<string>(this.getDatastoreNameUrl, requestOptions).pipe(
      tap(data => console.log(data),
        error => catchError(this.handleError('getDatastoreName', error))
      ));
  }

  getDatastoreList(): Observable<DatastoreDto[]> {
    return this.http.get<DatastoreDto[]>(this.getDatastoreListUrl).pipe(
      tap(data => console.log(data),
        error => catchError(this.handleError('getDatastoreList', error))
      ));
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
