import { InfoManagementService } from './../service/info-management.service';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upload-dataset-dialog',
  templateUrl: './upload-dataset-dialog.component.html',
  styleUrls: ['./upload-dataset-dialog.component.css']
})
export class UploadDatasetDialogComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({ url: '', queueLimit: 1});
  private unId: string;
  onAdd = new EventEmitter();
  constructor(private infoManagementService: InfoManagementService,
    public dialogRef: MatDialogRef<UploadDatasetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private _snackBar: MatSnackBar) {
    this.unId = data.unId;
  }

  ngOnInit(): void {
  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  upload() {      
    this.infoManagementService.uploadDataSet(this.unId, this.getFiles()[0])
      .subscribe(result => {
        this.onAdd.emit(result);
        this.openSnackBar(result,"Ok");
      });     
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
