
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapterService } from './utils/dateadapter.service';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { FileUploadModule } from 'ng2-file-upload';
import { AppMaterialModule } from '../app.material.module';
import { UpdateNoticeService } from './service/updatenotice.service';

import { UpdateNoticeRoutingModule } from './update-notice-routing.module';
import { UpdatenoticeListComponent } from './updatenotice-list/updatenotice-list.component';
import { UpdatenoticeListProvidersComponent } from './updatenotice-list-providers/updatenotice-list-providers.component';
import { NewUpdateNoticeComponent } from './new-updatenotice/new-updatenotice.component';
import { ReviewUpdateNoticeComponent } from './review-updatenotice/review-updatenotice.component';
import { EditUpdateNoticeComponent } from './edit-updatenotice/edit-updatenotice.component';
import { DeleteUpdateNoticeComponent } from './delete-updatenotice/delete-updatenotice.component';
//import { TimezonePickerModule } from 'ng2-timezone-selector';
import { RejectUnDialogComponent } from './reject-un-dialog/reject-un-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import {  MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DeleteItemDialogComponent } from './delete-item-dialog/delete-item-dialog.component';
// import { AiracDateComponent } from '../airac-date/airac-date.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        RouterModule,
        AppMaterialModule,
        FileUploadModule,
        UpdateNoticeRoutingModule,
        // TimezonePickerModule,
        MatSortModule,
        MatTableModule,
        BrowserModule,
        BrowserAnimationsModule,
        OverlayModule
    ],
    declarations: [
        UpdatenoticeListComponent,
        UpdatenoticeListProvidersComponent,
        ReviewUpdateNoticeComponent,
        EditUpdateNoticeComponent,
        DeleteUpdateNoticeComponent,
        NewUpdateNoticeComponent,
        RejectUnDialogComponent,
        DeleteItemDialogComponent,
        // AiracDateComponent,
        ConfirmationDialogComponent
    ],
    providers: [
        UpdateNoticeService,
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        DateAdapterService
    ],
    exports: [
        UpdatenoticeListComponent,
        UpdatenoticeListProvidersComponent,
        ReviewUpdateNoticeComponent,
        EditUpdateNoticeComponent,
        DeleteUpdateNoticeComponent,
        NewUpdateNoticeComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UpdateNoticeModule { }
