<h3  mat-dialog-title > Add Log Entry </h3>

<div mat-dialog-content>
    <form #f="ngForm" name="newLogEntryForm">
        <mat-form-field>
            <textarea matInput placeholder="Description" name="description" [(ngModel)]="logentry.description"></textarea>
        </mat-form-field>
    </form>

</div>
  
<div mat-dialog-actions>
    <!-- (click) passes input value to add() and then clears the input -->
    <button mat-raised-button mat-button color="primary" [mat-dialog-close]="data"
    (click)="add();">Confirm</button>  
  
    <button mat-raised-button mat-button color="primary" (click)="onNoClick()">Cancel</button>
</div>