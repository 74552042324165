import { UnclaimdialogComponent } from "./../unclaimdialog/unclaimdialog.component";
import { ClaimdialogComponent } from "./../claimdialog/claimdialog.component";
import { DialogComponent } from "./../complete-dialog/dialog.component";
import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { Item } from "../model/item";
import { ActivatedRoute, Router } from "@angular/router";
import { QualityProjectService } from "./../service/quality-project.service";
import { LogDialogComponentComponent } from "../log-dialog-component/log-dialog-component.component";
import { AddNewItemDialogComponent } from "../add-new-item-dialog/add-new-item-dialog.component";
import {  MatDialog } from "@angular/material/dialog";
import { tap, filter } from "rxjs/operators";

@Component({
  selector: "app-data-input",
  templateUrl: "./data-input.component.html",
  styleUrls: ["./data-input.component.css"],
})
export class DataInputComponent implements OnInit {
  @Output() myEvent = new EventEmitter<String>();

  @Output() itemName = new EventEmitter<String>();

  items: Item[] = [];

  itemsAtLaterSteps: number;

  totalItems: number;

  assignedItems: number;

  projectId: string = this.route.snapshot.params.id;

  isProjectManager = this.qualityProjectService.isProjectManager;

  isProjecEditor = this.qualityProjectService.isProjectEditor;

  constructor(
    private qualityProjectService: QualityProjectService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}
  ngOnInit() {}

  @Input()
  set collectItems(items: Item[]) {
    if (items != undefined) {
      this.items = items.filter((item) => item.currentActivity == "Data input");
      this.itemsAtLaterSteps = items.filter(
        (item) => item.currentActivity != "Data input"
      ).length;
      this.totalItems = items.length;

      this.assignedItems = items.filter((item) => item.assignee != null).length;
    }
  }

  openClaimDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(ClaimdialogComponent, {
      width: "500px",
      data: { name: name, id: id },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap(() => this.myEvent.emit(this.projectId))
      )
      .subscribe();
  }

  openCompleteDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(DialogComponent, {
      width: "500px",
      data: { name: name, id: id },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap(() => this.myEvent.emit(this.projectId))
      )
      .subscribe();
  }

  openUnclaimDialog(name: string, id: string): void {
    let dialogRef = this.dialog.open(UnclaimdialogComponent, {
      width: "500px",
      data: { name: name, id: id },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap(() => this.myEvent.emit(this.projectId))
      )
      .subscribe();
  }

  getLogOfAssignment(executionId: string) {
    this.dialog.open(LogDialogComponentComponent, {
      width: "600px",
      data: executionId,
    });
  }

  openAddNewItemDialog() {
    let dialogRef = this.dialog.open(AddNewItemDialogComponent, {
      width: "500px",
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((response) => !!response),
        tap((result) => {
          let newItem = new Item();
          newItem.name = result;

          this.itemName.emit(result);
        })
      )
      .subscribe();
  }
}
