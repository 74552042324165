<mat-card class="box column">
    <mat-card-title>
        <h2>Done</h2>
    </mat-card-title>
    <mat-card-content>
        <div class="inner-content">
            <div class="item-wrapper" *ngFor="let item of items">
                <div class="text-inside-grid title">{{item.name}}</div>
                <i class="material-icons" (click)="getLogOfAssignment(item.executionId)" style="cursor:pointer"
                    matTooltip="Log of Assignment"> history </i>
            </div>
        </div>

    </mat-card-content>
    <!-- <mat-card-footer class="space">
        <i class="material-icons md-30" style="cursor:pointer" 
               matTooltip="Add new Item">add_circle</i>
        </mat-card-footer>-->
</mat-card>