<ng-container *ngIf="{projectSummary: projectSummary$ | async } as vm">
    <mat-card>
        <mat-card-content>
            <div class="space">
                <!-- Home/Quality/ -->
                <span><a [routerLink]="['/quality/projects']">QualityAssementProjects</a></span>
            </div>
            <div class="row action-btn">
                <h2 class="project-title">{{projectId}}</h2>
                <div class="btn-action-group" *ngIf="isProjectManager">
                    <button 
                        [routerLink]="['/quality', 'edit', projectId, vm.projectSummary?.projectSetting?.pid]"
                        class="action-btn" mat-icon-button>
                        <i class="material-icons" style="font-size: 18px;" matTooltip="Edit Project">edit</i>
                    </button>
                    <button class="action-btn" mat-icon-button><i class="material-icons" style="font-size: 18px;"
                            matTooltip="Delete Project">delete</i></button>
                </div>
            </div>
            <!--  <div class="space">Overallprogess: 15%</div>
        <div class="space">
        <mat-radio-group>
            <mat-radio-button value="1">All tasks</mat-radio-button>
            <mat-radio-button value="2" class="space">My tasks only</mat-radio-button>
            <mat-radio-button value="3" class="space">Available tasks only</mat-radio-button>
        </mat-radio-group>  
        </div>-->
            <div class="justify">
                <app-data-input [collectItems]="vm.projectSummary?.items" (myEvent)="getItems($event)"
                    (itemName)="addNewItem($event)">
                </app-data-input>
                <app-text-edition [collectItems]="vm.projectSummary?.items"
                    (myEvent)="getItems($event)"></app-text-edition>
                <app-review [collectItems]="vm.projectSummary?.items" (myEvent)="getItems($event)"></app-review>
                <app-done [collectItems]="vm.projectSummary?.items"></app-done>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>