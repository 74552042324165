import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Inject, Optional, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable()
export class DateAdapterService extends MomentDateAdapter{

constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale:string) {
super(dateLocale);
}

setTimezone(timezone: string) { moment.tz.setDefault(timezone); }

setLocale(locale: string) { super.setLocale(locale); }
}