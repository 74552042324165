import { Injectable } from '@angular/core';
import { DataProvider, Dataprovider } from '../model/dataprovider';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import * as GlobalVar from '../../utils/global.var';
import { ConfigService } from '../../utils/config.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DataProviderService {
  //private dataproviderUrl = 'api/dataprovider'; //URL to web api 
  private apiServer = ConfigService.settings.apiServer;
  private dataProviderListUrl = this.apiServer.url + '/dataProvider/list';
  private dataproviderUrl = this.apiServer.url  + '/dataProvider';
  private usergroupUrl = this.apiServer.url  + '/userGroup/list';
  private linkgroupUrl = this.apiServer.url  + '/dataProvider/link';

  constructor(private http: HttpClient) { }

  /** GET dataproviders from the server */
  getDataProviders(): Observable<DataProvider[]> {
    return this.http.get<DataProvider[]>(this.dataProviderListUrl)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getDataProviders', error))
      ));
  }

  /** GET user groups from the server */
  getuserGroups(): Observable<string[]> {
    return this.http.get<string[]>(this.usergroupUrl)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getuserGroups', error))
      ));
  }

  /** Link unit to  user group */
  linkUniToGroup(id: String, name: String): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: " + id);
    const url = `${this.linkgroupUrl}/${id}`;
    console.log("The name is " + name);
    return this.http.post<string>(url, name, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('linkUnitToGroup', error))
      ));
  }

  /** unlink an AIXM unit from user group. */
  unLink(identifier: string): Observable<string> {
    return this.http.delete<string>(`${this.dataproviderUrl}/${identifier}`)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('unLink', error))
      ));
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
