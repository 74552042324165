<h3 mat-dialog-title>Upload a dataset </h3>

<mat-dialog-content>

    <input type="file" hidden ng2FileSelect #file [uploader]="uploader"/>
    <button type="button" matTooltip="Add a dataset" class="material-icons" (click)="file.click()">
        &#xE226;</button>

    <span class="spacer" *ngFor="let item of uploader.queue">{{ item?.file?.name }}</span>

</mat-dialog-content>

<div mat-dialog-actions>
    <button mat-raised-button mat-button color="primary" [mat-dialog-close]="data" (click)="upload()" [disabled]="uploader.queue.length == 0">Confirm</button>

    <button mat-raised-button mat-button color="primary" (click)="onNoClick()">Cancel</button>
</div>