import { Component, OnInit } from '@angular/core';
import { UserManageMentService } from '../service/user-management.service';
import { CreateGroup } from '../model/create-group-model';
import { UntypedFormGroup, UntypedFormBuilder, Validators,FormControl } from '@angular/forms';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import {  MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-groups',
  templateUrl: './create-groups.component.html',
  styleUrls: ['./create-groups.component.css']
})
export class CreateGroupsComponent implements OnInit {

  group:CreateGroup = new CreateGroup();

  form: UntypedFormGroup;
  constructor(public usermanagementServie:UserManageMentService,public dialog: MatDialog,public fb:UntypedFormBuilder) 
  { 
    this.form = fb.group({
      groupName:['',Validators.required],
    })
  }

  ngOnInit() {
  }

  createNewGroup(formValue):void{
    /*     console.log("The user in service is "+ this.user.firstName +""+
        this.user.userName+""+this.user.lastName+""+this.user.password); */
        let group: CreateGroup ={
          groupName:formValue.groupName
        }
        console.log("The group in service is "+ this.group.groupName);
        this.usermanagementServie.createGroup(group)
          .subscribe(result => {
            //this.result = result,    
            let dialogRef = this.dialog.open(SuccessDialogComponent, {
            width: '500px',
            data: {result}
          });
      
          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            console.log('After the dialog was closed:'+ result);
            //this.result = result,
            //this.ngOnInit();
          });
            //alert("Created Project: " + result);
          });
      }

}
