import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { LogsheetService } from '../service/logsheet.service';
import { Logsheet } from '../model/logsheet';
import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import {  MatDialog,  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewlogsheetdialogComponent } from '../newlogsheetdialog/newlogsheetdialog.component';
import { LogSheetSummaryDTO } from '../model/logsheetSummaryDTO';

@Component({
  selector: 'app-logsheet',
  templateUrl: './log-sheet-list.component.html',
  styleUrls: ['./log-sheet-list.component.css']
})
export class LogSheetComponent implements OnInit{
  private logsheets: LogSheetSummaryDTO[] = [];
  
  //datasource for table
  displayedColumns = ['status', 'type', 'id', 'subject', 'lastCreatedDate'];
  logsheets_dataSource = new MatTableDataSource<LogSheetSummaryDTO>(this.logsheets);
  
  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.logsheets_dataSource.sort = this.sort;
    this.logsheets_dataSource.paginator = this.paginator;
  }
  
  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.logsheets_dataSource.filter = filterValue;
  }

  constructor(private logSheetService: LogsheetService, public dialog: MatDialog){}

  ngOnInit() {
    this.getLogSheets();
   }

  getLogSheets(): void {
    this.logSheetService.getLogSheets()
    .subscribe(logsheets => this.logsheets_dataSource.data = logsheets);
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(NewlogsheetdialogComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.componentInstance.onAdd.subscribe((result) => {
      console.log("The result is "+ JSON.stringify(result));
      if(JSON.stringify(result))
      this.ngOnInit();
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed' + result);
    });
  }
}



