import { ReferencePropertyField } from './model/reference-property-field';
import { Injectable } from '@angular/core';
import { Property, MultipleProperty, LazyLoadedProperty } from './model/property';
import { CodeListPropertyField } from './model/code-list-property-field';
import { TextPropertyField, TranslatedNotePropertyField } from './model/text-property-field';
import { WithUnitPropertyField } from './model/with-unit-property-field';
import { PropertyGroup } from './model/property-group';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../utils/config.service';
import { TextAreaPropertyField } from './model/textarea-property-field';
import { ChoicePropertyField } from './model/choice-property-field';

@Injectable()
export class PropertyService {
  //private propertiesUrl = 'api/properties';
  //private lazyLoadedPropertiesUrl = 'api/LazyLoadedProperties';
  //private lazyLoadedPropertiesUrl1 = 'api/LazyLoadedProperties1';
  private apiServer = ConfigService.settings.apiServer;
  private propertiesUrl = this.apiServer.url + '/aixm/forms/model';
  private gmlNameUrl = this.apiServer.url + '/aixm/forms/gmlname';
  private lazyLoadedPropertiesUrl = this.apiServer.url + '/aixm/forms/lazyLoaded';
  private annotationCreateUrl = this.apiServer.url + '/aixm/forms/annotation/create';

  constructor(private http: HttpClient) { }

  getPropertiesOfBaseLineTS(featureId: string, unId: string, tsId: string): Observable<any> {
    const url = `${this.propertiesUrl}/${featureId}/${unId}/${tsId}`;
    return this.http.get<any[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('getPropertiesOfBaseLineTS', error))
      ));
  }

  getGmlName(featureId: string, unId: string, tsId: string): Observable<string> {
    let requestOptions: Object = { responseType: 'text' }
    const url = `${this.gmlNameUrl}/${featureId}/${unId}/${tsId}`;
    return this.http.get<string>(url, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('getGmlName', error))
      ));
  }

  // getProperties(): Observable<any> {
  //   const url = `${this.propertiesUrl}/${'gl51d3cf-e8d7-4fa2-b48d-3fddab484d62'}/${'BASELINE'}/${'1'}/${'0'}`;
  //   return this.http.get<any[]>(url)
  //     .pipe(tap(data => console.log(data),
  //       error => catchError(this.handleError<any>('getProperties', error))
  //     ));
  // }

  getLazyLoadedProperties(featureId: string, unId: string, id: string, group: string, key: string, propOrderIndex: number): Observable<any> {
    var tsId: object;
    const url = `${this.lazyLoadedPropertiesUrl}/${featureId}/${unId}/${tsId}/${id}/${group}/${key}/${propOrderIndex}`;
    return this.http.get<any[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('loadLazyProperty', error))
      ));
  }

  getLazyLoadedPropertiesWithEmptyData(featureId: string, unId: string, id: string, group: string, key: string, propOrderIndex: number): Observable<any> {
    var isEmptyData: boolean = true;
    var tsId: object;
    const url = `${this.lazyLoadedPropertiesUrl}/${featureId}/${unId}/${tsId}/${id}/${group}/${key}/${propOrderIndex}/${isEmptyData}`;
    return this.http.get<any[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('loadLazyProperty', error))
      ));
  }

  getEmptyForm(group: string, unId: string): Observable<any> {
    const url = `${this.lazyLoadedPropertiesUrl}/${unId}/${group}`;
    return this.http.get<any[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError<any>('getEmptyForm', error))
      ));
  }

  jsonToProperty(property: any): Property {
    if (property != undefined) {
      let parentProperty: Property = null;
      // simple property
      parentProperty = this.createPropertyField(property);

      // complex property
      // It's possible that annotations already converted to MultipleProperty from Object
      if (property.annotations && !(property.annotations instanceof MultipleProperty)) {
        parentProperty.annotations = this.createPropertyField(property.annotations);
      }
      //console.log("parentProperty:" + JSON.stringify(parentProperty));
      return parentProperty;
    }
  }

  createPropertyField(property: any): any {
    let resultProperty: Property;

    if (property.class == "TextPropertyField") {
      resultProperty = new TextPropertyField(property);
    }
    else if (property.class == "WithUnitPropertyField") {
      resultProperty = new WithUnitPropertyField(property);
    }
    else if (property.class == "CodeListPropertyField") {
      resultProperty = new CodeListPropertyField(property);
    }
    else if (property.class == "ChoicePropertyField") {
      return new ChoicePropertyField(property)
    }
    else if (property.class == "TranslatedNotePropertyField") {
      resultProperty = new TranslatedNotePropertyField(property);
    }
    else if (property.class == "LazyLoadedProperty") {
      return new LazyLoadedProperty(property);
    }
    else if (property.class == "PropertyGroup") {
      let propGroup = new PropertyGroup(property);
      // complex property
      if (propGroup.annotations) {
        propGroup.annotations = this.createPropertyField(propGroup.annotations);
      }
      let props: Property[] = [];
      propGroup.properties.forEach(property => {
        if (propGroup.modified) {
          property.modified = true;
        }
        props.push(this.createPropertyField(property));
        propGroup.properties = props;
      });
      // console.log("propGroup:" + JSON.stringify(propGroup));
      return propGroup;
    }
    else if (property.class == "MultipleProperty") {
      let multiProp = new MultipleProperty(property);
      // complex property
      if (multiProp.annotations) {
        multiProp.annotations = this.createPropertyField(multiProp.annotations);
      }
      let props: Property[] = [];
      multiProp.properties.forEach(property => {
        if (multiProp.modified) {
          property.modified = true;
        }
        props.push(this.createPropertyField(property));
        multiProp.properties = props;
      });
      // console.log("multiProp:" + JSON.stringify(multiProp));
      return multiProp;
    }
    else if (property.class == "ReferencePropertyField") {
      resultProperty = new ReferencePropertyField(property);
    }
    else if (property.class == "TextAreaPropertyField") {
      resultProperty = new TextAreaPropertyField(property);
    }

    // There can be annotations in simple properties inside drawers
    if (resultProperty != undefined && resultProperty.annotations) {
      resultProperty.annotations = this.createPropertyField(resultProperty.annotations);
    }
    return resultProperty;
  }

  createAnnotationProperty(): any {
    // return this.jsonToProperty(this.emptyAnnotationJson);
    return this.http.get<any[]>(this.annotationCreateUrl)
      .pipe(
        tap(data => console.log(data)),
        catchError(this.handleError('createAnnotationProperty')),
        map(annotationJson => this.jsonToProperty(annotationJson))
      ).toPromise();
  }

  emptyAnnotationJson: object = {
    "class": "MultipleProperty",
    "key": "aixm:annotations,aixm:Note",
    "label": "Annotations",
    "hint": "",
    "description": "",
    "group": "AirportHeliportPropertyGroup",
    "value": "",
    "properties": [
      {
        "class": "PropertyGroup",
        "key": "aixm:annotation",
        "label": "Annotation",
        "hint": "",
        "description": "",
        "properties": [
          {
            "class": "CodeListPropertyField",
            "key": "aixm:purpose_1",
            "label": "Purpose",
            "hint": "string",
            "description": "",
            "group": "NotePropertyGroup",
            "value": "",
            "type": "string",
            "codeList": [
              {
                "key": "DESCRIPTION",
                "label": "DESCRIPTION"
              },
              {
                "key": "REMARK",
                "label": "REMARK"
              },
              {
                "key": "WARNING",
                "label": "WARNING"
              },
              {
                "key": "DISCLAIMER",
                "label": "DISCLAIMER"
              }
            ]
          },
          {
            "class": "MultipleProperty",
            "key": "aixm:translatedNotes_2,aixm:LinguisticNote",
            "label": "TranslatedNotes",
            "hint": "",
            "description": "",
            "group": "NotePropertyGroup",
            "properties": [
              {
                "class": "PropertyGroup",
                "key": "aixm:translatedNote",
                "label": "TranslatedNote",
                "hint": "",
                "description": "",
                "properties": [
                  {
                    "class": "TranslatedNotePropertyField",
                    "key": "aixm:note_1",
                    "label": "Note",
                    "hint": "string",
                    "description": "",
                    "group": "LinguisticNotePropertyGroup",
                    "value": "",
                    "lang": "",
                    "type": "string",
                    "maxLength": "10000",
                    "minLength": "1"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };

  /* getPropertiesAbandoned() {
    let propertyFields: Property[] = [

      new CodeListPropertyField({
        key: 'designator',
        label: 'CodeListPropertyField sample',
        hint: 'Longest description in AIXM 5.1',
        description: `Simplified Directional Facility (SDF) provides a final approach course similar to that
      of the ILS localizer although is not as precise as Localizer. It does not provide glide slope
      information. The SDF transmits signals within the range of 108.10 to 111.95 MHz. The approach
      techniques and procedures used in an SDF instrument approach are essentially the same as those
      employed in executing a standard localizer approach except the SDF course may not be aligned with
      the runway and the course may be wider, resulting in less precision. Usable off-course indications
      are limited to 35 degrees either side of the course centreline. Instrument indications received
      beyond 35 degrees should be disregarded. This angle is generally not more than 3 degrees. The SDF
      signal is fixed at either 6 degrees or 12 degrees as necessary to provide maximum flyability and
      optimum course quality.`,
        codeList: [
          { key: 'solid', label: 'Solid' },
          { key: 'great', label: 'Great' },
          { key: 'good', label: 'Good' },
          { key: 'unproven', label: 'Unproven' }
        ],
        order: 30
      }),

      new TextPropertyField({
        key: 'firstName',
        label: 'Text Property Sample',
        hint: `English letters A to Z`,
        description: 'Long description here',
        maxLength: 6,
        value: 'Bombasto',
        order: 10,
        annotations:
          new MultipleProperty({
            key: 'annotations',
            label: 'Annotations',
            maxOccurs: -1,
            properties: [
              new PropertyGroup({
                key: 'annotation',
                label: 'Annotation',
                properties: [
                  new CodeListPropertyField({
                    key: 'purpose',
                    label: 'Purpose',
                    value: 'REMARK',
                    description: 'An indication of the goal which led to the provision of the free text note.',
                    codeList: [
                      { key: 'DESCRIPTION', label: 'Description' },
                      { key: 'REMARK', label: 'Remark' },
                      { key: 'WARNING', label: 'Warning' },
                      { key: 'DISCLAIMER', label: 'Disclaimer' },
                    ]
                  }),
                  new PropertyGroup({
                    key: 'translatedNote',
                    label: 'Translated Note',
                    maxOccurs: -1,
                    properties: [
                      new TranslatedNotePropertyField({
                        key: 'note',
                        label: 'Note',
                        maxLength: 10000,
                        description: 'The text of the Note, in a specific language.',
                        value: 'This is a remark.',
                        lang: 'en-GB'
                      }),
                      new TranslatedNotePropertyField({
                        key: 'note',
                        label: 'Note',
                        maxLength: 10000,
                        description: 'The text of the Note, in a specific language.',
                        value: 'Ceci est une remarque.',
                        lang: 'fr-FR'
                      })
                    ]
                  })
                ]
              }),
              new PropertyGroup({
                key: 'annotation',
                label: 'Annotation',
                maxOccurs: -1,
                properties: [
                  new CodeListPropertyField({
                    key: 'purpose',
                    label: 'Purpose',
                    value: 'DESCRIPTION',
                    description: 'An indication of the goal which led to the provision of the free text note.',
                    codeList: [
                      { key: 'DESCRIPTION', label: 'Description' },
                      { key: 'REMARK', label: 'Remark' },
                      { key: 'WARNING', label: 'Warning' },
                      { key: 'DISCLAIMER', label: 'Disclaimer' },
                    ]
                  }),
                  new PropertyGroup({
                    key: 'translatedNote',
                    label: 'Translated Note',
                    maxOccurs: -1,
                    properties: [
                      new TranslatedNotePropertyField({
                        key: 'note',
                        label: 'Note',
                        maxLength: 10000,
                        description: 'The text of the Note, in a specific language.',
                        value: 'This is a description.',
                        lang: 'en-GB'
                      }),
                      new TranslatedNotePropertyField({
                        key: 'note',
                        label: 'Note',
                        maxLength: 10000,
                        description: 'The text of the Note, in a specific language.',
                        value: 'Ceci est une description.',
                        lang: 'fr-FR'
                      })
                    ]
                  })
                ]
              })
            ] // end of MultipleProperty.properties
          }), // end of MultipleProperty
      }), // end of TextPropertyField

      new MultipleProperty({
        key: 'emailAddress',
        label: 'Email Addresses',
        maxOccurs: -1,
        order: 90,
        template: function () {
          new TextPropertyField({
            key: 'emailAddress',
            label: 'Email',
            hint: `A coded designator  between 0001 and
        9999.`,
            maxLength: 6,
            maxOccurs: -1,
            type: 'email',
            order: 200
          })
        },
        properties: [
          new TextPropertyField({
            key: 'emailAddress',
            label: 'Email',
            hint: `A coded designator  between 0001 and
          9999.`,
            maxLength: 6,
            maxOccurs: -1,
            type: 'email',
            value: 'test@example.com',
            order: 2
          }),
          new TextPropertyField({
            key: 'emailAddress',
            label: 'Email',
            hint: `A coded designator  between 0001 and
          9999.`,
            maxLength: 6,
            maxOccurs: -1,
            type: 'email',
            value: 'anothertest@example.com',
            order: 2
          })

        ]
      }),

      new WithUnitPropertyField({
        key: 'referenceTemperature',
        label: 'WithUnitPropertyField sample',
        type: 'number',
        hint: 'Decimal number',
        description: `The monthly mean of the daily maximum temperatures for the hottest month of the
      year at an aerodrome.`,
        order: 20,
        minOccurs: 0,
        value: '20.5',
        unit: 'C',
        unitList: [
          { key: 'C', label: 'Celsius' },
          { key: 'F', label: 'Farenheit' },
          { key: 'K', label: 'Kelvin' },
        ],
      }),

      new PropertyGroup({
        key: 'group',
        label: 'Group Sample',
        hint: 'group hint',
        order: 40,
        maxOccurs: -1,
        properties: [
          new TextPropertyField({
            key: 'sub-prop1',
            label: 'Sub-property 1',
            hint: `A coded designator  between 0001 and 9999.`,
            maxLength: 6,
            value: 'xxx',
            order: 1
          }), new TextPropertyField({
            key: 'sub-prop2',
            label: 'Sub-property 2',
            hint: `A coded designator  between 0001 and 9999.`,
            maxLength: 6,
            value: 'yyy',
            order: 2
          })
        ]
      })
    ];

    return propertyFields.sort((a, b) => a.order - b.order);
  } */

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
