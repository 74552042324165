<div cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="!isCircle">
  <div>
    <!-- <div>
      <button type="button" mat-icon-button matTooltip="Set to nil" [disabled]="isEmpty()" (click)="setToNil()">
        <mat-icon color="warn" aria-label="Set property to nil" [ngClass]="{'disable':isEmpty(), 'enable':!isEmpty()}">
          delete
        </mat-icon>
      </button>
      <button type="button" mat-icon-button matTooltip="Revert to initial value" [disabled]="isInitialState"
        (click)="revert()">
        <mat-icon color="primary" aria-label="Set property to nil"
          [ngClass]="{'disable':isInitialState, 'enable':!isInitialState}">undo</mat-icon>
      </button>
    </div> -->

    <mat-table #table width="100%" [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="location_on">
        <th mat-header-cell *matHeaderCellDef></th>
        <tr mat-cell *matCellDef="let row">
          <td>
            <mat-icon matListIcon cdkDragHandle class="icon-2x spacing" matTooltip="Move this point">location_on
            </mat-icon>
          </td>
        </tr>
      </ng-container>
      <ng-container matColumnDef="segment">
        <th mat-header-cell *matHeaderCellDef></th>
        <tr mat-cell *matCellDef="let row; let i = index; let last = isLast">
          <td>
            <geo-point [segment]="row" [previousPoint]="getSegments()[(i == 0)? getSegments().length-1 : i-1]"
              [showInterpolation]="isSurface || !isLast" [isSurface]="isSurface" [isFirstSegment]="(i == 0)? true:flase"
              (startPointCoordinatesChanged)="onStartPointCoordinatesChangedAt(i, $event)" [direction]="direction"
              (interpolationChanged)="onInterpolationChanged(i, $event)" (segmentChange)="onSegmentChange(i, $event)"
              (onError)="onerror($event)" [isHighlight]="isHighlight" (radiusChange)="onRadiusChange()"
              (radiusUomChange)="onRadiusUomChange()" (centrePointChange)="onCentrePointChange()"
              (arcDirectionChange)="onArcDirectionChanged(i,$event)">
            </geo-point>
          </td>
        </tr>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <tr mat-cell *matCellDef="let row; let i = index">
          <td>
            <button type="button" mat-icon-button matTooltip="Remove this point" (click)="removePoint(i,row)"
              [disabled]="(dataSource.data.length <= 2 && !this.isSurface)||(dataSource.data.length <= 3 && this.isSurface)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </td>
        </tr>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

  </div>
  <mat-paginator *ngIf="dataSource.data.length > 0 && !isCircle" [length]="dataSource.data.length" [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
  </mat-paginator>
</div>

<!-- <div *ngIf="getSegments().length > 0 && !isCircle">
  <mat-paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons>
  </mat-paginator>
</div> -->

<button mat-fab matTooltip="Add a point by coordinates" class="spacing" (click)="addPoint(gmlPointType.COORDINATES)">
  <mat-icon>add_location_alt</mat-icon>
</button>
<button mat-mini-fab color="" matTooltip="Add a point reference" class="spacing"
  (click)="addPoint(gmlPointType.POINT_REFERENCE)">
  <mat-icon>insert_link</mat-icon>
</button>
<button mat-mini-fab color="" matTooltip="Add a GeoBorder reference" class="spacing"
  (click)="addPoint(gmlPointType.GEO_BORDER_POINT)">
  <mat-icon>flag</mat-icon>
</button>