<div class="container">
  <div class="message">
    <ng-container *ngIf="data.statusCode === 400">
      <b>Bad Request</b><br />
       {{ data.message }} <br/>
      <i>(Error code: HTTP 400)</i>
    </ng-container>
    <ng-container *ngIf="data.statusCode === 500">
      <b>Unexpected turbulence on our side.</b><br />
      You didn’t do anything wrong; just buckle up while our mechanic gives the system a gentle nudge. If the issue
      persists, please contact technical support.<br/>
      <i>(Error code: HTTP 500)</i>
    </ng-container>
    <ng-container *ngIf="data.statusCode === 502">
      <b>Sorry, something went wrong on our end.</b><br />
      We’re online, but an internal service isn’t answering. It might be undergoing maintenance or just not feeling
      talkative. Please contact our technical support if this continues. <br />
      <i>(Error code: HTTP 502)</i>
    </ng-container>
    <ng-container *ngIf="data.statusCode === 504">
      <b>Sorry, the system took too long to respond.</b><br />
      Either an uploaded file was too large, or a task took too long. A part of the cargo may have landed safely: please
      review what was processed. You can cancel it or upload just the rest.
      <i>(Error code: HTTP 504)</i>
    </ng-container>
  </div>
  <div class="action" matSnackBarActions>
    <button mat-button matSnackBarAction color="accent" (click)="close()">OK</button>
  </div>
</div>
