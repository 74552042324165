<mat-table #table [dataSource]="groups_dataSource" matSort>

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
  
  <!-- Name Column -->
  <ng-container matColumnDef="groupName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Group Name </mat-header-cell>
    <mat-cell *matCellDef="let group"><a [routerLink]="['/group/detail', group.id]">{{group.groupName}}</a></mat-cell>
  </ng-container>

  <ng-container matColumnDef="delete_icon">
      <mat-header-cell *matHeaderCellDef ></mat-header-cell>
      <mat-cell *matCellDef="let group"><i class="material-icons" (click)="openDeleteDialog(group.id)" style="cursor:pointer">delete</i> </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table> 
