import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { DataProviderService } from '../service/data-provider.service';

export interface DialogData {
  id: string;
  name: string;
}
@Component({
  selector: 'app-linkdialog',
  templateUrl: './linkdialog.component.html',
  styleUrls: ['./linkdialog.component.css']
})

export class LinkdialogComponent implements OnInit {

  constructor(private dataProviderService: DataProviderService, private snackBar: MatSnackBar,  
    public dialogRef: MatDialogRef<LinkdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  linkUnitToGroup(id: String,name:String): void {
   
    this.dataProviderService.linkUniToGroup(id,name)
    .subscribe(result => {
      //this.result = result,
      //console.log("Claimed Task: " + result);
      this.snackBar.open(result, "OK", {
        duration: 2000,
      });
    }); 
  }

}
