import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() { }

    public get unid() {
        return localStorage.getItem('unid');
    }

    public set unid(value) {
        localStorage.setItem('unid', value);
    }

    public get featureType() {
        return localStorage.getItem('featureType');
    }

    public set featureType(value) {
        localStorage.setItem('featureType', value);
    }

    public get isClaim() {
        return localStorage.getItem('isClaim');
    }

    public set isClaim(value) {
        localStorage.setItem('isClaim', value);
    }

    public get readonly() {
        return localStorage.getItem('readonly');
    }

    public set readonly(value) {
        localStorage.setItem('readonly', value);
    }
    
}