import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geotest',
  templateUrl: './geotest.component.html',
  styleUrls: ['./geotest.component.css']
})
export class GeotestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
