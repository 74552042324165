import { Component , OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TitleService } from './service/title.service';
import { filter, map, mergeMap } from 'rxjs';

export enum Layouts {
  SecureLayout
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  Layouts = Layouts
  layout: Layouts;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private titleService: TitleService
  ) {}

  // We can't use `ActivatedRoute` here since we are not within a `router-outlet` context yet.
  ngOnInit() {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.layout = data.state.root.firstChild.data.layout;
      }
    });


    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
    .subscribe((event) => this.title.setTitle(event['title']));
      
  }

}





 