<h3 mat-dialog-title>Log Of Assignment</h3>
<div mat-dialog-content>
  <mat-card-content>
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
      <mat-icon matSuffix (click)="applyFilter('');filter.value=''" style="cursor:pointer">clear</mat-icon>
    </mat-form-field>

    <mat-table #table [dataSource]="log_dataSource" matSort>

      <!-- User Name Column -->
      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header> User Name </mat-header-cell>
        <mat-cell *matCellDef="let log">{{log.assignee.name}}</mat-cell>
      </ng-container>

      <!-- Task Name Column -->
      <ng-container matColumnDef="taskname">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Task Name </mat-header-cell>
        <mat-cell *matCellDef="let log">{{log.taskname}}</mat-cell>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
        <mat-cell *matCellDef="let log">{{log.action}}</mat-cell>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
        <mat-cell *matCellDef="let log">{{log.timestamp | date:'dd MMM yyy HH:mm'}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-card-content>
</div>
<div mat-dialog-actions>
  <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
  </mat-paginator>
  <button mat-raised-button mat-button color="primary" (click)="onNoClick()">Close</button>
</div>