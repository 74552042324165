import { KeycloakConfig } from 'keycloak-js';

// Add here your keycloak setup infos
let keycloakConfig: KeycloakConfig = {
  url: 'https://staging.facilis.aero/auth',
  realm: 'faim-staging',
  clientId: 'faim-web-ng'
};

let roles: any = {
  role_FO_Module: "ROLE_FACILIS_ORIGIN_MODULE",
  role_Ais_Admin: "ROLE_AIS_ADMIN",
  role_Ais_Reviewer: "ROLE_AIS_REVIEWER",
  role_Data_Manager: "ROLE_DATA_MANAGER",
  role_Data_Provider: "ROLE_DATA_PROVIDER",
  role_Data_Reader: "ROLE_DATA_READER",
  role_LS_Module: "ROLE_LOG_SHEET_MODULE",
  role_Fqa_Module: "ROLE_FQA_MODULE",
  role_Fqa_Quality_Contoller: "ROLE_FQA_QUALITY_CONTROLLER",
  role_Validator_Module: "ROLE_FAIVA_MODULE"
};

let attributes: any = {
  datastore: "DefaultDatastore",
  tenantName: "TenantName",
  tenantStatus: "TenantStatus",
  tenantDisplayName: "TenantDisplayName"
}

//TimelineOptions
let min: string = '2021-01-01T00:00:00Z';
let max: string = '2025-01-01T00:00:00Z';

export const environment = {
  production: false,
  staging: true,
  noticeNumber: 10,
  roles: roles,
  attributes: attributes,
  keycloak: keycloakConfig,
  min: min,
  max: max,
  appVersion: require('../../package.json').version
};