interface ValidationStatistic {
  yearOfValidation: number;
  numberOfValidations: number[];
  successValidations: number[];
}

interface ValidationStatisticLastTwelveMonths {
  monthYearOfValidation: string;
  numberOfValidations: number;
  successValidations: number;
}

interface ValidationStatisticsOverview {
  totalValidations: number;
  totalSuccessValidations: number;
}

interface ValidationStatisticActiveUsers {
  numberOfActiveUsers: number;
}

interface CardTplContext {
  id: string;
  title: string;
  value: number;
  sub: string;
  icon: string;
  color: string;
}

type ValidationStatisticsFullYear = ValidationStatistic[];

type ValidationStatisticsLastTwelveMonths = ValidationStatisticLastTwelveMonths[];

enum StatsType {
  FULL_YEAR = "fullYear",
  LAST_12_MONTHS = "last12Months",
}

export {
  StatsType,
  ValidationStatistic,
  ValidationStatisticLastTwelveMonths,
  ValidationStatisticsFullYear,
  ValidationStatisticsLastTwelveMonths,
  ValidationStatisticsOverview,
  ValidationStatisticActiveUsers,
  CardTplContext,
};
