<h3 class="mat-headline-small title">Select Data Providers who can edit this feature:</h3>

<mat-dialog-content>
    <mat-selection-list dense multiple (selectionChange)="onSelect($event)">
        <mat-list-option 
            *ngFor="let provider of dataproviders" 
            [value]="provider" 
            [selected]="selectedEditors.isSelected(provider)" 
            checkboxPosition="before" 
            color="primary">
            {{provider.name}}
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>


<mat-card-actions style="margin-left: 10px; display: flex;">
    <button [disabled]="selectedEditors.isEmpty()" type="submit" mat-raised-button mat-button color="primary" (click)="assign()">Assign</button>
    <button mat-raised-button mat-button color="warn" (click)="onNoClick()">Close</button>
</mat-card-actions>