import { Component, OnInit, Inject, EventEmitter} from '@angular/core';
import {  MatDialog,  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { TaskService } from '../service/task.service';

export interface DialogData {
  id: string;
  name: string;
}

@Component({
  selector: 'app-claimdialog',
  templateUrl: './claimdialog.component.html',
  styleUrls: ['./claimdialog.component.css']
})
export class ClaimdialogComponent implements OnInit {
  private result: string;

  onAdd = new EventEmitter();

  constructor(private taskService: TaskService, private snackBar: MatSnackBar,  
    public dialogRef: MatDialogRef<ClaimdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  claim(taskId: string): void {
    console.log("In component: Claim Function: " + taskId);
   
    this.taskService.claim(taskId)
    .subscribe(result => {
      this.result = result,
      console.log("Claimed Task: " + result);
      this.onAdd.emit(result);
      this.snackBar.open(result, "OK", {
        duration: 3000,
      });
    });
  }
}
