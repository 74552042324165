import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'OrderBy'})
export class OrderByPipe implements PipeTransform {
  transform(input: any, key: string) {
    if (!input) return [];

    return [].slice.call(input).sort(function(itemA, itemB) {
      if (itemA[key] > itemB[key]) {
        return 1;
      } else if(itemA[key] < itemB[key]) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}