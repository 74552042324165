import { FeatureSelectorService } from './../../feature-selector/feature-selector.service';
import LineString  from 'ol/geom/LineString';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GmlPoint } from "../model/GmlPoint";
import { AixmFeatureDTO } from './../model/AixmFeatureDTO';
import { GeoJSON } from 'ol/format';
import Point from 'ol/geom/Point';
import { Feature } from 'ol';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'point-reference',
  templateUrl: './point-reference.component.html',
  styleUrls: ['./point-reference.component.css']
})
export class PointReferenceComponent implements OnInit {

  @Input() segment: Feature;
  @Input() isHighlight: boolean;
  @Output() startPointCoordinatesChanged = new EventEmitter<number[]>();
  @Output() refPropertyChanged = new EventEmitter<string>();
  @Output() centrePointChange = new EventEmitter<boolean>();
  @Output() onError = new EventEmitter<boolean>();

  showFeatureList: boolean = false;
  public color: string;
  point: GmlPoint = new GmlPoint();
  unId: string = null;
  feature: AixmFeatureDTO = new AixmFeatureDTO({ featureId: '', featureType: '', featureName: '', summary: '', lastEdited: '', tsId: null });
  constructor(private featureSelectorService: FeatureSelectorService, private route: ActivatedRoute) {
    this.unId = this.route.snapshot.params['unId']; ''
  }

  ngOnInit() {
    // if "startPoint" of this segment is not undefined, it's list of points
    if (this.segment.get("startPoint") != undefined) {
      if (this.segment.get("startPoint").ref != '' && this.segment.get("startPoint").isUNFeature != undefined) {
        this.featureSelectorService.getReferencedGmlFeature('point', this.segment.get("startPoint").ref.replace('urn:uuid:', ''), this.unId,
          this.segment.get("startPoint").isUNFeature).subscribe(pointFeature => this.point.title = pointFeature.featureName);
      }
    }

    // if "centre" of this segment is not undefined, it's circle
    if (this.segment.get("centre") != undefined) {
      if (this.segment.get("centre").properties.centrePoint.ref != '' && this.segment.get("centre").properties.centrePoint.isUNFeature != undefined) {
        this.featureSelectorService.getReferencedGmlFeature('point', this.segment.get("centre").properties.centrePoint.ref.replace('urn:uuid:', ''), this.unId,
          this.segment.get("centre").properties.centrePoint.isUNFeature).subscribe(pointFeature => this.point.title = pointFeature.featureName);
      }
    }

    if (this.isHighlight) {
      this.color = 'yellow';
    }
  }

  /* isEmpty() {
    return this.point.ref.length == 0;
  } */

  showFeaturesSelector() {
    this.showFeatureList = true;
  }

  setToReferencePropertyValue(feature: AixmFeatureDTO) {
    console.log('Picked ReferencePropertyValue: ', feature);
    this.feature = feature;
    this.point.title = feature.featureName;
    this.showFeatureList = false;
  }

  setCoordinates(point: any) {
    setTimeout(() => {
      console.log("referenced point: " + JSON.stringify(point));

      // if "startPoint" of this segment is not undefined, it's list of points
      if (this.segment.get("startPoint") != undefined) {
        this.segment.get("startPoint").ref = 'urn:uuid:' + this.feature.featureId;
        if (this.feature.tsId == null) {
          this.segment.get("startPoint").isUNFeature = undefined
        } else {
          this.segment.get("startPoint").isUNFeature = (this.feature.tsId.interpretation == 'BASELINE') ? false : true
        };

        if (point.properties['pos'] != null) {
          let geom: number[][] = (<LineString>this.segment.getGeometry()).getCoordinates();
          geom[0] = [point.properties['pos'].split(" ")[0], point.properties['pos'].split(" ")[1]];
          (<LineString>this.segment.getGeometry()).setCoordinates(geom);
        } else {
          throw new Error("Point is empty on the selected feature.")
        }
      }
      // if "center" of this segment is not undefined, it's circle
      if (this.segment.get("centre") != undefined) {
        this.segment.get("centre").properties.centrePoint.ref = 'urn:uuid:' + this.feature.featureId;
        if (this.feature.tsId == null) {
          this.segment.get("centre").properties.centrePoint.isUNFeature = undefined
        } else {
          this.segment.get("centre").properties.centrePoint.isUNFeature = (this.feature.tsId.interpretation == 'BASELINE') ? false : true
        };

        if (point.properties['pos'] != null) {
          let geom: number[][] = (<LineString>this.segment.getGeometry()).getCoordinates();
          geom = this.segment.get("centre").geometry.coordinates;
          geom[0] = [<number>point.properties['pos'].split(" ")[0], <number>point.properties['pos'].split(" ")[1]];
          this.segment.get("centre").geometry.coordinates = geom;
        } else {
          throw new Error("Point is empty on the selected feature.")
        }
      }
      this.startPointCoordinatesChanged.emit([+point.properties['pos'].split(" ")[1], +point.properties['pos'].split(" ")[0]]);
      this.onCentrePointChange();
    });
  }
  onCentrePointChange() {
    this.centrePointChange.emit(true);
  }

}