import { Component, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { Feature } from 'ol';
import { GmlPointType } from '../model/GmlPointType';
import { GmlInterpolation } from '../model/GmlInterpolation';
import LineString from 'ol/geom/LineString';

/**
 * Interface to configure a single segment, except its end point, which is configured on the next segment on the curve.
 * The last point in the parent list is special: user can only configure the coordinates of the start point,
 * and there's no interpolation and end point.
 */
@Component({
  selector: 'geo-point',
  templateUrl: './geo-point.component.html',
  styleUrls: ['./geo-point.component.css']
})
export class GeoPointComponent implements OnChanges {

  @Input() segment: Feature;
  @Input() showInterpolation: boolean;
  @Input() isHighlight: boolean;
  @Input() previousPoint: Feature;
  @Input() isSurface: boolean;
  @Input() isFirstSegment: boolean;
  @Input() interpolation: GmlInterpolation;
  @Input() direction: string;

  @Output() startPointCoordinatesChanged = new EventEmitter<number[]>();
  @Output() segmentChange = new EventEmitter<Feature>();
  @Output() onError = new EventEmitter<boolean>();
  @Output() interpolationChanged = new EventEmitter<GmlInterpolation>();
  @Output() radiusChange = new EventEmitter<boolean>();
  @Output() radiusUomChange = new EventEmitter<boolean>();
  @Output() centrePointChange = new EventEmitter<boolean>();
  @Output() arcDirectionChange = new EventEmitter<string>();

  gmlPointType = GmlPointType;
  gmlInterpolation = GmlInterpolation;

  startPointCoordinates: number[];
  startPointType: GmlPointType;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.segment) {
      this.startPointCoordinates = this.getStartPointCoordinates();
      this.startPointType = this.getStartPointType();
      this.interpolation = this.getInterpolation();
    }
  }

  getStartPointType(): GmlPointType {
    let startPoint = this.segment.get("startPoint");
    let type = (<any>GmlPointType)[startPoint["type"]];
    return type;
  }

  setStartPointType(type: GmlPointType) {
    let startPoint = this.segment.get("startPoint");
    // To check if user selects the same point type again
    let isSamePointType: boolean = startPoint.type == type;
    startPoint.type = type;
    this.startPointType = type;
    if (!isSamePointType) {
      let geom: number[][] = (<LineString>this.segment.getGeometry()).getCoordinates();
      if (geom.length > 0) {
        if (this.segment.get("startPoint").ref != undefined) {
          this.segment.get("startPoint").ref = "";
        }
        this.startPointCoordinates = [0, 0];
        this.onStartPointCoordinatesChanged([0, 0]);
      }
    }
  }

  getInterpolation(): GmlInterpolation {
    return (<any>GmlInterpolation)[this.segment.get("interpolation")];
  }

  /**
   * Return the coordinates in long, lat format separated by a space
   */
  getStartPointCoordinates(): number[] {
    return (<LineString>this.segment.getGeometry()).getFirstCoordinate();
  }

  onStartPointCoordinatesChanged(newCoord: number[]) {
    //this.startPointCoordinates = newCoord;    
    let geom: number[][] = (<LineString>this.segment.getGeometry()).getCoordinates();
    geom[0] = newCoord;
    (<LineString>this.segment.getGeometry()).setCoordinates(geom);
    // update the last coordinates of the previous segment, or of the last segment if we're on the 1st one
    this.startPointCoordinatesChanged.emit(newCoord);
  }

  onSegmentChange(segment: Feature) {
    /* if (this.interpolation == "ARC_BY_CENTRE") {
      let seg = JSON.parse(JSON.stringify(segment));
      //seg.values_.clockwise = this.clockwise;
      segment.set("clockwise", this.clockwise, false);
    } */
    this.segmentChange.emit(segment);
  }

  onerror(isInvalid: boolean) {
    this.onError.emit(isInvalid);
  }

  setInterpolation(interpolation: GmlInterpolation) {
    this.onInterpolationChanged(interpolation);
  }

  onInterpolationChanged(interpolation) {
    this.interpolationChanged.emit(interpolation);
  }

  setDirection(directionChange: string) {
    this.arcDirectionChange.emit(directionChange);
  }

  onRadiusChange() {
    this.radiusChange.emit(true);
  }

  onRadiusUomChange() {
    this.radiusUomChange.emit(true);
  }

  onCentrePointChange() {
    this.centrePointChange.emit(true);
  }
}