import { GeotestComponent } from './geotest/geotest.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Layouts } from '../app.component';

const routes: Routes = [
  {
    path: 'geo-editor/geotest',
    component: GeotestComponent,
    data: { layout: Layouts.SecureLayout }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeoEditorRoutingModule { }
