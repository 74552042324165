import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ApplicationProperties } from './../model/application-properties.model';
import { UserProperties } from './../model/user-properties.model';
import * as GlobalVar from '../../utils/global.var';
import { ConfigService } from '../../utils/config.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class PropertiesService {
  //private applicationpropertiesUrl = 'api/applicationproperties'; // URL to web api
  //private userpropertiesUrl = 'api/userproperties'; // URL to web api
  //private userpropertieslistUrl='http://localhost:8081/faim-web/UserProperties/list';
  private apiServer = ConfigService.settings.apiServer;
  private applicationpropertiesUrl = this.apiServer.url + '/applicationProperties';
  private userpropertiesUrl = this.apiServer.url + '/userProperties';

  constructor(private http: HttpClient) { }

  /** GET userProperties from the server */
  getUserProperties(): Observable<UserProperties[]> {
    return this.http.get<UserProperties[]>(this.userpropertiesUrl)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getUserProperties', error))
      ));

  }

  getUserProperty(eventId: string): Observable<UserProperties> {
    const url = `${this.userpropertiesUrl}/${eventId}`;
    return this.http.get<UserProperties>(url).pipe(tap(data => console.log(data),
      error => catchError(this.handleError('getUserProperties', error))
    ));
  }

  editUserProperty(oldUPid: string, userProperties: UserProperties): Observable<string> {
    const url = `${this.userpropertiesUrl}/${oldUPid}`;
    return this.http.put<string>(url, userProperties, httpOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('editUserProperty', error))
      ));
  }

  /** GET Application Properties from the server */
  getApplicationProperties(): Observable<ApplicationProperties[]> {
    return this.http.get<ApplicationProperties[]>(this.applicationpropertiesUrl)
      .pipe(
        catchError(this.handleError('getApplicationProperties', []))
      );
  }

  addUserProperties(userProperties: UserProperties): Observable<any> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    var formData = new FormData();

    //Append CreateUserProperties data to the multi-part FormData
    const dtoString: string = JSON.stringify(userProperties);
    const dtoBlob = new Blob([dtoString], { type: 'application/json' })
    console.log(dtoBlob);
    formData.append('CreateUserProperties', dtoBlob);

    return this.http.post<FormData>(this.userpropertiesUrl, formData, requestOptions)
      .pipe(tap((formData: FormData) =>
        catchError(this.handleError<FormData>('addUserProperties'))));

  }
  /**
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

