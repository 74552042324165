import { Component, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { GmlPointType } from "../model/GmlPointType";
import { Feature } from 'ol';
import { GmlInterpolation } from '../model/GmlInterpolation';
import LineString from 'ol/geom/LineString';

/**
 * A gml:ArcByCenterPoint is defined by:
  - its center, either coordinates or a point reference
  - its radius and uom
  - start and end angle with uom = "deg"
  - interpolation is fixed to "geodesic"
  - numArc is fixed to 1

  The arc direction is defined by convention: clockwise if start < end, counter-clockiwse otherwise. This convention imposes the use
  of negative angle values in some cases. In practice, users will probably want to select the direction,
  because negative angles are not common in AIPs. We have to calculate the angles and there's no need to show them to the user.

  Reference:
  https://ext.eurocontrol.int/aixm_confluence/display/ACG/Arc+by+Centre+Point
 */
@Component({
  selector: 'geo-arc-by-center',
  templateUrl: './geo-arc-by-center.component.html',
  styleUrls: ['./geo-arc-by-center.component.css']
})
export class GeoArcByCenterComponent implements OnChanges {

  @Input() segment: Feature;
  @Input() interpolation: GmlInterpolation;

  @Output() radiusChange = new EventEmitter<boolean>()
  @Output() radiusUomChange = new EventEmitter<boolean>()
  @Output() centrePointChange = new EventEmitter<boolean>()
  @Output() segmentChange = new EventEmitter<Feature>()

  centrePointCoordinates: number[];
  centrePointType: GmlPointType;
  gmlPointType = GmlPointType;
  radius: number;
  radiusUom: string;
  centrePoint: any;
  public color: string = 'white';
  @Input() isHighlight: boolean;

  constructor() { }

  ngOnInit() {
    if (this.isHighlight) {
      this.color = 'yellow';
    }
  }

  getCentrePointType(): GmlPointType {
    let centrePoint = this.segment.get("centre").properties.centrePoint;
    let type = (<any>GmlPointType)[centrePoint["type"]];
    console.log("centrePointType: '" + type + "'");
    return type;
  }

  setCentrePointType(type: GmlPointType) {
    let centrePoint = this.segment.get("centre").properties.centrePoint;
    // To check if user selects the same point type again
    let isSamePointType: boolean = centrePoint.type == type;
    centrePoint.type = type;
    this.centrePointType = type;
    if (!isSamePointType) {
      let geom: number[][] = (<LineString>this.segment.getGeometry()).getCoordinates();
      if (geom.length > 0) {
        if (this.segment.get("centre").properties.centrePoint.ref != undefined) {
          this.segment.get("centre").properties.centrePoint.ref = "";
        }
        this.onCoordinatesChange([0, 0]);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.segment) {
      this.centrePointCoordinates = this.getCentrePointCoordinates();
      this.centrePointType = this.getCentrePointType();
      this.radius = this.getRadius();
      this.radiusUom = this.getRadiusUom();
      this.interpolation = GmlInterpolation.ARC_BY_CENTRE;
    }
    if (this.interpolation == GmlInterpolation.ARC_BY_CENTRE) {
      this.onCoordinatesChange(this.segment.get("centre").geometry.coordinates);
    }

  }

  /**
   * Return the coordinates in long, lat format separated by a space
   */
  getCentrePointCoordinates(): number[] {
    this.segment.set("interpolation", GmlInterpolation.ARC_BY_CENTRE);

    // if the centre point is undefined, set it default to [0,0]
    if (this.segment.get("centre") == undefined) {
      this.segment.set("centre", {
        geometry: {
          coordinates: []
        },
        properties: {
          centrePoint: {
            type: "COORDINATES"
          },
          type: "Feature"
        }
      })
    }
    return this.segment.get("centre").geometry.coordinates;
  }

  getInterpolation(): string {
    return this.segment.get("interpolation");
  }

  getRadius(): number {
    return this.segment.get("radius");
  }

  getRadiusUom(): string {
    return this.segment.get("radiusUom");
  }

  onCoordinatesChange(newCoord: number[]) {
    let centreObj: any = this.segment.get("centre");
    // let geom: number[][] = centreObj.geometry.coordinates;
    // geom[0] = centrePointCoordinates;
    centreObj.geometry.coordinates = newCoord;
    this.segment.set("centre", centreObj, true);
    this.segmentChange.emit(this.segment);
    //this.centrePointChange.emit(true);
  }

  onRadiusChange(event: any) {
    this.color = 'yellow';
    this.segment.set("radius", event.target.value, true);
    this.segmentChange.emit(this.segment);
    this.radiusChange.emit(true);
  }

  onRadiusUomChange(event: any) {
    this.color = 'yellow';
    this.segment.set("radiusUom", event.value, true);
    this.segmentChange.emit(this.segment);
    this.radiusUomChange.emit(true);
  }

  onCentrePointChange() {
    this.centrePointChange.emit(true);
  }

}