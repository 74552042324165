<mat-expansion-panel [expanded]="expanded" (opened)="getLazyLoadedPropertyFieldsOfGroup(); " style="margin-bottom: 10px;">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="label">{{propertyGroup.label}}</span>
      <span class="fill-remaining-space"></span>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <p *ngIf="propertyGroup.description">{{propertyGroup?.description}}</p>

  <div *ngFor="let property of propertyGroup.properties" class="form-row">
    <ng-container [ngSwitch]="property.propertyType">
      <ng-container *ngIf="notChoiceProperty(property.key)">
        <im-property-switch 
          *ngSwitchDefault 
          [property]="property" 
          [copyProperty]="property"
          [parentFormGroup]="parentFormGroup.controls[getControlName()]"
          [readonly]="readonly"
          [fromChoice]="fromChoice">
        </im-property-switch>
        <!-- [test]="parentFormGroup.controls[1]" [controlName]="getControlName()" -->
        <mat-expansion-panel *ngSwitchCase="'lazyLoaded'">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span>{{property.label}}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p *ngIf="property.description">{{property.description}}</p>
        </mat-expansion-panel>
      </ng-container>
      

      <!-- Point of choice tpl -->
      <ng-container *ngSwitchCase="'choice'">
        <im-choice-field
          [id]="property.key"
          [property]="property"
          [propertyGroup]="propertyGroup"
          [parentFormGroup]="parentFormGroup"
          [positionInArray]="positionInArray"
          [readonly]="readonly"
          [fromChoice]="fromChoice"
          >
        </im-choice-field>
      </ng-container>
    </ng-container>
  </div>

  <mat-action-row *ngIf="propertyGroup.key !== 'aixm:annotation' && propertyGroup.key !== 'aixm:translatedNote'">
    <span class="field-buttons">
      <!-- Field-specific buttons for delete, annotations and info -->
      <button type="button" mat-icon-button [disabled]="readonly" matTooltip=" Set to nil"
        (click)="setToNil(); stopPropagation($event)">
        <mat-icon color="warn" aria-label="Set property to nil" [ngClass]="{'disable':readonly, 'enable': !readonly}">
          delete</mat-icon>
      </button>
      <button type="button" mat-icon-button matTooltip="Revert to initial value"
        (click)="revert(); stopPropagation($event)" [disabled]="readonly">
        <mat-icon color="primary" aria-label="Set property to nil"
          [ngClass]="{'disable':readonly, 'enable': !readonly}">undo</mat-icon>
      </button>
      <button type="button" mat-icon-button matTooltip="Annotations"
        *ngIf="propertyGroup.key !== 'aixm:annotation' && propertyGroup.key !== 'aixm:translatedNote'"
        (click)="showAnnotations? hideAnnotaion(): createAnnotation()" [disabled]="readonly && !propertyGroup.annotations">
        <mat-icon color="primary" aria-label="Annotations"
        [ngClass]="{'disable':readonly && !propertyGroup.annotations, 'enable': propertyGroup.annotations}">note</mat-icon>
      </button>
      <!-- <button type="button" mat-icon-button matTooltip="Property description"
        (click)="showInfo = !showInfo; stopPropagation($event)">
        <mat-icon color="primary" aria-label="Description">info</mat-icon>
      </button> -->
    </span>
  </mat-action-row>

  <div *ngIf="showAnnotations" class="indented">
    <!-- [arrayName]="getControlName() + '-annotations'" -->
    <im-property-switch [property]="propertyGroup.annotations"
      [parentFormGroup]="parentFormGroup.controls[getControlName()]" [arrayName]="'0-annotations'" [readonly]="readonly"></im-property-switch>
  </div>
</mat-expansion-panel>