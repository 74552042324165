<h3>Groups</h3>
	<mat-selection-list dense #groupList [(ngModel)]="selectedGroups"
	  (ngModelChange)="onNgModelChange($event)">
	  <mat-list-option *ngFor="let group of grouplist" [value]="group">
      <span><mat-icon (click)="showInfo = !showInfo" 
        color="primary" aria-label="Property description" matTooltip="Property description">info</mat-icon>
        {{group.name}}</span>
	  </mat-list-option>
	</mat-selection-list>
<ul>
	<li *ngFor="let sel of selectedGroups">{{sel}}</li>
</ul>


