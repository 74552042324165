import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-points-confirmation-dialog',
  templateUrl: './delete-points-confirmation-dialog.component.html',
  styleUrls: ['./delete-points-confirmation-dialog.component.css']
})
export class DeletePointsConfirmationDialogComponent implements OnInit {

  onChange = new EventEmitter();
  constructor(public dialogRef: MatDialogRef<DeletePointsConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.onChange.emit("cancel");
  }

  ngOnInit(): void {
  }

  deletePoints(numOfPoint: number) {
    this.onChange.emit(numOfPoint);
  }

  deleteAllPoints() {
    this.onChange.emit("deleteAll");
  }

}
