<mat-toolbar>
  <span>
    <h3> AIXM 5.x dataset validation</h3>
  </span>
</mat-toolbar>

<mat-card>
  <mat-card-content>
    <mat-label class="space"> Dataset {{fileName}} Uploaded :</mat-label>
    <br>
    <div class="space">
      Aixm Version : {{aixmVersion}}
    </div>
    <br>
    <br>
    <!-- <mat-label class="title space"> Select a schema profile </mat-label> -->
    <br>
    <mat-form-field class="formField space">
      <mat-select name="schemaProfiles" [(value)]="selectedProfile" placeholder="Select a schema profile">
        <mat-option *ngFor="let profile of schemaProfiles" [value]="profile">
          {{profile}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <br>
    <!-- <mat-label class="title space"> Select business rules </mat-label> -->
    <br>
    <mat-form-field class="formField space">
      <mat-select name="businessRules" [(value)]="selectedRules" placeholder="Select business rules" multiple>
        <mat-option *ngFor="let rule of businessRules" [value]="rule">
          {{rule}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <br>
    <button class="space" mat-raised-button color="primary" (click)="validate()">
      Validate
    </button>
  </mat-card-content>
</mat-card>