<h3  mat-dialog-title >Log Sheet</h3>

<div mat-dialog-content>
  
      <mat-form-field>
          <mat-select placeholder="Log Sheet Type" #logSheetType required>
            <mat-option>--</mat-option>
            <mat-option *ngFor="let type of types" [value]="type">
              {{type}}
            </mat-option>
          </mat-select>
      </mat-form-field>
     <br>
  
      <mat-form-field>
          <input matInput placeholder="Subject" #logSheetSubject required>
      </mat-form-field>

</div>

<div mat-dialog-actions>
 
    <!-- (click) passes input value to add() and then clears the input -->
    <button mat-raised-button mat-button color="primary" [mat-dialog-close]="data"
    (click)="add(logSheetType.value,logSheetSubject.value);">Confirm</button>  

    <button mat-raised-button mat-button color="primary" (click)="onNoClick()">Cancel</button>
</div>
