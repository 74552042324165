import { PropertyField } from "./property-field";
import { PropertyGroup } from "./property-group";
import { MultipleProperty } from "./property";

/**
 * Represents a property that is not constrained by a list of values.
 * The type is used to further define the kind of "text" that is allowed, based on the types of Angular 5
 * input element types: 
 * https://material.angular.io/components/input/overview#supported-code-lt-input-gt-code-types
 */
export class TextPropertyField extends PropertyField<string> {
  controlType = 'textbox';
  type: string;

  constructor(options: {
    key: string,
    label?: string,
    hint?: string,
    description?: string,
    order?: number,
    minOccurs?: number,
    maxOccurs?: number,
    nil?: boolean,
    nilReason?: string,
    annotations?: MultipleProperty,
    value?: string,
    minLength?: number,
    maxLength?: number,
    minExclusive?: number,
    maxExclusive?: number,
    minInclusive?: number,
    maxInclusive?: number,
    pattern?: string,
    type?: string,
    modified: boolean
  }) {
    super(options);
    this.type = (options['maxLength'] >= 100 && options['type'] == 'string')? 'textarea': TypeMapper[options['type']] 
    || TypeMapper.string;
  }
}

export class TranslatedNotePropertyField extends PropertyField<string> {
  controlType = 'translatedNote';
  type: string = 'text';
  lang: string;

  constructor(options: {
    key: string,
    label?: string,
    hint?: string,
    description?: string,
    order?: number,
    minOccurs?: number,
    maxOccurs?: number,
    nil?: boolean,
    nilReason?: string,
    value?: string,
    lang?: string,
    minLength?: number,
    maxLength?: number,
    modified: boolean
  }) {
    super(options);
    this.lang = options['lang'] || 'text';
  }
}

export enum TypeMapper {
  string = 'text',
  decimal = 'number',
  date = 'date',
  dateTime = 'datetime-local',
  unsignedInt = 'number',
  tel = 'tel'
}