<mat-toolbar>
  <span>
    <h3> About Facilis.aero Aeronautical Information Management </h3>
  </span>
</mat-toolbar>

<div class="space">
  <span>
    <h3> FAIM server version : {{serverVersion}} </h3>
  </span>
</div>

<div class="space">
  <span>
    <h3> FAIM Web client version : {{clientVersion}}</h3>
  </span>
</div>

<div class="space">
  <p>Facilis.aero is a service of <a href="http://www.nilacandi.com"> Nilacandi SRL </a></p>

</div>

<div class="space">
  <p>
    <I>Copyright 2018-{{year}} Nilacandi SRL</I>
  </p>
</div>