import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicationPropertiesComponent } from './application-properties/application-properties.component';
import { UserPropertiesComponent } from './user-properties/user-properties.component';
import { Layouts } from '../app.component';
import { NewUserpropertiesComponent } from './new-userproperties/new-userproperties.component';
import { NewAppPropertiesComponent } from './new-app-properties/new-app-properties.component';
import { EditUserpropertiesComponent } from './edit-userproperties/edit-userproperties.component';


const routes: Routes = [
  {
    path : 'userproperties',
    component : UserPropertiesComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path : 'userproperties/:id',
    component : UserPropertiesComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path : 'applicationproperties',
    component : ApplicationPropertiesComponent,
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'new-userproperties',
    component: NewUserpropertiesComponent,
    data: {layout: Layouts.SecureLayout}
  },
  {
    path: 'new-app-properties',
    component: NewAppPropertiesComponent,
    data: {layout: Layouts.SecureLayout}
  },
  {
    path: 'edit-userproperties/:id',
    component: EditUserpropertiesComponent,
    data: {layout:Layouts.SecureLayout}
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PropertiesRoutingModule { }
