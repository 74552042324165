import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailsService } from '../login/services/UserDetails.service';
import { DatastoreDto } from './model/DatastoreDto';
import { DatastoreService } from './service/datastore-service';

@Component({
  selector: 'app-datastore',
  templateUrl: './datastore.component.html',
  styleUrls: ['./datastore.component.css']
})
export class DatastoreComponent implements OnInit {

  public selectedDatastore: string;
  public datastoreList: DatastoreDto[];

  constructor(private userDetailsService: UserDetailsService,
    private datastoreService: DatastoreService,
    private router: Router) { }

  ngOnInit(): void {
    this.selectedDatastore = this.userDetailsService.getDefaultDatastore();
    this.datastoreService.getDatastoreList().subscribe(result => this.datastoreList = result);
  }

  saveDatastore(): void {
    this.datastoreService.save(this.selectedDatastore).subscribe(result => {
      this.router.navigate(['home-page']).then(result => {
        location.reload();
      });
    });
  }

}
