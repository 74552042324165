export class Profile {
  id: string;
  name: string;
  description: string;

  constructor(options: {
    id?: string,
    name?: string,
    description?: string,
  } = {}) {
    this.id = options.id;
    this.name = options.name || '';
    this.description = options.description || '';
  }
}
