import { QAProjectSummaryDTO } from './../model/QAProjectSummaryDTO';
import { Item } from '../model/item'
import { Assignment } from './../model/assignment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { CreateQAProjectDto } from './../model/qualityproject';
import { LogOfAssignment } from '../model/LogOfAssignment';
import { ConfigService } from '../../utils/config.service';
import { KeycloakService } from 'keycloak-angular';
import { ProjectSetting } from '../model/ProjectSetting';

const httpOptions = {
  headers: new HttpHeaders()
};

@Injectable()
export class QualityProjectService {

  private apiServerUrl = ConfigService.settings.apiServer.fqaUrl;

  private qualityProjectUrl = this.apiServerUrl + '/qaProject';
  private taskListUrl = this.apiServerUrl + '/listUnassignTasks';
  private claimUrl = this.apiServerUrl + '/claim';
  private completeUrl = this.apiServerUrl + '/complete';
  private QAProjectSummaryDTOUrl = this.apiServerUrl + '/qaProject/list';
  private itemUrl = this.apiServerUrl + '/item/list'
  private releaseUrl = this.apiServerUrl + '/release';
  private logUrl = this.apiServerUrl + '/item/log';
  private newItemUrl = this.apiServerUrl + '/newItem';
  private projectSettingUrl = this.apiServerUrl + '/projectsetting';

  sections: Array<File> = [];

  isQualityController: boolean = this.keycloackService.isUserInRole(ConfigService.settings.role.role_Fqa_Quality_Contoller);

  isProjectManager: boolean = this.keycloackService.isUserInRole(ConfigService.settings.role.role_Fqa_Project_Manager);

  isProjectEditor: boolean = this.keycloackService.isUserInRole(ConfigService.settings.role.role_Fqa_Editor);

  enableFourEyes$ = new BehaviorSubject(false);

  constructor(private http: HttpClient, private keycloackService: KeycloakService) { }

  createNewProject(qualityProjectDTO: CreateQAProjectDto, items: Array<File>): Observable<any> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("The hasDataInput in service is " + qualityProjectDTO.hasDataInput)
    var formData = new FormData();
    console.log("Success");
    // Append QualityProjectDTO data to the multi-part FormData
    const dtoString: string = JSON.stringify(qualityProjectDTO);
    const dtoBlob = new Blob([dtoString], { type: 'application/json' })
    console.log(dtoBlob);
    formData.append('CreateQAProjectDto', dtoBlob);

    // Append each file
    for (let j = 0; j < items.length; j++) {
      formData.append("uploadSection", <File>items[j])
      console.log(items[j].name);
    }
    console.log(items.length);
    return this.http.post<FormData>(this.qualityProjectUrl, formData, requestOptions).pipe(
      tap((formData: FormData) => catchError(this.handleError<FormData>('createNewProject'))));
  }

  observableSubscribeTest(): Observable<any> {
    const myObservable = of(1, 2, 3);
    return myObservable;
  }

  /** GET QA Project from the server */
  getQAProjects(): Observable<QAProjectSummaryDTO[]> {
    return this.http.get<QAProjectSummaryDTO[]>(this.QAProjectSummaryDTOUrl)
      .pipe(catchError(this.handleError('getQAProjects', []))
      );
  }

  /** GET QA Project from the server */
  getItmes(projectId: string): Observable<Item[]> {
    const url = `${this.itemUrl}/${projectId}`;
    return this.http.get<Item[]>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getItmes', error))
      ));
  }

  getTaskList(id: string): Observable<Assignment[]> {
    const url = `${this.taskListUrl}/${id}`;
    console.log("The id is " + id)
    console.log("The assignment is " + this.http.get<Assignment[]>(url))
    return this.http.get<Assignment[]>(url)
      .pipe(
        catchError(this.handleError('getTaskList', []))
      );
  }

  claim(taskId: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: Claim Function: " + taskId);
    const url = `${this.claimUrl}/${taskId}`;
    return this.http.get<string>(url, requestOptions).pipe(
      tap(data => console.log(data),
        error => catchError(this.handleError<string>(`claim id=${taskId}`, error))
      ));
  }

  release(taskId: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: Release Function: " + taskId);
    const url = `${this.releaseUrl}/${taskId}`;
    return this.http.get<string>(url, requestOptions).pipe(
      tap(_ => catchError(this.handleError<string>(`release id=${taskId}`))
      ));
  }

  complete(taskId: string): Observable<string> {

    console.log("in service: Claim Function: " + taskId);
    const url = `${this.completeUrl}`;
    return this.http.post<string>(url, taskId).pipe(
      tap(data => console.log(data),
        error => catchError(this.handleError<string>(`complete id=${taskId}`, error))
      ));
  }

  completeReview(taskId: string, decision: boolean): Observable<string> {

    console.log("in service: Claim Function: " + taskId);
    const url = `${this.completeUrl}/${decision}`;
    return this.http.post<string>(url, taskId).pipe(
      tap(data => console.log(data),
        error => catchError(this.handleError<string>(`claim id=${taskId}`, error))
      ));
  }

  getLogOfAssignment(executionId: string): Observable<LogOfAssignment[]> {
    const url = `${this.logUrl}/${executionId}`;
    return this.http.get<LogOfAssignment[]>(url).pipe(
      tap(data => console.log(data),
        error => catchError(this.handleError<LogOfAssignment[]>(`log of task execution id=${executionId}`, error))
      ));
  }

  /**
    * Handle Http operation that failed.
    * Let the app continue.
    * @param operation - name of the operation that failed
    * @param result - optional value to return as the observable result
    */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  addNewItem(qualityProjectDTO: CreateQAProjectDto, itemName: string): Observable<any> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    const url = `${this.newItemUrl}/${itemName}`;

    return this.http.post<any>(url, qualityProjectDTO, requestOptions).pipe(
      tap(data => console.log(data),
        error => catchError(this.handleError<string>(`addNewItem`, error))
      ));
  }

  getProjectSetting(pid: string) : Observable<ProjectSetting> {
    return this.http.get<ProjectSetting>(`${this.projectSettingUrl}/${pid}`).pipe(
      catchError(error => of(error))
    )
  }

  editProjectSetting(payload: ProjectSetting) {
    return this.http.put<ProjectSetting>(this.projectSettingUrl, payload).pipe(
      catchError(error => throwError(error))
    )
  }
}
