import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about.component';
import { AppMaterialModule } from '../app.material.module';

@NgModule({
  imports: [
    CommonModule,
    AboutRoutingModule,
    AppMaterialModule
  ],

  declarations: [
    AboutComponent
  ],
})

export class AboutModule { }
