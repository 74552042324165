import { Property, MultipleProperty } from "./property";
import { PropertyField } from "./property-field";

export class PropertyGroup extends Property {
  propertyType = 'group';
  maxOccurs: number; // -1 means unbounded
  id: string;

  constructor(options: {
    key: string,
    label?: string,
    hint?: string,
    order?: number,
    minOccurs?: number,
    maxOccurs?: number,
    id: string,
    nil?: boolean,
    nilReason?: string,
    annotations?: MultipleProperty,
    properties?: Property[]
  }) {
    super(options);
    this.id = options["id"];
    this.properties = options.properties;
    this.maxOccurs = options.maxOccurs || 1; // by default, maximum 1
  }
}