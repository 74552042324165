import { UserDetailComponent } from './user-detail/user-detail.component';
import { Layouts } from './../app.component';
import { UserManagementListComponent } from './user-management-list/user-management-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateUsersComponent } from './create-users/create-users.component';
import { CreateGroupsComponent } from './create-groups/create-groups.component';
import { GroupDetailComponent } from './group-detail/group-detail.component';

const routes: Routes = [
  { path: 'user-management', component: UserManagementListComponent, data: { layout: Layouts.SecureLayout }},
  { path: 'create-user', component: CreateUsersComponent, data: { layout: Layouts.SecureLayout }},
  { path: 'user/detail/:id', component: UserDetailComponent, data: { layout: Layouts.SecureLayout }},
  { path: 'create-group', component: CreateGroupsComponent, data: { layout: Layouts.SecureLayout }},
  { path: 'group/detail/:id', component: GroupDetailComponent, data: { layout: Layouts.SecureLayout }}
  ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class UserManagementRoutingModule { }