import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Layouts } from '../app.component';
import {AccountManagementComponent} from './account-management/account-management.component';

const routes: Routes = [
  { path: 'account-management', component: AccountManagementComponent, data: { layout: Layouts.SecureLayout } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountManagementRoutingModule { }
