import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { GmlPointType } from "../model/GmlPointType";
import Collection from 'ol/Collection';
import { Feature } from 'ol';
import LineString from 'ol/geom/LineString';
import { GmlInterpolation } from "../model/GmlInterpolation";
import { BehaviorSubject } from 'rxjs';
import {  MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {  MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import {  MatDialog } from '@angular/material/dialog';
import { DeletePointsConfirmationDialogComponent } from './../delete-points-confirmation-dialog/delete-points-confirmation-dialog.component';
import { FeatureSelectorService } from './../../feature-selector/feature-selector.service';
import { ArcByCentre } from '../model/arc-by-centre';


/**
 * Interface to manage a list of points, which form a series of GmlsegmentCollection. This component is responsible for chaining 
 * the points together by informing each GmlSegment when they are moved around or chained.
 */
@Component({
  selector: 'geo-point-list',
  templateUrl: './geo-point-list.component.html',
  styleUrls: ['./geo-point-list.component.css']
})
export class GeoPointListComponent implements OnInit {

  _segmentCollection = new BehaviorSubject<Collection<Feature>>(null);

  @Input()
  set segmentCollection(value) {
    this._segmentCollection.next(value);
  }
  get segmentCollection(): any { return this._segmentCollection.getValue(); };
  @Input() isSurface: boolean;
  @Input() isCircle: boolean;
  @Input() isHighlight: boolean;
  @Input() isRevert: boolean;
  @Input() isEmpty: boolean;
  @Input() direction: string;

  @Output() segmentCollectionChange = new EventEmitter<Collection<Feature>>();
  @Output() onError = new EventEmitter<boolean>();
  @Output() radiusChange = new EventEmitter<boolean>();
  @Output() radiusUomChange = new EventEmitter<boolean>();
  @Output() centrePointChange = new EventEmitter<boolean>();
  @Output() isGeoEmpty = new EventEmitter<boolean>();
  @Output() arcDirectionChange = new EventEmitter<string>();

  errorNumber: number = 0;
  erroredItem: number[] = [];

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // MatPaginator Output
  pageEvent: PageEvent;
  currentPage: any;
  pageSize: any;

  gmlPointType = GmlPointType;
  gmlInterpolation = GmlInterpolation;
  unId: string = null;

  isReference: boolean = true;

  backupData: any = [];

  //clockwise: boolean = true;
  isTentative: boolean;
  isInitialState: boolean = true;
  geometryCollection: any = [];

  displayedColumns = ['location_on', 'segment', 'delete'];
  dataSource: any = new MatTableDataSource(this.geometryCollection);

  constructor(private route: ActivatedRoute, private router: Router, public dialog: MatDialog, private featureSelectorService: FeatureSelectorService) {
    this.isTentative = this.route.snapshot.params['isTentative']
    this.unId = this.route.snapshot.params['unId']
  }

  onFormSubmit() {
    //alert(JSON.stringify(this.formGroup.value, null, 2));
  }

  ngOnInit(): void {
    let features = this.segmentCollection.getArray();
    this.dataSource.data = [];
    if (features.length > 0) {
      for (let i = 0; i < features.length; i++) {
        if (features[i].get("interpolation") != "CIRCLE_BY_CENTRE") {
          if (features[i].get("startPoint") == undefined) {
            let previous = i === 0 ? features[features.length - 1] : features[i - 1];
            features[i].set("startPoint", previous.get("startPoint"));
          }
          this.dataSource.data.push(features[i]);
          this.onSegmentChange(i, features[i]);
        }
      }
    }
    this.pageSize = 0;
    this.currentPage = 0;

    if (features.length == 0) {
      if (this.isSurface) {
        for (let i = 1; i < 4; i++) {
          this.addPoint(this.gmlPointType.COORDINATES);
        }
      }
      else {
        if (features.length == 0) {
          for (let i = 1; i < 3; i++) {
            this.addPoint(this.gmlPointType.COORDINATES);
          }
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.segmentCollection) {

      if (this.isEmpty && this.dataSource.data.length != 0) {
        console.log("SET TO NIL ")
        let firstSeg: Feature = this.dataSource.data[0];
        if ((<LineString>firstSeg.getGeometry()).getCoordinates().length != 0) {
          this.openConfirmationDialog();
        }
      }

      if (this.isRevert == true) {
        this.geometryCollection = this.segmentCollection.getArray();
        this.revert();
      }

      /* let segments = this.segmentCollection.getArray();
       for (let i = 0; i < segments.length; i++) {
         if (segments[i].getGeometry().getCoordinates() != 0) {
           if (this.geometryCollection[i] == undefined) {
             this.geometryCollection[i] = segments[i];
           } 
         }
       }  */
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  drop(event: CdkDragDrop<string[]>) {
    //moveItemInArray(this.segmentCollection.getArray(), event.previousIndex, event.currentIndex);
    const item = this.removePoint(event.previousIndex, null);
    this.insertPointAt(event.currentIndex, item);
  }

  onStartPointCoordinatesChangedAt(index: number, newCoord: number[]) {
    index = this.getCurrentIndex(index);
    console.log("Change at Index : " + index)
    let previousSegment: Feature = index === 0 ? this.dataSource.data[this.dataSource.data.length - 1] : this.dataSource.data[index - 1];
    let nextSegment: Feature = index === (this.dataSource.data.length - 1) ? this.dataSource.data[0] : this.dataSource.data[index + 1];
    let currentSegment: Feature = this.dataSource.data[index];

    if (previousSegment) {
      let geom: number[][] = (<LineString>previousSegment.getGeometry()).getCoordinates();
      let nextGeom: number[][] = (<LineString>nextSegment.getGeometry()).getCoordinates();
      if (geom.length == 1) {
        geom.push(newCoord);
      } else {
        geom[geom.length - 1] = newCoord;
      }
      (<LineString>previousSegment.getGeometry()).setCoordinates(geom);
      previousSegment.set('endPoint', (<Feature>currentSegment).get('startPoint'));

      //Close the polygon if Surface
      if (this.isSurface && nextGeom.length > 1) {
        (<LineString>currentSegment.getGeometry()).setCoordinates([newCoord, nextGeom[0]]);
      }
    }
    this.segmentCollectionChange.emit(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
  }

  onSegmentChange(index: number, segment: Feature) {
    //this.segmentCollection.setAt(index, segment);
    //this.geometryCollection[index] = segment;
    //this.segmentCollectionChange.emit(this.geometryCollection);
    let seg = JSON.parse(JSON.stringify(segment));

    if (seg.values_.interpolation == "ARC_BY_CENTRE" || seg.values_.interpolation == "ARC_BY_CENTER") {
      index = this.getCurrentIndex(index);
    }
    console.log("Segment change at index  " + index)

    this.dataSource.data[index] = segment;
    this.segmentCollectionChange.emit(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
  }

  removePoint(index: number, item: Feature): Feature {
    index = this.getCurrentIndex(index);
    console.log("Remove Point at Index : " + index)
    // Guard clause
    if (index < 0 || index >= this.dataSource.data.length) {
      throw new RangeError("There is no item at index '" + index + "'");
    }

    // We need at least 2 semgents for curve (the last one is just for its start point)
    if (this.dataSource.data.length <= 2 && !this.isSurface) {
      throw new Error("A list of points needs at least 2 points");
    }

    // We need at least 3 semgents for surface (the last one is just for its start point)
    if (this.dataSource.data.length <= 3 && this.isSurface) {
      throw new Error("A list of points needs at least 3 points");
    }

    const previous: Feature = index === 0 ? this.dataSource.data[this.dataSource.data.length - 1] : this.dataSource.data[index - 1];
    const next: Feature = index === (this.dataSource.data.length - 1) ? this.dataSource.data[0] : this.dataSource.data[index + 1];
    //const removedSegment = this.segmentCollection.removeAt(index);    

    // connect the previous segment to the next one
    if (index === 0 && !this.isSurface) {
      // we're removing the 1st point: nothing to reconnect for curve
    } else if (index === (this.dataSource.data.length - 1) && !this.isSurface) {
      // we're removing the last point
      previous.set('endPoint', undefined);
      let prevGeom: number[][] = (<LineString>previous.getGeometry()).getCoordinates();
      if (prevGeom.length > 1) {
        prevGeom.pop();
      }
      (<LineString>previous.getGeometry()).setCoordinates(prevGeom);
    } else {
      // set start and end point properties of prev and next segments
      if ((<LineString>next.getGeometry()).getCoordinates().length > 0) {
        // if next segment is not empty, takes it priority when connecting segments
        previous.set('endPoint', next.get('startPoint'));
        next.set('startPoint', previous.get('endPoint'));

        // connect start and end point coordinates of prev and next segments
        let prevGeom: number[][] = (<LineString>previous.getGeometry()).getCoordinates();
        let nextGeom: number[][] = (<LineString>next.getGeometry()).getCoordinates();
        prevGeom[prevGeom.length - 1] = nextGeom[0];
        nextGeom[0] = prevGeom[prevGeom.length - 1];
        (<LineString>previous.getGeometry()).setCoordinates(prevGeom);
        (<LineString>next.getGeometry()).setCoordinates(nextGeom);
      } else if (!(<LineString>previous.getGeometry()).getCoordinates().length && (<LineString>previous.getGeometry()).getCoordinates().length > 0) {
        // if next segment is empty, takes prev segment priority when connecting segments
        next.set('startPoint', previous.get('endPoint'));

        // connect start and end point coordinates of prev and next segments
        let prevGeom: number[][] = (<LineString>previous.getGeometry()).getCoordinates();
        let nextGeom: number[][] = (<LineString>next.getGeometry()).getCoordinates();
        nextGeom[0] = prevGeom[prevGeom.length - 1];
        (<LineString>next.getGeometry()).setCoordinates(nextGeom);
      }
    }

    // Find the index of the current segment and reomve it from segment collection and geometryCollection
    let ind: number = this.dataSource.data.findIndex(d => d === item);
    const removedSegment = this.dataSource.data.splice(ind, 1); //this.geometryCollection.splice(ind, 1);
    this.segmentCollection.removeAt(ind);
    this.segmentCollectionChange.emit(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
    return removedSegment;
  }

  insertPointAt(index: number, segment: Feature) {
    index = this.getCurrentIndex(index);
    console.log("Insert Point at Index : " + index)
    // Guard clause
    if (index < 0 || index >= this.segmentCollection.getLength()) {
      throw new RangeError("There is no item at index '" + index + "'");
    }

    const previous = index === 0 ? undefined : this.segmentCollection.item(index - 1);
    const next = index === (this.segmentCollection.getLength() - 1) ? undefined : this.segmentCollection.item(index + 1);
    this.segmentCollection.insertAt(index, segment);

    if (previous !== undefined) {
      // connect the previous segment to new one
      previous.set('endPoint', segment.get('startPoint'));
    }
    if (next !== undefined) {
      // connect the new segment to the next one
      segment.set('endPoint', next.get('startPoint'));
    }
  }

  addPoint(type: GmlPointType) {
    let newSegment: Feature;
    //const previous = this.segmentCollection.item(this.segmentCollection.getLength() - 1);

    const previous: Feature = this.dataSource.data[this.dataSource.data.length - 1];
    // create the new segment starting with the end point of the previous segment
    if (previous != undefined && previous.get("endPoint") != undefined) {
      // get coordinates of end point of previous segment
      const previousGeometry = previous.getGeometry(); // always a LineString
      if (!(previousGeometry instanceof LineString)) {
        // Should not happen
        throw new Error("Unexpected geometry '" + typeof previousGeometry + "' for last segment");
      }
      const previousLine = <LineString>previousGeometry;
      const previousEndCoord = previousLine.getLastCoordinate();

      newSegment = new Feature({ geometry: new LineString([]) });
      newSegment.set('startPoint', previous.get('endPoint'));
    }
    else {
      newSegment = new Feature({ geometry: new LineString([]) });
      newSegment.set("startPoint", { type: type });
    }

    newSegment.set("interpolation", type === GmlPointType.GEO_BORDER_POINT ?
      GmlInterpolation.GEO_BORDER : GmlInterpolation.GREAT_CIRCLE);

    this.segmentCollection.push(newSegment);
    this.dataSource.data.push(newSegment);
    this.dataSource.paginator = this.paginator;
  }

  getSegments(): Feature[] {
    //return this.segmentCollection.getArray();    
    return this.dataSource.data;
  }

  onerror(isInvalid: boolean) {
    console.log("error: " + isInvalid);
    /* setTimeout(() => {
      //this.pageEvent.pageIndex+1
      if(!this.erroredItem.includes(index)){
      this.errorNumber++;
      this.erroredItem.push(index);
      }
   }); */
    this.onError.emit(isInvalid);
  }

  onInterpolationChanged(index: number, interpolation: GmlInterpolation) {
    console.log("Interpolation change to " + interpolation);
    index = this.getCurrentIndex(index);
    console.log("Interpolation Change at Index : " + index)
    let segment: any = this.dataSource.data[index]

    // Guard clause
    if (index < 0 || index >= this.dataSource.data.length) {
      throw new RangeError("There is no item at index '" + index + "'");
    }
    // We always need at least 2 semgents (the last one is just for its start point)
    if (this.dataSource.data.length <= 2) {
      throw new Error("A list of points needs at least 2 points");
    }

    const previous: Feature = index === 0 ? this.dataSource.data[this.dataSource.data.length - 1] : this.dataSource.data[index - 1];
    const next: Feature = index === (this.dataSource.data.length - 1) ? this.dataSource.data[0] : this.dataSource.data[index + 1];

    if (segment.values_.interpolation.includes("ARC_BY_CENTRE")) {
      //ARC_BY_CENTRE to GREAT_CIRCLE and PARALLEL
      if (interpolation == "GREAT_CIRCLE" || interpolation == "PARALLEL") {
        console.log("Arc to " + interpolation)
        //segment.set("interpolation", interpolation);

        // connect the previous segment to the next one
        if (index === 0 && !this.isSurface) {
          // we're removing the 1st point: nothing to reconnect for curve
        } else if (index === (this.dataSource.data.length - 1) && !this.isSurface) {
          // we're removing the last point
          previous.set('endPoint', undefined);
          let prevGeom: number[][] = (<LineString>previous.getGeometry()).getCoordinates();
          prevGeom.pop();
          (<LineString>previous.getGeometry()).setCoordinates(prevGeom);
        } else {
          // set start and end point properties of prev and next segments
          previous.set('endPoint', next.get('startPoint'));
          next.set('startPoint', previous.get('endPoint'));
        }

        // connect start and end point coordinates of prev and next segments
        let prevGeom: number[][] = (<LineString>previous.getGeometry()).getCoordinates();
        let nextGeom: number[][] = (<LineString>next.getGeometry()).getCoordinates();

        let currentGeom: number[][] = (<LineString>segment.getGeometry()).getCoordinates();
        currentGeom[0] = prevGeom[1];
        currentGeom[1] = nextGeom[0];

        let coord = [];
        let coordinates = [(<LineString>previous.getGeometry()).getLastCoordinate(), (<LineString>next.getGeometry()).getCoordinates()[0]];
        for (let i = 0; i < coordinates.length; i++) {
          coord.push(coordinates[i][0])
          coord.push(coordinates[i][1])
        }
        //segment = JSON.parse(JSON.stringify(segment));
        segment.values_.geometry.flatCoordinates = coord
        segment.values_.interpolation = interpolation;

        delete segment.values_.startAngle;
        delete segment.values_.endAngle;
        delete segment.values_.clockwise;
        delete segment.values_.radius;
        delete segment.values_.radiusUom;
        delete segment.values_.centre;
      }
    }
    else {
      segment.set("interpolation", interpolation);
    }
    this.onSegmentChange(index, segment)
  }

  revert() {
    console.log("REVERT");
    let features = this.geometryCollection;
    this.dataSource.data = [];
    for (let i = 0; i < features.length; i++) {
      if (features[i].get("interpolation") != "CIRCLE_BY_CENTRE") {
        this.dataSource.data[i] = features[i];
      }
    }
    this.isRevert = false;
    this.dataSource = new MatTableDataSource(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
    this.segmentCollectionChange.emit(this.dataSource.data);
  }

  onRadiusChange() {
    this.radiusChange.emit(true);
  }

  onRadiusUomChange() {
    this.radiusUomChange.emit(true);
  }

  onCentrePointChange() {
    this.centrePointChange.emit(true);
  }

  getCurrentIndex(index) {
    if (this.paginator != undefined) {
      this.currentPage = this.paginator.pageIndex;
      this.pageSize = this.paginator.pageSize;
    }
    console.log(this.currentPage + " " + this.pageSize);
    return index + (this.currentPage * this.pageSize);
  }

  openConfirmationDialog(): void {
    let noOfPoints: number;
    let dialogRef = this.dialog.open(DeletePointsConfirmationDialogComponent, {
      width: '350px'
    });

    dialogRef.componentInstance.onChange.subscribe((result) => {
      if (result != null && result != "deleteAll") {
        noOfPoints = +result;
        this.geometryCollection = this.dataSource.data;
        let geoColLen = this.geometryCollection.length;
        if (noOfPoints > geoColLen) {
          noOfPoints = geoColLen;
        }

        for (let i = 1; i <= noOfPoints; i++) {
          if (this.geometryCollection[geoColLen - i].get("interpolation") == GmlInterpolation.ARC_BY_CENTRE) {
            this.geometryCollection[geoColLen - i].set("interpolation", GmlInterpolation.GREAT_CIRCLE);
          }
          if (this.geometryCollection[geoColLen - i].get("startPoint").type == GmlPointType.GEO_BORDER_POINT) {
            this.geometryCollection[geoColLen - i].set("startPoint", { type: GmlPointType.COORDINATES });
          }

          if (noOfPoints < 3 && this.geometryCollection[geoColLen - i].getGeometry().getCoordinates().length == 0) {
            noOfPoints += 1;
          }
          else {
            if (geoColLen - i != 0) {
              let geom: number[][] = (<LineString>this.geometryCollection[geoColLen - (i + 1)].getGeometry()).getCoordinates();
              this.geometryCollection[geoColLen - (i + 1)].getGeometry().setCoordinates([geom[0]])
            }

            this.geometryCollection[geoColLen - i].getGeometry().setCoordinates([]);
          }
        }

        let noOfSegment = geoColLen - noOfPoints;
        // Surface
        if (this.isSurface) {
          if (result != "cancel") {
            if (noOfSegment >= 3) {
              for (let i = 1; i <= noOfPoints; i++) {
                let index = this.geometryCollection.length - 1;
                this.geometryCollection.splice(index, 1);
              }
            }
            else {
              for (let i = 4; i <= geoColLen; i++) {
                let index = this.geometryCollection.length - 1;
                this.geometryCollection.splice(index, 1);
              }

            }
          }
        }
        //Segments
        else {
          if (result != "cancel") {
            if (noOfSegment >= 2) {
              for (let i = 1; i <= noOfPoints; i++) {
                let index = this.geometryCollection.length - 1;
                this.geometryCollection.splice(index, 1);
              }
            }
            else {
              for (let i = 3; i <= geoColLen; i++) {
                let index = this.geometryCollection.length - 1;
                this.geometryCollection.splice(index, 1);
              }
            }
          }
        }

        if (noOfSegment == 0) {
          this.geometryCollection = [];
          this.deleteAllPoints();
        } else {
          this.dataSource.data = [];
          this.dataSource.data = this.geometryCollection;
          this.dataSource.paginator = this.paginator;
        }

      }
      //Delete all points
      else {
        this.deleteAllPoints();
      }
      if (result != "cancel") {
        this.segmentCollectionChange.emit(this.dataSource.data);
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed' + result);   
    });
  }

  deleteAllPoints() {
    this.dataSource.data = [];
    if (this.isSurface) {
      for (let i = 0; i < 3; i++) {
        this.addPoint(this.gmlPointType.COORDINATES);
      }
    }
    else {
      for (let i = 0; i < 2; i++) {
        this.addPoint(this.gmlPointType.COORDINATES);
      }
    }
  }

  onArcDirectionChanged(index: number, directionChange: string) {
    console.log("Arc direction changed At : " + index);
    let segment: any = this.dataSource.data[index];
    segment.set("direction", directionChange)
    let interpolation = directionChange === "clockwise" ? "ARC_BY_CENTRE_CLOCKWISE" : "ARC_BY_CENTRE_ANTICLOCKWISE"
    segment.set("interpolation", interpolation)

    this.arcDirectionChange.emit(directionChange);
    this.dataSource.data[index] = segment;
    this.segmentCollectionChange.emit(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
  }

}
