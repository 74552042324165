import { AbstractControl } from '@angular/forms';
import { Temporality } from '../model/temporality';
export class EditDateValidation {

    static CheckDate(AC: AbstractControl) {
        let effectiveDateAc = AC.get('effectiveDate');
        let cancelDateAc = AC.get('cancellationDate');
        if (effectiveDateAc != null && cancelDateAc != null) {
            let effectiveDate = new Date(effectiveDateAc.value);
            let cancelDate = new Date(cancelDateAc.value);
            let temporality = AC.get('temporality').value;
            if ((cancelDate.getTime() < effectiveDate.getTime() || cancelDate.getTime() == effectiveDate.getTime())
                && cancelDate.getTime() != 0 && temporality == Temporality.TEMPORARY) {
                // console.log('false');
                AC.get('cancellationDate').setErrors({ CheckDate: true })
                return { CheckDate: true }
            } else {
                // console.log('true');
                AC.get('cancellationDate').setErrors(null)
                return null;
            }
        }
    }
}