import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../environments/environment';

export function KeycloakInitializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        // await keycloak.init({
        //   config: environment.keycloak,
        //   initOptions: {
        //     onLoad: 'login-required',
        //     checkLoginIframe: false
        //   },
        //   enableBearerInterceptor: true,
        //   bearerExcludedUrls: [],
        //   loadUserProfileAtStartUp: true
        // });
        resolve('');
      } catch (error) {
        reject(error);
      }
    });
  };
}


// import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
// import { User } from '../model/user.model';

// declare var Keycloak: any;

// @Injectable()
// export class KeycloakService {
//     static auth: any = {};
//     static user: User;

//         KeycloakService.auth.loggedIn = false;

//         return new Promise((resolve, reject) => {
//             keycloakAuth
//                 .init({ onLoad: 'login-required' })
//                 .success(() => {
//                     KeycloakService.auth.loggedIn = true;
//                     KeycloakService.auth.authz = keycloakAuth;
//                     KeycloakService.auth.logoutUrl =
//                         keycloakAuth.authServerUrl +
//                         '/realms/' +
//                         environment.KEYCLOAK_REALM +
//                         '/protocol/openid-connect/logout?redirect_uri=' +
//                         document.baseURI;

//                     KeycloakService.auth.authz.loadUserProfile().success(data => {
//                         this.user = new User();
//                         this.user.username = data.username;
//                         this.user.firstName = data.firstName;
//                         this.user.lastName = data.lastName;
//                         this.user.email = data.email;
//                         this.user.dataProviderId = KeycloakService.auth.authz.tokenParsed.data_provider_id;
//                         resolve();
//                     });
//                      console.log(KeycloakService.auth.authz.token);
//                    //console.log(KeycloakService.auth.authz.tokenParsed);
//                 })
//                 .error(() => {
//                     reject();
//                 });
//         });
//     }

//     // Used by other components to query roles (permissions) of the current user.
//     hasAnyRole(roles: String[]): boolean {
//         for (let i = 0; i < roles.length; i++) {
//             if (this.hasRole(roles[i])) {
//                 return true;
//             }
//         }
//         return false;
//     }

//     hasRole(role: String): boolean {
//         return KeycloakService.auth.authz.hasRealmRole(role);
//     }

//     hasManageUsersRole(): boolean {
//         return KeycloakService.auth.authz.hasResourceRole('manage-users', 'realm-management');
//     }

//     logout() {
//         console.log('*** LOGOUT');
//         KeycloakService.auth.loggedIn = false;
//         KeycloakService.auth.authz = null;

//         window.location.href = KeycloakService.auth.logoutUrl;
//     }

//     getToken(): string {
//         return KeycloakService.auth.authz != null && 
//         KeycloakService.auth.authz.authenticated 
//         && KeycloakService.auth.authz.token;
//     }

//     // getToken(): Promise<string> {
//     //     return new Promise<string>((resolve, reject) => {
//     //         if (KeycloakService.auth.authz.token) {
//     //             KeycloakService.auth.authz
//     //                 .updateToken(90) // refresh token if it will expire in 90 seconds or less
//     //                 .success(() => {
//     //                     resolve(<string>KeycloakService.auth.authz.token);
//     //                 })
//     //                 .error(() => {
//     //                     reject('Failed to refresh token');
//     //                 });
//     //         } else {
//     //             reject('Not logged in');
//     //         }
//     //     });
//     // }

//     getUser(): User {
//         return KeycloakService.user;
//     }
// }