import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UpdateNoticeService } from '../service/updatenotice.service';

export interface DialogData {
  id: string;
}

@Component({
  selector: 'app-reject-un-dialog',
  templateUrl: './reject-un-dialog.component.html',
  styleUrls: ['./reject-un-dialog.component.css']
})
export class RejectUnDialogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<RejectUnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router,public updatenoticeService: UpdateNoticeService) { }

  ngOnInit() {
  }

  onNoClick():void{
    this.dialogRef.close();
  }

  reject(featureId:string,description:string):void{
    console.log("The featrueId for reject UN is "+ featureId +"The description is "+ description);
    this.updatenoticeService.rejectUN(featureId,description).subscribe(result=>{
      console.log(result);
      this.router.navigate(['/update']);
    });
  }

}
