import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GmlPointType } from "../model/GmlPointType";
import { Feature } from 'ol';
import LineString from 'ol/geom/LineString';

@Component({
  selector: 'geo-circle',
  templateUrl: './geo-circle.component.html',
  styleUrls: ['./geo-circle.component.css']
})
export class GeoCircleComponent implements OnInit {

  @Input() circle: Feature;
  @Input() isHighlight: boolean;

  @Output() circleChange = new EventEmitter<Feature>();
  @Output() radiusUomChange = new EventEmitter<boolean>();
  @Output() radiusChange = new EventEmitter<boolean>();
  @Output() centrePointChange = new EventEmitter<boolean>();

  radius: number;
  radiusUom: string;

  centrePointCoordinates: number[] = [];
  centrePointType: GmlPointType;
  gmlPointType = GmlPointType;

  public color: string = 'white';

  constructor() { }

  ngOnInit() {
    if (this.isHighlight) {
      this.color = 'yellow';
    }

    if (this.circle == undefined) {
      this.circle = new Feature({ geometry: new LineString([]) });
      this.circle.set("centre", {
        "type": "Feature",
        "properties": {
          "centrePoint": {
            "type": "COORDINATES"
          }
        },
        "geometry": {
          "type": "Point",
          "coordinates": []
        }
      }, true);
      this.centrePointCoordinates = this.getCentrePointCoordinates();
      this.centrePointType = this.getCentrePointType();
      //this.circle.set("radius", this.radius, true);
      //this.circle.set("radiusUom", this.radiusUom, true);
      this.setInterpolation();
    }
    else {
      this.centrePointCoordinates = this.getCentrePointCoordinates();
      this.centrePointType = this.getCentrePointType();
      this.radius = this.getRadius();
      this.radiusUom = this.getRadiusUom();
      this.setInterpolation();
    }
  }

  getCentrePointType(): GmlPointType {
    let centrePoint = this.circle.get("centre").properties.centrePoint;
    let type = (<any>GmlPointType)[centrePoint["type"]];
    return type;
  }

  setCentrePointType(type: GmlPointType) {
    let centrePoint = this.circle.get("centre").properties.centrePoint;
    // To check if user selects the same point type again
    let isSamePointType: boolean = centrePoint.type == type;
    centrePoint.type = type;
    this.centrePointType = type;
    if (!isSamePointType) {
      let geom: number[][] = (<LineString>this.circle.getGeometry()).getCoordinates();
      if (geom.length > 0) {
        if (this.circle.get("centre").properties.centrePoint.ref != undefined) {
          this.circle.get("centre").properties.centrePoint.ref = "";
        }
        this.onCoordinatesChange([0, 0]);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.circle && this.circle != undefined) {
      //this.centrePointCoordinates = this.getCentrePointCoordinates();
      //this.centrePointType = this.getCentrePointType();
      //this.radius = this.getRadius();
      //this.radiusUom = this.getRadiusUom();
      //this.setInterpolation(); 
      this.circleChange.emit(this.circle);

    } /* else if (changes.circle && this.circle == undefined) {
      this.circle = new Feature({ geometry: new LineString([]) });
      this.circle.set("centre", {
        "type": "Feature",
        "properties": {
          "centrePoint": {
            "type": "COORDINATES"
          }
        },
        "geometry": {
          "type": "Point",
          "coordinates": []
        }
      }, true);
      this.centrePointCoordinates = this.getCentrePointCoordinates();
      this.centrePointType = this.getCentrePointType();
      this.circle.set("radius", this.radius, true);
      this.circle.set("radiusUom", this.radiusUom, true);
      this.setInterpolation();
    } */
  }

  /**
   * Return the coordinates in long, lat format separated by a space
   */
  getCentrePointCoordinates(): number[] {
    return this.circle.get("centre").geometry.coordinates;
  }

  getRadius(): number {
    return this.circle.get("radius");
  }

  getRadiusUom(): string {
    return this.circle.get("radiusUom");

  }

  setInterpolation() {
    return this.circle.set("interpolation", "CIRCLE_BY_CENTRE");
  }

  onCoordinatesChange(newCoord: number[]) {
    let centrePointCoordinates = newCoord;
    let centreObj: any = this.circle.get("centre");
    // let geom: number[][] = centreObj.geometry.coordinates;
    // geom[0] = centrePointCoordinates;
    centreObj.geometry.coordinates = centrePointCoordinates;
    this.circle.set("centre", centreObj, true);
    this.circleChange.emit(this.circle);
    this.centrePointChange.emit(true);
  }

  onRadiusChange(event: any) {
    this.color = 'yellow';
    this.circle.set("radius", event.target.value, true);
    this.circleChange.emit(this.circle);
    this.radiusChange.emit(true);
  }

  onRadiusUomChange(event: any) {
    this.color = 'yellow';
    this.circle.set("radiusUom", event.value, true);
    this.circleChange.emit(this.circle);
    this.radiusUomChange.emit(true);
  }
}