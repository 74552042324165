import {  MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataProviderService } from '../service/data-provider.service';
import { DataProvider, Dataprovider } from '../model/dataprovider';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-provider',
  templateUrl: './data-provider.component.html',
  styleUrls: ['./data-provider.component.css']
})
export class DataProviderComponent implements OnInit {
  private dataproviders: DataProvider[] = [];

  displayedColumns = ['name', 'linked', 'dataprovider'];
  dataproviderlist_dataSource = new MatTableDataSource<DataProvider>(this.dataproviders);

  //sorting
  @ViewChild(MatSort) sort: MatSort;
  //pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /**
   * Set the sort after the view init since this component will
   * be able to query its view for the initialized sort.
   */
  ngAfterViewInit() {
    this.dataproviderlist_dataSource.sort = this.sort;
    this.dataproviderlist_dataSource.paginator = this.paginator;
  }

  //filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataproviderlist_dataSource.filter = filterValue;
  }

  constructor(private router: Router, private DataProviderService: DataProviderService) { }

  public ngOnInit(): void {
    this.getDataProviders();
  }

  getDataProviders(): void {
    this.DataProviderService.getDataProviders()
      .subscribe(dataproviders => this.dataproviderlist_dataSource.data = dataproviders);
  }

  unLink(identifier: string): void {
    this.DataProviderService.unLink(identifier).subscribe(result => {
      this.ngOnInit();
    }
    );
  }

}
