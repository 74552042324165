import { Temporality } from "./temporality";

/**
 * DTO for creating a new Update Notice.
 *
 */

export class CreateUpdateNoticeDTO {

    // Mandatory fields
    name: string;
    effectiveDate: Date;
    temporality: Temporality;

    // Optional fields
    cancelDate: Date;
    description: string;

    constructor(options: {
        name?: string,
        effectiveDate?: Date,
        temporality?: Temporality,
        cancelDate?: Date,
        description?: string,
    } = {}) {
        this.name = options.name || '';
        this.effectiveDate = options.effectiveDate;
        this.temporality = options.temporality;
        this.cancelDate = options.cancelDate;
        this.description = options.description || '';
    }
}