export class UserProperties{
    id:string;
    modulename : string;
    property : string;
    propertyvalue : string;

    constructor(options:{
        id ?: string;
        modulename ?: string;
        property ?: string;
        propertyvalue ?: string;
    }={}){
        this.id=options.id || '';
        this.modulename = options.modulename || '';
        this.property = options.property || '';
        this.propertyvalue = options.propertyvalue || '';
    }
}