import { HttpClient } from '@angular/common/http';
import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { AixmValidatorService } from '../service/aixm-validator.service';
import { ActivatedRoute } from '@angular/router';
import { bizrule, profile } from '../aixm-validator.component';
import { Input } from '@angular/core';
import {  MatPaginator } from '@angular/material/paginator';
import FileSaver from 'file-saver';
import { ngxCsv } from 'ngx-csv';
import { ExportDatasetDialogComponent } from '../export-dataset-dialog/export-dataset-dialog.component';
import {  MatDialog } from '@angular/material/dialog';

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'app-validation-report',
  templateUrl: './validation-report.component.html',
  styleUrls: ['./validation-report.component.scss']
})
export class ValidationReportComponent implements OnChanges {

  @Input() selectedRules;
  @Input() selectedProfile;
  @Input() taskId: string;
  @Input() aixmVersion: string;
  @Input() doValidate: boolean;
  @Input() fileName: boolean;

  date: string;
  result: string = "Pending…";
  reportFileName: string;
  certificateFileName: string;
  certificateLabel: string;
  retryCount: number;

  allRowsExpanded: boolean = true;

  // Datasoure for table
  validatedResult_DataSource = new MatTableDataSource<any>();
  displayedColumnsName = ['Type', 'Schema Name', 'Severity', 'Description', 'Location'];
  groupByColumnName = [
    { name: 'Type' },
    { name: 'Schema Name' },
    { name: 'Severity' },
    { name: 'Description' },
    { name: 'Location' }
  ]
  // Sorting
  @ViewChild(MatSort) sort: MatSort;
  // Pagination
  @ViewChild(MatPaginator) paginator: MatPaginator;

  businessRules: bizrule[] = [];
  schemaProfiles: profile[] = [];

  selectedRulesName: string[] = [];
  selectedProfileName: string[] = [];

  columns: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];

  isChecked: boolean = true;
  reportList: any;

  constructor(private http: HttpClient,
    private aixmValidatorService: AixmValidatorService,
    public dialog: MatDialog,
    private route: ActivatedRoute) {
    this.reportFileName = this.route.snapshot.params['reportFileName'];

    this.columns = [{
      field: 'type'
    }, {
      field: 'name'
    }, {
      field: 'flag'
    }, {
      field: 'error'
    }, {
      field: 'location'
    }];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['errorMessage'];
  }

  ngOnInit(): void {
    if (this.reportFileName != undefined) {
      this.result = "Loading report…";
      this.doValidate = true;
      this.aixmValidatorService.getValidationReportFromHistory(this.reportFileName).subscribe(
        (response) => {

          this.date = moment(new Date(response.date)).format('DD MMM YYYY');
          this.selectedProfile = response.schemaList;
          this.selectedRules = response.bizRuleList;
          this.aixmVersion = response.versionCode;
          this.fileName = response.datasetName;
          this.result = response.validationReportStatus;
          if (this.result === null) {
            this.result = response.valid ? "VALID" : "INVALID";
          }
          this.certificateLabel = this.result === "VALID" ? "Download" : "";
          this.certificateFileName = response.taskId + ".pdf";

          this.selectedProfileName = [];
          this.selectedRulesName = [];

          for (let rule of this.selectedRules) {
            this.selectedRulesName.push(rule.name);
          }
          for (let profile of this.selectedProfile) {
            this.selectedProfileName.push(profile.name);
          }
          this.reportList = response.reportList;
          this.validatedResult_DataSource.data = this.addGroups(response.reportList, this.groupByColumns);
          this.validatedResult_DataSource.filterPredicate = this.customFilterPredicate.bind(this);
          this.validatedResult_DataSource.filter = performance.now().toString();
          this.validatedResult_DataSource.paginator = this.paginator;

          //this.validatedResult_DataSource = new MatTableDataSource<any>(response.reportList);
          //this.validatedResult_DataSource.paginator = this.paginator;
        });
    }

  }

  ngOnChanges(): void {
    this.date = moment(new Date()).format('DD MMM YYYY');
    this.result = "Pending…";
    // 5 secs * 2 = 10 seconds
    this.retryCount = 2;

    this.validatedResult_DataSource.data = [];
    this.selectedProfileName = [];
    this.selectedRulesName = [];

    for (let rule of this.selectedRules) {
      this.selectedRulesName.push(rule.name);
    }
    this.selectedProfileName.push(JSON.parse(JSON.stringify(this.selectedProfile)).name);

    if (this.taskId != null && this.doValidate == true) {

      if (this.taskId != null || this.taskId != undefined || this.taskId != "") {
        setTimeout(() => {
          this.getStatus()
        }, 5000);
      }
    }
  }

  ngAfterViewInit() {
    this.validatedResult_DataSource.sort = this.sort;
    this.validatedResult_DataSource.paginator = this.paginator;
  }

  // Filter rows
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.validatedResult_DataSource.filter = filterValue;
  }

  getStatus() {
    if (this.taskId != null) {

      this.aixmValidatorService.getStatus(this.taskId).subscribe(
        (response) => {
          try {
            let data = response.reportList;
            this.reportList = data;

            if (data instanceof Array) {

              this.validatedResult_DataSource = new MatTableDataSource<any>(data);
              this.validatedResult_DataSource.paginator = this.paginator;

              this.checkGroup("Group");
              this.result = response.validationReportStatus;
              if (this.result === null) {
                this.result = response.valid ? "VALID" : "INVALID";
              }
              this.certificateLabel = this.result === "VALID" ? "Download" : "";
              this.certificateFileName = response.taskId + ".pdf";
            } else {
              throw new Error("The report is not ready, the validation is still running.");
            }
          } catch (e) {
            if (this.retryCount >= 1) {

              console.log(e + " Retrying in 5 seconds… Retry count: " + this.retryCount + " left.");
              this.retryCount--;

              setTimeout(() => {
                this.getStatus()
              }, 5000);
            } else {
              this.result = "The report is not ready in 15 seconds. Please check the status in Validation History.";
            }
          }
        },
        (error) => { })
    }
  }

  transformValidationReportStatus(status: string) {
    if (status.toUpperCase() === "VALID") {
      status = "Valid";
    } else if (status.toUpperCase() === "INVALID") {
      status = "Invalid";
    } else if (status.toUpperCase() === "FAILED") {
      status = "Failed";
    } else if (status.toUpperCase() === "PENDING") {
      status = "Pending…";
    }
    return status;
  }

  hasReportFileName(): boolean {
    return this.reportFileName != undefined;
  }

  getCertificate(certificateFileName: string) {
    this.aixmValidatorService.getCertificate(certificateFileName).subscribe(
      (response: ArrayBuffer) => {
        const blob: any = new Blob([response], { type: 'blob' });
        FileSaver.saveAs(blob, certificateFileName);
      }
    );
  }

  // below is for grid row grouping
  customFilterPredicate(data: any | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.validatedResult_DataSource.data.filter(
      row => {
        if (!(row instanceof Group)) {
          return false;
        }
        let match = true;
        this.groupByColumns.forEach(column => {
          if (!row[column] || !data[column] || row[column] !== data[column]) {
            match = false;
          }
        });
        return match;
      }
    );

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.validatedResult_DataSource.filter = performance.now().toString();  // bug here need to fix
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(
        row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }
      ),
      JSON.stringify);

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  checkGroup(event: any) {
    if (event == "Group") {
      this.validatedResult_DataSource.data = this.addGroups(this.reportList, this.groupByColumns);
      this.validatedResult_DataSource.filterPredicate = this.customFilterPredicate.bind(this);
      this.validatedResult_DataSource.filter = performance.now().toString();
      this.validatedResult_DataSource.paginator = this.paginator;
    } else {
      this.validatedResult_DataSource = new MatTableDataSource<any>(this.reportList);
      this.validatedResult_DataSource.paginator = this.paginator;
    }
  }

  exportToCsv() {
    let options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Validation Report',
      useBom: true,
      noDownload: false,
      headers: ["Type", "Schema Name", "Severity", "Description", "Location"]
    };

    if (this.reportList != undefined) {
      let newReportList: any[] = [];

      this.reportList.forEach((report) => {

        // let modifiedMsg = "";
        // let msgArr = report.errorMessage.split(/\r?\n|\r|\n/g);
        // for (let index = 0; index < msgArr.length; index++) {
        //   const msg = msgArr[index];
        //   modifiedMsg = modifiedMsg == "" ? msg : modifiedMsg + " " + msg;
        // }
        // if (msgArr.length < 1) {
        //   modifiedMsg = report.errorMessage;
        // }

        let modifiedMsg = report.errorMessage.replace(/\r?\n|\r|\n/g, "");
        let newReport = {
          "Type": report.type,
          "Schema Name": report.schemaName,
          "Severity": report.flag,
          "Description": modifiedMsg,
          "Location": report.location
        }
        newReportList.push(newReport);

      })

      let dialogRef = this.dialog.open(ExportDatasetDialogComponent, {
        width: '500px',
        data: {}
      });

      dialogRef.componentInstance.onConfirm.subscribe((result) => {
        console.log(result);
        if (result == "download") {
          new ngxCsv(newReportList, "Validation Report", options);
        }
      });
    }
  }

  onGroupnameChange(groupby: any) {
    console.log(groupby.value.name);
    let groupByName = groupby.value.name;
    if (groupByName == "Description") {
      this.groupByColumns = ['errorMessage']
    } else if (groupByName == "Type") {
      this.groupByColumns = ['type']
    } else if (groupByName == "Schema Name") {
      this.groupByColumns = ['schemaName']
    } else if (groupByName == "Severity") {
      this.groupByColumns = ['flag']
    } else {
      this.groupByColumns = ['location']
    }
    this.isChecked = true;
    this.allRowsExpanded = true;
    this.validatedResult_DataSource.data = this.addGroups(this.reportList, this.groupByColumns);
    this.validatedResult_DataSource.filterPredicate = this.customFilterPredicate.bind(this);
    this.validatedResult_DataSource.filter = performance.now().toString();
    this.validatedResult_DataSource.paginator = this.paginator;
  }

  public toggle() {
    this.isChecked = true;
    this.allRowsExpanded = !this.allRowsExpanded;
    if (this.allRowsExpanded) {
      this.validatedResult_DataSource.data = this.addGroups(this.reportList, this.groupByColumns);

    } else {
      console.log('Collapse');

      let data = this.reportList;
      let level = 0;
      const groups = this.uniqueBy(
        data.map((row) => {
          const result = new Group();
          result.level = level + 1;
          result.parent = new Group();
          for (let i = 0; i <= level; i++) {
            result[this.groupByColumns[i]] = row[this.groupByColumns[i]];
          }
          return result;
        }),
        JSON.stringify
      );

      const currentColumn = this.groupByColumns[level];
      let subGroups = [];
      groups.forEach((group) => {
        const rowsInGroup = data.filter(
          (row) => group[currentColumn] === row[currentColumn]
        );
        group.totalCounts = rowsInGroup.length;
        group.expanded = false;
        const subGroup = this.getSublevel(
          rowsInGroup,
          level + 1,
          this.groupByColumns,
          group
        );
        subGroup.unshift(group);
        subGroups = subGroups.concat(subGroup);
        //console.log('Sub Group : ' + JSON.stringify(subGroups));
      });

      //console.log('New Groups : ' + JSON.stringify(groups));
      this.validatedResult_DataSource.data = subGroups;
    }

    this.validatedResult_DataSource.filterPredicate = this.customFilterPredicate.bind(this);
    this.validatedResult_DataSource.filter = performance.now().toString();
    this.validatedResult_DataSource.paginator = this.paginator;
  }

}
