import { AppConfig } from "./AppConfig";
import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { KeycloakService } from "keycloak-angular";
import { UserDetailsService } from "../login/services/UserDetails.service";
import { AccessControlService } from "../shared/access-control.service";
import { catchError, from, lastValueFrom, map, of, switchMap, tap, throwError } from "rxjs";

@Injectable({ providedIn: "root" })
export class ConfigService {
  static settings: AppConfig;
  private _env: string;
  private http: HttpClient;

  constructor(
    private handler: HttpBackend,
    private keycloakservice: KeycloakService,
    private userDetailsService: UserDetailsService,
    private accessControlService: AccessControlService
  ) {
    this.http = new HttpClient(handler);
  }

  loadAppConfig() {
    return new Promise<AppConfig>(async (resolve, reject) => {
      this._env = "development";

      if (environment.production) {
        this._env = "production";
      }

      if (environment.staging) {
        this._env = "staging";
      }
      console.log(this._env, "## ENV");

      // await this.keycloakservice.init({
      //   config: environment.keycloak,
      //   initOptions: {
      //     onLoad: 'login-required',
      //     checkLoginIframe: false
      //   },
      //   enableBearerInterceptor: true,
      //   bearerExcludedUrls: [],
      //   loadUserProfileAtStartUp: true
      // });
      // var defaultDatastore = this.userDetailsService.getDefaultDatastore();
      // this._env = this._env + '-' + defaultDatastore;

      const loadConfig$ = this.loadConfigFromAssets();
      /** the `lastValueFrom` operator will convert Observable into a Promise */
      const loadAppConfigToPromise = lastValueFrom(loadConfig$);

      loadAppConfigToPromise
        .then(async (result) => {
          console.log(result, "### loadAppConfigToPromise resolved ");
          resolve(ConfigService.settings);
        })
        .catch((error) => {
          reject(error);
        });

      // this.http.get('./assets/config/' + this._env + '.json').toPromise().then((response: AppConfig) => {

      //   ConfigService.settings = <AppConfig>response;

      //   // load access control config
      //   ConfigService.settings.isDataProvider = this.userDetailsService.hasRole(ConfigService.settings.role.role_Data_Provider);
      //   if (ConfigService.settings.isDataProvider == true) {
      //     this.accessControlService.checkUserIsDP(ConfigService.settings.apiServer.url + '/checkDP').subscribe(result => {
      //       ConfigService.settings.isDataProvider = result;
      //     });
      //   }
      //   ConfigService.settings.isUnReader = this.userDetailsService.hasRole(ConfigService.settings.role.role_Data_Reader);
      //   ConfigService.settings.isAis = this.userDetailsService.hasRole(ConfigService.settings.role.role_Ais_Admin);

      //   console.log('Config Loaded');
      //   console.log(ConfigService.settings);
      //   resolve(ConfigService.settings);

      // }).catch((response: any) => {
      //   reject(`Could not load the config file`);
      // });
    });
  }

  loadConfigFromAssets() {
    const assetsConfig$ = this.http.get("./assets/config/" + this._env + ".json");
    return assetsConfig$.pipe(
      catchError(() => of(`Could not load the config file`)),
      tap((response: AppConfig) => {
        ConfigService.settings = response;
      }),
      switchMap((assetsConfig) => {
        // Create observable when init keycloack instance
        const initKeycloack$ = from(
          this.keycloakservice.init({
            // Load keycloack settings from <environment>.json file
            config: ConfigService.settings.keycloakServer,
            initOptions: {
              onLoad: "login-required",
              checkLoginIframe: false,
            },
            enableBearerInterceptor: true,
            bearerExcludedUrls: [],
            loadUserProfileAtStartUp: true,
          })
        );

        return initKeycloack$.pipe(
          switchMap(() => {
            // After init keycloack, proceed to assign user property
            return this.accessControlService.checkUserIsDP(`${ConfigService.settings.apiServer.url}/checkDP`).pipe(
              catchError((err) => throwError('Assign access control failed: Please check `checkUserIsDP` Service')),
              tap((response) => console.log(response, "### this.accessControlService.checkUserIsDP")),
              map((checkUserIdDp: boolean) => ({ assetsConfig, checkUserIdDp })),
              tap(({ checkUserIdDp }) => {
                const lookup = {
                  isDataProvider: this.userDetailsService.hasRole(ConfigService.settings.role.role_Data_Provider),
                  isUnReader: this.userDetailsService.hasRole(ConfigService.settings.role.role_Data_Reader),
                  isAis: this.userDetailsService.hasRole(ConfigService.settings.role.role_Ais_Admin),
                };
                console.log(lookup, "### lookup");

                for (const property in lookup) {
                  ConfigService.settings[property] = lookup[property];
                  if (ConfigService.settings.isDataProvider) {
                    ConfigService.settings.isDataProvider = checkUserIdDp;
                  }
                }
              }),
              map(() => ConfigService.settings),
              tap(() => console.log(ConfigService.settings, "## final tap"))
            );
          })
        );
      })
    );
  }

  static getConfig() {
    return ConfigService.settings;
  }

  // Gets a value of specified property in the configuration file
  static get(key: any) {
    return ConfigService.settings[key];
  }
}
