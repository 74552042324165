import { Injectable } from '@angular/core';
import { IcaoCatalogConfig } from './IcaoCatalogConfig';
import { HttpClient, HttpBackend } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IcaoCatalogService {

  static settings: IcaoCatalogConfig;

  private http: HttpClient
  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(handler);
    
   }

  loadAppConfig() {    
    const jsonFile = 'assets/icao-data-catalog/ICAO-data-catalogues.json';
        
    return new Promise<void>(async(resolve, reject) => {
        this.http.get(jsonFile).toPromise().then((response : IcaoCatalogConfig) => {
          IcaoCatalogService.settings = <IcaoCatalogConfig>response;

           console.log('Config Loaded');
           console.log( IcaoCatalogService.settings);
           resolve();
           
        }).catch((response: any) => {
           reject(`Could not load the config file`);
        });
    });
}

  getConfig() {
    return IcaoCatalogService.settings;
  }
}
