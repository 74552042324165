import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { UpdateNoticeService } from '../service/updatenotice.service';

export interface DialogData {
  featureId: string;
  itemId: string;
}
@Component({
  selector: 'app-delete-item-dialog',
  templateUrl: './delete-item-dialog.component.html',
  styleUrls: ['./delete-item-dialog.component.css']
})
export class DeleteItemDialogComponent implements OnInit {

  private result: string;
  onAdd = new EventEmitter();

  constructor(private updateNoticeService:UpdateNoticeService,private snackBar: MatSnackBar,  
    public dialogRef: MatDialogRef<DeleteItemDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteItem(featureId:string,itemId: string): void {
    console.log("In component: Claim Function: " + itemId);

    this.updateNoticeService.deleteItem(featureId, itemId).subscribe(result => {
      this.result = result,
      console.log("Claimed Task: " + result);
      this.onAdd.emit(result);
      this.snackBar.open("Successfully Deleted", "OK", {
        duration: 3000,
      });
    });
   
  }

}
