import { Logsheet } from './../model/logsheet';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { LogEntry } from '../model/logentry';
import { LogSheetSummaryDTO } from '../model/logsheetSummaryDTO';
import { LogEntrySummaryDTO } from '../model/logentrySummaryDTO';
import * as GlobalVar from '../../utils/global.var';
import { ConfigService } from '../../utils/config.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LogsheetService {
  //private logsheetUrl = 'api/logsheet'; //URL to web api 
  //private logentriesUrl = 'api/logentry'; //URL to web api 
  private apiServer = ConfigService.settings.apiServer;
  private logentriesUrl = this.apiServer.url + '/logEntry/list';
  private logsheetUrl = this.apiServer.url + '/logSheet';
  private closeUrl = this.apiServer.url + '/logSheet/close';
  private reopenUrl = this.apiServer.url + '/logSheet/reopen';
  private logentryUrl = this.apiServer.url + '/logEntry';

  constructor(private http: HttpClient, private messageService: MessageService) { }

  /** GET logsheets from the server */
  getLogSheets(): Observable<LogSheetSummaryDTO[]> {
    return this.http.get<LogSheetSummaryDTO[]>(this.logsheetUrl)
      .pipe(tap(logsheets => this.log(`fetched logsheets`)),
        catchError(this.handleError('getLogSheets', []))
      );
  }

  /** GET Log Entries from the server */
  getLogEntries(id: string): Observable<LogEntrySummaryDTO[]> {
    const url = `${this.logentriesUrl}/${id}`;
    return this.http.get<LogEntrySummaryDTO[]>(url)
      .pipe(tap(logentries => this.log(`fetched log entries`)),
        catchError(this.handleError('getLogEntries', []))
      );
  }

  //////// Save methods //////////

  /** POST: add a new Log Sheet to the server */
  addLogSheet(logsheet: Logsheet): Observable<string> {
    console.log("in service: " + logsheet.subject);
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }

    return this.http.post<string>(this.logsheetUrl, logsheet, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('addLogSheet', error))
      ));
  }

  /** POST: add a new Log Entry to the server */
  addLogEntry(logEntry: LogEntry): Observable<string> {
    console.log("in service addlogentry: " + logEntry.description + logEntry.logSheetId);
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }

    return this.http.post<string>(this.logentryUrl, logEntry, requestOptions)
      .pipe(tap(data => console.log("The entry is" + data),
        error => catchError(this.handleError('addLogEntry', error))
      ));
  }

  /** PUT: add a new Log Entry to the server as a correction*/
  correctLogEntry(logEntry: LogEntry): Observable<any> {
    console.log("in service correctlogentry: " + logEntry.description + logEntry.logSheetId);
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }

    return this.http.put<any>(this.logentryUrl, logEntry, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('correctLogEntry', error))
      ));
  }

  /** GET Log Sheet by id. */
  getLogSheetById(id: string): Observable<LogSheetSummaryDTO> {
    console.log("in service: " + id);
    const url = `${this.logsheetUrl}/${id}`;
    return this.http.get<LogSheetSummaryDTO>(url).pipe(
      tap((logsheet: LogSheetSummaryDTO) => catchError(this.handleError<LogSheetSummaryDTO>(`getLogsheets`))
      ));
  }

  close(logsheet: LogSheetSummaryDTO): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("in service: " + logsheet.id);
    return this.http.post<string>(this.closeUrl, logsheet, requestOptions).pipe(
      tap((logsheet: string) => catchError(this.handleError<string>(`Close`))
      ));
  }

  /*   reopen(logsheet: LogSheetSummaryDTO): Observable<string> {
      console.log("in service: "+ logsheet.id);
      return this.http.post<string>(this.reopenUrl,logsheet).pipe(
      tap((logsheet: string)=> catchError(this.handleError<string>(`Reopen`))
      ));
    } */

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a Logsheet Service message with the MessageService */
  private log(message: string) {
    this.messageService.add('LogsheetService: ' + message);
  }
}