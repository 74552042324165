<h3 mat-dialog-title>Would you like to release {{data.name}} ?</h3>

<div mat-dialog-actions>

      <button mat-raised-button mat-button color="primary" class="example-btn-width" [mat-dialog-close]="data.id"
            (click)="release(data.id)" style="cursor:pointer">
            Release
      </button>

      <button mat-raised-button mat-button color="primary" class="example-btn-width"
            (click)="onNoClick()">Cancel</button>

</div>