import { PropertyService } from './property.service';
import { AixmFeatureDTO } from './../model/AixmFeatureDTO';
import { UpdateNoticeService } from '../../update-notice/service/updatenotice.service';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, AbstractControl, UntypedFormControl } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import { Property } from "./model/property";
import { PropertyFieldControlService } from "./property-field-control.service";
import { FeatureSearchComponent } from "../feature-search/feature-search.component";
import { FeatureGroup, TimeSlice } from '../model/feature-group';
import { AixmDynamicDTO } from '../../update-notice/model/aixmDynamicDTO';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ConfigService } from '../../utils/config.service';
import { DatePipe, Location } from '@angular/common';
import { DataproviderDialogComponentComponent } from '../dataprovider-dialog-component/dataprovider-dialog-component.component';
import { LocalStorageService } from '../service/local-storage.service';
import { UserDetailsService } from '../../login/services/UserDetails.service';
import { Dataprovider } from '../../data-provider/model/dataprovider';
import { InfoManagementService } from '../service/info-management.service';
import { LatlongComponent } from '../../geo-editor/latlong/latlong.component';
import moment from 'moment';
import { CodeListPropertyField } from './model/code-list-property-field';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { TimesliceDTO } from '../model/TimesliceDTO';
import { DataProviderService } from '../../data-provider/service/data-provider.service';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'im-input-form',
  templateUrl: './input-form.component.html',
  styleUrls: ['./input-form.component.css'],
  providers: [PropertyFieldControlService, PropertyService]
})
export class InputFormComponent implements OnInit {
  isDp: boolean = ConfigService.settings.isDataProvider;

  datePipe = new DatePipe("en-US");
  unId: string;
  unName: string;
  featureId: string;
  featureType: string;
  isTentative: boolean;
  effectiveDate: string;
  properties: Property[] = [];
  state: string;
  tsId: string;
  featureAnnotationProperty: Property;
  annotationProperty: Property;
  beginPosition: string;
  endPosition: string;
  gmlname: string;
  aeroInputForm: UntypedFormGroup = new UntypedFormGroup({});
  payLoad = '';
  groupString = '';
  showAnnotations: boolean = false;
  selectedTS: any;
  public readonly: boolean;
  /* BE_readonly: the property which is related to allowed editors send from BE. 
  We need to recalculate actual readonly property based on other constraints */
  public BE_readonly: boolean;
  allowedEditors: Set<Dataprovider>;

  @Input() featureGroup: FeatureGroup;
  @Input() feature: AixmFeatureDTO;
  @Input() timeSlice: TimeSlice;

  latLongRegexPattern = /^\d+°?\d+'?\d+\.?\d+"?[NS]\s\d+°?\d+'?\d+\.?\d+"?[EW]$/;
  decimalRegex = /^(-?\d\d?(\.\d+)?),?\s+?(-?\d\d?\d?(\.\d\d\d\d+)?)$/;

  constructor(
    private pfcs: PropertyFieldControlService,
    private updateNoticeService: UpdateNoticeService,
    private infoManagementService: InfoManagementService,
    private route: ActivatedRoute,
    private propertyService: PropertyService,
    private router: Router,
    private location: Location,
    public dialog: MatDialog,
    private userDetailsService: UserDetailsService,
    private localStorageService: LocalStorageService,
    private dataServiceProvider: DataProviderService
  ) {
    this.unId = this.route.snapshot.params['unId'],
      this.unName = this.route.snapshot.params['unName'],
      this.featureId = this.route.snapshot.params['featureId'],
      this.featureType = this.route.snapshot.params['featureType'],
      this.isTentative = this.route.snapshot.params['isTentative']

    // This is UN effective date just to be shown in aixm form
    if (this.route.snapshot.params['effDate'] != null) {
      this.effectiveDate = this.datePipe.transform(this.route.snapshot.params['effDate'], 'dd MMM yyy').toUpperCase();
    }
    this.localStorageService.featureType = this.featureType;
  }

  ngOnInit() {
    this.getAllowedEditors();
    // get the state of feature whether it is new or existing feature
    this.route.data.subscribe(data => {
      this.state = data.state;
    });
    this.isClaim();

    this.getGmlName();
    this.clearReadonlyStorage();
    this.constructFormPropertiesWithPreselectTs();

  }

  getAllowedEditors() {
    this.infoManagementService.getAllowedEditors(this.featureId).subscribe(allowedEditorDTO => {
      this.allowedEditors = allowedEditorDTO.allowedEditors;
      this.BE_readonly = allowedEditorDTO.readonly;

      if (this.unId) {
        this.getPropertyFields();
      } else {
        if (!this.isDp || this.unId == undefined || this.localStorageService.isClaim == 'false' || (this.BE_readonly && !this.isAisReviewer() && this.state != 'new')) {
          this.readonly = true;
          this.localStorageService.readonly = "true";
        } else {
          this.readonly = false;
          this.clearReadonlyStorage();
          this.aeroInputForm.enable();
        }
      }
    });
  }

  openDataproviderDialog(): void {
    this.dataServiceProvider
      .getDataProviders()
      .pipe(
        catchError((err) => {
          if (err) {
            return EMPTY;
          }
        }),
        filter((response) => !!response),
        tap((response) => {
          this.dialog.open(DataproviderDialogComponentComponent, {
            width: "500px",
            data: {
              featureId: this.featureId,
              featureType: this.featureType,
              allowedEditors: this.allowedEditors,
              dataProviders: response,
            },
          });
        })
      )
      .subscribe();
  }

  constructFormPropertiesWithPreselectTs(): void {
    const fromRedirect$ = this.route.queryParams.pipe(
      filter((params) => params.from === "browser"),
      map((params) => ({ unId: params.unId, tsId: params.tsId }))
    );

    fromRedirect$
      .pipe(
        switchMap(({ unId, tsId }) => {
          return this.propertyService.getPropertiesOfBaseLineTS(this.featureId, unId, tsId);
        }),
        tap((data) => {
          this.featureType = data.featureType;
          this.featureAnnotationProperty = this.propertyService.jsonToProperty(
            data.annotations
          );
          if (data.effectiveDate != null) {
            this.beginPosition = this.datePipe
              .transform(data.effectiveDate, "dd MMM yyy")
              .toUpperCase();
          }
          if (data.endPosition != null) {
            this.endPosition = this.datePipe
              .transform(data.endPosition, "dd MMM yyy")
              .toUpperCase();
          }
          if (data.timesliceId != undefined) {
            this.tsId = data.timesliceId;
          }

          if (data.properties != undefined) {
            this.properties = [];
            data.properties.forEach((property, index) => {
              this.properties.push(
                this.propertyService.jsonToProperty(property)
              );

            });
            this.aeroInputForm = this.pfcs.toFormGroup(this.properties);

            // Add feature level annotation control to form group when featureAnnotationProperty is exist
            if (this.featureAnnotationProperty) {
              this.aeroInputForm.addControl('0' + '-annotations', this.pfcs.property2Control(this.featureAnnotationProperty));
            }
            console.log("count: " + this.aeroInputForm.controls.length);

            if (this.readonly == true) {
              this.aeroInputForm.disable();
            } else {
              this.aeroInputForm.enable();
            }
          }
        })
      )
      .subscribe();
  }

  getPropertiesWithSelectedTS(baselineObj: TimesliceDTO) {
    let unId;
    let tsId;
    if (baselineObj.id.includes('_')) {
      unId = baselineObj.id.split("_")[0];
      tsId = baselineObj.id.split("_")[1];
    }

    this.propertyService.getPropertiesOfBaseLineTS(this.featureId, unId, tsId).subscribe(data => {
      this.featureType = data.featureType;
      this.featureAnnotationProperty = this.propertyService.jsonToProperty(data.annotations);
      if (data.effectiveDate != null) {
        this.beginPosition = this.datePipe.transform(data.effectiveDate, 'dd MMM yyy').toUpperCase();
      }

      if (baselineObj.end != null) {
        this.endPosition = this.datePipe.transform(baselineObj.end, 'dd MMM yyy').toUpperCase();
      } else {
        this.endPosition = "";
      }

      if (data.timesliceId != undefined) {
        this.tsId = data.timesliceId;
      }

      if (data.properties != undefined) {
        this.properties = [];
        data.properties.forEach((property, index) => {
          this.properties.push(this.propertyService.jsonToProperty(property));
        });
        this.aeroInputForm = this.pfcs.toFormGroup(this.properties);

        // Add feature level annotation control to form group when featureAnnotationProperty is exist
        if (this.featureAnnotationProperty) {
          this.aeroInputForm.addControl('0' + '-annotations', this.pfcs.property2Control(this.featureAnnotationProperty));
        }

        console.log("count: " + this.aeroInputForm.controls.length);
        const state = this.state;
        const isAllowedEditor = !this.BE_readonly;
        const isExisting = state === "existing";
        const isAbleToWrite = isExisting && isAllowedEditor;
        console.log({ isAllowedEditor, state });

        if (isAbleToWrite) {
          this.readonly = false;
        }

        if (this.readonly == true) {
          this.aeroInputForm.disable();
        }
      }

      this.localStorageService.unid = unId;
      localStorage.setItem('tsid', tsId);
      localStorage.removeItem('reference');

      if (this.route.snapshot.queryParams.from) {
        const path = this.location.path().split("?")[0];
        this.location.replaceState(path);
        return;
      }
    });
  }

  isClaim() {
    this.updateNoticeService.checkTaskIsClaimedByCurrentUser(this.unId).subscribe(isClaim =>
      this.localStorageService.isClaim = String(isClaim)
    );
  }

  isAisReviewer(): boolean {
    return this.userDetailsService.hasRole(ConfigService.settings.role.role_Ais_Reviewer);
  }

  getGmlName() {
    if (this.featureId != null) {
      let tsId: string;
      if (this.featureId.includes('_')) {
        this.unId = this.featureId.split("_")[1];
        tsId = this.featureId.split("_")[2];
      }

      this.propertyService.getGmlName(this.featureId, this.unId, tsId).subscribe(gmlName => this.gmlname = gmlName);
    }
  }

  getPropertyFields() {
    console.log("fe" + this.featureId);
    let genericParam = (this.featureId == null) ? this.featureType : this.featureId;
    if (this.unId == null) {
      this.unId = " ";
    }
    let tsId: string;
    if (genericParam.includes('_')) {
      let id = genericParam;
      genericParam = genericParam.split("_")[0];
      this.unId = id.split("_")[1];
      tsId = id.split("_")[2];
    }

    this.propertyService.getPropertiesOfBaseLineTS(genericParam, this.unId, tsId).subscribe(data => {
      this.featureType = data.featureType;
      this.featureAnnotationProperty = this.propertyService.jsonToProperty(data.annotations);
      if (data.effectiveDate != null) {
        this.beginPosition = this.datePipe.transform(data.effectiveDate, 'dd MMM yyy').toUpperCase();
      }
      if (data.endPosition != null) {
        this.endPosition = this.datePipe.transform(data.endPosition, 'dd MMM yyy').toUpperCase();
      }
      if (data.timesliceId != undefined) {
        this.tsId = data.timesliceId;
      }

      if (data.properties != undefined) {
        data.properties.forEach((property, index) => {
          this.properties.push(this.propertyService.jsonToProperty(property));
        });

        this.aeroInputForm = this.pfcs.toFormGroup(this.properties);

        // Add feature level annotation control to form group when featureAnnotationProperty is exist
        if (this.featureAnnotationProperty) {
          this.aeroInputForm.addControl('0' + '-annotations', this.pfcs.property2Control(this.featureAnnotationProperty));
        }
        // console.log("count: " + this.aeroInputForm.controls.length);

        // if data is new
        // if data existing with allowed editor
        const isAllowedEditor = !this.BE_readonly;
        const isExistingData = this.state === 'existing'
        const isNewData = this.state === 'new'
        const isAbleToWrite = isNewData || (isExistingData && isAllowedEditor)
        console.log({ isAllowedEditor, isAbleToWrite, state: this.state })

        // The EFF date must be strictly before the decommissioning date to accept changes
        const accpetChanges = this.beginPosition < this.endPosition || this.endPosition != null ? true : false; 
        
        if (accpetChanges || !this.isDp || this.unId == undefined || this.localStorageService.isClaim == 'false' || !isAbleToWrite) {
          this.readonly = true;
          this.localStorageService.readonly = "true";
        } else {
          this.readonly = false;
          this.clearReadonlyStorage();
        }

        if (this.readonly == true) {
          this.aeroInputForm.disable();
        } else {
          this.aeroInputForm.enable();
        }
      }
    });
  }

  onSubmit(): void {
    let changedProperties = new Map();

    Object.keys(this.aeroInputForm.controls)
      .forEach(key => {
        let currentControl = this.aeroInputForm.controls[key];

        // If there is date, format it as "YYYY-MM-DD". Eg. AirportHeliport > CertificationDate
        let dateValue = currentControl.value[0];
        if (moment.isMoment(dateValue)) {
          currentControl.value[0] = moment(dateValue).format("YYYY-MM-DD");
        }

        if (currentControl.dirty || this.state == "new" && !key.includes("aixm:Point")) {
          changedProperties[key] = currentControl.value;
        }

        // Pass the feature level annotation value as changed properties even the control is not yet touced / dirty & the key should be present
        if (key === '0-annotations') {
          changedProperties[key] = currentControl.value
        }

        if (key.includes("aixm:Point") || key.includes("aixm:ElevatedPoint")) {
          let coordinates = this.aeroInputForm.controls[key].get("gml:pos_1").value[0];
          let nilReason = this.aeroInputForm.controls[key].get("gml:pos_1").value[1];

          let coord = coordinates.trim().replace(/  +/g, ' ').replace(/[°'"]/g, '').replace(/[ ,]+/g, ' ');
          let newCoordinates: number[];
          let regExp = new RegExp("[NSEW]");

          // if (coord.match(this.latLongRegexPattern)) {
          //   console.log("DMS")
          //   let pair: string[] = coord.split(' ').reverse();
          //   newCoordinates = pair.map(LatlongComponent.parseDms);
          // }
          // else if (coord.match(this.decimalRegex)) {
          //   console.log("Decimal Degree")
          //   if (regExp.exec(coord)) {
          //     let pair: string[] = coord.split(' ').reverse();
          //     pair[1] = pair[1].replace(/[SN]/g, '');
          //     pair[0] = pair[0].replace(/[EW]/g, '');
          //     newCoordinates = [+pair[0], +pair[1]];
          //   }
          //   else {
          //     newCoordinates = [+coord.split(' ')[1], +coord.split(' ')[0]];
          //   }
          // }
          const latLongCompInstance = new LatlongComponent();
          const result = latLongCompInstance.getCoordinatesFromLatLonRegex(coordinates.trim())
          newCoordinates = result;
          console.log("New Coordinates : " + newCoordinates)

          if (newCoordinates != undefined) {
            let latitude = newCoordinates[1];
            let longitude = newCoordinates[0];
            this.aeroInputForm.controls[key].get("gml:pos_1").setValue([latitude + " " + longitude, nilReason])
          }

          changedProperties[key] = this.aeroInputForm.controls[key].value;
        }

      });

    const handlePointChoiceProperty = (passedProperties) => {
      const walk = (passedProperties) => {
        let selectedChoiceLookup = {};
        Object.keys(passedProperties).forEach((key) => {
          if (!Array.isArray(passedProperties[key])) {
            walk(passedProperties[key]);
          } else if (
            Array.isArray(passedProperties[key]) &&
            passedProperties[key].every((value) => typeof value === "object" && !Array.isArray(value))
          ) {
            // Loop if property contains array of objects
            passedProperties[key].forEach((value) => walk(value));
          } else if (key.includes("gml:pos") && passedProperties[key]?.[0]) {
            // Handle parsing for point choice gml_pos property
            const coordinates = passedProperties[key]?.[0];
            const latLongCompInstance = new LatlongComponent();
            const result = latLongCompInstance.getCoordinatesFromLatLonRegex(coordinates.trim());
            passedProperties[key][0] = result.reverse().join(" ");
          }

          const isChoiceCtrl = key.includes("_choiceCtrl");
          const isAnnotations = key.includes('-annotations');
          if ((this.isChoiceProperty(key) || isChoiceCtrl) && !isAnnotations) {
            const isChoiceSelected = isChoiceCtrl && !!passedProperties[key][0];
            if (isChoiceSelected) {
              // store current selected choice value
              const selected = passedProperties[key][0];
              selectedChoiceLookup[selected] = true;
            }

            const notFound = !selectedChoiceLookup[key];
            // console.log(key, passedProperties[key], selectedChoiceLookup, notFound, isAnnotations);
            if ((notFound || isChoiceCtrl)) {
              delete passedProperties[key];
            }
          }
        });
      };
      walk(passedProperties);
    };

    handlePointChoiceProperty(changedProperties);

    console.log(changedProperties, '## Changed Property')

    let aixmDynamicDTO: AixmDynamicDTO = {
      unId: this.unId,
      featureId: this.featureId,
      featureType: this.featureType,
      changedProperties: changedProperties,
      decommission: false
    }
    Object.keys(this.getChangedProperties).forEach(element => {
      console.log("getChangedProperties: " + this.getChangedProperties[element]);
    });
    this.updateNoticeService.addChanges(aixmDynamicDTO).subscribe(aixm => {
      this.router.navigate(['/update/id/', this.unId]);
    });
  }

  decommission(): void {
    let aixmDynamicDTO: AixmDynamicDTO = {
      unId: this.unId,
      featureId: this.featureId,
      featureType: this.featureType,
      changedProperties: new Map(),
      decommission: true
    }

    this.updateNoticeService.addChanges(aixmDynamicDTO).subscribe(aixm => {
      this.router.navigate(['/update/id/', this.unId]);
    });
  }

  // Abandoned function
  getChangedProperties(aeroInputForm: UntypedFormGroup) {
    let parentChangedProperties = new Map();

    Object.keys(aeroInputForm.controls)
      .forEach(key => {
        let currentControl = aeroInputForm.controls[key];
        this.addNewChangedProperty(key, currentControl, parentChangedProperties);
      });

    return parentChangedProperties;
  }

  // get Changed property for a formcontrol and add it to the parent properties Map
  addNewChangedProperty(key: string, currentControl: AbstractControl, changedProperties: Map<string, object>) {
    if (currentControl.dirty) {
      changedProperties[key] = this.contol2Value(currentControl);
      console.log("addNewChangedProperty: " + key + " " + changedProperties[key]);
    }
  }

  // get form value from FormControl, FormGroup and FormArray, depending on its type
  contol2Value(currentControl: AbstractControl) {
    if (currentControl instanceof UntypedFormControl) {
      return currentControl.value;
    }
    else if (currentControl instanceof UntypedFormGroup) {
      //console.log("FormGroup: " + currentControl.getRawValue().toSource());
      return this.getChangedProperties(currentControl);
    }
    else if (currentControl instanceof UntypedFormArray) {
      let values: any[] = [];
      let valuesGroup = new Map();

      currentControl.controls.forEach(control => {
        if (control instanceof UntypedFormControl) {
          values.push(this.contol2Value(control));
          //console.log("FormArray => FormControl: " + values);
        } else if (control instanceof UntypedFormGroup) {
          //console.log("FormArray => FormGroup: " + control.getRawValue().toSource());
          valuesGroup = this.getChangedProperties(control);
        }
      });

      if (values.length == 0) {
        return valuesGroup;
      } else {
        return values;
      }
    }
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(FeatureSearchComponent, {
      width: '95vw',
      height: '95vh',
      data: {
        featureGroup: this.featureGroup,
        feature: this.feature,
        timeSlice: this.timeSlice
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.timeSlice = result;
    });
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  async createAnnotation() {
    if (!this.featureAnnotationProperty) {
      this.annotationProperty = await this.propertyService.createAnnotationProperty();

      console.log("feature level annotations:" + JSON.stringify(this.annotationProperty));

      this.aeroInputForm.addControl('0' + '-annotations', this.pfcs.property2Control(this.annotationProperty));
      // console.log("parentFormGroup with annotations: " + this.parentFormGroup.getRawValue().toSource());

    } else {
      this.annotationProperty = this.featureAnnotationProperty;
      console.log("feature level annotations:" + JSON.stringify(this.annotationProperty));
      this.aeroInputForm.addControl('0' + '-annotations', this.pfcs.property2Control(this.annotationProperty));
    }

    // this.state != 'new' && this.tsId.split("-")[0] == "BASELINE": if "BASELINE", it means it is published.
    // if the feature is new or unpublished it will be editable regardless of allowed editor.
    // if (!this.isDp || this.unId == undefined || this.localStorageService.isClaim == 'false'
    //   || (this.BE_readonly && !this.isAisReviewer() && this.state != 'new' && this.tsId.split("-")[0] == "BASELINE")) {
    //   this.readonly = true;
    //   this.localStorageService.readonly = "true";
    // } else {
    //   this.readonly = false;
    //   this.clearReadonlyStorage();
    // }

    // make form readonly again after adding featureAnnotationProperty control
    // if (this.readonly == true) {
    //   this.aeroInputForm.disable();
    // } else {
    //   this.aeroInputForm.enable();
    // }

    this.showAnnotations = true;
  }

  hideAnnotaion() {
    this.showAnnotations = false;
  }

  isDisableDecommission(): boolean {
    if (new Date(this.endPosition) < new Date() || this.state == 'new')
      return true;
    else return false;
  }

  isChoiceProperty(propertyKey: string): boolean {
    const [propertyKeyPrefix] = propertyKey.split('_')
    const regex = [
      new RegExp(`${propertyKeyPrefix}_.*?_ref_.*?`),
      new RegExp(/aixm:(\w+_\w+_\d+),aixm:(\w+)/),
    ];
    return propertyKey ? regex.some(value => value.test(propertyKey)) : false;
  }

  private clearReadonlyStorage() {
    localStorage.removeItem('readonly');
  }

}
