import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {  MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { AixmValidatorService } from '../service/aixm-validator.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validation-option',
  templateUrl: './validation-option.component.html',
  styleUrls: ['./validation-option.component.css']
})
export class ValidationOptionComponent implements OnInit {

  doValidate: boolean = false;

  aixmVersion: string;
  businessRules: string[];
  schemaProfiles: string[];
  fileName: string;

  selectedRules: string;
  selectedProfile: string;

  constructor(private http: HttpClient, private aixmValidatorService: AixmValidatorService, public router: Router, private route: ActivatedRoute) {
    this.aixmVersion = this.route.snapshot.params['aixmVersion'],
      this.fileName = this.route.snapshot.params['fileName']
  }

  ngOnInit(): void {
  }

  validate() {
    if (this.aixmValidatorService != undefined && this.selectedProfile != undefined && this.selectedRules != undefined) {
      this.doValidate = true;
    }
    this.router.navigate(['/validation-report', this.selectedProfile, JSON.stringify(this.selectedRules)]);
    // { queryParams: { businessRules: this.selectedRules}
  }

}
