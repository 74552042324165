<div [formGroup]="parentFormGroup">
  <div [ngSwitch]="propertyField.controlType" class="aero-form-field">

    <ng-container *ngSwitchCase="'textbox'">
      <div [ngSwitch]="hasUnit()">

        <form *ngSwitchCase=false [formGroup]="parentFormGroup.controls[getControlName()]">
          <mat-form-field class="main-field"
            *ngIf="propertyField.key != 'gml:pos_1' && propertyField.type == 'text' || propertyField.type == 'number' || propertyField.type == 'tel'">
            <mat-label> {{ propertyField.label }} </mat-label>
            <input #input matInput [formControlName]="0" [type]="propertyField.type"
              (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'" (input)="normalizeInput($event)" 
              [ngClass]="{'highlight-input-field': isModified()}">
            <mat-hint align="start" *ngIf="propertyFocused == 'true'">{{propertyField.hint}}</mat-hint>
            <mat-hint align="end" *ngIf="propertyFocused == 'true' && propertyField.maxLength < 10000">
              {{input.value?.length
              || 0}}/{{propertyField.maxLength}}</mat-hint>
          </mat-form-field>

          <!--  <mat-form-field class="main-field" *ngIf="propertyField.key == 'gml:pos_1'">
             <input #input matInput placeholder="Latitude" [formControlName]="0" [type]="propertyField.type"
              (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'" (input)="changeInitialState()"
              [ngClass]="{'highlight': isModified()}">
            <mat-hint align="start" *ngIf="propertyFocused == 'true'">{{propertyField.hint}} min -90 max 90</mat-hint>
            <mat-hint align="end" *ngIf="propertyFocused == 'true' || propertyField.maxLength < 9">{{input.value?.length
              || 0}}/8</mat-hint>            
          </mat-form-field> -->

          <!-- <mat-form-field class="main-field" *ngIf="propertyField.key == 'gml:pos_1'" class="fieldspace">
            <input #input matInput placeholder="Longitude" [formControlName]="1" [type]="propertyField.type"
              (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'" (input)="changeInitialState()"
              [ngClass]="{'highlight': isModified()}">
            <mat-hint align="start" *ngIf="propertyFocused == 'true'">{{propertyField.hint}} min -180 max 180</mat-hint>
            <mat-hint align="end" *ngIf="propertyFocused == 'true'">{{input.value?.length
              || 0}}/8</mat-hint>
          </mat-form-field> -->

          <mat-form-field class="main-field" *ngIf="propertyField.key == 'gml:pos_1'">
            <mat-label>Latitude Longitude</mat-label>
            <input #input matInput [formControlName]="0" [type]="propertyField.type" (focus)="propertyFocused='true'"
              (focusout)="propertyFocused='false'" (input)="changeInitialState()" [ngClass]="{'highlight': isModified()}">
            <mat-hint align="start" *ngIf="propertyFocused == 'true'">DMS or DEG</mat-hint>
            <button type="button" mat-icon-button matPrefix
              matTooltip="Geographical coordinates of the point, either in decimal degrees or degrees, minutes, seconds, decimal of seconds. Use a space between latitude and longitude.">
              <mat-icon color="primary" aria-label="Description">info</mat-icon>
            </button>
          </mat-form-field>

          <!--  <mat-form-field class="main-field" *ngIf="propertyField.key == 'gml:pos_1'">
            <latlong *ngSwitchCase="COORDINATES" [formControlName]="0" [coordinates]="[0,0]" [isHighlight]="isHighlight"
              [isSurface]="isSurface" [isFirstSegment]="isFirstSegment">
            </latlong>
          </mat-form-field>
 -->
          <mat-form-field class="main-field" *ngIf="propertyField.type == 'textarea'">
            <mat-label> {{propertyField.label}} </mat-label>
            <textarea #input matInput rows="3" [formControlName]="0"
              (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'" (input)="changeInitialState()"
              [ngClass]="{'highlight': isModified()}"></textarea>
            <mat-hint align="start" *ngIf="propertyFocused == 'true'">{{propertyField.hint}}</mat-hint>
            <mat-hint align="end" *ngIf="propertyFocused == 'true' && propertyField.maxLength < 10000">
              {{input.value?.length
              || 0}}/{{propertyField.maxLength}}</mat-hint>
          </mat-form-field>

          <mat-form-field class="main-field"
            *ngIf="propertyField.type == 'date' || propertyField.type == 'datetime-local'">
            <mat-label>{{ propertyField.label }}</mat-label>
            <input matInput [matDatepicker]="picker1" [min]="today"
              [formControlName]="0" (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'"
              (input)="changeInitialState()" [ngClass]="{'highlight': isModified()}">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker [dateClass]="dateClass" #picker1></mat-datepicker>
            <mat-hint align="start" *ngIf="propertyFocused == 'true'">"UTC Timezone"</mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="isEmpty()" class="nil-field indented">
            <mat-label>Nil Reason</mat-label>
            <mat-select [(value)]="propertyField.nilReason" [formControlName]="1"
              (selectionChange)="changeInitialState()">
              <mat-option *ngFor="let reason of nilReasons" [value]="reason.key">
                {{reason.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <!-- Use latlon component for a single pair coordinates -->
        <!--   <form *ngSwitchCase=false [formGroup]="parentFormGroup.controls[getControlName()]">
          <latlong *ngIf="propertyField.key == 'gml:pos_1'" [coordinates]="getGmlPoint()" [isHighlight]="isModified()"
            [isSurface]="isSurface" [isGmlPos]="isGmlPos" (coordinatesChange)="onStartPointCoordinatesChanged($event)">
          </latlong>
          <mat-form-field *ngIf="isEmpty() && propertyField.key == 'gml:pos_1'" class="nil-field indented">
            <mat-select placeholder="Nil Reason" [(value)]="propertyField.nilReason" [formControlName]="1"
              (selectionChange)="changeInitialState()">
              <mat-option *ngFor="let reason of nilReasons" [value]="reason.key">
                {{reason.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form> -->

        <form *ngSwitchCase=true [formGroup]="parentFormGroup.controls[getControlName()]">
          <mat-form-field class="main-field">
            <mat-label> {{propertyField.label}} </mat-label>
            <input #input matInput [formControlName]="0" [type]="propertyField.type"
              (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'" (input)="normalizeInput($event)"
              [ngClass]="{'highlight-input-field': isModifiedUnitField()}">
            <mat-hint align="start" *ngIf="propertyFocused == 'true'">{{propertyField.hint}}</mat-hint>
            <mat-hint align="end" *ngIf="propertyFocused == 'true' && propertyField.maxLength < 10000">
              {{input.value?.length
              || 0}}/{{propertyField.maxLength}}</mat-hint>
          </mat-form-field>

          <mat-form-field *ngIf="!isEmpty()" class="unit-field indented">
            <mat-label>Unit</mat-label>
            <mat-select [formControlName]="1" [ngClass]="{'highlight': isModifiedUnitField()}"
              (selectionChange)="changeInitialState()">
              <mat-option *ngFor="let unit of propertyField.unitList | OrderBy: 'label'" [value]="unit.key">
                {{unit.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="isEmpty()" class="nil-field indented">
            <mat-label>Nil Reason</mat-label>
            <mat-select [(value)]="propertyField.nilReason" [formControlName]="2"
              (selectionChange)="changeInitialState()">
              <mat-option *ngFor="let reason of nilReasons" [value]="reason.key">
                {{reason.displayValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </ng-container>

    <form *ngSwitchCase="'translatedNote'" style="display: flex;align-items: flex-start;" [formGroup]="parentFormGroup.controls[getControlName()]">
      <mat-form-field class="main-field">
        <mat-label>{{propertyField.label}}</mat-label>
        <textarea style="height: 100px;" #input matInput [formControlName]="0"
          [type]="propertyField.type" (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'"
          (input)="changeInitialState()" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
        <mat-hint align="start" *ngIf="propertyFocused == 'true'">{{propertyField.hint}}</mat-hint>
        <mat-hint align="end" *ngIf="propertyFocused == 'true' && propertyField.maxLength < 10000">{{input.value?.length
          || 0}}/{{propertyField.maxLength}}</mat-hint>
      </mat-form-field>

      <mat-form-field *ngIf="!isEmpty()" class="indented">
        <mat-label>Language</mat-label>
        <mat-select [formControlName]="1" (selectionChange)="changeInitialState()">
          <mat-option *ngFor="let language of languages | OrderBy: 'label'" [value]="language.key">
            {{language.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="isEmpty()" class="nil-field indented">
        <mat-label>Nil Reason</mat-label>
        <mat-select [(value)]="propertyField.nilReason" [formControlName]="2"
          (selectionChange)="changeInitialState()">
          <mat-option *ngFor="let reason of nilReasons" [value]="reason.key">
            {{reason.displayValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <form *ngSwitchCase="'reference'" [formGroup]="parentFormGroup.controls[getControlName()]">
      <mat-form-field class="main-field" *ngIf="!referencePropHasValue; else containsValue">
        <mat-label>{{propertyField.label}}</mat-label>
        <input ngif #input matInput [formControlName]="0" [type]="propertyField.type"
          (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'" (input)="changeInitialState()"
          readonly>
        <button type="button" mat-icon-button matSuffix (click)="selectReferencedFeature()" *ngIf="!readonly">
          <mat-icon color="primary" matTooltip="A linked feature" *ngIf="!readonly">insert_link</mat-icon>
        </button>
        <mat-hint align="start" *ngIf="propertyFocused == 'true'">{{propertyField.hint}}</mat-hint>
        <mat-hint align="end" *ngIf="propertyFocused == 'true' && propertyField.maxLength < 10000">{{input.value?.length
          || 0}}/{{propertyField.maxLength}}</mat-hint>
      </mat-form-field>
      
      <ng-template #containsValue>
        <div style="display: flex; align-items: center; gap: 8px; color: blue; cursor: pointer;">
          <div style="display: flex; align-items: center">
            <ng-container *ngIf="parentFormGroup.controls[getControlName()].at(2).value | refUrl: featureId: (!!unId ? unId : undefined) | async as link">
              <a 
                [routerLink]="!link.isBroken ? link.path: null" 
                [queryParams]="!link.isBroken && link?.queryParams ? link?.queryParams : null"
                [class.is-broken]="link.isBroken"
                target="_blank"
                class="target-ref-link">
                {{ parentFormGroup.controls[getControlName()].at(0).value || parentFormGroup.controls[getControlName()].at(2).value}}
                <mat-icon style="font-size: 12px; height: 12px; width: 12px;">open_in_new</mat-icon>
              </a>
              <ng-container *ngIf="link.isBroken">
                <ng-template #templatePortalContent>
                  <div class="error-message ref-link">
                    The referenced feature doesn't exist in this database
                  </div>
                </ng-template>
              </ng-container>
            </ng-container> 
          </div>
          <button type="button" mat-icon-button (click)="showFeatureList = true" *ngIf="!readonly">
            <mat-icon style="font-size: 18px; height: 18px; line-height: 18px;" color="primary" matTooltip="A linked feature" *ngIf="!readonly">insert_link</mat-icon>
          </button>
        </div>
      </ng-template>
      <div *ngIf="showFeatureList">
        <app-feature-type-search-component [isReference]="true" [isUn]="!!unId" referenceType="aixm" [referencedFeature]="refFeature"
          (referencePropertyValuePicked)="setToReferencePropertyValue($event)">
        </app-feature-type-search-component>
      </div>

      <mat-form-field *ngIf="isEmpty()" class="nil-field indented">
        <mat-label>Nil Reason</mat-label>
        <mat-select [(value)]="propertyField.nilReason" [formControlName]="1"
          (selectionChange)="changeInitialState()">
          <mat-option *ngFor="let reason of nilReasons" [value]="reason.key">
            {{reason.displayValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <!--  <form *ngSwitchCase="'textarea'" [formGroup]="parentFormGroup.controls[getControlName()]">
      <mat-form-field>
        <textarea #input matInput rows="5" [placeholder]="propertyField.label" [formControlName]="0"
          (focus)="propertyFocused='true'" (focusout)="propertyFocused='false'" (input)="changeInitialState()"
          [ngClass]="{'highlight': isModified()}"></textarea>
      </mat-form-field>
    </form> -->

    <form *ngSwitchCase="'textarea'">
      <geo-editor [gml]="loadGmlData()" [isSurface]="isSurface" [idPrefix]="propertyField.id"
        (gmlChange)="addGeoJsonInFormGroup($event)" (onError)="onerror($event)" [isShowMap]="isShowMap"
        [isHighlight]="isModified()" [isRevert]="isRevert" [isEmpty]="isEmpty()" (isGeoEmpty)="isSegmentEmpty($event)">
      </geo-editor>
    </form>

    <!-- In the following ngSwitchCase, 's' is added to "textarea" in order to hide it. -->
    <form *ngSwitchCase="'textareas'" [formGroup]="parentFormGroup.controls[getControlName()]">
      <!-- <geo-editor [gml]="loadGmlData()" [isSurface]="isSurface" [idPrefix]="propertyField.id"
        (gmlChange)="addGeoJsonInFormGroup($event)" [isShowMap]="isShowMap">
      </geo-editor> -->
      <mat-form-field class="invisible">
        <textarea #input matInput [formControlName]="0" readonly="true"></textarea>
      </mat-form-field>

      <mat-form-field *ngIf="isEmpty()" class="nil-field">
        <mat-label>Nil Reason</mat-label>
        <mat-select [(value)]="propertyField.nilReason" [formControlName]="1"
          (selectionChange)="changeInitialState()">
          <mat-option *ngFor="let reason of nilReasons" [value]="reason.key">
            {{reason.displayValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <form *ngSwitchCase="'dropdown'" [formGroup]="parentFormGroup.controls[getControlName()]">
      <mat-form-field>
        <mat-label> {{propertyField.label}} </mat-label>
        <mat-select #input [id]="getControlName()" [formControlName]="0"
          [ngClass]="{'highlight': isModified()}" (selectionChange)="changeInitialState()" 
          [matTooltip]="input.triggerValue" 
          [matTooltipDisabled]="input.triggerValue.length < 20">
          <ng-container *ngFor="let opt of propertyField.codeList | OrderBy: 'label'">
            <mat-option 
              [style.display]="opt.shadowValue ? 'none': 'flex'"
              [value]="opt.key"
              [matTooltip]="opt.description">
              {{ opt.label }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="isEmpty()" class="nil-field indented">
        <mat-label>Nil Reason</mat-label>
        <mat-select [(value)]="propertyField.nilReason" [formControlName]="1"
          (selectionChange)="changeInitialState()">
          <mat-option *ngFor="let reason of nilReasons" [value]="reason.key">
            {{reason.displayValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <!-- Field-specific buttons for delete, annotations and info -->
    <span class="field-buttons">
      <span class="error-message" *ngIf="!isValid && propertyField.controlType == 'textarea'">Please correct this
        value</span>

      <button type="button" mat-icon-button matTooltip="Set to nil" (click)="setToNil(); showFeatureList = false">
        <mat-icon color="warn" aria-label="Set property to nil" [ngClass]="{'disable':readonly ,'enable':!readonly}">
          delete
        </mat-icon>
      </button>
      <button type="button" mat-icon-button matTooltip="Revert to initial value" [disabled]="isInitialState"
        (click)="revert(); showFeatureList = false">
        <mat-icon color="primary" aria-label="Set property to nil"
          [ngClass]="{'disable':isInitialState, 'enable':!isInitialState}">undo</mat-icon>
      </button>
      <button type="button" mat-icon-button matTooltip="Annotations" [disabled]="readonly && !propertyField.annotations"
        *ngIf="propertyField.key.substr(0, propertyField.key.indexOf('_')) !== 'aixm:purpose' 
        && propertyField.key.substr(0, propertyField.key.indexOf('_')) !== 'aixm:note'"
        (click)="showAnnotations? hideAnnotaion(): createAnnotation()">
        <mat-icon color="primary" aria-label="Annotations"
          [ngClass]="{'disable':readonly && !propertyField.annotations, 'enable': propertyField.annotations}">note</mat-icon>
      </button>
      <button type="button" mat-icon-button matTooltip="{{propertyField.description}}">
        <!-- (click)="showInfo = !showInfo" -->
        <mat-icon color="primary" aria-label="Description">info</mat-icon>
      </button>
      <button type="button" color="primary" mat-icon-button
        *ngIf="propertyField.key == 'gml:patches_1' || propertyField.key == 'gml:segments_1'" (click)="showMap()">
        <mat-icon matTooltip="Location on map">map</mat-icon>
      </button>
      <button type="button" color="primary" mat-icon-button *ngIf="propertyField.key == 'gml:pos_1'"
        (click)="displayPointOnMap()">
        <mat-icon matTooltip="Location on map">map</mat-icon>
      </button>

    </span>

    <div *ngIf="showInfo" class="indented">
      {{propertyField.description}}
    </div>

    <div *ngIf="showAnnotations && !fromChoice" class="indented">
      <im-property-switch [property]="propertyField.annotations" [parentFormGroup]="parentFormGroup"
        [arrayName]="getControlName() + '-annotations'"></im-property-switch>
    </div>

    <div class="error-message indented" *ngIf="!isValid && propertyField.controlType != 'textarea'">Please correct this
      value</div>

    <ng-template [cdkPortalOutlet]="refLinkErrorPortal"></ng-template>
  </div>
  <!--  <div *ngIf="displayMap" class="left"><br><app-map-search></app-map-search></div>  -->
  <div *ngIf="showAnnotations && fromChoice">
    <im-property-switch [property]="propertyField.annotations" [parentFormGroup]="parentFormGroup"
      [arrayName]="getControlName() + '-annotations'"></im-property-switch>
  </div>
</div>


<!-- <div *ngIf="showNilReasonList" class="indented">
      <mat-form-field class="nil-field">
        <mat-select placeholder="Nil Reason" [(value)]="propertyField.nilReason" [formControlName]="propertyField.key">
          <mat-option *ngFor="let reason of nilReasons" [value]="reason.key">
            {{reason.displayValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->