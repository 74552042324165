import { environment } from './../../environments/environment';
import { AppAuthGuard } from './../login/services/app.authguard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Layouts } from '../app.component';
import { AixmValidatorComponent } from './aixm-validator.component';
import { ValidationHistoryComponent } from './validation-history/validation-history.component';
import { ValidationOptionComponent } from './validation-option/validation-option.component';
import { ValidationReportComponent } from './validation-report/validation-report.component';
import { ValidationStatsComponent } from './validation-stats/validation-stats.component';

const routes: Routes = [

  {
    path: 'aixm-validator', component: AixmValidatorComponent, 
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'validation-history', component: ValidationHistoryComponent, 
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'validation-option/:fileName/:aixmVersion', 
    component: ValidationOptionComponent, 
    data: { layout: Layouts.SecureLayout },
  },
  {
    path: 'validation-report/:reportFileName', 
    component: ValidationReportComponent, 
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'validation-report/:aixmVersion/:schemaProfile/:businessRules', 
    component: ValidationReportComponent, 
    data: { layout: Layouts.SecureLayout }
  },
  {
    path: 'validation-stats', 
    component: ValidationStatsComponent, 
    data: { layout: Layouts.SecureLayout }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AixmValidatorRoutingModule {
}
