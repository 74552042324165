import { UserDetailsService } from './../../login/services/UserDetails.service';
import { KeycloakService } from 'keycloak-angular';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SidenavService } from '../../service/sidenav.service';
import { TitleService } from '../../service/title.service';
import { DatastoreService } from '../../datastore/service/datastore-service';
import { ConfigService } from '../../../app/utils/config.service';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    private titleName: String = "";
    public username: string;
    public currentPageTitle: string;
    public defaultDatastore: string;
    public tenantDisplayName: string;

    private role = ConfigService.settings.role;
    private baseHelpUrl = ConfigService.settings.baseHelpUrl;

    constructor(private sidenavService: SidenavService,
        private titleService: TitleService,
        private keycloakService: KeycloakService,
        private userDetailsService: UserDetailsService,
        private datastoreService: DatastoreService,
        private render: Renderer2) { }

    ngOnInit() {
        this.username = this.userDetailsService.getUser().username;
        this.currentPageTitle = this.titleService.getTitle();
        this.defaultDatastore = this.userDetailsService.getDefaultDatastore();
        this.datastoreService.getDatastoreName().subscribe(result => this.tenantDisplayName = result);
    }

    toggleRightSidenav() {
        this.sidenavService.toggle();
    }

    logout(): void {
        this.keycloakService.logout();
    }

    // Check the current user has ROLE_FAIVA_MODULE role
    hasValidatorModuleRole(): boolean {
        return this.userDetailsService.hasRole(this.role.role_Validator_Module);
    }

    // Check the current user has ROLE_FACILIS_ORIGIN_MODULE
    hasFOModuleRole(): boolean {
        return this.userDetailsService.hasRole(this.role.role_FO_Module);
    }

    openHelp() {
        let selectedUrl = this.hasValidatorModuleRole() ? this.baseHelpUrl.faivaDocUrl : this.baseHelpUrl.xwikiUrl;
        const newWindow = window.open(selectedUrl, '_blank');
        if (newWindow) {
            newWindow.focus();
        }
    }

}
