import { ClaimdialogComponent, DialogData } from './../claimdialog/claimdialog.component';
import { QualityProjectService } from './../service/quality-project.service';
import { Component, OnInit, Inject } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-unclaimdialog',
  templateUrl: './unclaimdialog.component.html',
  styleUrls: ['./unclaimdialog.component.css']
})
export class UnclaimdialogComponent implements OnInit {
  //private result: string;

  constructor(private qaService: QualityProjectService, private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UnclaimdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  release(taskId: string): void {
    console.log("In component: Release Function: " + taskId);
   
    this.qaService.release(taskId)
    .subscribe(result => {
      //this.result = result,
      //console.log("Claimed Task: " + result);
      this.snackBar.open(result, "OK", {
        duration: 2000,
      });
    });
  }
}
