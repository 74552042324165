import { Component, OnInit } from '@angular/core';
import { ProfileVersion } from '../model/profileversion.model';
import { DataprofileService } from './../service/dataprofile.service';
import {ActivatedRoute,Router} from '@angular/router';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { SuccessVersionDialogComponent } from '../success-version-dialog/success-version-dialog.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-new-profile-version',
  templateUrl: './new-profile-version.component.html',
  styleUrls: ['./new-profile-version.component.css']
})
export class NewProfileVersionComponent implements OnInit {

  profileVersion: ProfileVersion= new ProfileVersion();
  schemaFile: File;
  fileIndex:number;
  //fileName : String;
  form: UntypedFormGroup;
  isDirty:boolean=false;
  isDeleted:boolean=false;
  isUndo:boolean=false;
  isSaved:boolean=false;
  //files: Array<File> = [];
  fileNames: string[] = [];
  fileNamesToDelete: string[] = [];
  previousVersionId: string;
  public uploader: FileUploader = new FileUploader({url: ''});

  constructor(private route: ActivatedRoute, private snackBar: MatSnackBar,  
    private router: Router, private dataProfileService: DataprofileService,
    public dialog: MatDialog,public fb:UntypedFormBuilder
  ) {
    this.form = fb.group({
      //formControlFileName : [''],
      description: ['', Validators.required],
    })
  }

  ngOnInit() {
    this.getLatestSchemaFiles();
  }

  getLatestSchemaFiles():void{
    const id = this.route.snapshot.params['id'];
    this.dataProfileService.getLatestSchemaFiles(id).subscribe(version=>{
      this.previousVersionId = version.number;
      this.fileNames = version.fileNames
      //this.form.get('formControlFileName').setValue(this.fileName);
    });

  }

  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map((fileItem) => {
      return fileItem.file;
    });
  }

  remove(item: any) {
    var index = this.uploader.queue.indexOf(item);
    console.log("The index is " + index);
    this.uploader.queue.splice(index, 1);
    if(this.uploader.queue.length==0){
      this.isDirty=false;
    }
  }

  delete(fileName: string) {
    this.isDirty=true;
    this.isDeleted=true;
    if(this.isSaved==true){
      if(this.fileNamesToDelete.length>0){
      this.fileNamesToDelete=[];
      }
    }
    this.fileNamesToDelete.push(fileName);
    this.fileNames.splice(this.fileNames.indexOf(fileName),1);
  }

  unDo(fileName: string){   
    if(this.fileNamesToDelete.length>0){
      let filename:string;
      this.fileIndex=this.fileNamesToDelete.indexOf(fileName);
      filename=this.fileNamesToDelete[this.fileIndex];
      this.fileNamesToDelete.splice(this.fileNamesToDelete.indexOf(filename),1);
      this.fileNames.push(filename);
      this.isDeleted=true;      
    } 
  }


/*   fileChange(file) {
    this.schemaFile = file.target.files[0];
    this.form.get('formControlFileName').setValue(file.target.files[0].name);
    console.log(this.form.get('formControlFileName').value);
    console.log(this.schemaFile.name);
  } */

  createNewVersion(versionFormValue):void{
    const id = this.route.snapshot.params['id'];
    //this.profileVersion.creationDate = new Date();
    let profileVersion: ProfileVersion = {
      number: null,
      description: versionFormValue.description,
      originators:null,
      profileId: null,
      creationDate: new Date(),
      fileNames:null
    }
    this.dataProfileService.addProfileVersion(id,this.previousVersionId,profileVersion,
      this.getFiles(), this.fileNames,this.fileNamesToDelete)
    .subscribe(createdVersionId => {
      let dialogRef = this.dialog.open(SuccessVersionDialogComponent, {
        width: '500px',
        data: {createdVersionId}
      });
      console.log("The created Version id is "+ createdVersionId)
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        console.log('After the dialog was closed:'+ createdVersionId);
        this.router.navigate(['/edit-profile-version/profileId/versionId', id, createdVersionId])
        //this.result = result,
        //this.ngOnInit();
      });
    });
  }

  download(fileName: string) {
    const id = this.route.snapshot.params['id'];
    this.dataProfileService.download(this.previousVersionId, fileName)
      .subscribe(blob => {
        saveAs(blob, fileName);
        this.router.navigate(['/new-profile-version/id', id])
      });
  }

  cancel(){
    const id = this.route.snapshot.params['id'];
    this.router.navigate(['/profile/id',id])
  }

}
