<div class="container">
<mat-toolbar><h3>Edit User Properties</h3></mat-toolbar>

<mat-card>
  <mat-card-content>
    <ng-container *ngIf="Uprops">       
      <div>
        <div id="left">
          <mat-card-subtitle> Module Name </mat-card-subtitle>
        </div>
        <div id="center">
            <form>
           <mat-form-field>
             <input matInput [(ngModel)]="Uprops.modulename" [ngModelOptions]="{standalone: true}">
           </mat-form-field>
           </form>
        </div>      
      </div>
      <br/>
      <br>
      <div>
          <div id="left">
              <mat-card-subtitle> Property </mat-card-subtitle>
          </div>
          <div id="center">
            <form>
               <mat-form-field >
                 <input matInput [(ngModel)]="Uprops.property" [ngModelOptions]="{standalone: true}">
               </mat-form-field>
            </form>
          </div>          
      </div>
      <br/>
      <br>
      <div>
        <div id="left">
            <mat-card-subtitle> Property Value </mat-card-subtitle>
        </div>
        <div id="center">
          <form>
             <mat-form-field >
               <input matInput [(ngModel)]="Uprops.propertyvalue" [ngModelOptions]="{standalone: true}">
             </mat-form-field>
            </form>
        </div>      
      </div>
      <br/>
      <br>
      <br>
      <mat-card-actions class="buttonspace">
        <button mat-raised-button mat-button color="primary"  
         (click)="editUserproperties()">Save</button>
        <button mat-raised-button color="primary" (click)="clear()">
          Reset
        </button>
      </mat-card-actions>
    </ng-container>
  </mat-card-content>
</mat-card>
</div>
