import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about.component';
import { Layouts } from '../app.component';

const routes: Routes = [  {
  path : 'about',
  component : AboutComponent,
  data: { layout: Layouts.SecureLayout }
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AboutRoutingModule { }
