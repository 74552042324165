<mat-toolbar>
    <span>
        <h3>Switch datastore</h3>
    </span>
</mat-toolbar>

<mat-card>

    <mat-card-content>
        <mat-form-field appearance="fill">
            <mat-label>Datastore</mat-label>
            <mat-select [(value)]="selectedDatastore">
                <mat-option *ngFor="let datastore of datastoreList" [value]="datastore.name">
                    {{datastore.displayName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div>
            <button mat-raised-button color="primary" class="example-btn-width-blue button-size"
                (click)="saveDatastore()" [disabled]="selectedDatastore === ''">
                Save
            </button>
        </div>
    </mat-card-content>

</mat-card>