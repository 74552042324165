import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AixmValidatorComponent } from "./aixm-validator.component";
import { RouterModule } from "@angular/router";
import { AixmValidatorRoutingModule } from "./aixm-validator-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppMaterialModule } from "../app.material.module";
import { FileUploadModule } from "ng2-file-upload";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { InMemoryDataService } from "../service/in-memory-data.service";
import { AixmValidatorService } from "./service/aixm-validator.service";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { ValidationHistoryComponent } from "./validation-history/validation-history.component";
import { ValidationOptionComponent } from "./validation-option/validation-option.component";
import { ValidationReportComponent } from "./validation-report/validation-report.component";
import { ExportDatasetDialogComponent } from "./export-dataset-dialog/export-dataset-dialog.component";
import { ValidationStatsComponent } from "./validation-stats/validation-stats.component";
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from "ng2-charts";

@NgModule({
  declarations: [
    AixmValidatorComponent,
    ValidationHistoryComponent,
    ValidationOptionComponent,
    ValidationReportComponent,
    ValidationStatsComponent,
    ExportDatasetDialogComponent,
  ],
  exports: [RouterModule],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    FileUploadModule,
    AppMaterialModule,
    MatSortModule,
    MatTableModule,
    AixmValidatorRoutingModule,
    BaseChartDirective
  ],
  providers: [
    InMemoryDataService,
    AixmValidatorService,
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables()),
  ],
})
export class AixmValidatorModule {}
