import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import {  MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-export-dataset-dialog',
  templateUrl: './export-dataset-dialog.component.html',
  styleUrls: ['./export-dataset-dialog.component.css']
})
export class ExportDatasetDialogComponent implements OnInit {
  onConfirm = new EventEmitter();
  constructor(public dialogRef: MatDialogRef<ExportDatasetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  confirm(): void {
    this.onConfirm.emit("download");
  }

}
