<mat-card>
  <mat-card-content>
    <form #f="ngForm" name="newProjectForm">
      <div class="project">
        <h2>{{!isEdit ? 'New Project' : 'Edit Project'}}</h2>
      </div>
      <mat-form-field>
        <input [disabled]="isEdit" matInput placeholder="Name" name="name" [(ngModel)]="qualityProject.projectName" required>
      </mat-form-field>

      <div class="text">
        Work flow
      </div>
      <div class="cb-wrapper">
        <mat-checkbox [disabled]="isEdit" name="hasDataInput" [(ngModel)]="checkedhasDataInput">Data Input task</mat-checkbox>
        <mat-checkbox [disabled]="isEdit" name="hasTextEdition" [(ngModel)]="checkedhasTextEdition">Text Edition task</mat-checkbox>
        <!-- <mat-checkbox name="has2ndReview" [(ngModel)]="qualityProject.has2ndReview"> 2nd review task</mat-checkbox> -->
      </div>

      <div class="text">
        Items
      </div>
      <div class="justify">
        <input [disabled]="isEdit" type="file" name="items" (change)="uploadSection($event)" multiple required>
        <div class="horizontal" *ngFor='let item of items' height="200">
          {{item.name}}
          <button mat-icon-button (click)="remove(item)">
            <i class="material-icons">delete</i></button>
        </div>
      </div>
      <ng-container *ngIf="isEdit">
        <div class="text">Setting</div>
        <div class="cb-wrapper" [formGroup]="projectSetting">
          <mat-checkbox formControlName="use4eyes">Use four eyes</mat-checkbox>
        </div>
      </ng-container>
      <mat-card-actions class="justify">
        <button type="submit" mat-raised-button mat-button color="primary" (click)="!isEdit? createNewProject() : saveProject()"
          class="example-btn-width" [disabled]="(!f.valid || f.prestine) && !isEdit">{{!isEdit  ? 'Create' : 'Save'}} Project</button>
        <button mat-raised-button mat-button color="primary" class="example-btn-width" (click)="cancel()">Cancel</button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>