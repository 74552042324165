import { GmlPointType } from "./GmlPointType";

/**
 * Our data about a point
 */
export class GeoPoint {
  
  type: GmlPointType;
  annotation: string;
  ref: string;

  constructor(options: {type?: GmlPointType, annotation?: string, ref?: string}) {
    this.type = options.type? options.type : GmlPointType.COORDINATES;
    this.annotation = options.annotation;
    this.ref = options.ref;
  }

  //static 
}