<div>
  
  <mat-dialog-content>
  Profile Version is successfullly created
  </mat-dialog-content>
  <div mat-dialog-actions align="center">
  
  <button mat-raised-button mat-button color="primary" class="example-btn-width" (click)="Success()">Ok</button>

  </div>
</div>