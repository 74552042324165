<div class="container">
  <mat-card>

    <mat-card-header>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" #filter>
        <mat-icon matSuffix (click)="applyFilter('');filter.value=''" style="cursor:pointer">clear</mat-icon>
      </mat-form-field>
    </mat-card-header>

    <mat-card-content>
      <mat-table #table [dataSource]="users_dataSource" matSort>

        <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="userName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> User Name </mat-header-cell>
          <mat-cell *matCellDef="let user"><a [routerLink]="['/user/detail', user.id]">{{user.userName}}</a></mat-cell>
        </ng-container>

        <!-- First Name Column -->
        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.firstName}}</mat-cell>
        </ng-container>

        <!-- Last Name Column -->
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.lastName}}</mat-cell>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
          <mat-cell *matCellDef="let user">{{user.email}}</mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="enabled">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.enabled == true ? 'Active' : 'Block' }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <mat-tab-group mat-stretch-tabs="false">
        <mat-tab>
          <div class="justify">
            <i class="material-icons md-48" [routerLink]="['/create-user']" style="cursor:pointer"
              matTooltip="Add new user">add_circle</i>
          </div>
        </mat-tab>

        <mat-tab label="Groups">
          <app-group-list></app-group-list>
          <div class="justify">
            <i class="material-icons md-48" [routerLink]="['/create-group']" style="cursor:pointer"
              matTooltip="Add new role">add_circle</i>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>

    <mat-card-actions>
      <p class="right"><i class="material-icons md-48" [routerLink]="['/create-user']" style="cursor:pointer"
          matTooltip="Add new role">add_circle</i></p>
    </mat-card-actions>

    <mat-card-footer>
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-footer>

  </mat-card>
</div>