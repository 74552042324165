import { User } from './../model/user.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EditUserDTO } from '../model/EditUserDTO';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { GroupSummaryDTO } from '../model/groupSummaryDTO';
import { EditGroupDTO } from '../model/editGroupDTO';
import { UserSummaryDto } from '../model/UserSummaryDto';
import { CreateUserDTO } from '../model/CreateUserDTO';
import { CreateGroup } from '../model/create-group-model';
import { ConfigService } from '../../utils/config.service';

const httpOptions = {
  headers: new HttpHeaders()
};

@Injectable()
export class UserManageMentService {

  // update api url
  private apiServerUrl = ConfigService.settings.apiServer;

  private createUserUrl = this.apiServerUrl.url + '/user/createuser';
  private createGroupUrl = this.apiServerUrl.url + '/group/creategroup';
  private userListUrl = this.apiServerUrl.url + '/user/list';
  private userUrl = this.apiServerUrl.url + '/user';

  private groupUrl = this.apiServerUrl.url + '/group/list';
  private groupDetailUrl = this.apiServerUrl.url + '/group/group-detail';
  private editGroupUrl = this.apiServerUrl.url + '/group/editGroup';
  private deleteGroupUrl = this.apiServerUrl.url + '/group/deleteGroup';
  private resetPwdUrl = this.apiServerUrl.url + '/user/resetpassword';

  constructor(private http: HttpClient) { }

  createNewUser(user: CreateUserDTO): Observable<CreateUserDTO> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("The user in service is " + user.userName + "" + user.firstName + "" +
      user.lastName + "" + user.password)
    return this.http.post<CreateUserDTO>(this.createUserUrl, user, requestOptions).pipe(
      tap((user: CreateUserDTO) => catchError(this.handleError<CreateUserDTO>('adduser'))));
  }

  /** GET Users from the server */
  getUsers(): Observable<UserSummaryDto[]> {
    return this.http.get<UserSummaryDto[]>(this.userListUrl)
      .pipe(tap(data => console.log(data), catchError(this.handleError('getUsers', []))
      ));
  }

  /** GET user By ID from the server */
  getUserById(userId: string): Observable<UserSummaryDto> {
    const url = `${this.userUrl}/${userId}`;
    return this.http.get<UserSummaryDto>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getUserById', error))
      ));
  }

  resetPasswordById(userId: string, user: User): Observable<User> {
    let requestOptions: Object = {
      /*other options here */
      responseType: 'text'
    }
    const url = `${this.resetPwdUrl}/${userId}`;
    return this.http.post<User>(url, user, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getUserById', error))
      ));
  }

  /** Assign role to group */
  assignRole(groupId: string, roles: string[]): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }

    const url = `${this.groupUrl}/assignRole/${groupId}`;
    return this.http.post<string>(url, roles, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('assignRole', error))
      ));
  }


  /** save edited user to the server */
  saveUser(user: EditUserDTO): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    return this.http.put<string>(this.userUrl, user, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('saveUser', error))
      ));
  }

  createGroup(group: CreateGroup): Observable<String> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    return this.http.post<String>(this.createGroupUrl, group, requestOptions).pipe(
      tap((group: String) => catchError(this.handleError<String>('addgroup'))));
  }

  getGroups(): Observable<GroupSummaryDTO[]> {
    return this.http.get<GroupSummaryDTO[]>(this.groupUrl)
      .pipe(tap(data => console.log(data), catchError(this.handleError('getGroups', []))
      ));
  }


  /** GET group By ID from the server */
  getGroupById(groupId: string): Observable<EditGroupDTO> {
    const url = `${this.groupDetailUrl}/${groupId}`;
    return this.http.get<EditGroupDTO>(url)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('getGroupById', error))
      ));
  }

  updateGroup(editGroup: EditGroupDTO): Observable<String> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    console.log("The edited group name is " + editGroup.groupName)
    return this.http.put<String>(this.editGroupUrl, editGroup, requestOptions).pipe(
      tap((group: String) => catchError(this.handleError<String>('editgroup'))));
  }

  /** Join user to group */
  joinGroup(userId: string, groupId: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }

    const url = `${this.userUrl}/${userId}`;
    return this.http.put<string>(url, groupId, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('joinGroup', error))
      ));
  }

  /** Leave user from group */
  leaveGroup(userId: string, groupId: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }

    const url = `${this.userUrl}/leavegroup/${userId}`;
    return this.http.put<string>(url, groupId, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('leaveGroup', error))
      ));
  }

  /** Unassign role from group */
  unassignRole(groupId: string, roles: string[]): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    const url = `${this.groupUrl}/unassignRole/${groupId}`;
    return this.http.put<string>(url, roles, requestOptions)
      .pipe(tap(data => console.log(data),
        error => catchError(this.handleError('unassignRole', error))
      ));
  }

  deleteGroup(id: string): Observable<string> {
    let requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    const url = `${this.deleteGroupUrl}/${id}`;
    return this.http.get<string>(url, requestOptions).pipe(
      tap((group: string) => catchError(this.handleError<string>('deleteGroup'))));
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
