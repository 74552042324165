<div>
  <mat-form-field class="text-search">
    <input matInput #coordinate type="search" placeholder="Find by latitude and longitude...">
  </mat-form-field>
  <button mat-flat-button color="primary" (click)="pointSearch(coordinate.value)" [disabled]="!coordinate.value">Search</button>
  <mat-form-field class="space form-width">
    <input matInput [matDatepicker]="picker" #effectivedate [min]="today">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
  </mat-form-field>
  <button mat-flat-button class="space" color="primary" (click)="displayOfficialData(effectivedate.value)" [disabled]="!effectivedate.value">Search</button>
  <div id="map" class="map"></div>    
</div>
